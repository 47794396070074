import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';
import {GetResourcesFilter} from "../../../repositories/workerRepository";

type ARGS = {
	companyId: string;
	filter?: GetResourcesFilter;
	sort?: SortMeta;
};

class GetAvailableMachinesCount implements UseCase<ARGS, number> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, filter, sort }: ARGS): Promise<number> {
		return await this.repository.getAvailableMachinesCount(companyId, filter, sort);
	}
}

export default GetAvailableMachinesCount;
