import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import {
  getSectionPermissions,
  Permission,
} from "../../Permissions/Permissions";
import { useHasPermissions } from "../../Permissions/RenderIf";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface WorkingSitesDetailTopBarProps {
  emptyItems?: boolean;
}

const WorkingSitesDetailsTopBar = ({
  emptyItems = false,
}: WorkingSitesDetailTopBarProps) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const hasDetailsPermission = useHasPermissions( [Permission.Worksite_ShowDetails, Permission.Worksite_ShowSettings ], PermissionCheck.Some)
  const workingSiteResourcesPermissions = [
    Permission.Worksite_ShowWorkers,
    Permission.Worksite_ShowVehicles,
    Permission.Worksite_ShowMachines,
    Permission.Worksite_ShowTools,
    Permission.Worksite_ShowChemicals
  ];
  const items: TopBarItem[] = [
      {
        title: t("summary"),
        path: `/workingsites/${siteId}/site`,
        permissions: [Permission.Worksite_ShowDetails, Permission.Worksite_ShowSettings],
        hidden: !hasDetailsPermission
      },
      {
        title: t("resources"),
        path: `/workingsites/${siteId}/resources`,
        permissions: workingSiteResourcesPermissions,
      },
      {
        title: t("documents"),
        path: `/workingsites/${siteId}/documents`,
        permissions: [Permission.Worksite_ShowReq],
      },
      {
        title: t("subcontract", { ns: "supplier" }),
        path: `/workingsites/${siteId}/subcontractors`,
        permissions: [Permission.Worksite_ShowSubcontractors],
      },
    ];

  return (
    <TopBar
      title={t("site")}
      items={emptyItems ? [] : items}
      backPath="/workingsites"
    />
  );
};

export default WorkingSitesDetailsTopBar;
