import { makeMachineRepository } from './repositories';
import GetMachine from '../../../../domain/interactors/resources/machines/getMachine';
import GetMachines from '../../../../domain/interactors/resources/machines/getMachines';
import CreateMachine from '../../../../domain/interactors/resources/machines/createMachine';
import UpdateMachine from '../../../../domain/interactors/resources/machines/updateMachine';
import DeleteMachine from '../../../../domain/interactors/resources/machines/deleteMachine';
import GetMachineDocuments from '../../../../domain/interactors/resources/machines/getMachineDocuments';
import CreateMachineDocument from '../../../../domain/interactors/resources/machines/createMachineDocument';
import AddTypologyToMachine from '../../../../domain/interactors/resources/machines/addTypologyToMachine';
import RemoveTypologyFromMachine from '../../../../domain/interactors/resources/machines/removeTypologyFromMachine';
import UploadMachines from '../../../../domain/interactors/resources/machines/uploadMachines';
import CreatePropagableMachineDocument from '../../../../domain/interactors/resources/machines/createPropagableMachineDocument';
import EvaluateMachineDocument from '../../../../domain/interactors/resources/machines/evaluateMachineDocument';
import GetAvailableMachinesCount from '../../../../domain/interactors/resources/machines/getAvailableMachinesCount';
import RestoreMachine from '../../../../domain/interactors/resources/machines/restoreMachine';
import RepropagateEvaluationMachineDocument from '../../../../domain/interactors/resources/machines/repropagateEvaluationMachineDocument';

export const makeGetMachines = () => {
	return new GetMachines(makeMachineRepository());
};

export const makeRestoreMachine = () => {
	return new RestoreMachine(makeMachineRepository());
};

export const makeGetAvailableMachinesCount = () => {
	return new GetAvailableMachinesCount(makeMachineRepository());
};

export const makeGetMachine = () => {
	return new GetMachine(makeMachineRepository());
};

export const makeCreateMachine = () => {
	return new CreateMachine(makeMachineRepository());
};
export const makeUploadMachines = () => {
	return new UploadMachines(makeMachineRepository());
};

export const makeUpdateMachine = () => {
	return new UpdateMachine(makeMachineRepository());
};

export const makeDeleteMachine = () => {
	return new DeleteMachine(makeMachineRepository());
};

export const makeGetMachineDocuments = () => {
	return new GetMachineDocuments(makeMachineRepository());
};

export const makeCreateMachineDocument = () => {
	return new CreateMachineDocument(makeMachineRepository());
};

export const makeCreatePropagableMachineDocument = () => {
	return new CreatePropagableMachineDocument(makeMachineRepository());
};

export const makeAddTypologyToMachine = () => {
	return new AddTypologyToMachine(makeMachineRepository());
};

export const makeRemoveTypologyFromMachine = () => {
	return new RemoveTypologyFromMachine(makeMachineRepository());
};

export const makeEvaluateMachineDocument = () => {
	return new EvaluateMachineDocument(makeMachineRepository());
};

export const makeRepropagateEvaluationMachineDocument = () => {
	return new RepropagateEvaluationMachineDocument(makeMachineRepository());
};
