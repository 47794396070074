import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useSteps
} from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheckSquare, FaWindowClose } from "react-icons/fa";
import DocumentType from "../../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import DocumentTypeWithPublic from "../../../../domain/entities/documentTypeWithPublic";
import { Identifiable } from "../../../../domain/entities/interfaces/identifiable";
import { Named } from "../../../../domain/entities/interfaces/named";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Requirement from "../../../../domain/entities/requirement";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import Site from "../../../../domain/entities/site";
import Variant from "../../../../domain/entities/variant";
import { GetSitesFilter } from "../../../../domain/repositories/siteRepository";
import { UseAddDocumentType } from "../../../hooks/Document/useAddDocumentType";
import { UpdateFilter } from "../../../hooks/Requirements/useRequirementsViewModel";
import TagList from "../../../screens/Common/TagList";
import { InfoBanner } from "../../Common/alerts/InfoBanner";
import SelectedTagList from "../../Common/SelectedTagList";
import Steps from "../../Common/stepper/Steps";
import CellPlusButton from "../../Common/table/CellPlusButton";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import TextInput from "../../Common/TextInput";
import { DocumentTypeWithRequirement } from "./AddDocumentTypesModal";
import AddVariantsAndSpecializationsToRequirementModal from "./AddVariantsAndSpecializationsToRequirementModal";
import SelectSitesView from "./SelectSitesView";

interface CreateRequirementModalProps extends UseAddDocumentType {
    onClose: () => void;
    onConfirm: (
        documentTypes: (
            | DocumentType
            | DocumentTypeWithRequirement
            | DocumentTypeWithPublic
        )[],
        siteIds: string[],
        selectAllSites?: boolean
    ) => void;
    isRequirement?: boolean;
    isPropagable?: boolean;
    title?: string;
    isFetching?: boolean;
    documentTypesHasNextPage: boolean;
    documentTypesFetchNextPage: () => void;
    requirements?: Requirement[];
    variants?: Variant[];
    specializations?: Variant[];
    propagableSites?: Site[];
    propagableSitesHasNextPage?: boolean,
    propagableSitesFetchNextPage?: () => void,
    propagableSitesCount?: number,
    propagableSitesIsFetching?: boolean,
    updatePropagableSites?: UpdateFilter,
    propagableFilterSites?: GetSitesFilter,
    setSortSites?: (value: SortMeta) => void,
    sortSites?: SortMeta,
    showSiteSelection?: boolean;
    isWorkingSite?: boolean;
    systemDocumentTypesFetchNextPage: () => void;
    systemDocumentTypesHasNextPage: boolean;
    isAddingDocumentsApi?: boolean;
    enableVariantSpecSelection?: boolean;
    showAvailableDocuments?: boolean;
    requirementSubject?: RequirementSubject;
    supplierRequirementSource?: RequirementSubject;
    setSupplierRequirementSource?: (value: RequirementSubject) => void;
    setHookDocumentCategory: (category: DocumentTypeCategory) => void;
    createVariant?: (variant: Variant) => Promise<Variant>;
    createSpecialization?: (specialization: Variant) => Promise<Variant>
}

const initData = {
    isOptional: true,
    graceDays: 0,
    variants: [],
    specializations: [],
};
const CreateRequirementModal = ({
    onConfirm,
    onClose,
    isRequirement,
    isPropagable,
    isFetching,
    documentTypes: initialTypes,
    documentTypesHasNextPage,
    documentTypesFetchNextPage,
    getDocumentTypes,
    getSystemDocTypes,
    setGetSystemDocTypes,
    search,
    setSearch,
    updateFilters,
    filters,
    allTags,
    sort,
    setSort,
    requirements,
    variants,
    specializations,
    propagableSites,
    propagableSitesHasNextPage,
    propagableSitesFetchNextPage,
    propagableSitesCount,
    propagableSitesIsFetching,
    propagableFilterSites,
    updatePropagableSites,
    setSortSites,
    sortSites,
    isWorkingSite,
    systemDocumentTypesFetchNextPage,
    systemDocumentTypesHasNextPage,
    isAddingDocumentsApi,
    enableVariantSpecSelection,
    showAvailableDocuments = true,
    requirementSubject,
    supplierRequirementSource,
    setSupplierRequirementSource,
    setHookDocumentCategory,
    createVariant,
    createSpecialization
}: CreateRequirementModalProps) => {
    const { t } = useTranslation("requirements");
    const mapTypes = useMemo(() => {
        if (isRequirement) {
            return initialTypes?.map((documentType) => ({
                ...documentType,
                ...initData,
            }));
        }
        if (isPropagable) {
            return initialTypes?.map((documentType) => ({
                ...documentType,
                isPublic: false,
            }));
        }
        return initialTypes;
    }, [isRequirement, isPropagable, initialTypes]);

    const [documentTypes, setDocumentTypes] = useState<DocumentType[] | DocumentTypeWithRequirement[] | DocumentTypeWithPublic[]>(mapTypes);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState<DocumentType[] | DocumentTypeWithRequirement[] | DocumentTypeWithPublic[]>([]);
    const [editRequirementVariantsAndSpecializations, setEditRequirementVariantsAndSpecializations,] = useState<false | DocumentTypeWithRequirement>(false);
    const [showSelectSites, setShowSelectSites] = useState(false);
    const [showVariantSelection, setShowVariantSelection] = useState(false);
    const [siteIds, setSiteIds] = useState<string[]>([]);
    const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);
    const [selectAllSites, setSelectAllSites] = useState<boolean>(false);

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 2,
    });
//NM:I removed the option to make the requirement public/private because with this
// modal a requirement is added, and this entity does not have any public/private status (only documents can be public/private).
  const columns: ({ field: string; label: string; type?: "text" | "tags" } )[] = [
        { field: "name", label: t("name", { ns: "common" }), type: "text" },
        { field: "tags", label: t("tags", { ns: "common" }), type: "tags" },
        ...(showAvailableDocuments && isRequirement
            ? [
                {
                    field: "availableDocuments",
                    label: t("availableDocuments", { ns: "requirements" }),
                },
            ]
            : []),
        ...(isRequirement
            ? [{ field: "graceDays", label: t("graceDays", { ns: "requirements" }) }]
            : []),
        ...(isRequirement
            ? [
                {
                    field: "isOptional",
                    label: t("isOptional", { ns: "requirements" }),
                },
            ]
            : []),
    ];

    const handleSwitch = (
        documentType: DocumentTypeWithRequirement,
        value: boolean,
    ) => {
        setDocumentTypes((prev) =>
            prev.map((prevDocumentType) =>
                prevDocumentType.id === documentType.id
                    ? { ...prevDocumentType, isOptional: value }
                    : prevDocumentType,
            ),
        );
    };

    const handleGraceDays = (
        documentType: DocumentTypeWithRequirement,
        value: number,
    ) => {
        setDocumentTypes((prev) =>
            prev.map((prevDocumentType) =>
                prevDocumentType.id === documentType.id
                    ? { ...prevDocumentType, graceDays: parseInt(value.toString()) }
                    : prevDocumentType,
            ),
        );
    };

    const handleRemoveElement = (element: Identifiable & Named) => {
        setSelectedDocumentTypes((prev) =>
            prev.filter((prevElement) => prevElement.id !== element.id),
        );
    };

    const handleSelectDocumentType = (
        documentType: DocumentType | DocumentTypeWithRequirement,
    ) => {
        // if it's not a requirement there is no need for variant and specs selection
        if (!enableVariantSpecSelection) {
            setSelectedDocumentTypes((prev) => [...prev, documentType]);
            return;
        }

        if (isRequirement && requirements && variants && specializations) {
            if (requirements.some((r) => r.documentType?.id === documentType.id)) {
                // we need to select variants and specialization avoiding those already assigned to the same document type
                setShowVariantSelection(true);
                setEditRequirementVariantsAndSpecializations(
                    documentType as DocumentTypeWithRequirement,
                );
                return;
            }
        }
        setShowVariantSelection(true);
        setEditRequirementVariantsAndSpecializations(
            documentType as DocumentTypeWithRequirement,
        );
        setShowInfoBanner(true);
    };

    useEffect(() => {
        if (isWorkingSite) {
            getSystemDocTypes();
            setGetSystemDocTypes(true);
        } else {
            getDocumentTypes();
        }
    }, []);

    useEffect(() => {
        setDocumentTypes(mapTypes);
    }, [mapTypes]);

    const updateSiteIds = (siteIds: string[]) => {
        setSiteIds(siteIds);
    };

    const excludeSort = new Set(["graceDays", "isOptional", "tags"]);
    const [isMobile] = useMediaQuery("(max-width: 767px)");

    //in case of supplier category we show an additional first step to choose between company and site source
    const stepperSteps = [
        ...(requirementSubject === RequirementSubject.SUPPLIER ? [{ title: t("requirementsSource") }] : []),
        { title: t("requirementsList") },
        ...(isPropagable ? [{ title: t("requirementSites") }] : []),
    ];

    const handleSourceSelection = (e) => {
        const source = e.target.value;
        setSupplierRequirementSource(source);
        if(!source?.includes(t("selectSource"))) {
           setHookDocumentCategory(source as DocumentTypeCategory);
        }else if(source?.includes(t("selectSource"))) {
           setSupplierRequirementSource(undefined);
        }
    };

    const handleNext = async () => {
        switch (activeStep) {
            case 0:
                if(requirementSubject === RequirementSubject.SUPPLIER ) {
                    setShowSelectSites(true)
                    return setActiveStep(activeStep + 1)
                }else if([
                    RequirementSubject.VEHICLE,
                    RequirementSubject.TOOL,
                    RequirementSubject.WORKER,
                    RequirementSubject.CHEMICAL,
                    RequirementSubject.MACHINE
                ].includes(requirementSubject) && !location.pathname.includes('/sites/')){
                    setShowSelectSites(true)
                    return setActiveStep(activeStep + 1)
                }else
                {
                    return onConfirm(selectedDocumentTypes, siteIds, selectAllSites)
                }
            case 1:
                if (requirementSubject === RequirementSubject.SUPPLIER && isPropagable) {
                    setActiveStep(activeStep + 1);
                } else {
                    onConfirm(selectedDocumentTypes, siteIds, selectAllSites);
                }
                break;
            case 2:
                // in this case we will select variants and specializations just for one times and apply those to every resource
                onConfirm(selectedDocumentTypes, siteIds, selectAllSites);
                break;
        }
    };
    const handleCancel = () => {
        switch (activeStep) {
            case 0:
                onClose();
                break;
            case 1:
                setActiveStep(0);
                break;
            case 2:
                setActiveStep(0);
                break;
        }
    };


    const checkConfirmEnabled = () => {
        switch (activeStep) {
            case 0:
                if (requirementSubject === RequirementSubject.SUPPLIER) {
                    return supplierRequirementSource === undefined;
                } else {
                    return selectedDocumentTypes?.length === 0;
                }
            case 1:
                if (requirementSubject === RequirementSubject.SUPPLIER) {
                    return selectedDocumentTypes?.length === 0;
                } else {
                    return false
                }
            case 2:
                return false
            default:
                return true;
        }
    };

    /**
     *
     * @returns the view that let the user choose for the source of the supplier requirement
     */
    const sourceSelection = (): ReactNode => {
        return (
            <Flex flexDirection={"column"} width={"100%"}>
                <Text fontSize="xl" fontWeight={500}>
                    {t("requirementsSource")}
                </Text>
                <Text fontSize="sm" mb={5}>
                    {t("requirementsSourceSub")}
                </Text>
                <Select onChange={handleSourceSelection} value={supplierRequirementSource} width={"100%"}>
                    <option value={null}>{t("selectSource")}</option>
                    <option key={"company"} value={"company"}>
                        {t("companyType")}
                    </option>
                    <option key={"site"} value={"site"}>
                        {t("siteType")}
                    </option>
                </Select>
            </Flex>
        );
    };

    /**
     *
     * @returns the component showing the list of sites where the document can be propagated
     */
    const propagationSitesList = (): ReactNode => {
        return (
            <SelectSitesView
                autosize
                title={t("selectSitesCreateRequirement", { ns: "requirements" })}
                reminderText={t("noSiteSelected", { ns: "common" })}
                alertText={t("siteSelectionAlert", { ns: "common" })}
                siteSelectedAction={(siteIds) => {
                    return updateSiteIds(siteIds);
                }}
                showSelectAll={true}
                siteList={propagableSites}
                hasNextPage={propagableSitesHasNextPage}
                fetchNextPage={propagableSitesFetchNextPage}
                siteCount={propagableSitesCount}
                isFetchingSites={propagableSitesIsFetching}
                filterResourceSites={propagableFilterSites}
                updateFilterResourceSites={(field, value) => {
                    updatePropagableSites(field, value);
                }}
                setSortResourceSites={setSortSites}
                sortResourceSites={sortSites}
                selectAllSites={selectAllSites}
                setSelectAllSites={setSelectAllSites}
                includeFooterButton={false}
            />
        );
    }

    /**
     *
     * @returns the list of document types that can be choosen to create a requirement
     */
    const requirementsList = (): ReactNode => {
        return (
            <Flex flexDirection={"column"} height={"100%"} width={"100%"}>
                <Text fontSize="xl" fontWeight={500}>
                    {t("requirementsList")}
                </Text>
                <Text fontSize="sm" mb={5}>
                    {t("createRequirementFromDocuType")}
                </Text>
                <TextInput
                    value={search}
                    onChange={setSearch}
                    placeholder={t("search", { ns: "common" })}
                />
                {selectedDocumentTypes.length > 0 && showInfoBanner && (
                    <InfoBanner
                        text={t("propagateRequirementInfo", { ns: "documents" })}
                    />
                )}

                {isWorkingSite && (
                    <InfoBanner
                        text={t("deleteRequirementsInfo", { ns: "documents" })}
                    />
                )}
                <Box mb={5}>
                    <SelectedTagList
                        elements={selectedDocumentTypes}
                        onRemove={handleRemoveElement}
                    />
                </Box>
                    <Flex
                      flexDirection="column"
                      alignItems="start"
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="10px"
                      width={isMobile ? "calc(100vw - 120px)" : "100%"}
                      position="relative"
                      overflow="auto"
                      id="table-container-modal"
                    >
                        <InfiniteTable
                          minHeight={300}
                          autosize
                          tableId="document-types-table-add-modal"
                          bottomThreshold={250}
                          infiniteScroll={{
                            dataLength: documentTypes.length,
                            hasNextPage: isWorkingSite
                                ? systemDocumentTypesHasNextPage
                                : documentTypesHasNextPage,
                            fetchNextPage: isWorkingSite
                                ? systemDocumentTypesFetchNextPage
                                : documentTypesFetchNextPage,
                          }}
                          isLoading={isFetching}
                          showEmptyText={
                            documentTypes.filter(
                              (dt) =>
                                !selectedDocumentTypes.some(
                                  (sdt) => sdt.id === dt.id,
                                ),
                            ).length === 0
                          }
                          emptyText={t("noDocumentTypes", { ns: "documents" })}
                        >
                          <Thead>
                            <Tr>
                              {columns.map((column) => (
                                <Th width={100} key={column.field}>
                                  <TableColumnHeader
                                    text={column.label}
                                    filter={
                                      column.type
                                        ? {
                                          isActive:
                                            !!filters[column.field] &&
                                            (!Array.isArray(filters[column.field]) ||
                                              !!filters[column.field][0]),
                                          component: (
                                            <ColumnFilterComponent
                                              tags={allTags}
                                              value={filters[column.field]}
                                              type={column.type}
                                              updateFilter={(value) => {
                                                updateFilters(column.field,
                                                  value as string | string[]
                                                )
                                              }}
                                            />
                                          ),
                                        }
                                        : undefined
                                    }
                                    sort={
                                      excludeSort.has(column.field)
                                        ? undefined
                                        : {
                                            handler: (direction) =>
                                                setSort({
                                                    field: column.field,
                                                    direction,
                                                }),
                                            direction:
                                                sort?.field === column.field
                                                    ? sort.direction
                                                    : null,
                                          }
                                    }
                                    isInModal
                                  />
                                </Th>
                              ))}
                              <Th width={100} />
                            </Tr>
                          </Thead>
                          <Tbody>
                            {documentTypes
                              .filter(
                                (dt) =>
                                  !selectedDocumentTypes.some(
                                      (sdt) => sdt.id === dt.id,
                                  ),
                              )
                              .map((documentType) => (
                                <Tr key={documentType?.id}>
                                  <Tooltip
                                    label={documentType?.description}
                                    placement="bottom-start"
                                    openDelay={500}
                                  >
                                    <Td width={100}>{documentType.name}</Td>
                                  </Tooltip>
                                  <Td width={100}>
                                    <TagList tags={documentType.tags} />
                                  </Td>
                                  {showAvailableDocuments && isRequirement && (
                                    <Td width={100} textAlign="center">
                                      {" "}
                                      {documentType.filesCount > 0 ? (
                                          <FaCheckSquare color="green" />
                                      ) : (
                                          <FaWindowClose color="red" />
                                      )}
                                    </Td>
                                  )}
                                  {isRequirement && (
                                    <Td width={100}>
                                      <Input
                                        type="number"
                                        defaultValue={documentType["graceDays"]}
                                        placeholder={t("insertHereGraceDays", {
                                          ns: "requirements",
                                        })}
                                        size="sm"
                                        onBlur={(e) =>
                                          handleGraceDays(
                                              documentType as DocumentTypeWithRequirement,
                                              e.target.value as unknown as number,
                                          )
                                        }
                                        isDisabled={isWorkingSite}
                                      />
                                    </Td>
                                  )}
                                  {isRequirement && (
                                      <Td width={100}>
                                        <Switch
                                          isChecked={!documentType["isOptional"]}
                                          onChange={(e) =>
                                              handleSwitch(
                                                  documentType as DocumentTypeWithRequirement,
                                                  !e.target.checked,
                                              )
                                          }
                                          isDisabled={isWorkingSite}
                                        />
                                      </Td>
                                  )}
                                  <Td width={100}>
                                      <CellPlusButton
                                        onClick={() =>
                                          handleSelectDocumentType(documentType)
                                        }
                                      />
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </InfiniteTable>
                    </Flex>

                {showVariantSelection &&
                    <AddVariantsAndSpecializationsToRequirementModal
                        variants={variants}
                        specializations={specializations}
                        requirements={requirements}
                        selected={
                            editRequirementVariantsAndSpecializations as unknown as DocumentTypeWithRequirement
                        }
                        onClose={() => {
                            setShowSelectSites(false);
                            setEditRequirementVariantsAndSpecializations(false);
                            setShowVariantSelection(false);
                        }}
                        setRequirements={(value) => {
                            setSelectedDocumentTypes(value);
                            setShowVariantSelection(false);
                        }
                        }
                        checkAlreadyCreated={true}
                        title={t("addVariantsAndSpecializationsToRequirementModal", {
                            ns: "requirements",
                        })}
                        createVariant={createVariant}
                        createSpecialization={createSpecialization}
                    />
                }
            </Flex>
        );
    }

    return (
        <Modal isOpen={true} onClose={onClose} size="4xl" trapFocus={false} isCentered>
          <ModalOverlay />
          <ModalContent
            style={{ background: "white", padding: "2rem" }}
            overflowY={"unset"}
          >
            <ModalHeader>
              <Box
                sx={{
                  padding: "1rem",
                  border: "1px solid",
                  borderColor: "gray.300",
                  borderRadius: "5px",
                  marginBottom: "20px",
                }}
              >
                <Steps
                  selected={activeStep}
                  steps={stepperSteps}
                />
              </Box>
            </ModalHeader>
            <ModalBody id="modal-body">
              {(() => {
                switch (activeStep) {
                  case 0:
                      if (requirementSubject === RequirementSubject.SUPPLIER) {
                        return (
                          <Flex width={"100%"}>
                            {sourceSelection()}
                          </Flex>
                        );
                      } else {
                        return (
                          <>
                            {requirementsList()}
                          </>
                        );
                      }
                  case 1:
                      if (requirementSubject === RequirementSubject.SUPPLIER) {
                          return (
                          <Flex width={"100%"}>
                            {requirementsList()}
                          </Flex>
                          );
                      } else {
                          return (
                          <Flex width={"100%"}>
                            {propagationSitesList()}
                          </Flex>
                          );
                      }
                  case 2:
                      return (
                      <Flex width={"100%"}>
                        {propagationSitesList()}
                      </Flex>
                      );
                  default:
                      return null;
                }
              })()}
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="flex-end" gap={2} mt={5}>
                <Button
                  isLoading={isAddingDocumentsApi}
                  isDisabled={ checkConfirmEnabled()}
                  colorScheme="blue"
                  onClick={handleNext}
                  mr="20px"
                >
                  {t("confirm", { ns: "common" })}
                </Button>
                <Button colorScheme="red" onClick={handleCancel}>
                  {t("cancel", { ns: "common" })}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
    );
};

export default CreateRequirementModal;
