import {
  Table,
  Tbody,
  Thead,
  TableProps,
  SystemStyleObject,
  Tr as ChakraTr,
  Skeleton,
  Td,
  Box,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import React, { ReactElement, ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RenderIf from "../../Permissions/RenderIf";

export interface SimpleTableProps extends TableProps {
  children?: ReactNode;
  sx?: SystemStyleObject;
  bottomThreshold?: number;
  maxHeight?: string;
  emptyText?: string;
  showEmptyText?: boolean;
  isLoading?: boolean;
  tableId?: string;
  autosize?: boolean;
  isCheckboxTable?: boolean;
  sameStyle?: boolean;
  disableDragAndDrop?: boolean
}

const headElementTableStyle = {
  width: "100%",
  "& tbody": {
    display: "table",
    tableLayout: "fixed",
    height: "100%",
    borderRadius: "0 0 10px 10px",
    width: "100%",
  },
  "& tr": {
    display: "table",
    tableLayout: "fixed",
    width: "100%",
    borderRadius: "10px 10px 0 0",
  },
};

const SimpleTable: React.FC<SimpleTableProps> = ({ children, ...props }) => (
  <Box
    sx={{
      borderRadius: "10px",
      border: "1px solid #CBD5E0",
    }}
  >
    <HeadElement {...props}>{children}</HeadElement>
    <BodyElement {...props}>{children}</BodyElement>
  </Box>
);

export const BodyElement = ({
  children,
  tbody,
  sx,
  bottomThreshold = 70,
  maxHeight,
  showEmptyText = false,
  isLoading = false,
  tableId = "simple-table",
  autosize,
  isCheckboxTable,
  sameStyle = false,
}: SimpleTableProps & { tbody?: ReactNode }) => {
  const bodyElem = tbody ?? getTableElement(children, Tbody);

  const tableStyle = {
    width: "100%",
    "& tbody": {
      display: "table",
      tableLayout: "fixed",
      height: "100%",
      borderRadius: "0 0 10px 10px",
      width: "100%",
      overflow: "hidden",
    },
    "& tr": {
      display: isCheckboxTable ? "table" : "",
      tableLayout: "fixed",
      width: "",
    },
  };

  const updateHeight = useCallback(() => {
    const tBodyElement = document.querySelector(`#${tableId}`);
    if (tBodyElement) {
      let mh = autosize ? maxHeight : '100%';
      if (!maxHeight) {
        const tableRect = tBodyElement.getBoundingClientRect();
        const viewPortHeight = window.innerHeight;
        const height = viewPortHeight - tableRect.top - bottomThreshold;
        mh = (height > 150 ? height : 240) + "px";
      }
      tBodyElement.setAttribute(
        "style",
        `max-height: ${mh}; overflow-y: auto;`,
      );
    }
  }, [autosize, bottomThreshold, maxHeight, tableId]);

  useEffect(() => {
    if (!autosize) {
      return;
    }

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [bottomThreshold, maxHeight, tableId, updateHeight]);

  useEffect(() => {
    if (!isLoading && autosize) {
      updateHeight();
    }
  }, [isLoading, updateHeight]);

  return (
    <div id={tableId}>
      <Table
        sx={{ ...(sameStyle ? headElementTableStyle : tableStyle), ...sx }}
      >
        {!isLoading && !showEmptyText && bodyElem}
      </Table>
    </div>
  );
};

export const HeadElement = ({
  children,
  isLoading,
  showEmptyText,
  emptyText,
}: {
  children: ReactNode;
  isLoading?: boolean;
  showEmptyText?: boolean;
  emptyText?: string;
}) => {
  const headElem = getTableElement(children, Thead);
  const { t } = useTranslation("common");
  
  const headCells = Array.isArray(headElem.props.children.props.children)
    ? headElem.props.children.props.children.flat().filter((v) => v)
    : [headElem.props.children.props.children];
  return (
    <>
      <Table sx={{...headElementTableStyle, width: "auto"}}>
        {headElem}
        {isLoading && <SkeletonRows thCells={headCells} />}
      </Table>
      {!isLoading && showEmptyText && (
        <Flex
          height={16}
          width="100%"
          backgroundColor="gray.100"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {emptyText ?? t("noItems", { ns: "common" })}
        </Flex>
      )}
    </>
  );
};

const SkeletonRows = ({ thCells }: { thCells: ReactElement[] }) => (
  <Tbody>
    {Array.from({ length: 3 }, (_, i) => (
      <ChakraTr key={i}>
        {thCells.map((cell, i) => (
          <Td
            key={i}
            {...(cell?.type === RenderIf
              ? cell?.props?.children?.props || {}
              : cell?.props || {})}
          >
            <Skeleton
              height={8}
              width={"50%"}
              borderRadius={8}
              startColor="gray.200"
              endColor="gray.300"
            />
          </Td>
        ))}
      </ChakraTr>
    ))}
  </Tbody>
);

export const getTableElement = (
  children: ReactNode,
  type: string | React.JSXElementConstructor<any>,
) =>
  React.Children.toArray(children).find(
    (child): child is ReactElement =>
      React.isValidElement(child) && child.type === type,
  );

export const Tr = ({
  data,
  onClick,
  children,
  handleOnMouseDown,
  }: {
  data: any;
  children: any;
  onClick?: (event, data: any) => void;
  handleOnMouseDown?: (event)  => void;
}) => (
  <ChakraTr
    onClick={(event) => onClick?.(event, data)}
    onMouseDown={(event) => handleOnMouseDown(event)}
    sx={{ cursor: onClick ? "pointer" : "arrow" }}
  >
    {children}
  </ChakraTr>
);

export default SimpleTable;
