// FIXME: use this component instead of DeleteActionAlert and similar.
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";

import {
  Button,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react";

// Props.
interface Props {
  title: string;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
  variant: "info" | "warning" | "question";
  closeClickingOutside?: boolean;
  isLoading?: boolean;
  isCloseDisabled?: boolean;
  onConfirmLabel?: string;
  onCancelLabel?: string;
}

const ConfirmAlert: FC<Props> = ({
  title,
  message,
  onCancel,
  onConfirm,
  variant,
  closeClickingOutside,
  isLoading,
  isCloseDisabled,
  onConfirmLabel,
  onCancelLabel,
}) => {
  // NOTE: Enclosing view must not render this alert if not visible.
  const { t } = useTranslation();

  return (
    <Modal isOpen onClose={onCancel} closeOnOverlayClick={closeClickingOutside} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flexDirection="column" paddingLeft={8} paddingRight={8}>
          <Flex
            direction="row"
            alignItems="center"
            width="100%"
            textAlign="start"
            marginLeft={-3}
            justifyContent={"center"}
          >
            {variant === "warning" && (
              <FiAlertTriangle style={{ fontSize: "30px" }} color="red" />
            )}
            {variant === "question" && (
              <AiOutlineQuestionCircle
                style={{ fontSize: "30px" }}
                color={COLORS.sikuroBlue}
              />
            )}
            {variant === "info" && (
              <FiInfo style={{ fontSize: "30px" }} color={COLORS.sikuroBlue} />
            )}
            <Text
              textColor={"black"}
              fontSize={22}
              fontWeight={"bold"}
              marginLeft={3}
              textAlign={"center"}
            >
              {title}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          id="modal-body"
          justifyContent={"center"}
          hidden={!message}
        >
          <Flex
            direction="row"
            alignItems="center"
            width="100%"
            textAlign="start"
            marginLeft={-3}
            justifyContent={"center"}
          >
            <Text
              fontSize={16}
              fontWeight="normal"
              textAlign={"center"}
              margin={3}
            >
              {message}
            </Text>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex
            direction="row"
            alignItems="center"
            width="100%"
            textAlign="start"
            justifyContent={"center"}
          >
            <Button
              bg={COLORS.sikuroBlue}
              textColor="white"
              onClick={onConfirm}
              borderRadius={15}
              marginRight={5}
              isLoading={isLoading}
            >
              {onConfirmLabel ?? t("confirm")}
            </Button>
            <Button
              bg="red.500"
              textColor="white"
              onClick={onCancel}
              borderRadius={15}
              isDisabled={isCloseDisabled}
            >
              {onCancelLabel ?? t("cancel")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { ConfirmAlert };
