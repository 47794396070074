import {
  Divider,
  Flex,
  GridItem,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  ModalOverlay,
  Text,
  Tooltip,
  useToast
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { IoMdDownload } from "react-icons/io";
import { SiteResourceDocument } from "../../../../domain/entities/document";
import FileEntity from "../../../../domain/entities/file";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import Site from "../../../../domain/entities/site";
import { COLORS } from "../../../assets/theme/colors";
import { DocumentCommunicationHook } from "../../../hooks/Document/useDocumentCommunicationVIewModel";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import CommentButton from "../../Common/CommentButton";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";
import CommentAuditButton from "../../Common/CommentAuditButton";
import useSitesAuditsViewModel from "../../../hooks/Site/useSiteAuditsViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import RenderIf from "../../Permissions/RenderIf";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { Alert } from "../../../screens/Common/Alert";
import { RequirementExpirationComponent } from "./RequirementExpirationComponent";
import { RequirementEvaluationComponent } from "./RequirementEvaluationComponent";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import ActionBarItem from "../../Common/ActionBarItem";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { useApp } from "../../../../app";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import { MessageType } from "../../../../domain/entities/documentComment";

interface Props {
  document: SiteResourceDocument;
  renameModels: string[];
  downloader: UseDownloader;
  evaluateDocument: (
    documentId: string,
    evaluationState?: ResourceDocumentEvaluationState,
    expirationDate?: Date,
    siteIds?: string[]
  ) => void;
  setAction: (action: "add" | "delete" | "evaluate" | "edit") => void;

  selectedEvaluationState: ResourceDocumentEvaluationState;
  setSelectedEvaluationState: (state: ResourceDocumentEvaluationState) => void;

  setShowEdit: (showEdit: boolean) => void;
  setActiveFile: (file: FileEntity | null) => void;
  setShowUpdateEvaluationDate?: (showEvaluationDate: boolean) => void;
  hasSites?: (action: string) => Promise<boolean>;
  communicationProps: DocumentCommunicationHook;
  updateEvaluationDate: string;
  sites?: Site[];
  siteGeneralDocument?: boolean;
  isWorkingSite?: boolean;
  documentsFetching?: boolean;
  requirementSubject?: RequirementSubject;
  onPrev: () => void;
  onNext: () => void;
  permissions: ResourceDocumentsPermissions;
  action?: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
  resourceId?: string;
  resourceCategory?: DocumentTypeCategory;
  showOverlay?: boolean;
  canComment?: boolean;
  isReadOnly?: boolean
  filesCount?: number
  documentEvaluation?: PendingEvaluation
  type: "document" | "requirements" | 'evaluation';
  validateEvaluation: (evaluationIds: string[], isApproved: boolean) => Promise<void>
  approveIsLoading: boolean
  onCloseModal?: () => void
}

export const RequirementDetailHeader = ({
    document,
    renameModels,
    downloader,
    requirementSubject,
    isWorkingSite,
    hasSites,
    documentsFetching,
    action,
    communicationProps,
    evaluateDocument,
    setAction,
    setShowEdit,
    selectedEvaluationState,
    setSelectedEvaluationState,
    showOverlay,
    setActiveFile,
    onPrev,
    onNext,
    setShowUpdateEvaluationDate,
    permissions,
    resourceId,
    resourceCategory,
    canComment=true,
    isReadOnly,
    filesCount,
    documentEvaluation,
    type,
    validateEvaluation,
    approveIsLoading,
    onCloseModal
  }: Props) => {

  const toast = useToast();
  const { context } = useApp();
  const { t } = useTranslation("documents");
  const { siteId } = useAuth();
  const [evaluationValidity, setEvaluationValidity] = useState<string>(
    document?.evaluationExpiresAt
      ? formatDateBasedOnLanguage(document?.evaluationExpiresAt)
      : null
  );
  const [currentRequirementRemarks, setCurrentRequirementRemarks] =
    useState<string>(document?.reportRemarks);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState<{
    item: PendingEvaluation;
    value: boolean;
  }>(undefined);



  const {
    createAuditNote,
    updateAuditNote,
    createAuditNoteIsLoading,
    updateAuditNoteIsLoading
  } = useSitesAuditsViewModel(
    siteId,
    document?.id,
    resourceCategory,
    resourceId,
    isWorkingSite
  );

  const handleEvaluation = (option?: ResourceDocumentEvaluationState) => {
    setAction("evaluate");
    setSelectedEvaluationState(option);
    setShowUpdateEvaluationDate(true);
    evaluateDocument(document.id, option, new Date(evaluationValidity), []);
  };

  const handleUpdateEvaluationDate = async () => {
    setAction("evaluate");
    const hasSiteItems = hasSites && (await hasSites("evaluate"));
    setShowEdit(true);
    if (hasSiteItems) {
      setShowUpdateEvaluationDate(false);
    } else {
      setShowUpdateEvaluationDate(true);
    }
  };
  useEffect(() => {
    if (document && !validateEvaluation) {
      setEvaluationValidity(
        document?.evaluationExpiresAt
          ? formatDateBasedOnLanguage(document?.evaluationExpiresAt)
          : null
      );
      setSelectedEvaluationState(document?.evaluationResult);
      setCurrentRequirementRemarks(document.reportRemarks);
    } else {
      setEvaluationValidity(
        documentEvaluation?.evaluation.expiresAt
          ? formatDateBasedOnLanguage(documentEvaluation?.evaluation.expiresAt)
          : null
      );
    }
  }, [document]);

  const typeName = documentEvaluation?.document?.type?.name || 'Unknown Type';
  const resource = documentEvaluation?.resource;

  let resourceName = 'Unknown Resource';

  if (resource) {
    if (resource.name) {
      resourceName = resource.name;
    } else if (resource.firstName || resource.lastName) {
      resourceName = `${resource.firstName || ''} ${resource.lastName || ''}`.trim();
    }
  }

  const displayText = `${typeName} - ${resourceName}`;

  return (
    <>
      <GridItem
        colSpan={1}
        rowSpan={1}
        w={"sm"}
        bg={COLORS.yellow}
        borderRadius={"5px 0 0 0 "}
        zIndex={action ? 2 : 1}
        borderBottom={"1px solid #efefef"}
      >
        {(action === "evaluate" ||
          action === "add" ||
          action === "edit" ||
          action === "editFile" ||
          action === "delete") &&
          showOverlay && <ModalOverlay></ModalOverlay>}
        {document && (
          <Flex
            py={8}
            px={6}
            gap={2}
            justifyContent={"space-between"}
            flexDirection={"column"}
          >
            <Flex flexDirection={"column"}>
              <Text fontWeight={600}>
                {document.documentTypeName ||
                 displayText}
              </Text>
              <Text fontSize={"small"}>
                {document?.filesCount || filesCount} file
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={3}>
              {canComment && communicationProps && (
                <RenderIf permissions={permissions.chat}>
                  <CommentButton
                    showShadow
                    onClick={(comment) => {
                      if (comment)
                        communicationProps.createResourceDocumentComment({
                          textComment: `#[${document.documentTypeName}](${document.id}) ${comment}`,
                          messageType: MessageType.COMMENT
                        });
                    }}
                    comments={
                      communicationProps.resourceDocumentComments?.filter((c) =>
                        c.comment.includes(`(${document.id})`),
                      ) ?? []
                    }
                    isLoading={communicationProps.isFetching}
                    color="black"
                    fontSize="23px"
                    taggableUsers={communicationProps?.taggableUsers}
                  />
                </RenderIf>
              )}
              {communicationProps && (
                <RenderIf permissions={permissions.report}>
                  <CommentAuditButton
                    onClick={async (comment) => {
                      document.reportRemarks = comment;
                      if (comment) {
                        if (document.reportRemarks) {
                          await updateAuditNote(comment);
                        } else {
                          await createAuditNote(comment);
                        }
                        setShowSuccessAlert(true);
                      }
                    }}
                    comment={currentRequirementRemarks}
                    color="black"
                    fontSize="24px"
                    taggableUsers={communicationProps?.taggableUsers}
                    isLoading={
                      createAuditNoteIsLoading || updateAuditNoteIsLoading
                    }
                  />
                </RenderIf>
              )}
                <RenderIf permissions={permissions.downloadAll} check={PermissionCheck.All}>
                  <Menu placement="auto">
                    <Tooltip label={t("downloadAllFiles", { ns: "documents" })}>
                      <MenuButton
                        minWidth="22px"
                        paddingLeft={2}
                        paddingRight={2}
                        paddingTop={2}
                        paddingBottom={2}
                        borderRadius={25}
                        _hover={{
                          paddingLeft: "2",
                          paddingRight: "2",
                          borderRadius: "25",
                          boxShadow: "0 0 12px 4px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <IoMdDownload fontSize="24px" color={"gray"} />
                      </MenuButton>
                    </Tooltip>
                    <MenuList>
                      <MenuGroup
                        title={t("downloadAllFiles", { ns: "documents" })}
                      >
                        {renameModels?.map((name) => (
                          <MenuItem
                            key={name}
                            minH="48px"
                            onClick={() => {
                              let documentId;
                              if (type === "evaluation") {
                                documentId = documentEvaluation.document.id;
                              } else {
                                documentId =
                                  requirementSubject === RequirementSubject.SITE
                                    ? document.id
                                    : document.documentId;
                              }

                              return downloader?.initiateZipDownload(
                                documentId,
                                name,
                                (ok) => {
                                  toast({
                                    duration: 5000,
                                    isClosable: true,
                                    colorScheme: ok ? "green" : "red",
                                    icon: ok ? <FiInfo /> : <FiAlertTriangle />,
                                    description: ok
                                      ? t("downloadAllFilesOk")
                                      : t("downloadAllFilesError"),
                                  });
                                },
                              );
                            }}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    </MenuList>
                  </Menu>
                </RenderIf>
            </Flex>
          </Flex>
        )}
      </GridItem>
      <GridItem
        colSpan={2}
        rowSpan={1}
        w={"full"}
        borderBottom={"1px solid #efefef"}
      >
        {document && (
          <Flex
            gap={4}
            zIndex={10}
            h={"full"}
            justifyContent={
              isWorkingSite || isReadOnly ? "flex-end" : "space-between"
            }
            paddingTop={4}
            pr={4}
          >
            {!isReadOnly && (
              <Flex gap={4} px={4}>
                <RenderIf
                  permissions={permissions.evaluate}
                  fallback={
                    <RenderIf permissions={permissions.viewEvaluations}>
                      <RequirementEvaluationComponent
                        action={action}
                        documentsFetching={documentsFetching}
                        readOnly
                        handleEvaluation={handleEvaluation}
                        selectedState={selectedEvaluationState}
                        setSelectedState={setSelectedEvaluationState}
                      />
                    </RenderIf>
                  }
                >
                  <RequirementEvaluationComponent
                    action={action}
                    documentsFetching={documentsFetching}
                    handleEvaluation={handleEvaluation}
                    readOnly={false}
                    selectedState={selectedEvaluationState}
                    setSelectedState={setSelectedEvaluationState}
                  />
                </RenderIf>
                <RenderIf
                  permissions={permissions.setExpiration}
                  fallback={
                    <RenderIf permissions={[permissions.viewEvaluations, permissions.viewEvaluationExpiration]} check={PermissionCheck.All}>
                      <RequirementExpirationComponent
                        document={document}
                        documentsFetching={documentsFetching}
                        evaluationValidity={evaluationValidity}
                        handleUpdateEvaluationDate={handleUpdateEvaluationDate}
                        readOnly
                        selectedState={selectedEvaluationState}
                      />
                    </RenderIf>
                  }
                >
                  <RequirementExpirationComponent
                    document={document}
                    documentsFetching={documentsFetching}
                    evaluationValidity={evaluationValidity}
                    handleUpdateEvaluationDate={handleUpdateEvaluationDate}
                    readOnly={false}
                    selectedState={selectedEvaluationState}
                  />
                </RenderIf>
              </Flex>
            )}
            <RenderIf permissions={permissions.approve}>
              {validateEvaluation && (
                <Flex
                gap={4}
                fontSize={"24px"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                  <RenderIf permissions={permissions.viewEvaluationExpiration} check={PermissionCheck.All}>
                    <Text fontSize={"16px"}>{t("validity")}:</Text>
                    <Text fontSize={"16px"} color={COLORS.sikuroBlue}>
                    {evaluationValidity ? evaluationValidity : "-"}
                    </Text>
                  </RenderIf>
                  <ActionBarItem
                    onClick={() => {
                      setShowConfirmAlert(true);
                      setSelectedEvaluation({
                        item: documentEvaluation,
                        value: true,
                      });
                    }}
                    icon={AiFillCheckCircle}
                    description={t("approveEvaluationTooltip", {
                      ns: "sites",
                    })}
                    color={"white"}
                    bgColor={COLORS.sikuroBlue}
                  />
                  {context.site.options
                    .isEvaluationApprovalRequiredForGlobalEvaluation && (
                    <ActionBarItem
                      onClick={() => {
                        setShowConfirmAlert(true);
                        setSelectedEvaluation({
                          item: documentEvaluation,
                          value: false,
                        });
                      }}
                      icon={AiFillCloseCircle}
                      description={t("rejectEvaluationTooltip", {
                        ns: "sites",
                      })}
                      color={"white"}
                      bgColor={COLORS.error}
                    />
                  )}
                </Flex>
              )}
            </RenderIf>
            <Flex
              height={"50px"}
              fontSize={"24px"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              backgroundColor={"gray.100"}
              alignSelf={"flex-end"}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              borderBottomRadius={0}
            >
              <Tooltip label={t("previousDocument")} shouldWrapChildren>
                <Flex paddingLeft={2} paddingRight={2}>
                  <FaChevronLeft
                    onClick={() => {
                      setActiveFile(null);
                      onPrev();
                      setAction(null);
                      setSelectedEvaluationState(null);
                    }}
                    cursor={"pointer"}
                  />
                </Flex>
              </Tooltip>
              <Divider backgroundColor={"gray.300"} width={"2px"} height={"100%"} marginLeft={0}/>
              <Tooltip label={t("nextDocument")} shouldWrapChildren>
                <Flex paddingLeft={2} paddingRight={2}>
                  <FaChevronRight
                    onClick={() => {
                      setActiveFile(null);
                      onNext();
                      setAction(null);
                      setSelectedEvaluationState(null);
                    }}
                    cursor={"pointer"}
                  />
                </Flex>
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </GridItem>
      {showSuccessAlert && (
        <Alert
          onClose={() => setShowSuccessAlert(false)}
          title={t("warning", { ns: "common" })}
          message={t("noteAuditSaved", { ns: "communication" })}
          variant="info"
        />
      )}
      {showConfirmAlert && (
        <ConfirmAlert
          title={t("attention", { ns: "common" })}
          message={
            selectedEvaluation.value
              ? t("confirmEvaluation", { ns: "sites" })
              : t("rejectEvaluation", { ns: "sites" })
          }
          variant="warning"
          closeClickingOutside={false}
          onCancel={() => {
            setSelectedEvaluation(undefined);
            setShowConfirmAlert(false);
          }}
          onConfirm={async () => {
            await validateEvaluation(
              [selectedEvaluation.item.id],
              selectedEvaluation.value,
            );
            setSelectedEvaluation(undefined);
            setShowConfirmAlert(false);
            onCloseModal();
          }}
          isLoading={approveIsLoading}
          isCloseDisabled={approveIsLoading}
        />
      )}
    </>
  );
};
