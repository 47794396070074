import { useMemo, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingView from "../../Common/LoadingView";
import ContentLayout from "../../../layout/ContentLayout";
import DocumentPresetsTable from "./DocumentPresetsTable";
import DocumentsPresetsActions from "./DocumentsPresetsActions";
import { ErrorBanner } from "../../../components/Common/alerts/ErrorBanner";
import { useDocumentsPresetsList } from "../../../hooks/DocumentsPresets/useDocumentsPresetsList";
import {
  DocumentPresetTarget,
  documentPresetTargetFromString,
} from "../../../../domain/entities/documentPresetTarget.enum";
import AddDocumentTypesModal from "../../../components/Views/common/AddDocumentTypesModal";
import { DeleteActionAlert } from "../../Common/DeleteActionAlert";
import DocumentPreset from "../../../../domain/entities/documentPreset";
import Variant from "../../../../domain/entities/variant";
import { COLORS } from "../../../assets/theme/colors";
import DOMPurify from "dompurify";
import { useMediaQuery } from "@chakra-ui/react";
import { OrderDocumentsByVariantModal } from "../../../components/Views/Sites/OrderDocumentsByVariantModal";
import { useHasPermissions } from "../../../components/Permissions/RenderIf";
import { Permission } from "../../../components/Permissions/Permissions";

const useResource = (): DocumentPresetTarget => {
  // React router V6 does NOT support regex in paths;
  // this hack is used to extract pluralized resource
  // name from path (routes are explicitly defined in
  // app router).
  const location = useLocation();
  const components = location.pathname.split("/");
  return documentPresetTargetFromString(components[components.length - 1]);
};

const DocumentsPresetsView = () => {
  const { t } = useTranslation("documentsPresets");
  const resource = useResource();
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const [selectTypeIsOpen, setSelectTypeIsOpen] = useState<boolean>(false);
  const [showDeleteDocumentTypologyAlert, setShowDeleteDocumentTypologyAlert] =
    useState<boolean>(false);
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);
  const [showOrderPresetModal, setShowOrderPresetModal] = useState(false);

  const [typologyToDelete, setTypologyToDelete] = useState<Variant>();
  const [presetToDelete, setPresetToDelete] = useState<string>();

  const [documentPresetToUnlink, setDocumentPresetToUnlink] =
    useState<DocumentPreset>();

  const {
    error,
    clearError,
    setTypologiesFilter,
    typologiesFilter,
    isLoading,
    typologies,
    presets,
    movePreset,
    linkTypologyToPreset,
    unlinkTypologyFromPreset,
    unlinkTypologyFromPresetIsLoading,
    setDocumentTypesAsPreset,
    creatingPresets,
    unsetDocumentTypeAsPreset,
    documentTypesProps,
    updateTypologyToPreset,
    renameTypologyError,
    isTagLoading,
    unsetDocumentTypeAsPresetIsLoading,
    presetsByTypology,
    presetsByTypologyFetching,
    setPresetDocumentByTypology,
    setTypologyId,
    movePresetsByTypology,
    presetsLoading,
  } = useDocumentsPresetsList(resource);

  const availableDocumentTypes = useMemo(
    () =>
      documentTypesProps.documentTypes.filter((documentType) => {
        return !presets?.find(
          (preset) => preset?.documentType?.id === documentType.id,
        );
      }),
    [documentTypesProps.documentTypes, presets],
  );

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t(`sectionDescriptions.${resource}`, { color: COLORS.sikuroBlue }),
  );

  const handleMovePresetsByTypology = (documentPresents: DocumentPreset[]) => {
    movePresetsByTypology(documentPresents);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <ContentLayout action={
    <DocumentsPresetsActions 
    onAddDocumentType={() => {
      setSelectTypeIsOpen(true)
      documentTypesProps?.setEnableGetTags(true);
    }}
    onShowOrderPreset={() => {
      setPresetDocumentByTypology(true);
      setShowOrderPresetModal(true)}}/>}>
      <Flex
        paddingLeft={10}
        paddingTop={10}
        paddingBottom={10}
        paddingRight={3}
        flex={1}
        h="100%"
        width="calc(100vw - 198px)"
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        <Text fontSize="larger" fontWeight="bold" textColor="#32A2DB">
          {t(`sectionHeaders.${resource}`)}
        </Text>
        <Text textAlign="start" dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
        {error && <ErrorBanner text={error} onClose={clearError} />}

        <DocumentPresetsTable
          typologies={typologies}
          rows={presets}
          presetsAreLoading={presetsLoading}
          onReorder={movePreset}
          onAddTypology={(preset, typology) =>
            linkTypologyToPreset({ typology, preset })
          }
          onDeleteTypology={(preset, typology) =>
            unlinkTypologyFromPreset({ preset, typology })
          }
          onDelete={(preset) => setPresetToDelete(preset.id)}
          setShowDeleteAlert={() => setShowDeleteDocumentTypologyAlert(true)}
          setAskConfirmDelete={() => setAskConfirmDelete(true)}
          setTypologyToRemove={(typo) => setTypologyToDelete(typo)}
          setDocuPresetToUnlink={(preset) => setDocumentPresetToUnlink(preset)}
          onUpdate={updateTypologyToPreset}
          renameTypologyError={renameTypologyError}
          isTagLoading={isTagLoading}
        />

        {showDeleteDocumentTypologyAlert && (
          <DeleteActionAlert
            isLoading={unlinkTypologyFromPresetIsLoading}
            onConfirm={async () => {
              await unlinkTypologyFromPreset({
                typology: typologyToDelete,
                preset: documentPresetToUnlink,
              });
              setShowDeleteDocumentTypologyAlert(false);
            }}
            onCancel={() => setShowDeleteDocumentTypologyAlert(false)}
            mainTitle={t("warning", { ns: "common" })}
            title={t("deletePresetTypology", { ns: "documents" })}
            leftButtonText={t("confirm", { ns: "common" })}
            rightButtonText={t("cancel", { ns: "common" })}
            isOpen={!!showDeleteDocumentTypologyAlert}
          />
        )}

        {askConfirmDelete && (
          <DeleteActionAlert
            isLoading={unsetDocumentTypeAsPresetIsLoading}
            onConfirm={async () => {
              await unsetDocumentTypeAsPreset(presetToDelete);
              setAskConfirmDelete(false);
            }}
            onCancel={() => setAskConfirmDelete(false)}
            mainTitle={t("warning", { ns: "common" })}
            title={t("deletePreset", { ns: "documents" })}
            leftButtonText={t("confirm", { ns: "common" })}
            rightButtonText={t("cancel", { ns: "common" })}
            isOpen={!!askConfirmDelete}
          />
        )}

        {showOrderPresetModal && (
          <OrderDocumentsByVariantModal
            title={t("orderPresets")}
            onClose={() => setShowOrderPresetModal(false)}
            documents={presetsByTypology}
            isButtonDisabled={false}
            isLoading={presetsByTypologyFetching}
            variants={typologies}
            setVariantId={setTypologyId}
            moveDocumentsByVariant={handleMovePresetsByTypology}
            labelTagSelect={t("typology", { ns: "documents" })}
            emptyText={t("noDocuments", { ns: "documents" })}
          />
        )}

        {selectTypeIsOpen && (
          <AddDocumentTypesModal
            {...documentTypesProps}
            documentTypes={availableDocumentTypes}
            onClose={() => setSelectTypeIsOpen(false)}
            onConfirm={async (documentTypes) => {
              await setDocumentTypesAsPreset(documentTypes);
              setSelectTypeIsOpen(false);
            }}
            isFetching={documentTypesProps.documentTypesIsFetching}
            isAddingDocumentsApi={creatingPresets}
            enableVariantSpecSelection={false}
            showAvailableDocuments={false}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default DocumentsPresetsView;
