import { Dispatch, SetStateAction } from "react";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  Select,
  Skeleton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import LastEvaluation from "../../../../domain/entities/lastEvaluation";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { FiRefreshCw } from "react-icons/fi";
import StateTag from "../../Common/StateTag";
import { Permission } from "../../Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface EvalutationComponentProps {
  documentsFetching: boolean;
  readOnly: boolean;
  selectedState: ResourceDocumentEvaluationState;
  handleEvaluation: (value: ResourceDocumentEvaluationState) => void;
  setSelectedState: Dispatch<SetStateAction<ResourceDocumentEvaluationState>>;
  action: "add" | "delete" | "evaluate" | "edit" | "editFile" | string | null;
  isPublic: boolean;
  disabledFunctions: boolean;
  lastEvaluation: LastEvaluation;
  handleRepropagation: (value: "evaluation") => void;
  permissionEvaluate: Permission;
  permissionShowEvalExp: Permission;
  viewEval: Permission;
}

export const DocumentEvaluationComponent = ({
  documentsFetching,
  readOnly,
  selectedState,
  handleEvaluation,
  setSelectedState,
  isPublic,
  disabledFunctions,
  action,
  lastEvaluation,
  handleRepropagation,
  permissionEvaluate,
  permissionShowEvalExp,
  viewEval,
}: EvalutationComponentProps) => {
  const { t } = useTranslation("documents");
  const canEvaluate = useHasPermissions([permissionEvaluate]);
  const canViewEval = useHasPermissions([viewEval]);
  const isWorkingSite = location.pathname.includes("/workingsites");

  return (
    <Box>
      {(isPublic || isWorkingSite) && (
        <>
          <Text fontWeight={600} pb={1}>
            {t("evaluationDetails")}
          </Text>
          {!documentsFetching ? (
            <>
              <RenderIf
                permissions={[permissionEvaluate]}
                check={PermissionCheck.All}
              >
                <Flex gap="4" alignItems="center">
                  {isPublic &&
                    lastEvaluation?.result &&
                    lastEvaluation?.result !== "unevaluated" && (
                      <Text>{t("new")}:</Text>
                    )}
                  <Select
                    placeholder={t("selectEvaluationState")}
                    _placeholder={{ fontSize: "12px" }}
                    size={"sm"}
                    value={selectedState || ""}
                    onChange={async (e) => {
                      setSelectedState(
                        e.target.value as ResourceDocumentEvaluationState,
                      );
                      if (
                        !e.target.value ||
                        (e.target.value as ResourceDocumentEvaluationState) ===
                          ResourceDocumentEvaluationState.UNDEFINED
                      ) {
                        return;
                      }
                      handleEvaluation(
                        e.target.value as ResourceDocumentEvaluationState,
                      );
                    }}
                    isDisabled={
                      !canEvaluate ||
                      disabledFunctions ||
                      action !== null ||
                      readOnly
                    }
                  >
                    <option value={ResourceDocumentEvaluationState.AVAILABLE}>
                      {t(
                        "combinedEvaluation." +
                          ResourceDocumentEvaluationState.AVAILABLE,
                        { ns: "enum" },
                      )}
                    </option>
                    <option
                      value={ResourceDocumentEvaluationState.NOT_AVAILABLE}
                    >
                      {t(
                        "combinedEvaluation." +
                          ResourceDocumentEvaluationState.NOT_AVAILABLE,
                        { ns: "enum" },
                      )}
                    </option>
                    <option
                      value={ResourceDocumentEvaluationState.NOT_APPLICABLE}
                    >
                      {t(
                        "combinedEvaluation." +
                          ResourceDocumentEvaluationState.NOT_APPLICABLE,
                        { ns: "enum" },
                      )}
                    </option>
                  </Select>
                </Flex>
              </RenderIf>

              {canViewEval &&
                lastEvaluation?.result &&
                lastEvaluation.result !== "unevaluated" &&
                (selectedState === "undefined" ||
                  selectedState === undefined) && (
                  <Flex gap="4" mt={2} alignItems="center">
                    <Text>{t("last")}: </Text>
                    <StateTag
                      value={lastEvaluation?.result}
                      type="combinedEvaluation"
                    />
                    <RenderIf
                      permissions={[permissionShowEvalExp]}
                      check={PermissionCheck.All}
                    >
                      <Text>{t("expiresOn")} </Text>
                      <Text mt={2} mb={2} textAlign="center">
                        {lastEvaluation?.expiresAt
                          ? formatDateBasedOnLanguage(lastEvaluation?.expiresAt)
                          : "-"}
                      </Text>
                    </RenderIf>
                    {canEvaluate && (
                      <Tooltip
                        label={t("repropagateEvaluation", { ns: "documents" })}
                      >
                        <Button
                          minWidth="22px"
                          paddingLeft={2}
                          paddingRight={2}
                          borderRadius={25}
                          backgroundColor="transparent"
                          _hover={{
                            paddingLeft: "2",
                            paddingRight: "2",
                            borderRadius: "25",
                            boxShadow: "0 0 12px 4px rgba(0, 0, 0, 0.2)",
                          }}
                          cursor="pointer"
                          onClick={() => {
                            handleRepropagation("evaluation");
                          }}
                        >
                          <FiRefreshCw fontSize="24px" color={"gray"} />
                        </Button>
                      </Tooltip>
                    )}
                  </Flex>
                )}
              {canViewEval &&
                (!lastEvaluation?.result ||
                  lastEvaluation.result === "unevaluated") &&
                (selectedState === "undefined" ||
                  selectedState === undefined) &&
                !canEvaluate && <Flex>{t("noEvaluationExist")}</Flex>}
            </>
          ) : (
            <Skeleton
              height={"30px"}
              w={"100%"}
              startColor={"gray.300"}
              endColor={"gray.300"}
            />
          )}
        </>
      )}
    </Box>
  );
};