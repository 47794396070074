import CreateDocumentType from '../../../../domain/interactors/documentTypes/createDocumentType';
import DeleteDocumentType from '../../../../domain/interactors/documentTypes/deleteDocumentType';
import GetDocumentType from '../../../../domain/interactors/documentTypes/getDocumentType';
import GetDocumentTypes from '../../../../domain/interactors/documentTypes/getDocumentTypes';
import UpdateDocumentType from '../../../../domain/interactors/documentTypes/updateDocumentType';
import LinkTagToDocumentType from '../../../../domain/interactors/documentTypes/linkTagToDocumentType';
import UnlinkTagFromDocumentType from '../../../../domain/interactors/documentTypes/unlinkTagFromDocumentType';
import { makeDocumentTypesRepository } from './repositories';
import GetRenameModels from '../../../../domain/interactors/documentTypes/getRenameModels';
import UpsertRenameModel from '../../../../domain/interactors/documentTypes/upsertRenameModel';
import GetDocumentTypesForAi from "../../../../domain/interactors/documentTypes/getDocumentTypesForAi";

export const makeGetDocumentTypes = () => {
	return new GetDocumentTypes(makeDocumentTypesRepository());
};

export const makeGetDocumentTypesForAi = () => {
	return new GetDocumentTypesForAi(makeDocumentTypesRepository());
}

export const makeGetDocumentType = () => {
	return new GetDocumentType(makeDocumentTypesRepository());
};

export const makeCreateDocumentType = () => {
	return new CreateDocumentType(makeDocumentTypesRepository());
};

export const makeUpdateDocumentType = () => {
	return new UpdateDocumentType(makeDocumentTypesRepository());
};

export const makeDeleteDocumentType = () => {
	return new DeleteDocumentType(makeDocumentTypesRepository());
};

export const makeLinkTagToDocumentType = () => {
	return new LinkTagToDocumentType(makeDocumentTypesRepository());
};

export const makeUnlinkTagFromDocumentType = () => {
	return new UnlinkTagFromDocumentType(makeDocumentTypesRepository());
};
export const makeGetRenameModels = () => {
	return new GetRenameModels(makeDocumentTypesRepository());
};

export const makeUpsertRenameModel = () => {
	return new UpsertRenameModel(makeDocumentTypesRepository());
};