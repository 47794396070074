import { useTranslation } from "react-i18next";
import { GiCrane } from "react-icons/gi";
import SubNavBar from "../../../components/Common/SubNavBar";
import { NavItemProps } from "../../../components/Common/NavItem";
import VehicleIcon from "../../../components/Common/icons/VehicleIcon";
import MachineIcon from "../../../components/Common/icons/MachineIcon";
import ToolIcon from "../../../components/Common/icons/ToolIcon";
import ChemicalIcon from "../../../components/Common/icons/ChemicalIcon";
import WorkerIcon from "../../../components/Common/icons/WorkerIcon";
import { Permission } from "../../../components/Permissions/Permissions";

const DocumentsPresetsSubNav = () => {
  const { t } = useTranslation("documentsPresets");

  const items: NavItemProps[] = [
    {
      Icon: WorkerIcon,
      path: "/company/documents-presets/workers",
      name: t("navbar.worker"),
    },
    {
      Icon: VehicleIcon,
      path: "/company/documents-presets/vehicles",
      name: t("navbar.vehicle"),
    },
    {
      Icon: MachineIcon,
      path: "/company/documents-presets/machines",
      name: t("navbar.machine"),
    },
    {
      Icon: ToolIcon,
      path: "/company/documents-presets/tools",
      name: t("navbar.tool"),
    },
    {
      Icon: ChemicalIcon,
      path: "/company/documents-presets/chemicals",
      name: t("navbar.chemical"),
    },
    {
      icon: GiCrane,
      path: "/company/documents-presets/sites",
      name: t("navbar.site"),
      permissions: [Permission.Sites_Show],
    },
  ];

  return <SubNavBar items={items} />;
};

export default DocumentsPresetsSubNav;
