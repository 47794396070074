import Machine from "../../../domain/entities/machine";
import {
  makeGetMachine,
  makeUpdateMachine,
} from "../../../core/app/factory/machines/usecases";
import {
  makeGetMachineSites,
  makeGetResourceLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import {
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import {
  makeAddResourceToSites,
  makeGetAvailableBadgesUseCase,
  makeLinkBadgesResource,
} from "../../../core/app/factory/workers/usecases";
import {
  makeUnlinkBadgeResource,
} from "../../../core/app/factory/badges/usecases";
import {
  GetAvailableBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import Badge from "../../../domain/entities/badge";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import Site, { ResourceSites } from "../../../domain/entities/site";
import {ResourceConversionViewModel} from "../resourceConversion/ResourceConversionViewModel";

class MachineViewModel extends ResourceConversionViewModel {
  private getMachineUseCase = makeGetMachine();
  private updateMachineUseCase = makeUpdateMachine();
  private getMachineSitesUseCase = makeGetMachineSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getAvailableBadgesUseCase = makeGetAvailableBadgesUseCase();
  private linkBadgesToResourceUseCase = makeLinkBadgesResource();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();

  async get(companyId: string, machineId: string): Promise<Machine> {
    return await this.getMachineUseCase.Call({ companyId, machineId });
  }

  async getMachineSites(
    companyId: string,
    machineId: string,
    sort: SortMeta,
    filter: GetSitesFilter,
    pageParam: number,
    includeWorkingSites?: boolean,
    ignoreUserSiteRoleRestrictions?: boolean,
    documentTypeId?: string,
  ): Promise<ResourceSites> {
    return await this.getMachineSitesUseCase.Call({
      companyId,
      machineId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      ignoreUserSiteRoleRestrictions,
      documentTypeId
    });
  }
  async getAvailableBadges(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetAvailableBadgesFilters,
  ): Promise<Badge[]> {
    return await this.getAvailableBadgesUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
    });
  }
  async update(
    companyId: string,
    machine: Machine,
    imageFile?: File,
  ): Promise<Machine> {
    return await this.updateMachineUseCase.Call({
      companyId,
      machine,
      imageFile,
    });
  }
  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<{results: Site[], count: number }> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }
  async linkBadgesToResource(
    companyId: string,
    badgeIds: string[],
    resourceType: BadgeResourceType,
    resourceId: string,
  ): Promise<void> {
    return await this.linkBadgesToResourceUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      badgeIds,
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }
  async linkResourceToSites(
    companyId: string,
    workerId: string,
    siteIds: string[],
    copyLastEvaluation: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "machine",
      resourceId: workerId,
      siteIds,
      copyLastEvaluation,
      selectAll,
      filters,
    });
  }
}

export { MachineViewModel };
