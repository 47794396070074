import { injectable } from 'tsyringe';
import DocumentType from '../../domain/entities/documentType';
import { ApiService } from '../utilities/apiService';
import ResourceConversionRepository from "../../domain/repositories/resourceConversionRepository";
import {ConversionType, CustomFieldMapRecordInput} from "../../presentation/hooks/Resources/useResourceConversionHook";

@injectable()
class ServerResourceConversionRepository implements ResourceConversionRepository {
	constructor(private apiService: ApiService) { }

  async getDocumentTypeMapRecords(companyId: string, baseType: ConversionType, twinType: ConversionType): Promise<{ baseDocumentType: DocumentType; twinDocumentType: DocumentType }[]> {
    const response = await this.apiService.fetchWithToken(
      `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/conversion-document-type-map/${baseType}-${twinType}`,
    );
    const payload = await response.json();

    return payload['results'] ?? payload;
  }

  async upsertDocumentTypeMapRecords(
    companyId: string,
    baseType: ConversionType,
    twinType: ConversionType,
    records: { baseDocumentTypeId: string; twinDocumentTypeId: string }[],
  ): Promise<void> {
    await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/conversion-document-type-map/${baseType}-${twinType}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ map: records }),
    });
  }

  async getCustomFieldMapRecords(companyId: string, baseType: ConversionType, twinType: ConversionType): Promise<CustomFieldMapRecordInput[]> {
    const response = await this.apiService.fetchWithToken(
      `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/conversion-custom-field-map/${baseType}-${twinType}`,
    );
    const payload = await response.json();

    return payload['results'] ?? payload;
  }

  async upsertCustomFieldMapRecords(
    companyId: string,
    baseType: ConversionType,
    twinType: ConversionType,
    records: { baseCustomFieldKey: string; twinCustomFieldKey: string }[],
  ): Promise<void> {
    await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/conversion-custom-field-map/${baseType}-${twinType}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ map: records }),
    });
  }

  async convertResource(companyId: string, baseType: ConversionType, twinType: ConversionType, resourceIds: string[], typologyId: string, allResources: boolean): Promise<string|string[]> {
    const body = { typologyId };
    let route = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/`
    if (resourceIds.length == 0 && !allResources) {
      throw new Error('No resources selected');
    }
    if (allResources || resourceIds.length > 1) {
      route += `convert-${baseType}s-to-${twinType}s`;
      body['resourceIds'] = resourceIds;
      body['allResources'] = allResources;
    } else {
      route += `${baseType}s/${resourceIds[0]}/convert-to-${twinType}`;
      body['resourceId'] = resourceIds[0];
    }

    const response = await this.apiService.fetchWithToken(
      route,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      },
    );
    if (resourceIds.length == 1) {
      const {resourceId} = await response.json();
      return resourceId;
    }
    const {conflicts} = await response.json();
    return conflicts;
  }
}

export default ServerResourceConversionRepository;
