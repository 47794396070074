import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useMediaQuery,
  useSteps,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import Role from "../../../domain/entities/role";
import User from "../../../domain/entities/user";
import Steps from "../../components/Common/stepper/Steps";
import { SelectUserSiteRole } from "./SelectUserSiteRole";
import { AddSiteUser } from "../../../domain/repositories/siteRepository";
import InputAnimatedSelect from "../../components/Common/InputAnimatedSelect";

interface AddUsersToSiteModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleSave: (params: AddSiteUser) => void;
  setRoleUser: Dispatch<
    SetStateAction<{
      [key: string]: string;
    }>
  >;
  users: User[];
  roles: Role[];
  updateFilterUsersSite: (row: string, value: string) => void;
  filter: { [p: string]: any };
  sort: SortMeta;
  setSort: (sort: SortMeta) => void;
  isLoading: boolean,
  hasNextPage: boolean,
  fetchNextPage: () => void
}

export const AddUsersToSiteModal = ({
  isOpen,
  onClose,
  handleSave,
  setRoleUser,
  users,
  roles,
  filter,
  setSort,
  sort,
  updateFilterUsersSite,
  isLoading,
  hasNextPage,
  fetchNextPage
}: AddUsersToSiteModalProps) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [userIds, setUserIds] = useState<string[]>([])
  const [selectedRole, setSelectedRole] = useState<string>()
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 1,
  });
  const onSelectStep = (index: number) => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(index);
  };
  const handleCancel = () => {
    switch (activeStep) {
      case 0:
        onClose();
        break;
      case 1:
        setActiveStep(0);
        break;
    }
  };

  useEffect(() => {
    if(!isOpen) {
      setUserIds([]);
      setSelectedRole(null);
      setActiveStep(0);
    }
  }, [isOpen]);

  const handleNext = () => {
    return activeStep === 1 ? handleSave({userIds: userIds, roleId: selectedRole}) : setActiveStep(activeStep + 1);
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" trapFocus={false}>
      <ModalOverlay />
      <ModalContent
        backgroundColor="white"
        padding={5}
        overflowY={"unset"}
      >
        <ModalHeader>
          <Box
            padding="1rem"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="5px"
            marginBottom="20px"
          >
            <Steps
              selected={activeStep}
              steps={[
                { title: t(`users.title`, { ns: "settings" }) },
                { title: t("roles.title", { ns: "settings" }) },
              ]}
              onSelect={onSelectStep}
            />
          </Box>
        </ModalHeader>
        <ModalBody id="modal-body">
          {activeStep === 0 && (
            <>
              <Text mb={4} fontWeight={"bold"}>
                {t("selectUsers", { ns: "sites" })}
              </Text>
              <Flex
                flexDirection="column"
                alignItems="start"
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                width={isMobile ? "calc(100vw - 120px)" : "100%"}
                marginTop={5}
                position="relative"
                overflow="auto"
                id="table-container-modal"
              >
                <SelectUserSiteRole
                  users={users}
                  roles={roles}
                  showSelectAll={true}
                  showSelectRoles={false}
                  setSort={setSort}
                  updateFilterUsersSite={updateFilterUsersSite}
                  sort={sort}
                  filter={filter}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isLoading={isLoading}
                  setRoleUser={setRoleUser}
                  userIds={userIds}
                  setUserIds={setUserIds}
                  isInModal={true}
                />
              </Flex>
            </>
          )}
          {activeStep === 1 && (
            <>
              <Text mb={4} fontWeight={"bold"}>
                {t("selectRoles", { ns: "sites" })}
              </Text>
              <InputAnimatedSelect id={'site-role'} label={t("select", {ns: 'common'})}
                noSelectionLabel={t("select", {ns: 'common'})}
                selectedValue={selectedRole}
                onSelect={(id: string) => setSelectedRole(id)}
                items={
                  roles.map((role) =>{
                    return {
                      id: role.id,
                      name: role.name,
                    };
                  })      }
              />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="flex-end" gap={2} mt={5}>
            <Button
              isLoading={isLoading}
              isDisabled={(activeStep === 0 && !userIds.length) ||
                (activeStep === 1 && !selectedRole)}
              colorScheme="blue"
              onClick={handleNext}
              mr="20px"
            >
              {t(activeStep === 0 ? "next" : "confirm", { ns: "common" })}
            </Button>
            <Button colorScheme="red" onClick={handleCancel}>
              {t("cancel", { ns: "common" })}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
