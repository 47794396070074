import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import { Permission } from "../../Permissions/Permissions";

const ResourcesTopBar = () => {
  const { t } = useTranslation();

  const items: TopBarItem[] = [
    {
      title: t("top.workers", { ns: "navbar" }),
      path: "/resources/workers",
      permissions: [Permission.Resources_ShowWorkersDetails],
    },
    {
      title: t("top.vehicles", { ns: "navbar" }),
      path: "/resources/vehicles",
      permissions: [Permission.Resources_ShowVehicleDetails]
    },
    {
      title: t("top.machinery", { ns: "navbar" }),
      path: "/resources/machines",
      permissions: [Permission.Resources_ShowMachineDetails]
    },
    {
      title: t("top.tools", { ns: "navbar" }),
      path: "/resources/tools",
      permissions: [Permission.Resources_ShowToolDetails]
    },
    {
      title: t("top.chemicals", { ns: "navbar" }),
      path: "/resources/chemicals",
      permissions: [Permission.Resources_ShowChemicalDetails]
    },
  ];

  return (
    <TopBar title={t("main.my resources", { ns: "navbar" })} items={items} />
  );
};

export default ResourcesTopBar;
