import { injectable } from "tsyringe";
import DocumentType, { RenameModel } from "../../domain/entities/documentType";
import DocumentTypeRepository, { GetDocumentTypeFilters } from "../../domain/repositories/documentTypeRepository";
import { DocumentTypeCategory } from "../../domain/entities/documentTypeCategory.enum";
import { SortMeta } from "../../domain/entities/interfaces/paginatedResults";
import { ApiService } from "../utilities/apiService";
import { dateIntervals } from "../utilities/filters";
import { ResourceType } from "../../presentation/screens/Site/ResourceSelectableTable";
import { ContextFiltersType } from "../../presentation/hooks/SmartDownload/useSmartDownloadViewModel";

@injectable()
class ServerDocumentTypeRepository implements DocumentTypeRepository {
	constructor(private apiService: ApiService) { }

	async getDocumentTypes(
		companyId: string,
		filter?: string,
		docTypeFilters?: GetDocumentTypeFilters,
		includePublic?: boolean,
		search?: string,
		docTypetags?: string[],
		sort?: SortMeta,
		pageParam?: number,
		withFileCount?: boolean,
		resourceId?: string,
		systemOnly?: boolean,
	): Promise<DocumentType[]> {
		const { name, description, tags, ...restFilter } = <GetDocumentTypeFilters>docTypeFilters || {};
		const params = new URLSearchParams({
			...(pageParam ? { page: pageParam.toString(), perPage: String(25) } : {}),
			...(filter ? { filter } : {}),
			...(includePublic ? { includePublic: '1' } : {}),
			...(search ? { search } : {}),
			...(docTypetags ? { tags: docTypetags.join(',') } : {}),
			...(systemOnly ? { systemOnly: '1' } : {}),
			...sort,
			...restFilter,
			...(tags && tags.length > 0 ? { tags: tags.join(',') } : {}),
			...(name ? { name } : {}),
			...(description ? { description } : {}),
		});
		// FIXME: find a better way to define this behaviour when declaring params.
		if (docTypetags?.length <= 0) {
			params.delete('tags');
		}
		let url: string;
		if (!withFileCount) {
			url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types?${params.toString()}`;
		} else {
			switch (filter) {
				case 'company':
					url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${resourceId?.length !== 0 ? resourceId : companyId}/company/document-types?${params.toString()}`;
					break;
				case 'site':
					url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/suppliers/${resourceId?.length !== 0 ? resourceId : companyId}/site/document-types?${params.toString()}`;
					break;
				case 'worker':
				case 'chemical':
				case 'tool':
				case 'vehicle':
				case 'machine':
					url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${filter}s/${resourceId}/document-types?${params.toString()}`;
					break;
				default:
					url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types?${params.toString()}`;
					break;
			}
		}
		const response = await this.apiService.fetchWithToken(url);
		const payload = await response.json();

		return payload['results'] ?? payload;
	}

	async getDocumentTypesSmartDownload(companyId: string, context: string,
	documentByResourceType?: ResourceType,filter?: GetDocumentTypeFilters,	sort?: SortMeta,	pageParam?: number,	contextFilters?: ContextFiltersType
	): Promise<{count: number, results: DocumentType[]}> {
		const { ...restFilter } = filter || {};
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			filter: documentByResourceType,
			...sort,
		});
		Object.keys(restFilter).forEach(key => {
			const value = restFilter[key];
			if (value !== undefined && value !== null) {
				params.append(key, String(value));
			}
		});
		const urlContext = context !== 'resource' ? 'by-sites' : 'by-resources'

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${urlContext}?${params.toString()}`,
			{headers: {'Content-Type': 'application/json'}, method: 'POST', body: JSON.stringify({ ...contextFilters })});
		return  await response.json();
	}

	async getDocumentTypesForAi(companyId: string, aiTaskId: string, resourceType?: DocumentTypeCategory): Promise<DocumentType[]> {
		const url = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/ai/task/${aiTaskId}/document-types`;
		const response = await this.apiService.fetchWithToken(url);

		const payload = await response.json();

		return payload['results'] ?? payload;
	}

	async getDocumentType(companyId: string, id: string): Promise<DocumentType | undefined> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${id}`,
		);
		return response.json();
	}

	async deleteDocumentType(companyId: string, id: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${id}`, {
			method: 'DELETE',
		});
	}

	async createDocumentType(companyId: string, resource: DocumentTypeCategory, documentType: DocumentType): Promise<DocumentType> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				...documentType,
				documentResourceType: resource,
			}),
		});
		return response.json();
	}

	async updateDocumentType(companyId: string, documentType: DocumentType): Promise<DocumentType> {
		const { tags, ...docType } = documentType;
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${documentType.id}`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(docType),
			},
		);
		return response.json();
	}

	async linkTagToDocumentType(companyId: string, documentTypeId: string, tagId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}` + `/document-types/${documentTypeId}/tags/${tagId}`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			},
		);

		if (response.status !== 200) {
			return Promise.reject('errors.cannotLinkTagToDocumentType');
		}

		return Promise.resolve();
	}

	async unlinkTagFromDocumentType(companyId: string, documentTypeId: string, tagId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${documentTypeId}/tags/${tagId}`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			},
		);

		if (response.status !== 200) {
			return Promise.reject('errors.cannotUnlinkTagFromDocumentType');
		}

		return Promise.resolve();
	}

	async getRenameModel(companyId: string, documentTypeId: string): Promise<RenameModel> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${documentTypeId}/rename-models`,
		);
		return response.json();
	}

	async upsertRenameModel(companyId: string, documentTypeId: string, renameModel: RenameModel): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/document-types/${documentTypeId}/rename-models`,
			{
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(renameModel),
			},
		);
	}
}

export default ServerDocumentTypeRepository;
