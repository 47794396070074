import Machine from '../../../entities/machine';
import UseCase from '../../../../core/app/usecase';
import MachineRepository from '../../../repositories/machineRepository';
import { PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';
import {GetResourcesFilter} from "../../../repositories/workerRepository";

type ARGS = {
	companyId: string;
	filter?: GetResourcesFilter;
	sort?: SortMeta;
	pageParam?: number;
	archived?: boolean;
};

class GetMachines implements UseCase<ARGS, PaginatedResults<Machine>> {
	constructor(private repository: MachineRepository) {}

	async Call({ companyId, filter, archived, sort, pageParam }: ARGS): Promise<PaginatedResults<Machine>> {
		return await this.repository.getMachines(companyId, filter, archived, sort, pageParam);
	}
}

export default GetMachines;
