import ContentLayout from "../../../layout/ContentLayout";
import { Evaluable } from "../../../../domain/entities/evaluable.enum";
import useSiteApproveEvaluationsViewModel, {
  PendingEvaluationsFilters
} from "../../../hooks/Site/useSiteApproveEvaluationsViewModel";
import { useParams } from "react-router-dom";
import { Button, Checkbox, Flex, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { EvaluationButton } from "../../Common/ResourceEvaluationToggle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { COLORS } from "../../../assets/theme/colors";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { ResourceDocumentEvaluationState } from "../../../../domain/entities/resourceDocumentEvaluationState.enum";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import SearchInput from "../../../screens/Common/SearchInput";
import { LuFileSearch2 } from "react-icons/lu";
import AddFileButton from "../../Common/table/AddFileButton";
import ActionButton from "../../Common/table/ActionButton";
import ActionBar from "../../Common/ActionBar";
import ActionBarItem from "../../Common/ActionBarItem";
import { DocumentDetailModal } from "../common/DocumentDetailModal";
import {
  useResourceFilesDownloader,
  useSupplierOrSiteFilesDownloader
} from "../../../hooks/Document/useDocumentsFilesDownloader";
import { useApp } from "../../../../app";
import { Alert } from "../../../screens/Common/Alert";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import { DownloadResourceFileScope } from "../../../../domain/repositories/documentsDownloader";

type Props = {
  type: Evaluable;
  permissions: {
    downloadAll: Permission;
    approve: Permission;
    showExpiration: Permission;
  }
};
const SiteApproveEvaluationsResourceView = ({ type, permissions }: Props) => {
  const { siteId } = useParams();
  const {
    evaluations,
    evaluationsIsLoading,
    pendingEvaluationsHasNextPage,
    pendingEvaluationsFetchNextPage,
    filterEvaluations,
    setFilterEvaluations,
    updateFilterEvaluations,
    sort,
    setSort,
    approve,
    approveIsLoading,
    setSearch,
    setEvaluationId,
    setSelectAllEvaluation,
    selectAllEvaluations,
    evaluationFiles,
    evaluationFilesIsLoading,
    setDocumentId,
    filesCount,
    setDocTypeId,
    renameModels,
    evaluationIds,
    setEvaluationIds,
    countPendingEvaluations,
    noRejectEvaluation,
    setNoRefejectEvaluation,
    setEnableGetFiles,
    docTypeId
  } = useSiteApproveEvaluationsViewModel(siteId, type);
  const { t } = useTranslation("sites");

  const [showConfirmAlert, setShowConfirmAlert] = useState<boolean>(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState<{
    item: PendingEvaluation;
    value: boolean;
  }>(undefined);
  const [showDocumentDetail, setShowDocumentDetail] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPaginatedEvaluations, setSelectAllPaginatedEvaluations] =
    useState<boolean>(false);
  const [checkButton, setCheckButton] = useState<"approved" | "rejected">();
  const [currentIndex, setCurrentIndex] = useState<number>();
  const [resourceId, setResourceId] = useState<string>();

  const supplierOrSiteFilesDownloader = useSupplierOrSiteFilesDownloader(siteId, "site", resourceId);
  const resourceFilesDownloader = useResourceFilesDownloader(siteId, resourceId, type+'s' as string as DownloadResourceFileScope);

  const downloader = location.pathname.includes('/approve/companies')
    ? supplierOrSiteFilesDownloader
    : resourceFilesDownloader;

  const [showClearButton, setShowClearButton] = useState(false);

  const { context } = useApp();

  const validateEvaluation = async (
    evaluationIds: string[],
    isApproved: boolean
  ) => {
    await approve({
      resource: type,
      evaluationIds: evaluationIds,
      isApproved
    });
    setFilterEvaluations({});
    setEvaluationIds([]);
  };

  const fieldEvaluations = filterEvaluations ?? {
    company: "",
    requirementId: "",
    result: ""
  };
  const canSeeExpiration = useHasPermissions(permissions.showExpiration);

  const columns: {
    field: keyof PendingEvaluationsFilters;
    type: "text" | "select" | "date-range";
    width: string;
    options?: Record<string, string>;
  }[] = [{ field: "company", type: "text", width: "180px" }];

  if (type !== Evaluable.COMPANY) {
    if (type !== Evaluable.WORKER) {
      columns.push({ field: "resource", type: "text", width: "200px" });
    } else {
      columns.push({ field: "lastName", type: "text", width: "200px" });
      columns.push({ field: "firstName", type: "text", width: "200px" });
    }
  }


  columns.push(
    { field: "requirement", type: "text", width: "200px" },
    {
      field: "result",
      type: "select",
      options: ResourceDocumentEvaluationState,
      width: "180px"
    },
  );
  canSeeExpiration &&
    columns.push({ field: "expiresAt", type: "date-range", width: "180px" });
  
  useEffect(() => {
    setFilterEvaluations({});
    deselectSelectAll()
  }, [type]);

  const openDocumentDetailModal = async (evaluation: PendingEvaluation) => {
    setEnableGetFiles(true);
    setResourceId(evaluation.resource.id);
    setDocumentId(evaluation.document.id);
    setDocTypeId(evaluation.document.type.id);
    deselectSelectAll()
    setShowDocumentDetail(true);
    setInitialCurrentIndex(evaluation);
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    if (!value) {
      setEvaluationIds([]);
      setSelectAllPaginatedEvaluations(false);
      setSelectAllEvaluation(false);
    } else {
      setSelectAllEvaluation(value);
      setSelectAllPaginatedEvaluations(value);
      setEvaluationIds(evaluations.map((evaluation) => evaluation.id));
    }
  };

  const handleSelectionButton = () => {
    setShowClearButton(!showClearButton);
    if (showClearButton) {
      setEvaluationIds([]);
      setSelectAll(false);
      setSelectAllEvaluation(false);
      setSelectAllPaginatedEvaluations(false);
    } else {
      setSelectAll(true);
      setSelectAllPaginatedEvaluations(true);
      setSelectAllEvaluation(true);
    }
  };

  const deselectSelectAll = () => {
    setEvaluationIds([]);
    setSelectAll(false);
    setSelectAllEvaluation(false);
    setSelectAllPaginatedEvaluations(false);
  }

  const toggleItem = (id: string) => {
    let updatedEvaluationsIds;

    if (evaluationIds && !evaluationIds.includes(id)) {
      updatedEvaluationsIds = [...evaluationIds, id];
    } else {
      updatedEvaluationsIds = evaluationIds.filter((i) => i !== id);
    }

    setEvaluationIds(updatedEvaluationsIds);

    if (updatedEvaluationsIds.length === evaluations.length) {
      setSelectAll(true);
      setSelectAllEvaluation(true);
      setSelectAllPaginatedEvaluations(true);
    } else {
      setSelectAll(false);
      setSelectAllEvaluation(false);
      setSelectAllPaginatedEvaluations(false);
    }
  };

  const setInitialCurrentIndex = (evaluation: PendingEvaluation) => {
    setCurrentIndex(evaluations.findIndex((d) => d?.id === evaluation?.id));
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + evaluations.length) % evaluations.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % evaluations.length);
  };

  useEffect(() => {
    if (currentIndex != null) {
      setDocumentId(evaluations[currentIndex]?.document.id);
      if (docTypeId !== evaluations[currentIndex]?.document?.type.id)
        setDocTypeId(evaluations[currentIndex]?.document?.type.id);
    }
  }, [currentIndex, evaluations]);


  useEffect(() => {
    if (
      evaluations?.length > 0 &&
      (selectAllPaginatedEvaluations || selectAllEvaluations) && !showClearButton
    ) {
      const ids = evaluations.map((evaluation) => evaluation.id);
      const allIds = [...ids, ...evaluationIds];
      const uniqueIds = Array.from(new Set(allIds));
      setEvaluationIds(uniqueIds);
    }
  }, [evaluations, selectAllPaginatedEvaluations, selectAllEvaluations]);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.approve}>
            <ActionBarItem
              onClick={() => {
                setShowConfirmAlert(true);
                setCheckButton("approved");
              }}
              icon={AiFillCheckCircle}
              description={t("approveAllEvaluationTooltip", { ns: "sites" })}
              isDisabled={!selectAll && evaluationIds.length === 0}
              color={"white"}
              bgColor={
                !selectAll && evaluationIds.length === 0
                  ? "#8bc7e5"
                  : COLORS.sikuroBlue
              }
              disabledDescription={t("disabledAllEvaluationTooltip", {
                ns: "sites"
              })}
            />
            {context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation &&
              <ActionBarItem
                onClick={() => {
                  setShowConfirmAlert(true);
                  setCheckButton("rejected");
                }}
                icon={AiFillCloseCircle}
                description={t("rejectAllEvaluationTooltip", { ns: "sites" })}
                isDisabled={!selectAll && evaluationIds.length === 0}
                color={"white"}
                bgColor={
                  !selectAll && evaluationIds.length === 0
                    ? "#f9b1b1"
                    : COLORS.error
                }
                disabledDescription={t("disabledAllEvaluationTooltip", {
                  ns: "sites"
                })}
              />}
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        marginTop={10}
        width="calc(100vw - 270px)"
        marginLeft={10}
      >
        <Text>{t("subtitle", { ns: "sites" })}</Text>
        <SearchInput onSearch={setSearch} />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="start"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width="calc(100vw - 270px)"
        marginLeft={10}
        marginRight={10}
        position="relative"
        overflow="auto"
        mt={4}
        id="table-container"
      >
        <InfiniteTable
          autosize
          infiniteScroll={{
            dataLength: evaluations.length,
            hasNextPage: pendingEvaluationsHasNextPage,
            fetchNextPage: pendingEvaluationsFetchNextPage
          }}
          isLoading={evaluationsIsLoading}
          tableId="site-approved-evaluations"
          showEmptyText={evaluations?.length === 0}
          emptyText={t("noEvaluations", { ns: "sites" })}
          isCheckboxTable={true}
          id="table-container"
        >
          <Thead>
            <Tr>
              <Th key="selectAllCheckbox" width={10}>
                <Checkbox
                  borderColor="gray.500"
                  isChecked={selectAll}
                  onChange={() => toggleSelectAll(!selectAll)}
                />
              </Th>
              {columns.map((c) => (
                <Th width={c.width} key={c.field}>
                  <TableColumnHeader
                    text={t(`${c.field}`, { ns: "sites" })}
                    filter={
                      c.field != "result" && {
                        isActive:
                          !!fieldEvaluations[c.field] &&
                          (!Array.isArray(fieldEvaluations[c.field]) ||
                            !!fieldEvaluations[c.field][0]),
                        component: (
                          <ColumnFilterComponent
                            value={fieldEvaluations[c.field]}
                            type={c.type}
                            updateFilter={(value) =>
                              updateFilterEvaluations(c.field, value)
                            }
                            namespace="enum"
                            selectOptions={c.options}
                          />
                        )
                      }
                    }
                    sort={
                      c.field != "result" && {
                        handler: (direction) =>
                          setSort({ field: c.field, direction }),
                        direction:
                          sort?.field === c.field ? sort.direction : null
                      }
                    }
                  />
                </Th>
              ))}
              <Th width={context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation ? "180px" : "150px"} />
            </Tr>
          </Thead>
          <Tbody borderRadius="xl">
            <Tr width="100%">
              {selectAllEvaluations && (
                <Th backgroundColor={"gray.100"}>
                  <Text textAlign="center" mx="auto">
                    {!selectAllPaginatedEvaluations &&
                      t("evaluationsSelectedVisible")}
                    {selectAllPaginatedEvaluations &&
                      t("evaluationsSelectedNotVisible", {
                        count: countPendingEvaluations
                      })}
                    {pendingEvaluationsHasNextPage && (
                      <Button
                        mt="10px"
                        ml="4px"
                        colorScheme="blue"
                        variant="link"
                        onClick={() => handleSelectionButton()}
                      >
                        {t(
                          showClearButton ? "clearSelection" : "evaluationsSelectAll"
                        )}
                      </Button>
                    )}
                  </Text>
                </Th>
              )}
            </Tr>
            {evaluations &&
              evaluations.map((item) => {
                let showLastUploadedFile;
                if (
                  item?.document.lastUploadedFileUri?.includes(".xlsx") ||
                  item?.document.lastUploadedFileUri?.includes(".xls") ||
                  item?.document.lastUploadedFileUri?.includes(".zip") ||
                  item?.document.lastUploadedFileUri?.includes(".doc") ||
                  item?.document.lastUploadedFileUri?.includes(".docx")
                ) {
                  showLastUploadedFile = false;
                } else {
                  showLastUploadedFile = true;
                }
                return (
                  <Tr width="100%" key={item.id}>
                    <Td width={10}>
                      <Checkbox
                        isChecked={
                          evaluationIds && evaluationIds.length > 0
                            ? evaluationIds?.includes(item?.id)
                            : false
                        }
                        onChange={() => toggleItem(item?.id)}
                      />
                    </Td>
                    <Td width={180}>{item.company.name}</Td>
                    {type !== Evaluable.COMPANY &&
                      type !== Evaluable.WORKER && (
                        <Td width={200}>{item.resource.name}</Td>
                      )}
                    {type === Evaluable.WORKER && (
                      <Td width={200}>{item.resource.lastName}</Td>
                    )}
                    {type === Evaluable.WORKER && (
                      <Td width={200}>{item.resource.firstName}</Td>
                    )}
                    <Tooltip
                      label={item.evaluation.resource.type.description}
                      placement="bottom-start"
                      openDelay={500}
                    >
                      <Td width={200}>
                        {item.evaluation.resource.type.name}{" "}
                        {!item.isOptional ? (
                          <span style={{ color: COLORS.error }}>*</span>
                        ) : (
                          ""
                        )}
                      </Td>
                    </Tooltip>
                    <Td width={180}>
                      <EvaluationButton
                        value={item.evaluation.result}
                        evaluationType="document"
                      />
                    </Td>
                    {canSeeExpiration &&
                      <Td width={180}>
                        <Flex justifyContent="center">
                          {
                            item.evaluation.expiresAt && item.evaluation.expiresAt !== null
                              ? formatDateBasedOnLanguage(item.evaluation.expiresAt)
                              : "-"
                          }
                        </Flex>
                      </Td>
                    }

                      <Td
                        width={
                          context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation ?
                            "180px" :
                            "150px"
                        }
                      >
                        <RenderIf
                          permissions={permissions.approve}
                          check={PermissionCheck.All}
                        >
                          <Tooltip
                            label={t("approveEvaluationTooltip", {
                              ns: "sites"
                            })}
                          >
                            <span>
                              <ActionButton
                                aria-label="approve"
                                onClick={() => {
                                  deselectSelectAll()
                                  setSelectedEvaluation({ item, value: true });
                                  setShowConfirmAlert(true);
                                }}
                                icon={
                                  <AiFillCheckCircle
                                    color={COLORS.sikuroBlue}
                                    size="30px"
                                  />
                                }
                              />
                            </span>
                          </Tooltip>
                          {context.site.options.isEvaluationApprovalRequiredForGlobalEvaluation &&
                            <Tooltip
                              label={t("rejectEvaluationTooltip", {
                                ns: "sites",
                              })}
                            >
                              <span>
                                <ActionButton
                                  aria-label="abort"
                                  onClick={() => {
                                    deselectSelectAll()
                                    setSelectedEvaluation({ item, value: false });
                                    setShowConfirmAlert(true);
                                  }}
                                  icon={
                                    <AiFillCloseCircle
                                      color={COLORS.error}
                                      size="30px"
                                    />
                                  }
                                />
                              </span>
                            </Tooltip>
                          }
                        </RenderIf>
                        <AddFileButton
                          justFile={false}
                          onClick={() => openDocumentDetailModal(item)}
                          filesCount={item.document.filesCount}
                          tooltipLabal={t("viewDocument", { ns: "documents" })}
                        />
                        <RenderIf permissions={permissions.downloadAll}>
                          {item.document.lastUploadedFileUri &&
                            showLastUploadedFile && (
                              <Tooltip
                                label={t("lastUpdatedFile", { ns: "documents" })}
                                placement="bottom-start"
                              >
                                  <span style={{ marginLeft: 10 }}>
                                    <ActionButton
                                      mt={1}
                                      aria-label="view-last-file"
                                      onClick={() => {
                                        window.open(
                                          item.document.lastUploadedFileUri,
                                          "_blank"
                                        );
                                      }}
                                      icon={
                                        <LuFileSearch2
                                          color={COLORS.sikuroBlue}
                                          cursor="pointer"
                                          fontSize="22px"
                                        />
                                      }
                                    />
                                  </span>
                              </Tooltip>
                            )}
                        </RenderIf>
                      </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </InfiniteTable>
        {showConfirmAlert && (
          <ConfirmAlert
            title={t("attention", { ns: "common" })}
            message={
              checkButton === "approved"
                ? t("confirmAllEvaluation")
                : checkButton === "rejected"
                  ? t("rejectAllEvaluation")
                  : selectedEvaluation.value
                    ? selectedEvaluation.item.evaluation.expiresAt ? t("confirmEvaluationWithDate", {date: formatDateBasedOnLanguage(selectedEvaluation.item.evaluation.expiresAt)}) : t("confirmEvaluation")
                    : selectedEvaluation.item.evaluation.expiresAt ?  t("rejectEvaluationWithDate", {date: formatDateBasedOnLanguage(selectedEvaluation.item.evaluation.expiresAt)}) : t("rejectEvaluation")
            }
            variant="warning"
            closeClickingOutside={false}
            onCancel={() => {
              setSelectedEvaluation(undefined);
              setShowConfirmAlert(false);
            }}
            onConfirm={async () => {
              await validateEvaluation(
                evaluationIds.length > 0 ? evaluationIds : [selectedEvaluation?.item.id],
                checkButton === "approved" || !!selectedEvaluation?.value
              );
              setSelectedEvaluation(undefined);
              setShowConfirmAlert(false);
              setSelectAll(false);
            }}
            isLoading={approveIsLoading}
            isCloseDisabled={approveIsLoading}
          />
        )}

        {showDocumentDetail && (
          <DocumentDetailModal
            document={evaluations[currentIndex]}
            fileIsLoading={evaluationFilesIsLoading}
            filesCount={filesCount}
            isOpen={showDocumentDetail}
            onClose={() => {
              setShowDocumentDetail(false);
              setCurrentIndex(null);
              setDocumentId(undefined);
              setEnableGetFiles(false)
            }}
            renameModels={renameModels?.download}
            downloader={downloader}
            isReadOnly={true}
            files={evaluationFiles}
            onPrev={handlePrev}
            onNext={handleNext}
            type="evaluation"
            docDetailPermissions={{
              addFile: Permission.Sites_EditReviewEval,
              chat: Permission.Sites_EditReviewEval,
              delete: Permission.Sites_EditReviewEval,
              downloadAll: permissions.downloadAll,
              edit: Permission.Sites_EditReviewEval,
              evaluate: Permission.Sites_EditReviewEval,
              setExpiration: Permission.Sites_EditReviewEval,
              viewEvaluationExpiration: permissions.showExpiration,
              viewEvaluations: Permission.Sites_EditReviewEval,
              report: Permission.Sites_EditReviewEval,
              approve: permissions.approve
            }}
            validateEvaluation={async (evaluationIds: string[], isApproved: boolean) => {
              await validateEvaluation(
                evaluationIds.length > 0 ? evaluationIds : [selectedEvaluation.item.id],
                isApproved
              );
            }}
            approveIsLoading={approveIsLoading}
          />
        )}
        {noRejectEvaluation && <Alert
          title={t("warning", { ns: "common" })}
          message={t("noRejectEvaluation", { ns: "errors" })}
          variant="info"
          onClose={() => {
            setNoRefejectEvaluation(undefined);
          }}
        />}
      </Flex>
    </ContentLayout>
  );
};

export default SiteApproveEvaluationsResourceView;
