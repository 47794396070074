import { Box, Tbody } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import {
  BodyElement,
  getTableElement,
  HeadElement,
  SimpleTableProps,
} from "./SimpleTable";

interface BaseTableProps extends SimpleTableProps {
  infiniteScroll: {
    hasNextPage: boolean;
    fetchNextPage: () => void;
    dataLength: number;
    hideInfiniteLoader?: boolean;
    scrollableTarget?: string
  };
  isCheckboxTable?: boolean;
  sameStyleHeadAndBodyTable?: boolean;
}

const InfiniteTable = ({
  children,
  infiniteScroll,
  isCheckboxTable,
  sameStyleHeadAndBodyTable,
  ...rest
}: BaseTableProps) => {
  const { t } = useTranslation();
  const bodyElem = getTableElement(children, Tbody);
  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: "1px solid #CBD5E0",
        overflow: "visible", 
      }}
    >
      <HeadElement {...rest} showEmptyText={infiniteScroll.dataLength === 0}>
        {children}
      </HeadElement>
      <BodyElement
        {...rest}
        overflowX="auto"
        showEmptyText={infiniteScroll.dataLength === 0}
        isCheckboxTable={isCheckboxTable}
        sameStyle={sameStyleHeadAndBodyTable}
        tbody={
          <InfiniteScroll
            dataLength={infiniteScroll.dataLength}
            next={infiniteScroll.fetchNextPage}
            hasMore={infiniteScroll.hasNextPage}
            loader={
              <div style={{ textAlign: "center" }}>
                {!infiniteScroll.hideInfiniteLoader && t("loading")}
              </div>
            }
            scrollableTarget={rest.autosize ? rest.tableId : (infiniteScroll.scrollableTarget ?? 'mainScrollArea')}
          >
            {bodyElem}
          </InfiniteScroll>
        }
      />
    </Box>
  );
};

export default InfiniteTable;
