import { MdClose } from "react-icons/md";
import { useApp } from "../../../app";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";
import { COLORS } from "../../assets/theme/colors";
import { Flex, Stack, Text } from "@chakra-ui/react";
import SupplierTopBarName from "./SupplierTopBarName";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DetailsHeader from "../../components/Common/DetailsHeader";
import { Column } from "../../components/Views/Documents/GeneralDocumentsView";
import { useSupplierDetailModel } from "../../hooks/Suppliers/useSupplierDetailModel";
import SupplierDocumentsTable, {
  SupplierDocumentsProps,
} from "../../components/Views/common/SupplierDocumentsTable";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import ContactField from "../../components/Common/table/ContactField";
import { Alert } from "../Common/Alert";
import { TbUserScan } from "react-icons/tb";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import RenderIf from "../../components/Permissions/RenderIf";
import { Permission } from "../../components/Permissions/Permissions";

interface Props {
  permissions: SupplierDocumentsProps["permissions"];
}

const SupplierDetailView: FC<Props> = ({ permissions }) => {
  const { t } = useTranslation("suppliers");
  const navigate = useNavigate();
  const { updateContext } = useApp();
  const { companyId: defaultCompany, company, user, companyUser, changeTenant } = useAuth();
  const [showDisabledCompany, setShowDisabledCompany] = useState(false);
  const [showConfirmChangeTenant, setShowConfirmChangeTenant] = useState(false);

  const {
    supplier,
    documents,
    isFetching,
    refetchDocument,
    documentInfoProps,
    updateFilterDocuments,
    updateSortDocuments,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,
    sortDocuments,
    setSortDocuments,
    filterDocuments,
    filterDocumentEvaluations,
    getDocumentEvaluations,
    updateFilterDocumentEvaluations,
    documentEvaluations,
    documentEvaluationsFetching,
    createDocument,
    updateDocument,
    tags,
    systemStaff,
    evaluateDocument,
    repropagateEvaluationDocument,
    setSuccessMsg,
    successMsg
  } = useSupplierDetailModel();

  useEffect(() => {
    updateContext({
      topBarTitle: <SupplierTopBarName supplier={supplier} />,
    });
  }, [supplier]);

  const manageTenantSelection = async () => {

    if (supplier.company.state === "disabled") {
      setShowDisabledCompany(true);
    } else {
      await changeTenant(supplier.company);
      localStorage.setItem(
        "lastTenantSelected",
        JSON.stringify(supplier.company),
      );
      localStorage.setItem("lastUserLogged", JSON.stringify(user));
    }
  };

  const { supplierId } = useParams();
  const downloader = useSupplierOrSiteFilesDownloader(supplierId, "company");

  const getAddress = (address) => {
    if (!address) return "";
    const { street, cap, city } = address;

    // Build the address parts array, excluding empty values
    const parts = [
      street && street.trim(),
      city && city.trim(),
      cap && cap.trim(),
    ].filter(Boolean); // Remove empty values
    return parts.join(" - ");
  };

  const detailAttributes = [
    "name",
    "fiscalCode",
    "vat",
    "email",
    "pec",
    "address",
    "phoneNumber",
  ].map((column) => {
    const value =
      column === "address"
        ? getAddress(supplier?.company?.address)
        : supplier?.company?.[column];
    return {
      label: t(column, { ns: "companies" }),
      value: value || "-",
    };
  });

  const contactAttributes = [
    "doctor",
    "rspp",
    "contact",
    "employer",
    "rls",
  ].map((role) => ({
    label: t(role, { ns: "companies" }),
    value: (
      <ContactField staff={systemStaff?.find((staff) => staff.role === role)} />
    ),
  }));

  const columns: Column[] = [
    { field: "name", filterType: "text" as const, label: t("name") },
    { field: "tags", filterType: "tags" as const, label: t("tags") },
  ];

  columns.splice(1, 0, {
    field: "expiresAt",
    filterType: "date-range",
    label: t("expireDate", { ns: "documents" }),
  });

  if (!supplier) {
    // Company data not yet available.
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() => navigate(`/suppliers`)}
          />
          {supplier.company.derivedFromCompanyId && 
            <ActionBarItem
              description={t("changeTenant", {ns: "common"})}
              icon={TbUserScan}
              onClick={()=>setShowConfirmChangeTenant(true)}
            />
          }
        </ActionBar>
      }
    >
      {supplier && (
        <Stack paddingLeft={5} paddingRight={5} paddingTop={5}>
          <RenderIf permissions={Permission.Supplier_ShowDetails}  check={PermissionCheck.All}>
          <Flex gap="20px" marginBottom={5}>
            <DetailsHeader
              isLoaded={!!supplier.company.name}
              title={t("details", { ns: "common" })}
              shouldShowImage={true}
              image={{ url: supplier.company.logo, disableFlag: true }}
              attributes={[...detailAttributes, ...contactAttributes]}
              isDerivedSupplier={supplier.company.derivedFromCompanyId ? true : false}
            />
           
          </Flex>
          </RenderIf>
          <RenderIf permissions={permissions.viewDocuments}  check={PermissionCheck.All}>
            {documents && (
              <Flex
                direction={"column"}
                padding={5}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
              >
                <Text textStyle="h2" mt={3}>
                  {t("documents", { ns: "documents" })}
                </Text>

                <SupplierDocumentsTable
                  tableColumns={columns}
                  downloader={downloader}
                  documents={documents}
                  isFetchingDocuments={isFetching}
                  refetchDocument={refetchDocument}
                  documentsHasNextPage={null}
                  documentsFetchNextPage={null}
                  sortDocuments={sortDocuments}
                  setSortDocuments={setSortDocuments}
                  filterDocuments={filterDocuments}
                  updateFilterDocuments={updateFilterDocuments}
                  updateSortDocuments={updateSortDocuments}
                  getDocumentEvaluations={getDocumentEvaluations}
                  documentEvaluations={documentEvaluations}
                  documentEvaluationsFetching={documentEvaluationsFetching}
                  filterDocumentEvaluations={filterDocumentEvaluations}
                  updateFilterDocumentEvaluations={
                    updateFilterDocumentEvaluations
                  }
                  sortDocumentEvaluations={sortDocumentEvaluations}
                  updateSortDocumentEvaluations={updateSortDocumentEvaluations}
                  {...documentInfoProps}
                  isCompanySupplier
                  createDocumentWithProperties={createDocument}
                  updateDocument={updateDocument}
                  tags={tags}
                  permissions={permissions}
                  evaluateDocument={evaluateDocument}
                  fromSupplierResource={false}
                  repropagateEvaluationDocument={(documentId: string, siteIds: string[], selectAll: boolean) => 
                    repropagateEvaluationDocument({
                      documentId, siteIds, selectAll
                    })}
                />
              </Flex>
            )}
          </RenderIf>
        </Stack>
      )}
      {successMsg && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(successMsg, { ns: "common" })}
          variant="info"
          onClose={() => {
            setSuccessMsg(null);
          }}
        />
      )} 
      {showDisabledCompany && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t("tenantMissingLicense", { ns: "common" })}
          variant="info"
          onClose={() => {
            setShowDisabledCompany(false);
          }}
        />
      )}
      {showConfirmChangeTenant && (
        <ConfirmAlert
          onCancel={() => setShowConfirmChangeTenant(false)}
          onConfirm={() => {
            manageTenantSelection(); 
            setShowConfirmChangeTenant(false);
          }}
          title={t("warning", { ns: "common" })}
          variant={"question"}
          message={t("confirmChangeTenant", { ns: "supplier" })}
        />
      )}
    </ContentLayout>
  );
};

export default SupplierDetailView;
