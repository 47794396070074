import { Button, Checkbox, Tag, TagLabel, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Supplier from "../../../../domain/entities/supplier";
import { COLORS } from "../../../assets/theme/colors";
import ColumnFilterComponent from "../table/ColumnFilterComponent";
import InfiniteTable from "../table/InfiniteTable";
import SkeletonTableRow from "../table/SkeletonTableRow";
import TableColumnHeader from "../table/TableColumnHeader";

interface SmartDownloadContextTableProps {
  data: Supplier[] | DocumentType[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  updateFilters: (field: string, value: string | string[]) => void;
  setSort: (sort: SortMeta) => void;
  sort: SortMeta;
  count: number;
  setSelectedItems: (items: any[]) => void;
  selectedItems: any[];
  setSelectedAllItems: (value: boolean) => void;
  columns: any[];
  filters: any;
}

export const SmartDownloadContextTable = ({
  data,
  hasNextPage,
  fetchNextPage,
  isLoading,
  updateFilters,
  setSort,
  sort,
  count,
  filters,
  setSelectedItems,
  selectedItems,
  columns,
  setSelectedAllItems
}: SmartDownloadContextTableProps) => {
  const  {t } = useTranslation();
  const [selectAllVisibleSuppliers, setSelectAllVisibleSuppliers] = useState<boolean>(false);
  const [selectAllPaginatedSuppliers, setSelectAllPaginatedSuppliers] = useState<boolean>(false);
  const toggleItem = (item: any) => {
    if (selectAllVisibleSuppliers || selectAllPaginatedSuppliers) {
      setSelectAllVisibleSuppliers(false);
      setSelectAllPaginatedSuppliers(false);
  }
    if (selectedItems.some((s) => s.id === item.id)) {
      setSelectedItems(selectedItems.filter((s) => s.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleSelectAll = (value: boolean) => {
    setSelectAllVisibleSuppliers(value);
    if (!value) {
      setSelectedItems([]);
      setSelectAllPaginatedSuppliers(false);
      setSelectAllVisibleSuppliers(false);
    } else {
      setSelectedItems(data.map((item) => item));
    }
  };

  const handleSelectionButton = () => {
    if (selectAllPaginatedSuppliers) {
      setSelectedItems([]);
      setSelectAllVisibleSuppliers(false);
      setSelectAllPaginatedSuppliers(false);
    } else {
      setSelectAllPaginatedSuppliers(true);
      setSelectedAllItems(true);
    }
  };

  const isAllSelected = useMemo(() => {
    return selectedItems?.length === data?.length && data?.length > 0;
  }, [selectedItems, data?.length]);

  useEffect(() => {
    if (
      data?.length > 0 &&
      (selectAllPaginatedSuppliers || selectAllVisibleSuppliers)
    ) {
      const ids = data.map((item) => item.id);
      const allIds = [...ids, ...selectedItems];
      const uniqueIds = Array.from(new Set(allIds));
      setSelectedItems(uniqueIds);
    }
  }, [data, selectAllPaginatedSuppliers]);

  return (
    <InfiniteTable
      tableId={"contextTable"}
      autosize
      infiniteScroll={{
        dataLength: data?.length,
        hasNextPage: hasNextPage,
        fetchNextPage: fetchNextPage
      }}
      isLoading={isLoading}
      emptyText={t("noItems")}
      showEmptyText={data?.length === 0}
      id="table-container-modal"
    >
      <Thead>
        <Tr bg={COLORS.table.headerBg}>
          <Th key={"selectAllCheckbox"} width={10}>
            <Checkbox
              borderColor={"gray.500"}
              isChecked={isAllSelected}
              onChange={() => toggleSelectAll(!selectAllVisibleSuppliers)}
            />
          </Th>
          {Array.isArray(columns) && columns.map((column) => (
            <Th width={200} key={column.field}>
              <TableColumnHeader
                text={column.field}
                filter={{
                  isActive: !!filters[column.field] &&
                    (!Array.isArray(filters[column.field]) ||
                      !!filters[column.field][0]),
                  component: (
                    <ColumnFilterComponent
                      type={column.type}
                      value={filters[column.field]}
                      updateFilter={(value) =>
                        updateFilters(
                          column.field,
                          value as string | string[]
                        )
                      }
                    />
                  )
                }}
                sort={{
                  handler: (direction) =>
                    setSort({ field: column.field, direction }),
                  direction:
                    sort?.field === column.field
                      ? sort.direction
                      : null
                }}
                isInModal
              />
            </Th>
          ))}
        </Tr>
      </Thead>
      {data?.length > 0 && (
        <Tbody
          style={selectAllVisibleSuppliers ? { "tableLayout": "auto" } : { "tableLayout": "fixed" }}
          className={data?.length > 0 ? "real-row" : "skeleton-row"}
        >
          {selectAllVisibleSuppliers && (
            <Tr width="100%">
              <Td colSpan={columns.length + 3} backgroundColor={"gray.100"} width={'100%'}>
                <Text textAlign="center" mx="auto">
                  {!selectAllPaginatedSuppliers &&
                    t("smartDownload.selectedVisibleItems", { ns: "common" })}
                  {selectAllPaginatedSuppliers &&
                    t("smartDownload.selectedNotVisibleItems", {
                      ns: "common",
                      count: count
                    })}
                  {hasNextPage && (
                    <Button
                      mt="10px"
                      ml="4px"
                      colorScheme="blue"
                      variant="link"
                      onClick={() => handleSelectionButton()}
                    >
                      {selectAllPaginatedSuppliers ? t("clearSelection" ,{ ns: "sites" }) : t("smartDownload.selectAll", { ns: "common" })}
                    </Button>
                  )}
                </Text>
              </Td>
            </Tr>
          )}
          {data?.length > 0 ? (
            data.map((item) => (
              <Tr key={item?.id}>
                <Td width={10} onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={selectedItems?.some((s) => s.id === item.id) || false}
                    onChange={(e) => {
                      toggleItem(item);
                      e.stopPropagation();
                    }}
                  />
                </Td>
                <Td width={200}>
                  {item?.name}
                  {item?.derivedFromCompanyId &&
                    <Tag size={"md"} key={item?.vat} variant='outline' colorScheme='gray' marginLeft={2}>
                      <TagLabel>{t("derived", {ns: "supplier"})}</TagLabel>
                    </Tag>
                  }
                </Td>
                <Td width={200}>{item?.vat || ''}</Td>
                <Td width={200}>{item?.street || ''}</Td>
              </Tr>
            ))
          ) : (
            <SkeletonTableRow
              columnNumber={4}
              rowNumber={3}
              hasAction={false}
            />
          )}
        </Tbody>
      )}
    </InfiniteTable>
  );
};
