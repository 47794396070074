import React, { FC, useEffect, useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import { UploadImageView } from "./UploadImageView";
import { Flex, IconButton, Image, Skeleton, useToast } from "@chakra-ui/react";
import { IoAlertCircleSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

interface Props {
  imageURL?: string;
  isLoaded?: boolean;
  isEditing?: boolean;
  disableFlag?: boolean;

  onChange?: (value: File) => void;
}

const ImageBox: FC<Props> = ({ imageURL, isLoaded, isEditing, onChange }) => {
  const { t } = useTranslation();
  const [showUploadView, setShowUploadView] = useState<boolean>(false);
  const [droppedImage, setDroppedImage] = useState<File>();
  const toast = useToast();

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e?.dataTransfer?.files[0]) {
      setDroppedImage(e.dataTransfer.files[0]);
      setShowUploadView(true);
    }
  };
  useEffect(() => {
    !showUploadView && setDroppedImage(null);
  }, [showUploadView]);
  useEffect(() => {
    isEditing &&
      toast({
        render: () => (
          <Flex
            color="white"
            p={3}
            bg="#bee3f8"
            textColor={"black"}
            borderRadius={10}
            gap={5}
          >
            <IoAlertCircleSharp size={"3rem"} color={"#3182ce"} />
            {t("dropImage", { ns: "common" })}
            <IoMdClose
              cursor={"pointer"}
              size={"3rem"}
              color={"black"}
              onClick={() => toast.closeAll()}
            />
          </Flex>
        ),
        duration: 5000,
        isClosable: true,
      });
  }, [isEditing]);

  return (
    <Flex
      direction="row"
      width="200px"
      justifyContent={"center"}
      borderRadius="10px"
    >
      <Skeleton
        height="160px"
        width="160px"
        borderRadius="10px"
        isLoaded={isLoaded}
      >
        {onChange && isEditing ? (
          <Flex flexDirection={"column"} gap={2} w={"160px"} h={"160px"}>
            <Image
              key={"photo"}
              borderRadius="10px"
              height="100%"
              width="100%"
              src={imageURL}
              backgroundColor={COLORS.lightYellow}
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => e.preventDefault()}
            />
          </Flex>
        ) : (
          <Image
            key={"photo"}
            borderRadius="10px"
            height="100%"
            width="100%"
            src={imageURL}
            backgroundColor={COLORS.lightYellow}
          />
        )}
      </Skeleton>
      {onChange && isEditing && (
        <IconButton
          onClick={() => setShowUploadView(true)}
          aria-label="upload-picture"
          icon={<FiUploadCloud />}
          size="md"
          variant="ghost"
          cursor="pointer"
          _hover={{ backgroundColor: undefined }}
        />
      )}

      {showUploadView && (
        <UploadImageView
          isOpen
          droppedImage={droppedImage}
          title={t("uploadBoxTitle")}
          onSave={onChange}
          onCancel={() => setShowUploadView(false)}
        />
      )}
    </Flex>
  );
};

export default ImageBox;
