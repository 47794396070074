import {
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { t } from "i18next";
import DocumentType from "../../../domain/entities/documentType";
import Tag from "../../../domain/entities/tag";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import DeleteButton from "../../components/Common/table/DeleteButton";
import EditButton from "../../components/Common/table/EditButton";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import TagListField from "../../components/Common/table/TagListField";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import React from "react";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentTypeFilters } from "../../../domain/repositories/documentTypeRepository";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf from "../../components/Permissions/RenderIf";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

type CompanyDocumentTypesTableProps = {
  documentTypes: DocumentType[];
  documentTypesHasNextPage: boolean;
  documentTypesFetchNextPage: () => void;
  allTags: Tag[];
  documentTypeFetching: boolean;
  openEditModal: (docTypes: DocumentType) => void;
  askConfirmDelete: (docTypes: DocumentType) => void;
  onUpdate: (tag: Tag) => void;
  target?: string;
  updateFilters: (column: string, value: string | string[]) => void;
  filters?: GetDocumentTypeFilters;
  setSort?: (sort: SortMeta) => void;
  sort?: SortMeta;
  permissions: {
    editDocumentTypes: Permission;
    deleteDocumentTypes: Permission;
}
};

const CompanyDocumentTypesTableView = ({
  documentTypes,
  documentTypesHasNextPage,
  documentTypesFetchNextPage,
  allTags,
  documentTypeFetching,
  openEditModal,
  askConfirmDelete,
  onUpdate,
  target,
  updateFilters,
  filters,
  setSort,
  sort,
  permissions
}: CompanyDocumentTypesTableProps) => {
  const columns = [
    { field: "name", type: "text", label: t("name", { ns: "common" }) },
    {
      field: "description",
      type: "text",
      label: t("description", { ns: "common" }),
    },
    { field: "tags", type: "tags", label: t("tags") },
  ] as {
    field: keyof GetDocumentTypeFilters;
    type: "text" | "tags";
    label: string;
  }[];

  const tableId = `company-doc-types-table-${target}`;
  const [isTablet] = useMediaQuery("(max-width: 1300px)");

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width="calc(100vw - 198px)"
      position="relative"
      overflow="auto"
      marginTop={3}
      id={tableId}
    >
      <InfiniteTable
        autosize
        infiniteScroll={{
          dataLength: documentTypes.length,
          hasNextPage: documentTypesHasNextPage,
          fetchNextPage: documentTypesFetchNextPage,
        }}
        tableId={tableId}
        isLoading={documentTypeFetching}
        emptyText={t("noDocumentTypes", { ns: "documents" })}
      >
        <Thead>
          <Tr>
            {columns.map((column) => {
              return (
                <Th width={isTablet ? "300px" : undefined} key={column.field}>
                  <TableColumnHeader
                    overflowHidden={(column.type as "text" | "tags") !== "tags"}
                    text={column.label}
                    filter={{
                      component: (
                        <ColumnFilterComponent
                          type={column.type}
                          value={filters[column.field]}
                          updateFilter={(value) =>
                            updateFilters(
                              column.field,
                              value as string | string[],
                            )
                          }
                          tags={allTags}
                          namespace="common"
                        />
                      ),
                      isActive: !!(Array.isArray(filters[column.field])
                        ? filters[column.field][0]
                        : filters[column.field]),
                    }}
                    sort={
                      column.field !== "tags" && {
                        handler: (direction) =>
                          setSort({ field: column.field, direction }),
                        direction:
                          sort && sort.field === column.field
                            ? sort.direction
                            : null,
                      }
                    }
                    tableId={tableId}
                  />
                </Th>
              );
            })}
            <Th width={"100px"}></Th>
          </Tr>
        </Thead>
        <Tbody>
          {documentTypes?.map((documentType) => (
            <Tr key={documentType.id}>
              <Td width={isTablet ? "300px" : undefined}>
                {documentType.name}
              </Td>
              <Td width={isTablet ? "300px" : undefined}>
                {documentType.description}
              </Td>
              <Td width={isTablet ? "300px" : undefined}>
                <TagListField
                  tags={documentType.tags}
                  editable={true}
                  onUpdate={onUpdate}
                />
              </Td>

              <Td w="100px">
                <RenderIf permissions={permissions.editDocumentTypes}  check={PermissionCheck.All}>
                  <EditButton onClick={() => openEditModal(documentType)} />
                </RenderIf>
                <RenderIf permissions={permissions.deleteDocumentTypes}  check={PermissionCheck.All}>
                  {target !== "system" && (
                    <DeleteButton
                      onClick={() => askConfirmDelete(documentType)}
                    />
                  )}
                </RenderIf>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </InfiniteTable>
    </Flex>
  );
};

export default CompanyDocumentTypesTableView;
