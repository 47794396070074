import { Grid, Select, Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "../../../../domain/entities/badge";
import {
  BadgeLogEntry,
  BadgeLogEntryAction,
  BadgeLogEntryActionResult,
} from "../../../../domain/entities/badgeLogEntry";
import BadgeReader from "../../../../domain/entities/badgeReader";
import BaseModal from "../../Common/alerts/BaseModal";
import EnumSelect from "../../Common/EnumSelect";
import InputAnimatedTimePicker from "../../Common/InputAnimatedTimePicker";
import InputField from "../../Common/table/InputField";
import { formatDateBasedOnLanguage } from "../../../../utils";

type CreateUpdateStampingModalProps = {
  onCancel: () => void;
  onConfirm: (badgeLogEntry: BadgeLogEntry) => void;
  stampFromBadge: boolean;
  badgeLogEntryToUpdate?: BadgeLogEntry;
  currentBadge?: Badge;
  currentBadgeReader?: BadgeReader;
  badgeReaders?: BadgeReader[];
  badges?: Badge[];
};
const CreateUpdateStampingModal = ({
  onCancel,
  onConfirm,
  badgeLogEntryToUpdate,
  currentBadge,
  currentBadgeReader,
  badgeReaders = [],
  badges = [],
  stampFromBadge,
}: CreateUpdateStampingModalProps) => {
  const { t } = useTranslation("badges");

  const [actionDate, setActionDate] = useState<Date>(
    badgeLogEntryToUpdate ? badgeLogEntryToUpdate?.actionDate : undefined
  );
  const [actionTime, setActionTime] = useState<Date>(
    badgeLogEntryToUpdate ? badgeLogEntryToUpdate?.actionDate : undefined
  );
  const [actionType, setActionType] = useState<BadgeLogEntryAction>(
    badgeLogEntryToUpdate ? badgeLogEntryToUpdate.actionType : undefined
  );
  const [actionResult, setActionResult] = useState<BadgeLogEntryActionResult>(
    badgeLogEntryToUpdate ? badgeLogEntryToUpdate.actionResult : undefined
  );
  const [badgeReader, setBadgeReader] = useState<BadgeReader>(
    currentBadgeReader ? currentBadgeReader : badgeLogEntryToUpdate?.reader
  );
  const [badge, setBadge] = useState<Badge>(
    currentBadge ? currentBadge : badgeLogEntryToUpdate?.badge
  );

  const [actionDateError, setActionDateError] = useState<string | null>(null);
  const [actionTimeError, setActionTimeError] = useState<string | null>(null);
  const [actionTypeError, setActionTypeError] = useState<string | null>(null);
  const [actionResultError, setActionResultError] = useState<string | null>(
    null
  );
  const [badgeReaderError, setBadgeReaderError] = useState<string | null>(null);
  const [badgeError, setBadgeError] = useState<string | null>(null);

  const handleDateChange = (fieldName, value) => {
    setActionDateError(null);
    setActionDate(new Date(value));
  };

  const handleTimeChange = (fieldName, value) => {
    setActionTimeError(null);
    const [hours, minutes] = value.split(":");
    const today = new Date();
    setActionTime(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        parseInt(hours),
        parseInt(minutes)
      )
    );
  };

  const handleChangeActionType = (value: BadgeLogEntryAction) => {
    setActionTypeError(null);
    setActionType(BadgeLogEntryAction[value]);
  };

  const handleChangeActionResult = (value: BadgeLogEntryActionResult) => {
    setActionResultError(null);
    setActionResult(BadgeLogEntryActionResult[value]);
  };

  const handleChangeBadgeReader = (value: string) => {
    setBadgeReaderError(null);
    setBadgeReader(badgeReaders.find((obj) => obj.name === value));
  };

  const handleChangeBadge = (value: string) => {
    setBadgeError(null);
    setBadge(badges.find((obj) => obj.code === value));
  };

  const handleCreate = () => {
    let anyError = false;
    if (!actionDate) {
      setActionDateError(t("stampingDateError"));
      anyError = true;
    }
    if (!actionTime) {
      setActionTimeError(t("stampingTimeError"));
      anyError = true;
    }
    if (!actionType) {
      setActionTypeError(t("stampingTypeError"));
      anyError = true;
    }
    if (badgeLogEntryToUpdate && !actionResult) {
      setActionResultError(t("stampingResultError"));
      anyError = true;
    }

    if (stampFromBadge && !badgeReader) {
      setBadgeReaderError(t("stampingBadgeReaderError"));
      anyError = true;
    }

    if (!stampFromBadge && !badge) {
      setBadgeError(t("stampingBadgeError"));
      anyError = true;
    }

    if (!anyError) {
      const stampingDate = new Date(
        actionDate.getFullYear(),
        actionDate.getMonth(),
        actionDate.getDate(),
        actionTime.getHours(),
        actionTime.getMinutes(),
        actionTime.getSeconds()
      );

      onConfirm({
        id: badgeLogEntryToUpdate ? badgeLogEntryToUpdate.id : "",
        actionDate: stampingDate,
        actionResult: actionResult,
        badge,
        badgeReaderName: badgeReader?.name,
        actionType: actionType,
        reader: badgeReader,
        anomaly: false,
      });
    }
  };
  return (
    <BaseModal
      title={t(badgeLogEntryToUpdate ? "updateStamping" : "createStamping")}
      onClose={onCancel}
      onConfirm={handleCreate}
      onCancel={onCancel}
      disableOnClose={true}
      closeOnOverlayClick={false}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <Box>
          <InputField
            field={"actionDate"}
            type={"date"}
            label={t("stampingDate")}
            value={formatDateBasedOnLanguage(actionDate) ?? ""}
            onChange={handleDateChange}
          />
          <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
            {actionDateError}
          </Text>
        </Box>

        <Box>
          <InputAnimatedTimePicker
            onChange={handleTimeChange}
            id={"actionTime"}
            label={t("stampingTime")}
            defaultValue={
              `${actionTime
                ?.getHours()
                ?.toString()
                ?.padStart(2, "0")}:${actionTime
                ?.getMinutes()
                ?.toString()
                ?.padStart(2, "0")}` ?? ""
            }
          />
          <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
            {actionTimeError}
          </Text>
        </Box>
        <Box>
          <EnumSelect
            isRequired
            value={actionType}
            emptySuggestion={t("stampingType")}
            onChange={(v) => handleChangeActionType(v)}
            enumObject={BadgeLogEntryAction}
            optionSlot={(key, label) => (
              <option key={key} value={key}>
                {t(`actionTypes.${label}`, { ns: "badges" })}
              </option>
            )}
          />
          <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
            {actionTypeError}
          </Text>
        </Box>

        {badgeLogEntryToUpdate && (
          <Box>
            <EnumSelect
              isRequired
              value={actionResult}
              emptySuggestion={t("stampingResult")}
              onChange={(v) => handleChangeActionResult(v)}
              enumObject={BadgeLogEntryActionResult}
              optionSlot={(key, label) => (
                <option key={key} value={key}>
                  {t(`actionResults.${label}`, { ns: "badges" })}
                </option>
              )}
            />
            <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
              {actionResultError}
            </Text>
          </Box>
        )}

        {stampFromBadge && (
          <Box>
            <Select
              required={true}
              value={badgeReader?.name}
              onChange={(v) => handleChangeBadgeReader(v.target.value)}
            >
              <option value="" disabled selected>
                {t("selectBadgeReader")}
              </option>
              {badgeReaders &&
                badgeReaders?.map((badgeReader) => (
                  <option key={badgeReader.id} value={badgeReader.name}>
                    {badgeReader.name}
                  </option>
                ))}
            </Select>
            <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
              {badgeReaderError}
            </Text>
          </Box>
        )}

        {!stampFromBadge && (
          <Box>
            <Select
              required={true}
              value={badge?.code}
              onChange={(v) => handleChangeBadge(v.target.value)}
            >
              <option value="" disabled selected>
                {Array.isArray(badges) &&  badges.length ? t("selectBadge") : t("noBadgesFound")}
              </option>
              {Array.isArray(badges) &&
                badges?.map((badge) => (
                  <option key={badge.id} value={badge.code}>
                    {badge.code}
                  </option>
                ))}
            </Select>
            <Text marginLeft={2} marginTop={2} fontSize={14} color={"red"}>
              {badgeError}
            </Text>
          </Box>
        )}
      </Grid>
    </BaseModal>
  );
};

export default CreateUpdateStampingModal;
