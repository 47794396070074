import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import Typology from "../../../domain/entities/typology";
import Steps from "../../components/Common/stepper/Steps";
import Site from "../../../domain/entities/site";
import RequirementsGroup from "../../../domain/entities/requirementsGroup";

import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useSteps
} from "@chakra-ui/react";
import Variant from "../../../domain/entities/variant";
import {FormProvider, useForm} from "react-hook-form";
import useSitesListViewModel, {CreateArgs} from "../../hooks/Site/useSitesListViewModel";
import {CustomField} from "../../../domain/entities/customFields";
import HookTextField from "../../components/Common/inputs/hook-form/HookTextField";
import HookTagField from "../../components/Common/inputs/hook-form/HookTagField";
import HookSelectField from "../../components/Common/inputs/hook-form/HookSelectField";

interface Props {
  onCancel: () => void;
  onConfirm: ReturnType<typeof useSitesListViewModel>["createSite"];
  requirementsGroups: RequirementsGroup[];
  typologies: Typology[];
  siteVariants: Variant[];
  setCreationRequirementGroup: Dispatch<SetStateAction<string>>;
  createSiteIsLoading: boolean;
  customFields: CustomField[];
}

const CreateSiteView: FC<Props> = ({
  onCancel,
  onConfirm,
  requirementsGroups,
  createSiteIsLoading,
  typologies: allTypologies,
  siteVariants,
  setCreationRequirementGroup,
  customFields
}) => {
  const { t } = useTranslation("sites");

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 3,
  });

  const methods = useForm<CreateArgs>({
    defaultValues: {
      site: {
        protocol: "",
        name: "",
        address: "",
        city: "",
        zipCode: "",
        ...customFields?.reduce((acc, item) => {
          acc[`custom_${item.key}`] = '';
          return acc;
        }, {})
      } as Site,
    }
  })

  const { handleSubmit, trigger, formState: { isValid} } = methods;

  const validateStep = async () => {
    let isValidStep = false;
    if (activeStep === 0) {
      isValidStep = await trigger('site');
    } else if (activeStep === 1) {
      isValidStep = await trigger(['typology', 'requirementsGroup']);
    } else if (activeStep === 2) {
      isValidStep = await trigger('siteVariant');
    }
    return isValidStep;
  };
  
  const nextStep = async () => {
    const isValidStep = await validateStep();
    if (isValidStep && activeStep < 2) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 2) {
      await handleSubmit((data) => {
        onConfirm(data);
        onCancel();
      })();
    }
  };

  const prevStep = async () => {
    if(activeStep === 0) {
      onCancel()
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <Modal isOpen onClose={onCancel} size="3xl" closeOnOverlayClick={false} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box padding={2} border="1px" borderColor="gray.200" borderRadius={6}>
            <Steps
              selected={activeStep}
              onSelect={(index) => isValid && setActiveStep(index)}
              steps={[
                { title: t("generalInformation") },
                { title: t("documents") },
                { title: t("variants") },
              ]}
            />
          </Box>
        </ModalHeader>

        <ModalBody
          id="modal-body"
          zIndex={"10"}
          overflow={"unset"}
        >
          <FormProvider {...methods}>
            {activeStep === 0 && (
              <Flex align="start" direction="column">
                <Text color={COLORS.sikuroBlue} fontSize="large">
                  {t("summary")}
                </Text>
                <Text>{t("create.subtitle")}</Text>
                <Text marginTop={4}>{t("generalInformation")}</Text>
                <Divider />
                <Flex w="100%" gap={4} mt={4}>
                  <HookTextField name="site.protocol" label={t("protocol")} options={{ required: t('error.protocolRequired') }} />
                  <HookTextField name="site.name" label={t("name")} options={{ required: t('error.nameRequired') }} />
                </Flex>

                <Text marginTop={4}>{t("location")}</Text>
                <Divider />
                <Flex w="100%" gap={4} mt={4}>
                  <Box w="55%">
                    <HookTextField name="site.address" label={t("address")}/>
                  </Box>
                  <Box w="27%">
                    <HookTextField name="site.city" label={t("city")} />
                  </Box>
                  <Box w="18%">
                    <HookTextField name="site.zipCode" label={t("zipCode")} />
                  </Box>
                </Flex>
                {customFields && customFields?.length > 0 &&
                  <>
                    <Text marginTop={4}>{t("customFields.title", { ns: "settings" })}</Text>
                    <Divider />
                    <Flex w="100%" gap={4} mt={4}>
                      {customFields?.map((field) => (
                        <HookTextField key={field.key} name={`site.custom_${field.key}`} label={field.label} />
                      ))}
                    </Flex>
                  </>}
              </Flex>
            )}

            {activeStep === 1 && (
              <Flex align="start" direction="column">
                <Text color={COLORS.sikuroBlue} fontSize="large">
                  {t("sitePresets")}
                </Text>
                <Text mt={1} mb={3}>
                  {t("sitePresetsDescription")}
                </Text>

                <HookTagField
                  selectProps={{ options: allTypologies, placeholder: t("noPresetSelected", { ns: "sites" }) }}
                  name="typology"
                  rules={{required:  t('error.typologyRequired')}}
                />

                <Text mt={3} color={COLORS.sikuroBlue} fontSize="large">
                  {t("requirementsGroup")}
                </Text>
                <Text my={1}>{t("requirementsGroupDescription")}</Text>
                <HookSelectField
                  optionKeys={{value: "id", label: "name"}}
                  name="requirementsGroup"
                  rules={{required: t('error.requirementsGroupsRequired')}}
                  selectProps={{
                    options: requirementsGroups,
                    placeholder: t("noRequirementsGroupSelected"),
                    onChange: (rg) => setCreationRequirementGroup(rg.id)
                  }}
                />
              </Flex>
            )}

            {activeStep === 2 && (
              <Flex mt={3} align="start" direction="column" zIndex={"10"}>
                <Text color={COLORS.sikuroBlue} fontSize="large">
                  {t("companyAndSiteVariants")}
                </Text>
                <Text my={3}>{t("selectSiteVariant")}</Text>
                <HookTagField
                  selectProps={{ options: siteVariants }}
                  name="siteVariant"
                  rules={{required: t('error.variantRequired')}}
                />
              </Flex>
            )}
          </FormProvider>
        </ModalBody>

        <ModalFooter zIndex={"1"}>
          <Spacer />
          <Button
            onClick={nextStep}
            bgColor={COLORS.sikuroBlue}
            size="sm"
            color="white"
            isLoading={createSiteIsLoading}
          >
            {t(activeStep <= 0 ? "next" : "confirm", { ns: "common" })}
          </Button>
          <Button
            onClick={prevStep}
            bgColor={COLORS.red}
            size="sm"
            color="white"
            marginLeft={4}
          >
            {t(activeStep <= 0 ? "cancel" : "back", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateSiteView;
