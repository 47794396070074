import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { useParams } from "react-router-dom";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import { useTranslation } from "react-i18next";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";
import { getSectionPermissions } from "../../Permissions/Permissions";

const SiteResourcesSubNav = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("navbar");
  const items: NavItemProps[] = [
      {
        Icon: WorkerIcon,
        path: `/sites/${siteId}/resources/workers`,
        name: t("top.workers"),
        permissions: getSectionPermissions("sites", "workers"),
      },
      {
        Icon: VehicleIcon,
        path: `/sites/${siteId}/resources/vehicles`,
        name: t("top.vehicles"),
        permissions: getSectionPermissions("sites", "vehicles"),
      },
      {
        Icon: MachineIcon,
        path: `/sites/${siteId}/resources/machines`,
        name: t("top.machinery"),
        permissions: getSectionPermissions("sites", "machines"),
      },
      {
        Icon: ToolIcon,
        path: `/sites/${siteId}/resources/tools`,
        name: t("top.tools"),
        permissions: getSectionPermissions("sites", "tools"),
      },
      {
        Icon: ChemicalIcon,
        path: `/sites/${siteId}/resources/chemicals`,
        name: t("top.chemicals"),
        permissions: getSectionPermissions("sites", "chemicals"),
      },
    ];
  return <SubNavBar items={items} />;
};

export default SiteResourcesSubNav;
