interface RequestOptions {
	method?: string;
	headers?: { [key: string]: string };
	body?: string | FormData;
}

/**
 * Class used to store the authentication token and give it to the fetch call
 *
 * setToken: allow to store the bearer token
 * fetchWithToken: wrap the fetch call adding the bearer header
 */
export class ApiService {
	private token;

	setToken(token) {
		this.token = token;
	}

	async fetchWithToken(url, options: RequestOptions = {}) {
		const headers = {
			...options.headers,
			Authorization: `Bearer ${this.token}`,
		};
		const response = await fetch(url, { ...options, headers });
		return response;
	}
}

const requestOptions = (method: string, body?: string): RequestOptions => {
	const headers = { 'Content-Type': 'application/json' };
	return body ? { body, method, headers } : { method, headers };
};

export const postRequest = (body?: string): RequestOptions => {
	return requestOptions('POST', body);
};

export const putRequest = (body?: string): RequestOptions => {
	return requestOptions('PUT', body);
};

export const patchRequest = (body?: string): RequestOptions => {
	return requestOptions('PATCH', body);
};

export const deleteRequest = (body?: string): RequestOptions => {
	return requestOptions('DELETE', body);
};

export const getRequest = (): RequestOptions => {
	return requestOptions('GET');
};

export const processBoolResponse = async (response: Response): Promise<boolean> => {
	if (!response.ok) {
		const { message } = await response.json();
		return Promise.reject(new Error(message));
	}

	return Promise.resolve(true);
};

export const processResponse = async <T>(response: Response): Promise<T> => {
	if (!response.ok) {
		const { message } = await response.json();
		return Promise.reject(new Error(message));
	}

	return await response.json();
};

export const processArrayResponse = async <T>(response: Response): Promise<Array<T>> => {
	if (!response.ok) {
		const { message } = await response.json();
		return Promise.reject(new Error(message ?? 'error.unknown'));
	}

	const { results } = await response.json();
	return Promise.resolve(results ?? []);
};
