import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { useParams } from "react-router-dom";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import { useTranslation } from "react-i18next";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";
import { TbBuildingFactory } from "react-icons/tb";
import { Permission } from "../../Permissions/Permissions";

const SiteApproveEvaluationsSubNav = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("navbar");

  const items: NavItemProps[] = [
    {
      icon: TbBuildingFactory,
      path: `/sites/${siteId}/approve/companies`,
      name: t("sub.companies"),
      permissions: [Permission.Sites_ShowCompanyPendingEvaluations]
    },
    {
      Icon: WorkerIcon,
      path: `/sites/${siteId}/approve/workers`,
      name: t("sub.workers"),
      permissions: [Permission.Sites_ShowWorkerPendingEvaluations]
    },
    {
      Icon: VehicleIcon,
      path: `/sites/${siteId}/approve/vehicles`,
      name: t("sub.vehicles"),
      permissions: [Permission.Sites_ShowVehiclePendingEvaluations]
    },
    {
      Icon: MachineIcon,
      path: `/sites/${siteId}/approve/machines`,
      name: t("sub.machines"),
      permissions: [Permission.Sites_ShowMachinePendingEvaluations]
    },
    {
      Icon: ToolIcon,
      path: `/sites/${siteId}/approve/tools`,
      name: t("sub.tools"),
      permissions: [Permission.Sites_ShowToolPendingEvaluations]
    },
    {
      Icon: ChemicalIcon,
      path: `/sites/${siteId}/approve/chemicals`,
      name: t("sub.chemicals"),
      permissions: [Permission.Sites_ShowChemicalPendingEvaluations]
    },
  ];

  return <SubNavBar items={items} />;
};

export default SiteApproveEvaluationsSubNav;
