import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Flex, Tbody, Td, Th, Thead, Tr, useMediaQuery } from "@chakra-ui/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import {
  DocumentActionLogEntry,
  DocumentFileLogEntryAction,
} from "../../../../domain/entities/documentLogEntry";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentLogFilter } from "../../../../domain/repositories/documentRepository";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { UpdateFilter } from "../../../hooks/Site/useSiteResourceDetailViewModel";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";

type DocumentActionsHistoryTableProps = {
  documentFileLogs: DocumentActionLogEntry[];
  updateFilterDocumentLog: UpdateFilter;
  filterDocumentLog: GetDocumentLogFilter;
  sortDocumentLog: SortMeta;
  setSortDocumentLog: (sort: SortMeta) => void;
  documentLogsFetching: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  autosize?: boolean;
};

const DocumentActionsHistoryTable = ({
  documentFileLogs,
  updateFilterDocumentLog,
  filterDocumentLog,
  sortDocumentLog,
  setSortDocumentLog,
  documentLogsFetching,
  hasNextPage,
  fetchNextPage,
  autosize,
}: DocumentActionsHistoryTableProps) => {
  const { t } = useTranslation("documents");
  const [isTablet] = useMediaQuery("(min-width: 1070px)");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const logColumns = ["actionDate", "author", "isPublic", "expiresAt"];
  const selectOptions = {
    actionType: Object.values(DocumentFileLogEntryAction).map((state) => ({
      option: state,
      label: t(`documentLogAction.${state}`, { ns: "enum" }),
    })),
  };
  const excludeFilters = ["isPublic", "expiresAt"];

  const filterColumnTypes = {
    actionDate: "date-range",
    fileName: "text",
    actionType: "select",
  };

  const updateCell = (log: DocumentActionLogEntry): ReactNode => {
    const prev = log.oldDate ? formatDateBasedOnLanguage(log.oldDate) : "-";
    const next = log.newDate ? formatDateBasedOnLanguage(log.newDate) : "-";
    return (
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        flexDirection: isTablet ? "row" : "column"
      }}>
        <Box minWidth={90} textAlign={"center"}>
          {prev}
        </Box>
        <ChevronRightIcon color={COLORS.sikuroBlue} />
        <Box minWidth={90} textAlign={"center"}>
          {next}
        </Box>
      </div>
    );
  };

  const cell = (field: string, log: DocumentActionLogEntry): ReactNode => {
    switch (field) {
      case "actionDate":
        return <>{formatDateBasedOnLanguage(log.actionDate, true)}</>;
      case "author":
        return <>{log?.author}</>;
      case "isPublic":
        return (
          <div style={{
            display: "flex",
            gap: "1.5rem",
            flexDirection: isMobile ? "column" : "row"
          }}>
            {log?.oldIsPublic
              ? t("yes", { ns: "common" })
              : t("no", { ns: "common" })}
            <ChevronRightIcon color={COLORS.sikuroBlue} />
            {log?.newIsPublic
              ? t("yes", { ns: "common" })
              : t("no", { ns: "common" })}
          </div>
        );
      case "expiresAt":
        return updateCell(log);
      default:
        return <></>;
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width={isMobile ? "calc(100vw - 120px)" : "100%"}
      position="relative"
      overflow="auto"
      marginTop={4}
      id="table-container-modal"
    >
      <InfiniteTable
        autosize={autosize}
        infiniteScroll={{
          dataLength: documentFileLogs?.length || 0,
          hasNextPage,
          fetchNextPage,
        }}
        isLoading={documentLogsFetching}
        showEmptyText={documentFileLogs?.length === 0}
        emptyText={t("noDocuments", { ns: "documents" })}
      >
        <Thead>
          <Tr>
            {logColumns.map((key) => (
              <Th
                width={
                  key === "isPublic" ? "80px" :
                    key === "expiresAt" && isTablet ? 160 :
                      120
                }
                key={key}
              >
                <TableColumnHeader
                  text={t(key)}
                  filter={
                    excludeFilters.includes(key)
                      ? undefined
                      : {
                          isActive: !!(Array.isArray(filterDocumentLog[key])
                            ? filterDocumentLog[key][1]
                            : filterDocumentLog[key]),
                          component: (
                            <ColumnFilterComponent
                              selectOptions={
                                selectOptions[key]
                                  ? [...selectOptions[key]]
                                  : undefined
                              }
                              type={filterColumnTypes[key]}
                              value={filterDocumentLog[key] ?? undefined}
                              updateFilter={(value) =>
                                updateFilterDocumentLog(key, value)
                              }
                            />
                          ),
                        }
                  }
                  sort={
                    excludeFilters.includes(key)
                      ? undefined
                      : {
                          handler: (direction) =>
                            setSortDocumentLog({ field: key, direction }),
                          direction:
                            sortDocumentLog?.field === key
                              ? sortDocumentLog.direction
                              : undefined,
                        }
                    }
                    isInModal
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {documentFileLogs?.map((log, k) => (
            <Tr key={log.actionDate + log.actionType + k}>
              <Td width={120}>{cell("actionDate", log)}</Td>
              <Td width={120}>{cell("author", log)}</Td>
              <Td width="80px">{cell("isPublic", log)}</Td>
              <Td width={isTablet ? 160 : 120}>{cell("expiresAt", log)}</Td>
            </Tr>
          ))}
        </Tbody>
      </InfiniteTable>
    </Flex>
  );
};

export default DocumentActionsHistoryTable;
