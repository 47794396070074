import Role from '../../entities/role';
import UseCase from '../../../core/app/usecase';
import RolesRepository from '../../repositories/rolesRepository';

interface Args {
	companyId: string;
	roleId: string;
	name: string;
}

class UpdateRoleName implements UseCase<Args, void> {
	constructor(private repository: RolesRepository) {}
	async Call({ companyId, roleId, name }: Args): Promise<void> {
		return await this.repository.updateRoleName(companyId, roleId, name);
	}
}

export default UpdateRoleName;
