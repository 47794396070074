import BadgeReader from "../../../domain/entities/badgeReader";
import {
  BadgeReadersFilters,
  VirtualBadgeReaderUsersFilters,
} from "../../../domain/repositories/badgeReaderRepository";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import {
  makeCreateBadgeReader,
  makeDeleteBadgeReader,
  makeGetBadgeReaders,
  makeGetBadgeReaderVitualAvailableUsers,
  makeUnlinkBadgeReader,
} from "../../../core/app/factory/badgeReaders/usecases";
import { BadgeReadersType } from "../../hooks/Site/useSiteBadgeReadersViewModel";
import { UserMini } from "../../../domain/entities/user";
import Site from "../../../domain/entities/site";

class SiteBadgeReadersViewModel {
  private getSiteBadgeReadersUseCase = makeGetBadgeReaders();
  private createBadgeReaderUseCase = makeCreateBadgeReader();
  private deleteBadgeReaderUseCase = makeDeleteBadgeReader();
  private getBadgeReaderVitualAvailableUsersUseCase =
    makeGetBadgeReaderVitualAvailableUsers();
  private unlinkBadgeReaderUseCase = makeUnlinkBadgeReader();

  async getSiteBadgeReaders(
    companyId: string,
    siteId: string,
    page: number,
    filter?: BadgeReadersFilters,
    sort?: SortMeta
  ): Promise<PaginatedResults<BadgeReader>> {
    return await this.getSiteBadgeReadersUseCase.Call({
      companyId,
      siteId,
      type: "physical",
      page,
      perPage: 25,
      filter,
      sort,
    });
  }

  async getVirtualSiteBadgeReaders(
    companyId: string,
    siteId: string,
    page: number,
    filter?: VirtualBadgeReaderUsersFilters,
    sort?: SortMeta
  ): Promise<PaginatedResults<BadgeReader>> {
    return await this.getSiteBadgeReadersUseCase.Call({
      companyId,
      siteId,
      type: "virtual",
      page,
      perPage: 25,
      filter,
      sort,
    });
  }

  async createBadgeReader(
    companyId: string,
    siteId: string,
    badgeReader: Partial<BadgeReader>
  ): Promise<BadgeReader> {
    return await this.createBadgeReaderUseCase.Call({
      companyId,
      siteId,
      badgeReader,
      type: "physical",
    });
  }

  async createVirtualBadgeReader(
    companyId: string,
    siteId: string,
    badgeReader: Partial<BadgeReader>
  ): Promise<BadgeReader> {
    return await this.createBadgeReaderUseCase.Call({
      companyId,
      siteId,
      badgeReader,
      type: "virtual",
    });
  }

  async deleteBadgeReader(
    companyId: string,
    siteId: string,
    badgeReaderId: string
  ): Promise<void> {
    return await this.deleteBadgeReaderUseCase.Call({
      companyId,
      siteId,
      badgeReaderId,
    });
  }

  async unlinkBadgeReader(
    companyId: string,
    siteId: string,
    badgeReaderId: string
  ): Promise<void> {
    return await this.unlinkBadgeReaderUseCase.Call({
      companyId,
      siteId,
      badgeReaderId,
    });
  }

  async getBadgeReaderVitualAvailableUsers(
    companyId: string,
    siteId: string,
    page: number,
    filter?: BadgeReadersFilters,
    sort?: SortMeta
  ): Promise<PaginatedResults<UserMini>> {
    return await this.getBadgeReaderVitualAvailableUsersUseCase.Call({
      companyId,
      siteId,
      page,
      perPage: 25,
      filter,
      sort,
    });
  }
}

export { SiteBadgeReadersViewModel };
