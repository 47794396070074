import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isExists, isValid, parse } from "date-fns";
import "./customDateInput.css";
import { Box } from "@chakra-ui/react";
import { getDateFormat, getLocale, getPlaceholder } from "../../../../../utils";

interface CustomDateInputProps {
  value?: string;
  onChange: (date: string | null) => void;
  minDate?: Date;
  maxDate?: Date;
  isRequired?: boolean;
  isClearable?: boolean;
  name?: string;
  hasError?: (val: boolean) => void;
  setValue?: (val: string) => void;
  backgroundColorSelected?: string;
  textColorSelected?: string;
  isDisabled?: boolean;
  withPortal?: boolean;
  readOnly?: boolean
}

const CustomDateInput: React.FC<CustomDateInputProps> = ({
  value,
  onChange,
  minDate,
  maxDate,
  isRequired = false,
  isClearable = true,
  name,
  hasError,
  setValue,
  backgroundColorSelected,
  textColorSelected,
  isDisabled,
  withPortal = false,
  readOnly = false
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const dateFormat = getDateFormat();
  useEffect(() => {
    if (value) {
      const parsedDate = parse(value, dateFormat, new Date());
      if (isValid(parsedDate)) {
        setInputValue(value);
        setSelectedDate(parsedDate);
      }
    } else {
      setInputValue("");
      setSelectedDate(null);
    }
    return () => {
      hasError && hasError(false);
      setInputValue("");
    };
  }, [value, dateFormat]);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    const formattedDate = date ? format(date, dateFormat) : "";
    setInputValue(formattedDate);
    hasError && hasError(false);
    onChange(formattedDate);
  };

  const validateAndSetDate = (input: string) => {
    const cleanedInput = input?.replace(/[^0-9]/g, "");
    let parsedDate: Date | null = null;

    if (cleanedInput?.length === 8) {
      let day, month;
      if (dateFormat === "dd/MM/yyyy") {
        day = parseInt(cleanedInput.slice(0, 2), 10);
        month = parseInt(cleanedInput.slice(2, 4), 10);
      } else if (dateFormat === "MM/dd/yyyy") {
        month = parseInt(cleanedInput.slice(0, 2), 10);
        day = parseInt(cleanedInput.slice(2, 4), 10);
      } else {
        return null;
      }
      const year = parseInt(cleanedInput.slice(4, 8), 10);
      if (isExists(year, month - 1, day)) {
        parsedDate = new Date(year, month - 1, day);
      }
    }
    if (!parsedDate) {
      parsedDate =
        cleanedInput?.length === 8
          ? parse(input, dateFormat, new Date())
          : null;
    }
    if (
      parsedDate &&
      isValid(parsedDate) &&
      parsedDate.getFullYear().toString()?.length === 4
    ) {
      if (minDate && parsedDate < minDate) {
        hasError && hasError(true);
        setSelectedDate(null);
        setInputValue(input);
      } else {
        handleDateChange(parsedDate);
        hasError && hasError(false);
      }
    } else {
      setSelectedDate(null);
      setInputValue(input);
      hasError && hasError(true);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let date = e.target.value;
    if (date?.length >= 2 && !date.includes("/"))
      date = date.slice(0, 2) + "/" + date.slice(2);
    if (date?.length >= 5) date = date.slice(0, 5) + "/" + date.slice(5);
    if (date?.length >= 8) {
      setSelectedDate(new Date(date));
    }
    setValue && setValue(date);
    setInputValue(date);
    validateAndSetDate(date);
  };

  const handleInputBlur = () => {
    if (inputValue && isRequired) {
      validateAndSetDate(inputValue);
    }
  };

  return (
    <Box width={"100%"} overflow={"unset"}>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        onChangeRaw={handleInputChange}
        onBlur={handleInputBlur}
        isClearable={isClearable}
        showYearDropdown
        yearDropdownItemNumber={35}
        scrollableYearDropdown
        openToDate={selectedDate}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat={dateFormat}
        locale={getLocale()}
        name={name}
        placeholderText={getPlaceholder()}
        disabled={isDisabled}
        withPortal={withPortal}
        readOnly={readOnly}
        customInput={
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoComplete={"new password"}
            min={minDate ? minDate?.toDateString() : ""}
            max={maxDate ? maxDate?.toDateString() : ""}
            style={{
              border: "1px solid #CBD5E0",
              borderRadius: "5px",
              zIndex: 1,
              width: "100%",
              height: "2.5rem",
              paddingLeft: "1rem",
              color: textColorSelected ? textColorSelected : isDisabled ? 'gray' : "black",
              backgroundColor: backgroundColorSelected
                ? backgroundColorSelected
                : "white",
            }}
          />
        }
      />
    </Box>
  );
};

export default CustomDateInput;
