import { FC, useState } from "react";
import { Alert } from "../../Common/Alert";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfirmAlert } from "../../Common/ConfirmAlert";
import { UserSiteRole } from "../../../../domain/entities/user";
import { Flex, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import InputAnimatedSelect from "../../../components/Common/InputAnimatedSelect";
import DeleteButton from "../../../components/Common/table/DeleteButton";
import TableColumnHeader from "../../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../../components/Common/table/ColumnFilterComponent";
import SimpleTable from "../../../components/Common/table/SimpleTable";

interface Props {
  autosize?: boolean;
  siteRolesProps: any;
  canAdd: boolean
  canDelete: boolean
}

const UserSiteRoles: FC<Props> = ({ autosize, siteRolesProps, canAdd, canDelete }) => {
  const [askRemove, setAskRemove] = useState<UserSiteRole | undefined>(
    undefined,
  );
  const [showDuplicate, setShowDuplicate] = useState<
    { site: string; role: string } | undefined
  >(undefined);
  const [askUpdate, setAskUpdate] = useState<
    { old: UserSiteRole; newRole: { id: string; name: string } } | undefined
  >(undefined);

  const { t } = useTranslation("settings");
  const {
    roles,
    sites,
    userSiteRoles,
    isLoading,
    context,
    addUserSiteRole,
    deleteUserSiteRole,
    updateUserSiteRole,
    deleteUserSiteRoleIsLoading,
    siteRoleFilters: filters,
    setSiteRoleFilters: setFilters,
  } = siteRolesProps;

  const confirmDelete = async () => {
    await deleteUserSiteRole({
      siteId: askRemove.site.id,
      roleId: askRemove.role.id,
    });
    setAskRemove(undefined);
  };

  const confirmUpdate = () => {
    updateUserSiteRole({
      siteId: askUpdate.old.site.id,
      oldRoleId: askUpdate.old.role.id,
      newRoleId: askUpdate.newRole.id,
    });
    setAskUpdate(undefined);
  };

  const roleDidChange = (old: UserSiteRole, newRoleId: string) => {
    const role = roles.find((r) => r.id === newRoleId);
    setAskUpdate({ old, newRole: role });
  };

  return (
    <Flex id="table-container">
      <SimpleTable
        autosize={autosize}
        tableId="user-site-roles-table"
        showEmptyText={userSiteRoles?.length === 0}
        isLoading={isLoading}
      >
        <Thead>
          <Tr>
            <Th>
              <TableColumnHeader
                text={t("userSiteRoles.site")}
                sort={{
                  direction:
                    filters?.sort?.field === "site"
                      ? filters.sort.direction
                      : undefined,
                  handler: (d) =>
                    setFilters({
                      ...filters,
                      sort: { field: "site", direction: d },
                    }),
                }}
                filter={{
                  isActive: filters?.site?.length > 0,
                  component: (
                    <ColumnFilterComponent
                      type="text"
                      value={filters?.site ?? ""}
                      updateFilter={(v: string) =>
                        setFilters({ ...filters, site: v })
                      }
                    />
                  ),
                }}
              />
            </Th>
            <Th>
              <TableColumnHeader
                text={t("userSiteRoles.role")}
                sort={{
                  direction:
                    filters?.sort?.field === "role"
                      ? filters.sort.direction
                      : undefined,
                  handler: (d) =>
                    setFilters({
                      ...filters,
                      sort: { field: "role", direction: d },
                    }),
                }}
              />
            </Th>
           {canDelete && <Th w={24} />}
          </Tr>
        </Thead>
        <Tbody>
          {userSiteRoles?.map((usr) => (
            <Tr key={usr.id}>
              <Td>{usr.site.name}</Td>
              <Td>
                <InputAnimatedSelect
                  id="role"
                  noSelectionLabel=""
                  label=""
                  marginTop={-8}
                  items={(roles ?? []).filter((r) => r.context === context)}
                  selectedValue={usr.role.id}
                  onSelect={(id) => roleDidChange(usr, id)}
                  isDisabled={!canAdd}
                />
              </Td>
              {canDelete && <Td
                isNumeric
                w={24}
                alignContent={"center"}
                justifyContent={"center"}
                align={"center"}
              >
                <DeleteButton
                  onClick={() => setAskRemove(usr)}
                  aria-label="remove-user-site-role"
                />
              </Td>}
            </Tr>
          ))}
        </Tbody>
      </SimpleTable>

      {askRemove && (
        <ConfirmAlert
          isLoading={siteRolesProps.deleteUserSiteRoleIsLoading}
          title={t("userSiteRoles.confirmDelete")}
          onConfirm={confirmDelete}
          onCancel={() => setAskRemove(undefined)}
          variant="question"
          message={t("userSiteRoles.confirmDeleteMessage", {
            site: askRemove.site.name,
            role: askRemove.role.name,
          })}
        />
      )}

      {showDuplicate && (
        <Alert
          title={t("userSiteRoles.roleSiteAlreadySet")}
          onClose={() => setShowDuplicate(undefined)}
          variant="info"
          message={t("userSiteRoles.roleSiteAlreadySetMessage", {
            site: showDuplicate.site,
            role: showDuplicate.role,
          })}
        />
      )}

      {askUpdate && (
        <ConfirmAlert
          title={t("userSiteRoles.confirmUpdate")}
          message={t("userSiteRoles.confirmUpdateMessage", {
            site: askUpdate.old.site.name,
            oldRole: askUpdate.old.role.name,
            newRole: askUpdate.newRole.name,
          })}
          onConfirm={confirmUpdate}
          onCancel={() => setAskUpdate(undefined)}
          variant="question"
        />
      )}
    </Flex>
  );
};

export default UserSiteRoles;
