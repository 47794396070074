import { BsGear } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiOutlineBookOpen } from "react-icons/hi";
import SubNavBar from "../../components/Common/SubNavBar";
import { Permission } from "../../components/Permissions/Permissions";
import { useHasPermissions } from "../../components/Permissions/RenderIf";

const SiteDetailsSubNav = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const canUseSettings = useHasPermissions([Permission.Sites_ShowSettings]);
  const canUseDetails = useHasPermissions([Permission.Sites_ShowDetails]);
  return (
    <SubNavBar
      items={[
        {
          icon: HiOutlineBookOpen,
          path: `/sites/${siteId}/site/details`,
          name: t("summary"),
          hidden: !canUseDetails
        },
        {
          icon: BsGear,
          path: `/sites/${siteId}/site/options`,
          name: t("options.title"),
          hidden: !canUseSettings,
        },
      ]}
    />
  );
};

export default SiteDetailsSubNav;
