import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Company from "../../../domain/entities/company";
import Staff, { StaffType } from "../../../domain/entities/staff";
import { Permission } from "../../components/Permissions/Permissions";
import CreateUpdateStaffModal from "../../components/Views/Staff/CreateStaffModal";
import { useCompanyDetailViewModel } from "../../hooks/Company/useCompanyDetailViewModel";
import ContentLayout from "../../layout/ContentLayout";
import LoadingView from "../Common/LoadingView";
import CompanyDetailActionBar from "./CompanyDetailActionBar";
import CompanyStaffTabs from "./CompanyStaffTabs";
import RecordsPanel from "./RecordsPanel";
import RenderIf from "../../components/Permissions/RenderIf";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

const CompanyDetailView = () => {
  const hook = useCompanyDetailViewModel();
  return hook.loading && !hook.updateFilter ?
    <LoadingView /> :
    <CompanyDetailViewMain hook={hook} />;
}

const memoizedShowStaffPermission = [Permission.Company_ShowStaff];
const memoizedEditStaffPermission = [Permission.Company_EditStaff];
const memoizedShowDetailsPermission = [Permission.Company_ShowDetails];
const memoizedEditDetailsPermission = [Permission.Company_EditDetails];

const CompanyDetailViewMain = ({hook}: {hook: ReturnType<typeof useCompanyDetailViewModel>}) => {
  const { t } = useTranslation("companies");
  const [isEditing, setIsEditing] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [staffType, setStaffType] = useState(StaffType.SYSTEM);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();

  const {
    company,
    isFetching,
    updateCompanyLogo,
    updateCompanyFields,
    staff,
    staffIsFetching,
    staffFilter,
    staffSort,
    createStaff,
    updateFilter,
    setStaffSort,
    deleteStaff,
    updateStaff,
    deleteStaffIsLoading,
    createStaffIsLoading,
    updateStaffIsLoading,
    systemStaff,
    systemStaffIsFetching,
    systemStaffFilter,
    systemStaffSort,
    setSystemStaffSort,
    updateSystemFilter,
    updateSystemStaff,
    coordinates,
    customFields
  } = hook;

  const onTabSelect = useCallback((t) => {
    setStaffType(t === 0 ? StaffType.SYSTEM : StaffType.CUSTOM);
  }, [setStaffType]);
  
  const onSubmit: SubmitHandler<Company> = (data) => {
    if (uploadedImageFile) {
      updateCompanyLogo(
        {
          ...data,
          id: company.id,
          fiscalCode: data.fiscalCode.toUpperCase(),
        },
        uploadedImageFile,
      );
    } else {
      updateCompanyFields({
        ...data,
        id: company.id,
        fiscalCode: data.fiscalCode.toUpperCase()
      });
    }
    setIsEditing(false);
  };

  const methods = useForm<Company>({ mode: "all", defaultValues: company });

  useEffect(() => {
    if (company) {
      methods.reset(company);
    }
  }, [company, methods]);

  const {
    handleSubmit,
    trigger,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { isValid },
  } = methods;


  const toggleIsEditing = (isEditing: boolean) => {
    if (!isEditing) {
      setUploadedImageFile(undefined);
      reset({
        ccnl: company.ccnl,
        vatCountryCode: company.vatCountryCode,
        address: company.address,
        name: company.name,
        fiscalCode: company.fiscalCode,
        phoneNumber: company.phoneNumber,
        pec: company.pec,
        vat: company.vat,
        email: company.email,
        businessSize: company.businessSize,
      });
    }
    setIsEditing(isEditing);
  };

  const createStaffAction = async (staffElement: Staff) => {
    await createStaff(staffElement);
    setShowCreate(false);
  };

  const staffTypeMemoized = useMemo(()=>{
    return staffType === StaffType.CUSTOM
    ? () => setShowCreate(true)
    : undefined;
  }, [staffType]);

  if (!company) {
    // Company data not yet available.
    return <LoadingView />;
  }

  return (
    <>
      <ContentLayout
        action={
          <CompanyDetailActionBar
            isEditing={isEditing}
            canSave={isEditing && isValid}
            onSave={handleSubmit(onSubmit)}
            onAdd={staffTypeMemoized}
            onToggleEdit={isFetching ? undefined : toggleIsEditing}
            editDetailsPermissions={memoizedEditDetailsPermission}
            editStaffPermissions={memoizedEditStaffPermission}
          />
        }
      >
        
          <Grid
            templateColumns={"repeat(2, 1fr)"}
            gap={4}
            paddingLeft={10}
            paddingRight={3}
            py={10}
            h="100%"
            width={"calc(100vw - 120px)"}
          >
            <GridItem colSpan={2}>
              <RenderIf permissions={memoizedShowDetailsPermission} check={PermissionCheck.All}>
                <Flex
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="10px"
                  width={"calc(100vw - 180px)"}
                  position={"relative"}
                  overflow="hidden"
                  flexDirection="column"
                >
                  <FormProvider {...methods}>
                  <RecordsPanel
                      trigger={trigger}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      coordinates={coordinates}
                      isEditing={isEditing}
                      isFetching={isFetching}
                      uploadedImageFile={uploadedImageFile}
                      setUploadedImageFile={setUploadedImageFile}
                      company={company}
                      customFields={customFields.data}
                    />
                  </FormProvider>
                </Flex>
              </RenderIf>
            </GridItem>
            <GridItem colSpan={2}>
              <RenderIf permissions={memoizedShowStaffPermission} check={PermissionCheck.All}>
                <CompanyStaffTabs
                  staffType={staffType}
                  onTabSelect={onTabSelect}
                  systemStaff={systemStaff}
                  staff={staff}
                  systemStaffIsFetching={systemStaffIsFetching}
                  staffIsFetching={staffIsFetching}
                  updateStaffIsLoading={updateStaffIsLoading}
                  deleteStaffIsLoading={deleteStaffIsLoading}
                  systemStaffSort={systemStaffSort}
                  staffSort={staffSort}
                  systemStaffFilter={systemStaffFilter}
                  staffFilter={staffFilter}
                  setSystemStaffSort={setSystemStaffSort}
                  setStaffSort={setStaffSort}
                  updateSystemFilter={updateSystemFilter}
                  updateFilter={updateFilter}
                  updateSystemStaff={updateSystemStaff}
                  updateStaff={updateStaff}
                  deleteStaff={deleteStaff}
                  memoizedEditRolesPermission={memoizedEditStaffPermission}
                />
              </RenderIf>
            </GridItem>
          </Grid>
        {showCreate && (
          <CreateUpdateStaffModal
            createUpdateStaffIsLoading={createStaffIsLoading}
            staffType={staffType}
            onCancel={() => {
              setShowCreate(false);
            }}
            onConfirm={createStaffAction}
            title={t("createStaff", { ns: "companies" })}
          />
        )}
      </ContentLayout>
    </>
  );
};

export default CompanyDetailView;
