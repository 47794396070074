import { useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import Typology from '../../../domain/entities/typology';
import { useAuth } from '../../providers/Auth0JWTProvider';
import { CompanyResourceType } from '../../../domain/entities/companyResourceType.enum';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import ImportInfo from '../../../domain/entities/importInfo';
import FileEntity from '../../../domain/entities/file';
import { GetResourcesFilter } from '../../../domain/repositories/workerRepository';
import { updateFilterWithDelete } from '../../../utils';
import { GetSitesFilter, LinkResourcesToSiteWarning } from '../../../domain/repositories/siteRepository';
import Site from '../../../domain/entities/site';
import {CustomField} from "../../../domain/entities/customFields";
import useResourceConversionHook, {ConversionType, useResourceConversionHookProps} from "./useResourceConversionHook";
import {ResourceConversionViewModel} from "../../viewmodels/resourceConversion/ResourceConversionViewModel";
import {DocumentTypeCategory} from "../../../domain/entities/documentTypeCategory.enum";
import {Identifiable} from "../../../domain/entities/interfaces/identifiable";

export interface ResourceListViewModel<T> extends ResourceConversionViewModel {
	get: (companyId: string, search?: GetResourcesFilter, archived?: boolean, sort?: SortMeta, pageParam?: number) => Promise<PaginatedResults<T>>;
	delete: (companyId: string, resourceId: string) => Promise<void>;
	restore: (companyId: string, resourceId: string) => Promise<void>;
	create: (companyId: string, resource: T, photo?: File) => Promise<T>;
	getTypologies: (companyId: string, type: DocumentTypeCategory) => Promise<Typology[]>;
	uploadResources: (companyId: string, file: FileEntity) => Promise<ImportInfo>;
	getResourceLinkableSites: (companyId: string, type: string, filters?: GetSitesFilter, sort?: SortMeta, pageParam?: number) => Promise<{
		results: Site[], count: number}>;
	addResourcesToSites: (
		companyId: string,
		resourceIds: string[],
		siteIds: string[],
		copyLastEvaluation: boolean,
		selectAllSites?: boolean,
		selectAllResources?: boolean,
		resourceFilters?: GetResourcesFilter,
		siteFilters?: GetSitesFilter
	) => Promise<LinkResourcesToSiteWarning>;
  getCustomFields: (companyId: string) => Promise<CustomField[]>;
}

export interface CreateResourceArgs<T> {
	resource: T;
	image?: File;
}

const useResourceListViewModel = <T extends Identifiable>(viewModel: ResourceListViewModel<T>, type: CompanyResourceType, twinTypes: ConversionType[] = []) => {
	const { companyId } = useAuth();
	const [search, setSearch] = useState<string>('');
	const [filter, setFilter] = useState<GetResourcesFilter>({});
	const [sort, setSort] = useState<SortMeta>();
	const [error, setError] = useState<string>();
	const [showArchived, setShowArchived] = useState<boolean>(false);
	const [sortSiteCollection, setSortSiteCollection] = useState<SortMeta>();
	const [filterSiteCollection, setFilterSiteCollection] = useState<GetSitesFilter>();
	const [resourcesLinkableSitesCount, setResourcesLinkableSitesCount] = useState<number>();
	const [resourcesCount, setResourcesCount] = useState<number>(0);
	const [addResourcesToSiteWarning, setAddResourcesToSiteWarning] = useState<LinkResourcesToSiteWarning | null>(null);
	const [selectAllLinkableSites, setSelectAllLinkableSites] = useState(false);
	const [enableGetLinkableSites, setEnableGetLinkableSites] = useState(false);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [selectAllPaginatedResources, setSelectAllPaginatedResources] =
    useState<boolean>(false);

	const updateFilterResources = (column: string, value: string) => {
		updateFilterWithDelete(setFilter, column, value);
	};
	const getResourcesQuery = useInfiniteQuery(
		['resources', type, companyId, search, filter, sort, showArchived],
		async ({ pageParam = 1 }) => {
			const { results, count } = await viewModel.get(
				companyId,
				search
					? {
							...filter,
							search: search,
						}
					: filter,
				showArchived,
				sort,
				pageParam,
			);
			setResourcesCount(count);
			return results;
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
		},
	);

	const getTypologiesQuery = useQuery(['typologies', companyId, type], async () => await viewModel.getTypologies(companyId, type), {
		initialData: [],
	});

	const deleteMutation = useMutation((resourceId: string) => viewModel.delete(companyId, resourceId), {
		onSuccess: () => getResourcesQuery.refetch(),
		onError: (e) => console.error(e),
	});

	const restoreMutation = useMutation((resourceId: string) => viewModel.restore(companyId, resourceId), {
		onSuccess: () => getResourcesQuery.refetch(),
		onError: (e) => console.error(e),
	});

	const createMutation = useMutation(
		async ({ resource, image }: CreateResourceArgs<T>) => {
			return await viewModel.create(companyId, resource, image);
		},
		{
			onSuccess: () => getResourcesQuery.refetch(),
			onError: (e) => console.error(e),
		},
	);
	const uploadResourcesMutation = useMutation(
		(file: FileEntity) => {
			return viewModel.uploadResources(companyId, file);
		},
		{
			onSuccess: () => {
				getResourcesQuery.refetch();
			},
			onError: (err: Error) => {
				console.log(err?.message);
			},
		},
	);

	const uploadResources = async (file: FileEntity) => {
		try {
			return await uploadResourcesMutation.mutateAsync(file);
		} catch (err) {
			setError(err.message);
		}
	};

	const getResourceLinkableSites = useInfiniteQuery(
		['site-collection', companyId, sortSiteCollection, filterSiteCollection],
		async ({ pageParam = 1 }) => {
			const { results, count } = await viewModel.getResourceLinkableSites(companyId, type, filterSiteCollection, sortSiteCollection, pageParam);
			setResourcesLinkableSitesCount(count);
			return  results
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage?.length === 25) {
					return pages.length + 1;
				}
			},
			enabled: enableGetLinkableSites
		},
	);
	const addResourcesToSitesMutation = useMutation(
		async ({
			resourceIds,
			siteIds,
			copyLastEvaluation,
			selectAllSites,
			selectAllResources,
		}: {
			resourceIds: string[];
			siteIds: string[];
			copyLastEvaluation: boolean,
			selectAllSites?: boolean;
			selectAllResources?: boolean;
		}) => {
			const response = await viewModel.addResourcesToSites(companyId, resourceIds, siteIds, 
				copyLastEvaluation, 
				selectAllSites, selectAllResources,	filter, filterSiteCollection);
			setAddResourcesToSiteWarning(response);
			return response;
		},
		{
			onSuccess: () => {
				// getResourcesQuery.refetch();
			},
		},
	);

	const resources = getResourcesQuery.data?.pages?.flat() ?? [];

  const conversionProps = useResourceConversionHook({
    companyId,
    resourceIds: selectedResources,
    baseType: type as ConversionType,
    twinTypes,
    hasSites: false,
    viewModel,
    allResources: selectAllPaginatedResources
  })

	return {
		resources: resources,
		resourcesCount,
		resourcesHasNextPage: getResourcesQuery.hasNextPage,
		resourcesFetchNextPage: getResourcesQuery.fetchNextPage,
		isLoading: getResourcesQuery.isLoading,
		isFetching: getResourcesQuery.isFetching,
		filter,
		setFilter,
		sort,
		setSort,
		search,
		setSearch,
		deleteResource: deleteMutation.mutateAsync,
		deleteResourcesLoading: deleteMutation.isLoading,
		createResource: createMutation.mutateAsync,
		createResourceLoading: createMutation.isLoading,
		restoreResource: restoreMutation.mutate,
		uploadResources,
		uploadResourcesError: error,
		typologies: getTypologiesQuery.data,
		typologiesIsFetching: getTypologiesQuery.isLoading,
		showArchived,
		setShowArchived,
		updateFilterResources,
		siteCollection: getResourceLinkableSites.data?.pages?.flat() ?? [],
		sortSiteCollection,
		setSortSiteCollection,
		filterSiteCollection,
		updateFilterSiteCollection: (field, value) => {
			updateFilterWithDelete(setFilterSiteCollection, field, value);
		},
		setFilterSiteCollection,
		siteCollectionHasNextPage: getResourceLinkableSites.hasNextPage,
		siteCollectionFetchNextPage: getResourceLinkableSites.fetchNextPage,
		refetchSiteCollection: getResourceLinkableSites.refetch,
		siteCollectionIsFetching: getResourceLinkableSites.isFetching,
		addResorcesToSites: addResourcesToSitesMutation.mutateAsync,
		isAddingResorcesToSites: addResourcesToSitesMutation.isLoading,
		addResourcesToSiteWarning,
		setAddResourcesToSiteWarning,
		resourcesLinkableSitesCount,
		selectAllLinkableSites,
		setSelectAllLinkableSites,
		setEnableGetLinkableSites,
    conversionProps,
    customFields: {
      data: conversionProps.baseCustomFields,
      isLoading: conversionProps.getCustomFieldsQuery.isLoading,
      error: conversionProps.getCustomFieldsQuery.error
    },
    loading: getResourcesQuery.isLoading || conversionProps.getCustomFieldsQuery.isLoading,
    selectedResources, setSelectedResources,
    selectAllVisibleResources: resources.length > 0 && selectedResources.length === resources.length,
    setSelectAllVisibleResources: (value: boolean) => setSelectedResources(value ? resources.map(r => r.id) : []),
    selectAllPaginatedResources, setSelectAllPaginatedResources,
	};
};

export { useResourceListViewModel };
