import {
  Box,
  Flex,
  Icon,
  IconButton,
  Portal,
  Text,
  useOutsideClick
} from "@chakra-ui/react";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from "react-icons/bs";
import { HiFilter, HiOutlineFilter } from "react-icons/hi";
import { SortDirection } from "../../../../domain/entities/interfaces/paginatedResults";
import { COLORS } from "../../../assets/theme/colors";

interface Props {
  overflowHidden?: boolean;
  text: string;
  filter?: { component: ReactNode; isActive: boolean };
  sort?: {
    direction: SortDirection;
    handler: (direction: SortDirection) => void;
  };
  isInModal?: boolean;
  tableId?: string;
}

const TableColumnHeader: FC<Props> = ({
  text,
  filter,
  sort,
  overflowHidden,
  isInModal = false,
  tableId = "table-container",
}) => {
  const headerRef = useRef(null);
  const filterRef = useRef(null);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterPosition, setFilterPosition] = useState({ top: 10, left: 5 });
  const idTable = isInModal ? "table-container-modal" : tableId;  
  
  const updateSorting = () => {
    const nextState =
      sort.direction === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending;
    sort?.handler(nextState);
  };

  const handleEdit = (e) => {
  e.stopPropagation();

  if (headerRef.current) {
    const headerRect = headerRef.current.getBoundingClientRect();
    const table = document.getElementById(idTable);
    const tableRect = table?.getBoundingClientRect();
    const minLeft = tableRect?.left - (isInModal ? 4.5 : 0);

    let calculatedLeft = headerRect.left;

    if (!showFilter) {
      setShowFilter(true);
      setFilterPosition({
            top: headerRect.bottom + window.scrollY,
            left: calculatedLeft,
          });
      setTimeout(() => {
        if (filterRef.current) {
          const filterWidth = filterRef.current.offsetWidth;
          const maxLeft = tableRect?.right - filterWidth;

          if (calculatedLeft < minLeft) {
            calculatedLeft = minLeft;
          } else if (maxLeft > 0 && calculatedLeft > maxLeft) {
            calculatedLeft = maxLeft;
          }

          setFilterPosition({
            top: headerRect.bottom + window.scrollY,
            left: calculatedLeft,
          });
        }
      }, 0);
    } else {
      setShowFilter(false);
    }
  }
};

  
  const updatePosition = useCallback(() => {
    if (headerRef.current) {
      const headerRect = headerRef.current.getBoundingClientRect();
      const table = document.getElementById(idTable);
      const tableRect = table?.getBoundingClientRect();      
      const filterWidth = filterRef.current.offsetWidth;
      const minLeft = tableRect?.left - (isInModal ? 4.5 : 0);
      const maxLeft = tableRect?.right - filterWidth;
      
      let calculatedLeft = headerRect?.left;
      if (calculatedLeft < minLeft) {
        calculatedLeft = minLeft;
      } else if (calculatedLeft > maxLeft) {
        calculatedLeft = maxLeft;
      }
      
      if (table && tableRect) {
        setFilterPosition({
          top: tableRect.top + headerRect?.height + (isInModal ? 20 : 14),
          left: calculatedLeft,
        });
      }
    }
  }, [isInModal, idTable]);

  const updatePositionOnPageScroll = useCallback(() => {
    if (headerRef.current) {
      const headerRect = headerRef.current.getBoundingClientRect();
      
      setFilterPosition({
        top: headerRect.top + 30,
        left: headerRect.left,
      });
    }
  }, []);

  useEffect(() => {
    if (!showFilter) return;
    const scrollAreaId = isInModal ? "modal-body" : "mainScrollArea";
    const scrollArea = document.getElementById(scrollAreaId); 
    const table = document.getElementById(idTable);
    
    table?.addEventListener("scroll", updatePosition);
    scrollArea?.addEventListener("scroll", updatePositionOnPageScroll);
    window.addEventListener("resize", updatePositionOnPageScroll);

    return () => {
      scrollArea?.removeEventListener("scroll", updatePositionOnPageScroll);
      window.removeEventListener("resize", updatePositionOnPageScroll);
      table?.removeEventListener("scroll", updatePosition);
    };
  },
    [
      isInModal,
      showFilter,
      idTable,
      updatePosition,
      updatePositionOnPageScroll
    ]
  );

  const handleClickOutside = (event) => {
    if (
      headerRef.current?.contains(event.target) ||
      filterRef.current?.contains(event.target)   
    ) {
      return;
    }
    setShowFilter(false);
  };

  useOutsideClick({
    ref: headerRef,
    handler:handleClickOutside,
  });

  const directionIcon = {
    [SortDirection.Ascending]: BsArrowUp,
    [SortDirection.Descending]: BsArrowDown,
  };

  return (
    <Box ref={headerRef}>
      <Flex direction="row" alignItems="center">
        {filter && (
          <>
            <IconButton
              variant="ghost"
              aria-label="toggle-filter"
              size="sm"
              onClick={handleEdit}
              sx={{
                height: "16px",
                minWidth: "16px",
                padding: 0,
                marginRight: "2px",
              }}
            >
              <Icon
                color={filter.isActive ? COLORS.sikuroBlue : undefined}
                as={filter.isActive ? HiFilter : HiOutlineFilter}
              />
            </IconButton>

            {showFilter && (
              <Portal>
                <Box
                  ref={filterRef}
                  padding={1}
                  position="absolute"
                  top={filterPosition.top}
                  left={filterPosition.left}
                  zIndex={isInModal ? 1450 : 1300}
                  backgroundColor="white"
                  height="fit-content"
                  borderRadius="10px"
                  border="1px solid"
                  borderColor="lightGray"
                  mt="2"
                  {...(overflowHidden && { overflow: "hidden" })}
                >
                  {filter.component}
                </Box>
              </Portal>
            )}
          </>
        )}
        <Text>{text}</Text>
        <div style={{ flexGrow: 1 }} />
        {sort && (
          <IconButton
            variant="ghost"
            aria-label="toggle-sort"
            size="sm"
            onClick={updateSorting}
            sx={{ height: "16px" }}
          >
            <Icon as={directionIcon[sort.direction] ?? BsArrowDownUp} />
          </IconButton>
        )}
      </Flex>
    </Box>
  );
};

export default TableColumnHeader;
