import { Checkbox, Flex, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DocumentType from "../../../domain/entities/documentType";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";

interface RequirementTableProps {
  documentTypeList: DocumentType[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
  requirementsListIsLoading: boolean;
  excludedRequirements: string[];
  setExcludedDocumentTypes: (requirementIds: string[]) => void;
  tableId: string;
}
const RequirementSelectableTable = ({
  documentTypeList,
  hasNextPage,
  fetchNextPage,
  requirementsListIsLoading,
  setExcludedDocumentTypes,
  excludedRequirements,
  tableId,
}: RequirementTableProps) => {
  const { t } = useTranslation("audit");

  const toggleItem = (id: string) => {
    if (!excludedRequirements.includes(id)) {
      setExcludedDocumentTypes([...excludedRequirements, id]);
    } else {
      setExcludedDocumentTypes(excludedRequirements.filter((i) => i !== id));
    }
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      h="100%"
      w="100%"
      position="relative"
      marginTop={2}
      marginBottom={3}
      id="table-container"
    >
      <InfiniteTable
        maxHeight="100vh"
        autosize={true}
        infiniteScroll={{
          dataLength: documentTypeList.length,
          hasNextPage: hasNextPage,
          fetchNextPage: fetchNextPage,
        }}
        tableId={"requirements-table"}
        showEmptyText={documentTypeList?.length === 0}
        isLoading={requirementsListIsLoading}
        emptyText={t("noResourceRequirements", { ns: "requirements" })}
      >
        <Thead>
          <Tr>
            <Th key={"selectAllCheckbox"} width={10}>
              {/* <Checkbox
                    borderColor={"gray.500"}
                    isChecked={parentSelectAllVisibile}
                    onChange={() => toggleSelectAll(!parentSelectAllVisibile)}
                  >
                  </Checkbox> */}
            </Th>
            <Th>
              <TableColumnHeader text={t("requirement", {ns: "requirements"})} />
            </Th>
          </Tr>
        </Thead>

        <Tbody borderRadius="xl">
          {documentTypeList &&
            documentTypeList?.map((documentType) => (
              <Tr
                key={documentType?.id}
                style={{
                  backgroundColor: "white",
                }}
              >
                <Td width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={
                      excludedRequirements
                        ? !excludedRequirements?.includes(documentType?.id)
                        : true
                    }
                    onChange={() => toggleItem(documentType?.id)}
                  ></Checkbox>
                </Td>
                <Td>{documentType?.name}</Td>
              </Tr>
            ))}
        </Tbody>
      </InfiniteTable>
    </Flex>
  );
};

export default RequirementSelectableTable;
