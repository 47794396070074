import {
  Button,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "../../../../domain/entities/badge";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import Tag from "../../../../domain/entities/tag";
import { GetAvailableBadgesFilters } from "../../../../domain/repositories/badgeRepository";
import { COLORS } from "../../../assets/theme/colors";
import { BadgeStatusSite } from "../../../hooks/Badge/useBadgeDetailViewModel";
import BaseModal from "../../Common/alerts/BaseModal";
import SelectBadgesTable from "./SelectBadgesTable";

type AddBadgesToSiteModalProps = {
  availableBadges: Badge[];
  availableBadgesTags: Tag[];
  availableBadgesHasNextPage: boolean;
  availableBadgesFetchNextPage: () => void;
  availableBadgesFilters: { [p: string]: any };
  availableBadgesSort: SortMeta;
  updateAvailableBadgesFilter: (
    field: keyof GetAvailableBadgesFilters,
    value: string | string[],
  ) => void;
  setAvailableBadgesSort: (sort: SortMeta) => void;
  onConfirm: (
    badgeIds: string[],
    selectAll?: boolean,
    selectedBadgesNumber?: number,
  ) => void;
  onClose: () => void;
  setBadgeId: (badgeId: string) => void;
  associatedSites?: BadgeStatusSite[];
  badgeSitesIsLoading?: boolean;
  selectableBadgesCount?: number;
  linkBadgesToSiteIsLoading: boolean;
  setBadgesInterval: Dispatch<SetStateAction<{ from: number; to: number }>>;
  refetchBadgeSiteFromInterval: () => void;
  badgesInterval: { from: number; to: number };
  badgeSiteInterval: {
    available: Badge[];
    not_available: number[];
  };
};
const AddBadgesToSiteModal = ({
  onClose,
  onConfirm,
  availableBadges,
  availableBadgesTags,
  availableBadgesFilters,
  updateAvailableBadgesFilter,
  refetchBadgeSiteFromInterval,
  availableBadgesSort,
  setAvailableBadgesSort,
  availableBadgesHasNextPage,
  availableBadgesFetchNextPage,
  linkBadgesToSiteIsLoading,
  associatedSites,
  setBadgeId,
  badgeSitesIsLoading,
  selectableBadgesCount,
  setBadgesInterval,
  badgesInterval,
  badgeSiteInterval,
}: AddBadgesToSiteModalProps) => {
  const { t } = useTranslation("badges");

  const [badgeIds, setBadgeIds] = useState<string[]>([]);
  const [selectedBadgesNumber, setSelectedBadgesNumber] = useState<number>(0);
  const [selectAllBadgesVisible, setSelectAllBadgesVisible] =
    useState<boolean>(false);
  const [selectAllMatching, setSelectAllMatching] = useState<boolean>(false);
  const [showNotAvailableCodeModal, setShowNotAvailableCodeModal] =
    useState(false);
  const [notAvailableCodes, setNotAvailableCodes] = useState<string>();
  const [refetch, setRefetch] = useState(false);

  const handleConfirm = async () => {
    await onConfirm(badgeIds, selectAllMatching, selectedBadgesNumber);
  };

  const handleSave = () => {
    if (badgesInterval.from > 0 || badgesInterval.to > 0) {
      refetchBadgeSiteFromInterval();
      setRefetch(true);
    }
  };

  useEffect(() => {
    if (badgeSiteInterval?.not_available.length > 0 && refetch) {
      const unavailableCodes = badgeSiteInterval.not_available;
      if (Array.isArray(unavailableCodes)) {
        setNotAvailableCodes(unavailableCodes.join(", "));
        setShowNotAvailableCodeModal(true);
      }
    }
  }, [refetch, badgeSiteInterval]);

  return (
    <>
      <BaseModal
        onClose={onClose}
        onConfirm={handleConfirm}
        isLoading={linkBadgesToSiteIsLoading}
        onConfirmDisabled={badgeIds.length <= 0}
        size="6xl"
      >
        <Text fontSize="xl" fontWeight={500}>
          {t("addBadges.title")}
        </Text>
        <Text fontSize="sm" mb={5}>
          {t("addBadges.subtitle")}
        </Text>
        <Flex gap={4}>
          <Text fontSize="xl" mt={1}>
            {t("addBadges.from")}
          </Text>
          <NumberInput
            min={0}
            onChange={(value) =>
              setBadgesInterval({ ...badgesInterval, from: Number(value) })
            }
            value={badgesInterval.from}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text fontSize="xl" mt={1}>
            {" "}
            {t("addBadges.to")}
          </Text>
          <NumberInput
            min={0}
            onChange={(value) =>
              setBadgesInterval({ ...badgesInterval, to: Number(value) })
            }
            value={badgesInterval.to}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Button
            sx={{
              background: COLORS.sikuroBlue,
              color: "white",
            }}
            onClick={handleSave}
          >
            {t("save", { ns: "common" })}
          </Button>
        </Flex>
        <SelectBadgesTable
          autosize
          badges={
            !showNotAvailableCodeModal &&
            badgeSiteInterval?.available.length > 0
              ? badgeSiteInterval?.available
              : availableBadges
          }
          hideInfiniteLoader={
            !showNotAvailableCodeModal &&
            badgeSiteInterval?.available.length > 0
          }
          badgesTags={availableBadgesTags}
          badgesFilters={availableBadgesFilters}
          badgesSort={availableBadgesSort}
          badgesHasNextPage={availableBadgesHasNextPage}
          badgesFetchNextPage={availableBadgesFetchNextPage}
          setBadgesSort={setAvailableBadgesSort}
          updateBadgesFilter={updateAvailableBadgesFilter}
          setBadgeIds={setBadgeIds}
          badgeIds={badgeIds}
          setBadgeId={setBadgeId}
          assocciatedSites={associatedSites}
          badgeSitesIsLoading={badgeSitesIsLoading}
          showExtraData={true} // This boolean manages the display of icons that provide information about the association of a badge with a site/resource.
          selectAllAvailable={true}
          parentSelectAllVisibile={selectAllBadgesVisible}
          setParentSelectAllVisibile={setSelectAllBadgesVisible}
          parentSelectAllMatching={selectAllMatching}
          setParentSelectAllMatching={setSelectAllMatching}
          allSelectableBadgeNumber={selectableBadgesCount}
          selectedBadgesNumber={selectedBadgesNumber}
          setSelectedBadgesNumber={setSelectedBadgesNumber}
          paginatedTable={true}
        />
      </BaseModal>
      {showNotAvailableCodeModal && (
        <BaseModal
          size="md"
          onClose={() => {
            setShowNotAvailableCodeModal(false), setRefetch(false);
          }}
          onConfirm={() => {
            setShowNotAvailableCodeModal(false);
          }}
          onCancel={() => {
            setShowNotAvailableCodeModal(false), setRefetch(false);
          }}
        >
          <Text fontSize="xl" fontWeight={500}>
            {t("badgeCodeNotAvailableTitle")}
          </Text>
          <Text fontSize="md" mt={4}>
            <div
              dangerouslySetInnerHTML={{
                __html: t("badgeCodeNotAvailableDesc", {
                  codes: notAvailableCodes,
                }),
              }}
            />
          </Text>
        </BaseModal>
      )}
    </>
  );
};

export default AddBadgesToSiteModal;
