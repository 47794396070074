import React, { FC, useEffect} from "react";
import { useTranslation } from "react-i18next";
import {Box,  Flex, Select, useMediaQuery} from "@chakra-ui/react";
import { SmartDownloadContextType, SmartDownloadSubContextType
} from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import { GetGlobalSupplierFilters } from "../../../../domain/repositories/supplierRepository";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { SmartDownloadContextTable } from "./SmartDownloadContextTable";

interface Props {
  context?: SmartDownloadContextType;
  setContext?: (context: SmartDownloadContextType) => void;
  subContext?: SmartDownloadSubContextType;
  setSubContext?: (subContext: SmartDownloadSubContextType) => void;
  data?: any;
  selectedItems?: any[];
  setSelectedItems?: (selectedItems: any[]) => void;
  setSelectedAllItems?: (selectedAllSuppliers: boolean) => void;
  resourceType?: ResourceType;
}

const contextOptions = [
  {
    value: 'supplier',
    label: 'supplierContext'
  },
  {
    value: 'documentType',
    label: 'documentContext'
  },
];

const columns: {
  field: keyof GetGlobalSupplierFilters;
  type: "text" | "select" | "date-range";
}[] = [
  { field: "name", type: "text" },
  { field: "vatCode", type: "text" },
  { field: "street", type: "text" },
];
const resourceColumns: {
  field: keyof GetGlobalSupplierFilters;
  type: "text" | "select" | "date-range";
}[] = [
  { field: "name", type: "text" },
];
const SmartDownloadSelectContext: FC<Props> = ({
  context,
  setContext,
  selectedItems,
  setSelectedItems,
  setSelectedAllItems,
  data,
  resourceType
}) => {
  const { t } = useTranslation("common");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const filters = data.filters ?? {
    name: "",
    protocol: "",
    state: "active",
    location: "",
  };
  const docsFilters = data.filters ?? {
    name: "",
  };

  useEffect(() => {
    if (location.pathname.includes('resources')){
      setContext('resource')
    }
  }, [context, data, resourceType, setContext]);
  return (
    <Box>
      {
        context !== 'resource' &&
        <Select placeholder={t("smartDownload.selectContext")} size='md'
                value={context}
                onChange={(e) => {setContext(e.target.value as SmartDownloadContextType);
        }}>
          {contextOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {t(`smartDownload.${option.label}`)}
            </option>
          ))}
        </Select>
      }
      {context === 'supplier' && (
        <Flex
          my={4}
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={isMobile ? "calc(100vw - 50px)" : "100%"}
          position="relative"
          overflow="auto"
          id="table-container-modal"
        >
          <SmartDownloadContextTable
            data={data.suppliers}
            hasNextPage={data.hasNextPage}
            fetchNextPage={data.fetchNextPage}
            isLoading={data.isLoading}
            columns={columns}
            setSort={data.setSort}
            sort={data.sort}
            filters={filters}
            updateFilters={data.updateFilters}
            count={data.count}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setSelectedAllItems={setSelectedAllItems}
          />
        </Flex>
      )}
      {context === 'resource' && (
        <Flex
          my={4}
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={isMobile ? "calc(100vw - 50px)" : "100%"}
          position="relative"
          overflow="auto"
          id="table-container"
        >
          <SmartDownloadContextTable
            data={data.documentTypes}
            hasNextPage={data.hasNextPage}
            fetchNextPage={data.fetchNextPage}
            isLoading={data.isLoading}
            columns={resourceColumns}
            setSort={data.setSort}
            sort={data.sort}
            filters={docsFilters}
            updateFilters={data.updateFilters}
            count={data.count}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setSelectedAllItems={setSelectedAllItems}
          />
        </Flex>
      )}
    </Box>
  );
};

export default SmartDownloadSelectContext;