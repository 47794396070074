import React, { useEffect, useState } from "react";
import { UseWebHookViewModel } from "../../../hooks/WebHook/useWebHookViewModel";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import DangerIcon from "../../../components/Common/DangerIcon";

const ErrorModals = (hook: UseWebHookViewModel) => {
  const { t } = useTranslation("webhooks");
  const [open, setOpen] = useState(false);
  const errorQuery = Object.keys(hook).find(
    (query) => hook[query].status === "error",
  );
  const { error } = hook[errorQuery] ?? { error: false };

  const onClose = () => {
    setOpen(undefined);
  };

  useEffect(() => {
    if (errorQuery) {
      setOpen(true);
    }
  }, [errorQuery]);

  return (
    <Modal isOpen={open} onClose={onClose} size="md" trapFocus={false}>
      <ModalOverlay />
      <ModalContent style={{ background: "white", padding: "2rem" }}>
        <ModalHeader
          sx={{
            display: "flex",
            textTransform: "uppercase",
            alignItems: "center",
          }}
        >
          <DangerIcon fill="red" mr={5} />
          {t("connectionError", { ns: "common" })}
        </ModalHeader>
        <ModalBody id="modal-body">
          <Text>{error}</Text>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="red">
            {t("close", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModals;
