import { Permission } from "../components/Permissions/Permissions";

export const commonResourcesFallbackRoutes = [
  {
    path: "resources/workers",
    permissions: [Permission.Resources_ShowWorkersDetails],
  },
  {
    path: "resources/vehicles",
    permissions: [Permission.Resources_ShowVehicleDetails],
  },
  {
    path: "resources/machines",
    permissions: [Permission.Resources_ShowMachineDetails],
  },
  {
    path: "resources/tools",
    permissions: [Permission.Resources_ShowToolDetails],
  },
  {
    path: "resources/chemicals",
    permissions: [Permission.Resources_ShowChemicalDetails],
  },
];

export const getResourcesFallbackRoutes = (currentRoute) => {
    let fallbackRoutes = [...commonResourcesFallbackRoutes];
  
    fallbackRoutes = fallbackRoutes.filter(route => route.path !== `resources/${currentRoute}`);
    return fallbackRoutes;
  };


