import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import useWorkingSiteWorkersViewModel from "../../hooks/WorkingSite/useWorkingSiteWorkersViewModel";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteWorkersView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["lastName", "firstName", "fiscalCode", "jobTitle"];
  return (
    <SiteResourcesListView<SiteWorkerParams>
      type={DocumentTypeCategory.WORKER}
      useViewModel={useWorkingSiteWorkersViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.WORKER,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Worksite_AddWorker]}
      removeFromSitePermissions={[Permission.Worksite_DeleteWorker]}
      showStatePermission={[Permission.Worksite_ShowGlobalWorkerEvaluation]}
    />
  );
};

export default SiteWorkersView;
