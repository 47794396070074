import { TbBuildingFactory } from "react-icons/tb";
import { GiCrane } from "react-icons/gi";

import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";
import { useTranslation } from "react-i18next";
import { Permission } from "../../Permissions/Permissions";

const CompanyDocuTypesSubNav = () => {
  const { t } = useTranslation("documentsPresets");

  const items: NavItemProps[] = [
    {
      icon: TbBuildingFactory,
      path: `/company/document-types/${DocumentTypeCategory.COMPANY}`,
      name: t("navbar.company"),
    },
    {
      Icon: WorkerIcon,
      path: `/company/document-types/${DocumentTypeCategory.WORKER}`,
      name: t("navbar.worker"),
    },
    {
      Icon: VehicleIcon,
      path: `/company/document-types/${DocumentTypeCategory.VEHICLE}`,
      name: t("navbar.vehicle"),
    },
    {
      Icon: MachineIcon,
      path: `/company/document-types/${DocumentTypeCategory.MACHINE}`,
      name: t("navbar.machine"),
    },
    {
      Icon: ToolIcon,
      path: `/company/document-types/${DocumentTypeCategory.TOOL}`,
      name: t("navbar.tool"),
    },
    {
      Icon: ChemicalIcon,
      path: `/company/document-types/${DocumentTypeCategory.CHEMICAL}`,
      name: t("navbar.chemical"),
    },
    {
      icon: GiCrane,
      path: `/company/document-types/${DocumentTypeCategory.SITE}`,
      name: t("navbar.site"),
      permissions: [Permission.Sites_Show],
    },
  ];

  return <SubNavBar items={items} />;
};

export default CompanyDocuTypesSubNav;
