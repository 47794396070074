// FIXME: use this component instead of DeleteActionAlert and similar.
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";

import {
  Button,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react";

// Props.
interface Props {
  title: string;
  message: string | ReactNode;
  onClose: () => void;
  variant: "info" | "warning" | "question";
}

const Alert: FC<Props> = ({ title, message, onClose, variant }) => {
  // NOTE: Enclosing view must not render this alert if not visible.
  const { t } = useTranslation();

  return (
    <Modal isOpen onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader flexDirection="column" paddingLeft={8} paddingRight={8}>
          <Flex
            direction="row"
            alignItems="center"
            width="100%"
            textAlign="start"
            marginLeft={-3}
            justifyContent={"center"}
          >
            {variant === "warning" && <FiAlertTriangle color="red" />}
            {variant === "question" && (
              <AiOutlineQuestionCircle color={COLORS.sikuroBlue} />
            )}
            {variant === "info" && <FiInfo color={COLORS.sikuroBlue} />}
            <Text
              textColor={"black"}
              fontSize={20}
              fontWeight={"bold"}
              marginLeft={3}
              textAlign={"center"}
            >
              {title}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody id="modal-body">
          <Text textAlign={"center"}>{message}</Text>
        </ModalBody>
        <ModalFooter justifyContent={"center"}>
          <Button
            bg={COLORS.sikuroBlue}
            textColor="white"
            onClick={onClose}
            borderRadius={15}
            marginRight={5}
          >
            {t("ok", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { Alert };
