import Vehicle from '../../../entities/vehicle';
import UseCase from '../../../../core/app/usecase';
import VehicleRepository from '../../../repositories/vehicleRepository';
import { PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';
import {GetResourcesFilter} from "../../../repositories/workerRepository";

type ARGS = {
	companyId: string;
	filter?: GetResourcesFilter;
	sort?: SortMeta;
	pageParam?: number;
	archived?: boolean;
};

class GetVehicles implements UseCase<ARGS, PaginatedResults<Vehicle>> {
	constructor(private repository: VehicleRepository) {}

	async Call({ companyId, filter, archived, sort, pageParam }: ARGS): Promise<PaginatedResults<Vehicle>> {
		return await this.repository.getVehicles(companyId, filter, archived, sort, pageParam);
	}
}

export default GetVehicles;
