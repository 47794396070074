import Company from './company';
import { Identifiable } from './interfaces/identifiable';

export enum AuditType {
	ITP = 'itp',
	RESOURCE = 'resources',
}

type Audit = Identifiable & {
	sequence?: string;
	type: AuditType;
	createdAt: Date;
	supplier: Company;
	uri: string;
};

export default Audit;
