import UseCase from '../../../core/app/usecase';

import { RequestFileContext, RequestDocumentFile, RequestResourceFile, RequestSupplierOrSiteFile } from './common';

import { DocumentsDownloader, makeDocumentsDownloaderRepository } from '../../repositories/documentsDownloader';

/**
 * Generates the URL that can be used to download a single file of a
 * specific document (or "requirement") linked to one site and resource.
 */
export class InitiateFileDownload implements UseCase<RequestFileContext, string> {
	constructor(private repository: DocumentsDownloader) {}

	async Call(input: RequestFileContext): Promise<string> {
		if (input instanceof RequestDocumentFile) {
			return this.repository.downloadDocumentFile(input.companyId, input.documentId, input.fileId, input.renameModel);
		}

		if (input instanceof RequestSupplierOrSiteFile) {
			return this.repository.downloadCompanyOrSiteDocumentFile(
				input.companyId,
				input.siteId,
				input.requirementId,
				input.fileId,
				input.renameModel,
				input.scope,
				input.supplierId,
			);
		}

		if (input instanceof RequestResourceFile) {
			return this.repository.downloadResourceDocumentFile(
				input.companyId,
				input.siteId,
				input.requirementId,
				input.resourceId,
				input.scope,
				input.fileId,
				input.renameModel,
			);
		}

		return Promise.reject(new Error('unsupported'));
	}
}

/**
 * InitiateFileDownload factory method.
 * @returns an instance of GetDocumentFile
 */
export const makeInitiateFileDownload = (): InitiateFileDownload => {
	return new InitiateFileDownload(makeDocumentsDownloaderRepository());
};
