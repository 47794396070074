import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Spacer,
  AccordionPanel,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionIcon,
  Tooltip,
  Divider, Stack
} from "@chakra-ui/react";
import { Skeleton } from '@chakra-ui/react'
import Supplier from "../../../../domain/entities/supplier";
import { useTranslation } from "react-i18next";
import StateTag from "../../Common/StateTag";
import { SupplierEvaluationState } from "../../../../domain/entities/supplierEvaluationState.enum";
import { SupplierStatus } from "../../../../domain/entities/supplierStatus.enum";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "../../../screens/Common/Alert";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { COLORS } from "../../../assets/theme/colors";
import { useHasPermissions } from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";

type SupplierListProps = {
  suppliers: Supplier[];
  suppliersNestedHasNextPage: boolean;
  suppliersNestedFetchNextPage: any;
  suppliersNestedIsFetching: boolean;
};

const SiteSuppliersNestedView: React.FC<SupplierListProps> = ({ suppliers, suppliersNestedHasNextPage,  suppliersNestedFetchNextPage, suppliersNestedIsFetching}) => {
  const { t } = useTranslation("supplier");
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [showNavigationAlert, setShowNavigationAlert] = useState<
    [boolean, string]
  >([false, ""]);
  const observerRef = useRef(null);
  const lastElementRef = useRef(null);
  const canShowSupplierState = useHasPermissions([Permission.Sites_ShowSupplierState])
  const canShowGlobalSupplierEvaluation = useHasPermissions([Permission.Sites_ShowGlobalSupplierEvaluation])
  const navigateToSupplierDetail = (supplier: Supplier) => {
    navigate(`/sites/${siteId}/suppliers/${supplier?.company?.id}/`);
  };
  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !suppliersNestedIsFetching) {
        suppliersNestedFetchNextPage();
      }
    });

    if (lastElementRef.current) {
      observerRef.current.observe(lastElementRef.current);
    }

    return () => {
      if (observerRef.current && lastElementRef.current) {
        observerRef.current.unobserve(lastElementRef.current);
      }
    };
  }, [suppliersNestedHasNextPage, suppliers]);

  const renderSupplier = (supplier: Supplier, invitedBy?: string, index?: number) => {
    return (
      <Accordion
        key={supplier?.company?.id}
        marginTop={0}
        width={"100%"}
        allowToggle
        defaultIndex={[0]}
        borderRadius={5}
        ref={index === suppliers.length - 1 ? lastElementRef : null}
      >
        <AccordionItem
          marginTop={0}
          paddingBottom={0}
          borderRadius={5}
          border={"none"}
        >
          <AccordionButton
            backgroundColor={supplier.archived ? COLORS.lightRed : "gray.100"}
            borderRadius={5}
          >
            <Tooltip
              label={t("navigateSupplierDetail", { ns: "supplier" })}
              isDisabled={supplier.archived}
            >
              <Flex
                borderRadius={5}
                flexDirection={"column"}
                onClick={() => {
                  let alertText;
                  if (supplier.status === SupplierStatus.PENDING) {
                    alertText = "navigationBlocked";
                  } else if (supplier.archived) {
                    alertText = "supplierNavigationBlocked";
                  }
                  supplier.status !== SupplierStatus.PENDING && !supplier.archived
                    ? navigateToSupplierDetail(supplier)
                    : setShowNavigationAlert([true, alertText]);
                }}
                sx={{
                  ":hover": {
                    backgroundColor: "gray.300"
                  }
                }}
              >
                <Flex flexDirection={"row"}>
                  <Text fontWeight="bold">{supplier?.company?.name}</Text>
                 {canShowGlobalSupplierEvaluation && <StateTag
                    marginLeft="2"
                    value={
                      supplier?.evaluationResult ??
                      SupplierEvaluationState.UNEVALUATED
                    }
                    type="supplierEvaluation"
                  />}
                 {canShowSupplierState && <StateTag
                    marginLeft="2"
                    value={supplier?.status}
                    type="supplierStatus"
                  />}
                </Flex>
                <Flex>
                  <Text fontSize={14}>
                    {t("vatCode", { ns: "supplier" })}: {supplier?.company?.vat}{" "}
                    {"-"}
                  </Text>
                  {invitedBy && (
                    <Text fontSize={14} marginLeft={2}>
                      {t("invitedBy", { ns: "supplier" })} {invitedBy} {"-"}
                    </Text>
                  )}
                  <Text fontSize={14} marginBottom={2} marginLeft={2}>
                    {t("columns.subscribeDate", { ns: "supplier" })} :{" "}
                    {formatDateBasedOnLanguage(supplier?.subscribeDate, true)}
                  </Text>
                </Flex>
              </Flex>
            </Tooltip>
            <Spacer />
            {supplier.subContractors && supplier?.subContractors?.length > 0 && (
              <AccordionIcon />
            )}
          </AccordionButton>

          {supplier.subContractors && supplier?.subContractors?.length > 0 && (
            <AccordionPanel
              pb={0}
              marginRight={0}
              paddingRight={0}
              border={"none"}
              width={"100%"}
            >
              <Flex flexDirection={"row"} width={"100%"}>
                {supplier.subContractors &&
                  supplier?.subContractors?.length > 0 && (
                    <Flex
                      width={"100%"}
                      flexDirection={"column"}
                      rowGap={2}
                      pl={4}
                    >
                      {supplier?.subContractors?.map((sub) => {
                          return renderSupplier(sub, supplier.company.name);
                        }
                      )}
                    </Flex>
                  )}
              </Flex>
            </AccordionPanel>
          )}
        </AccordionItem>
      </Accordion>
    );
  };
  return (
    <Box width={"100%"}>
      {
        suppliers.length === 0 && !suppliersNestedIsFetching ?
          <Text textAlign={'center'} fontWeight={500} my={5}>{t("noActiveSupplier" ,{ ns: "supplier" })}</Text> :
        suppliers.map((supplier, index ) => {
          return (
            <Flex
              key={supplier?.company?.id}
              marginTop={5}
              flexDirection={"column"}
            >
              { renderSupplier(supplier, null, index)}
              <Divider marginTop={5} />
            </Flex>
          );
        } )}
      {suppliersNestedIsFetching &&
        <Stack>
          <Skeleton height='70px' borderRadius={5} />
        </Stack>
      }
      {showNavigationAlert[0] && (
        <Alert
          title={t("warning", { ns: "common" })}
          message={t(showNavigationAlert[1], { ns: "supplier" })}
          variant="info"
          onClose={() => {
            setShowNavigationAlert([false, ""]);
          }}
        />
      )}
    </Box>
  );
};

export default SiteSuppliersNestedView;
