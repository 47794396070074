import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import { getSectionPermissions, Permission } from "../../Permissions/Permissions";
import { useHasPermissions } from "../../Permissions/RenderIf";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface SitesDetailTopBarProps {
  emptyItems?: boolean;
}

const SitesDetailsTopBar = ({ emptyItems = false }: SitesDetailTopBarProps) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const isReviewEnabled = localStorage.getItem("isReviewEnabled");

  const approvePermissions = [
    Permission.Sites_ShowCompanyPendingEvaluations,
    Permission.Sites_ShowWorkerPendingEvaluations,
    Permission.Sites_ShowVehiclePendingEvaluations,
    Permission.Sites_ShowMachinePendingEvaluations,
    Permission.Sites_ShowToolPendingEvaluations,
    Permission.Sites_ShowChemicalPendingEvaluations,
  ]
  const hasDetailsPermission = useHasPermissions( [Permission.Sites_ShowDetails, Permission.Sites_ShowSettings ], PermissionCheck.Some)
  const hasApprovePermission = useHasPermissions(approvePermissions, PermissionCheck.Some)

  const items : TopBarItem[] = [
      {
        title: t("summary"),
        path: `/sites/${siteId}/site`,
        permissions: [Permission.Sites_ShowDetails, Permission.Sites_ShowSettings],
        hidden: !hasDetailsPermission
      },
      {
        title: t("documents"),
        path: `/sites/${siteId}/documents`,
        permissions: [Permission.Sites_ShowGeneralDocs, Permission.Sites_ShowOwnerReq, Permission.Sites_EvaluationOverview],
      },
      {
        title: t("resources"),
        path: `/sites/${siteId}/resources`,
        permissions: [Permission.Sites_ShowWorkers,Permission.Sites_ShowVehicles, Permission.Sites_ShowMachines, Permission.Sites_ShowChemicals, Permission.Sites_ShowTools ],
      },
      {
        title: t("suppliers"),
        path: `/sites/${siteId}/suppliers`,
        permissions: [Permission.Sites_ShowSuppliers],
      },
      {
        title: t("requirements"),
        path: `/sites/${siteId}/requirements`,
        permissions: [Permission.Sites_ShowRequirements],
      },
      {
        title: t("access"),
        path: `/sites/${siteId}/access`,
        permissions: [Permission.Sites_ShowBadges, Permission.Sites_ShowPhysicalBadgeReaders]
      },
      isReviewEnabled === "true" && hasApprovePermission && {
        title: t("approve"),
        path: `/sites/${siteId}/approve`,
        permissions: approvePermissions,
      },
      {
        title: t("audit"),
        path: `/sites/${siteId}/audits`,
        permissions: [Permission.Sites_ShowReports]
      },
    ].filter(Boolean);

  return (
    <TopBar
      title={t("site")}
      items={emptyItems ? [] : items}
      backPath="/sites"
    />
  );
};

export default SitesDetailsTopBar;
