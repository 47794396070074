import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { ResourceDocumentsPermissions } from "../../components/Views/common/SiteResourceDocuments";
import DocumentsTable from "../../components/Views/Documents/DocumentsTable";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import useDocumentsListViewModel from "../../hooks/Document/useDocumentsViewModel";
import { SiteGeneralDocumentsViewModel } from "../../viewmodels/documents/SiteGeneralDocumentsViewModel";

interface WorkingSiteDocumensPanelProps {
    siteId: string;
    permission: ResourceDocumentsPermissions;
}

const WorkingSiteDocumentsPanel = ({
    siteId, permission}:
    WorkingSiteDocumensPanelProps) => {

    const { t } = useTranslation("sites");
    const viewModel = new SiteGeneralDocumentsViewModel(siteId);
    const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");

    const {
        documentsProps: { deleteDocument, ...docsProps },
        documentTypesProps,
        documentProps,
      } = useDocumentsListViewModel(
        viewModel,
        DocumentTypeCategory.SITE,
        "site-general-documents",
        siteId
      );

    return (

        <Flex
            marginTop={15}
            flexDirection={"column"}
            alignItems={"start"}
            flex={1}
            position="relative"
            overflow={"hidden"}
            >
            <DocumentsTable
                {...docsProps}
                {...documentProps}
                downloader={downloader}
                columns={[
                {
                    field: "name",
                    filterType: "text" as const,
                    label: t("name"),
                    width: "150px",
                },
                ]}
                tags={documentTypesProps.allTags}
                deleteDocument={deleteDocument}
                isReadOnly={true}
                showExpiresAt={false}
                fromSite={false}
                isWorkingSite={true}
                siteGeneralDocument={true}
                permissions={permission}
                deltaWidth="264px"
            />
            </Flex>

    );
}

export default WorkingSiteDocumentsPanel;