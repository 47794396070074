import { Permission } from "../components/Permissions/Permissions";

export const commonSiteFallbackRoutes = [
  {
    path: "sites",
    permissions: [Permission.Sites_Show],
  },
  {
    path: "sites/:siteId",
    permissions: [Permission.Sites_ShowDetails],
  },
  {
    path: "sites/:siteId/site",
    permissions: [
      Permission.Sites_ShowDetails,
      Permission.Sites_ShowSettings,
    ],
  },
  {
    path: "sites/:siteId/site/details",
    permissions: [Permission.Sites_ShowDetails],
  },
  {
    path: "sites/:siteId/site/options",
    permissions: [Permission.Sites_ShowSettings],
  },
  {
    path: "sites/:siteId/documents/general",
    permissions: [Permission.Sites_ShowGeneralDocs],
  },
  {
    path: "sites/:siteId/documents/supplier/:supplierId",
    permissions: [Permission.Sites_ShowOwnerReq],
  },
  {
    path: "sites/:siteId/documents/siteRequirements/:topic",
    permissions: [Permission.Sites_EvaluationOverview],
  },
  {
    path: "sites/:siteId/resources",
    permissions: [Permission.Sites_ShowWorkers,Permission.Sites_ShowVehicles, Permission.Sites_ShowMachines, Permission.Sites_ShowChemicals, Permission.Sites_ShowTools ],
  },
  {
    path: "sites/:siteId/requirements",
    permissions: [Permission.Sites_ShowRequirements],
  },
  {
    path: "sites/:siteId/access",
    permissions: [Permission.Sites_ShowPhysicalBadgeReaders, Permission.Sites_ShowVirtualBadgeReaders],
  },
  {
    path: "sites/:siteId/audits",
    permissions: [Permission.Sites_ShowReports],
  }
];

export const getSiteFallbackRoutes = (currentRoute: string) => {
  let fallbackRoutes = [...commonSiteFallbackRoutes];
  fallbackRoutes = fallbackRoutes.filter(
    (route) => route.path !== `sites/:siteId/${currentRoute}`,
  );
  return fallbackRoutes;
};

export const getDocumentSupplierFallbackRoutes = (
  currentRoute: string,
  id?: string,
) => {
  if (!id) {
    console.warn("Missing supplierId. Fallback routes may be incomplete.");
    return [];
  }

  return commonSiteFallbackRoutes
    .filter(
      (route) => route.path !== `sites/:siteId/${currentRoute}/:supplierId`,
    )
    .map((route) => ({
      ...route,
      path: route.path.replace(":supplierId", id),
    }));
};

export const getOverviewFallbackRoutes = (
  currentRoute: string,
  id?: string,
) => {
  if (!id) {
    console.warn("Missing supplierId. Fallback routes may be incomplete.");
    return [];
  }

  return commonSiteFallbackRoutes
    .filter(
      (route) => route.path !== `sites/:siteId/${currentRoute}/:supplierId`,
    )
    .map((route) => ({
      ...route,
      path: route.path.replace(":supplierId", id),
    }));
};
