import { useState } from "react";
import { useAuth } from "../../providers/Auth0JWTProvider";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import {
  GetAvailableBadgesFilters,
  GetBadgesQuery,
  GetSiteBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { SiteBadgesListViewModel } from "../../viewmodels/badges/SiteBadgesListViewModel";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import FileEntity from "../../../domain/entities/file";
import { getUniqueTags, updateFilterWithDelete } from "../../../utils";
import { GetSiteWorkersFilters } from "../../../domain/repositories/siteWorkerRepository";
import { GetSiteMachinesFilters } from "../../../domain/repositories/siteMachineRepository";
import { GetSiteVehiclesFilters } from "../../../domain/repositories/siteVehicleRepository";

export type AllResourcesFilters =
  keyof GetSiteWorkersFilters |
  keyof GetSiteMachinesFilters |
  keyof GetSiteVehiclesFilters;

export type GetSiteResourcesFilters =
  | GetSiteWorkersFilters
  | GetSiteMachinesFilters
  | GetSiteVehiclesFilters;

const useSiteBadgesListViewModel = (siteId: string) => {
  const { companyId } = useAuth();
  const viewModel = new SiteBadgesListViewModel();

  const [resourceType, setResourceType] = useState<BadgeResourceType>(null);
  const [siteBadgesFilters, setSiteBadgesFilters] =
    useState<GetSiteBadgesFilters>({});
  const [siteBadgesSort, setSiteBadgesSort] = useState<SortMeta>(null);
  const [availableBadgesFilters, setAvailableBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableBadgesSort, setAvailableBadgesSort] =
    useState<SortMeta>(null);
  const [availableSiteBadgesFilters, setAvailableSiteBadgesFilters] =
    useState<GetAvailableBadgesFilters>({});
  const [availableSiteBadgesSort, setAvailableSiteBadgesSort] =
    useState<SortMeta>(null);
  const [siteResourcesFilters, setSiteResourcesFilters] =
    useState<GetSiteResourcesFilters>({});
  const [siteResourcesSort, setSiteResourcesSort] = useState<SortMeta>(null);
  const [enableGetAvailableBadges, setEnableGetAvailableBadges] =
    useState(false);
  const [printModalOpen, setPrintModalOpen] =
    useState(false);
  const [badgesInterval, setBadgesInterval] = useState({
    from: 0,
    to: 0,
  });
  const [hasSiteImage, setHasSiteImage] =
    useState(false);
  const [enableGetSiteBadgeFromInterval, setEnableGetSiteBadgeFromInterval] =
    useState(false);

  const [badgeId, setBadgeId] = useState<string>();
  // Define a state to store the total count
  const [totalBadgeAvailableCount, setTotalBadgeAvailableCount] =
    useState<number>(0);
  const [totalBadgeRegisteredCount, setTotalBadgeRegisteredCount] =
    useState<number>(0);

  const [siteRegisteredBadgesCount, setSiteRegisteredBadgesCount] =
    useState<number>(0);
  const [siteRegisteredResourcesCount, setSiteRegisteredResourcesCount] =
    useState<number>(0);
  const [search, setSearch] = useState<string>();
  const [selectAllPaginatedBadges, setSelectAllPaginatedBadges] =
    useState<boolean>(false);

  const [importLinkError, setImportLinkError] = useState<string | null>();
  const [siteBadgesError, setSiteBadgesError] = useState<string | null>();
  const [successImport, setSuccessImport] = useState(false)

  const getSiteBadgesQuery = useInfiniteQuery(
    ["site-badges", companyId, siteId, siteBadgesFilters, siteBadgesSort],
    async ({ pageParam = 1 }) => {
      const {message, count, results} = await viewModel.list(
        companyId,
        siteId,
        pageParam,
        siteBadgesFilters,
        siteBadgesSort,
        25,
      );
      if (message) {
        setSiteBadgesError(message);
      }

      setTotalBadgeRegisteredCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const getAllSiteBadgesQuery = useQuery(
    ["all-site-badges", companyId, siteId],
    async () => {
      const { results } = await viewModel.list(
        companyId,
        siteId
      );
      return results;
    },
    {
      enabled: selectAllPaginatedBadges && printModalOpen,
      initialData: [],
    }
  );
  const getSiteDataQuery = useQuery(
    ["get-site-data", companyId, siteId],
    async () => {
      return  await viewModel.getSiteData(
        companyId,
        siteId
      );
    },
    {
      enabled: hasSiteImage,
    }
  );

  const getSiteBadgeFromInterval = useQuery(
    [
      "badge-interval",
      companyId,
      siteId,
      badgesInterval.from,
      badgesInterval.to,
    ],
    async () => {
      return await viewModel.listBadgeInterval(
        companyId,
        siteId,
        badgesInterval.from,
        badgesInterval.to,
      );
    },
    {
      enabled: enableGetSiteBadgeFromInterval,
    },
  );

  const getAvailableBadgesQuery = useInfiniteQuery(
    [
      "available-badges",
      companyId,
      availableBadgesFilters,
      availableBadgesSort,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.listAvailable(
        companyId,
        siteId,
        pageParam,
        availableBadgesFilters,
        availableBadgesSort,
      );
      setTotalBadgeAvailableCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetAvailableBadges,
    },
  );

  //recovers
  const getAvailableSiteBadgesQuery = useInfiniteQuery(
    [
      "available-site-badges",
      companyId,
      siteId,
      availableSiteBadgesFilters,
      availableSiteBadgesSort,
      resourceType,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.listSiteAvailableBadges(
        companyId,
        siteId,
        pageParam,
        resourceType === BadgeResourceType.WORKER ||
          resourceType === BadgeResourceType.VEHICLE ||
          resourceType === BadgeResourceType.MACHINE
          ? ("availableForResources" as GetBadgesQuery)
          : ("availableForSite" as GetBadgesQuery),
        availableSiteBadgesFilters,
        availableSiteBadgesSort,
      );
      setSiteRegisteredBadgesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !!resourceType,
    },
  );

  const getSiteResourcesQuery = useInfiniteQuery(
    [
      "site-resources",
      companyId,
      siteResourcesFilters,
      siteResourcesSort,
      resourceType,
      siteId,
      search,
    ],
    async ({ pageParam = 1 }) => {
      const filters = search
        ? { ...siteResourcesFilters, search }
        : siteResourcesFilters;
      const { results, count } = await viewModel.listSiteResources(
        companyId,
        siteId,
        resourceType,
        pageParam,
        filters,
        siteResourcesSort,
      );
      setSiteRegisteredResourcesCount(count);
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
    },
  );

  const linkBadgesToSiteMutation = useMutation(
    ({
      badgeIds,
      selectAll,
      selectedBadgesNumber,
    }: {
      badgeIds: string[];
      selectAll: boolean;
      selectedBadgesNumber: number;
      availableBadgesFilters: { [p: string]: any };
    }) =>
      viewModel.linkBadgesToSite(
        companyId,
        siteId,
        badgeIds,
        selectAll,
        selectedBadgesNumber,
        availableBadgesFilters,
      ),
    {
      onSuccess: () => {
        getAvailableBadgesQuery.refetch();
        getAvailableSiteBadgesQuery.refetch();
        getSiteBadgesQuery.refetch();
      },
    },
  );

  const linkBadgesToSiteResourcesMutation = useMutation(
    ({
      badgeIds,
      resourceIds,
      selectAllResources,
      selectedResourcesNumber,
      siteResourcesFilters,
      selectAllBadges,
      selectedBadgesNumber,
      availableBadgesFilters,
      automaticLinking,
    }: {
      badgeIds: string[];
      resourceIds: string[];
      selectAllResources: boolean;
      selectedResourcesNumber: number;
      siteResourcesFilters: GetSiteResourcesFilters;
      selectAllBadges: boolean;
      selectedBadgesNumber: number;
      availableBadgesFilters: GetAvailableBadgesFilters;
      automaticLinking: boolean;
    }) =>
      viewModel.linkBadgesToSiteResources(
        companyId,
        siteId,
        badgeIds,
        resourceIds,
        resourceType,
        selectAllResources,
        selectedResourcesNumber,
        siteResourcesFilters,
        selectAllBadges,
        selectedBadgesNumber,
        availableBadgesFilters,
        automaticLinking,
      ),
    {
      onSuccess: () => {
        getAvailableSiteBadgesQuery.refetch();
        getSiteBadgesQuery.refetch();
      },
    },
  );
  const getTagsQuery = useQuery(
    ["tags", companyId],
    async () => {
      return await viewModel.getTags(companyId);
    },
    {
      initialData: [],
    }
  );

  const unlinkBadgesToSiteMutation = useMutation(
    ({ badgeIds, selectAll }: { badgeIds: string[]; selectAll: boolean }) =>
      viewModel.unlinkBadgesFromSite(companyId, siteId, badgeIds, selectAll),
    {
      onSuccess: () => {
        getAvailableBadgesQuery.refetch();
        getAvailableSiteBadgesQuery.refetch();
        getSiteBadgesQuery.refetch();
      },
    },
  );

  const getBadgeSitesQuery = useQuery(
    ["get-badge-sites", companyId, badgeId],
    async () => {
      return viewModel.getBadgeSites(companyId, badgeId);
    },
    {
      enabled: !!badgeId,
    },
  );

  const importLinkResourceBadgesMutation = useMutation(
    (file: FileEntity) =>
      viewModel.importLinkResourcesBadges(
        companyId,
        siteId,
        resourceType,
        file,
      ),
    {
      onSuccess: () => {
        setSuccessImport(true)
        getSiteResourcesQuery.refetch();
        getSiteBadgesQuery.refetch();
      },
      onError: (error: string) => {
        setImportLinkError(error);
      },
    },
  );

  const updateSiteBadgesFilter = (
    field: keyof GetSiteBadgesFilters,
    value: string | string[],
  ) => {
    updateFilterWithDelete(setSiteBadgesFilters, field, value);
  };

  const updateAvailableBadgesFilter = (
    field: keyof GetAvailableBadgesFilters,
    value: string | string[],
  ) => {
    updateFilterWithDelete(setAvailableBadgesFilters, field, value);
  };

  const updateAvailableSiteBadgesFilter = (
    field: keyof GetAvailableBadgesFilters,
    value: string | string[],
  ) => {
    updateFilterWithDelete(setAvailableSiteBadgesFilters, field, value);
  };

  const updateSiteResourcesFilter = (
    field: keyof GetSiteResourcesFilters,
    value: string | string[],
  ) => {
    updateFilterWithDelete(setSiteResourcesFilters, field, value);
  };

  const siteBadges = getSiteBadgesQuery.data?.pages?.flat() ?? [];
  const availableBadges = getAvailableBadgesQuery.data?.pages?.flat() ?? [];

  const availableSiteBadges =
    getAvailableSiteBadgesQuery.data?.pages?.flat() ?? [];
  const siteResources = getSiteResourcesQuery.data?.pages?.flat() ?? [];

  const badgeSites = getBadgeSitesQuery.data;
  const badgeSiteInterval = getSiteBadgeFromInterval.data;

  return {
    siteBadges,
    siteBadgesError,
    setSiteBadgesError,
    badgeSiteInterval,
    totalBadgeRegisteredCount,
    siteAvailableBadgesTotalCount: totalBadgeAvailableCount,
    siteBadgesIsLoading: getSiteBadgesQuery.isLoading,
    siteBadgesHasNextPage: getSiteBadgesQuery.hasNextPage,
    siteBadgesFetchNextPage: getSiteBadgesQuery.fetchNextPage,
    siteBadgesFilters,
    updateSiteBadgesFilter,
    siteBadgesSort,
    setSiteBadgesSort,
    siteBadgesTags: getTagsQuery.data,
    getAvailableBadges: () => setEnableGetAvailableBadges(true),
    availableBadges,
    availableBadgesHasNextPage: getAvailableBadgesQuery.hasNextPage,
    availableBadgesFetchNextPage: getAvailableBadgesQuery.fetchNextPage,
    availableBadgeSitesIsLoading: getAvailableBadgesQuery.isLoading,
    availableBadgesFilters,
    updateAvailableBadgesFilter,
    availableBadgesSort,
    setAvailableBadgesSort,
    availableBadgesTags: getUniqueTags(availableSiteBadges),
    availableSiteBadges,
    availableSiteBadgesCount: siteRegisteredBadgesCount,
    availableSiteBadgesHasNextPage: getAvailableSiteBadgesQuery.hasNextPage,
    availableSiteBadgesFetchNextPage: getAvailableSiteBadgesQuery.fetchNextPage,
    availableSiteBadgesFilters,
    updateAvailableSiteBadgesFilter,
    availableSiteBadgesSort,
    setAvailableSiteBadgesSort,
    availableSiteBadgesTags: getUniqueTags(availableSiteBadges),
    linkBadgesToSiteResources: linkBadgesToSiteResourcesMutation.mutateAsync,
    linkingIsLoading: linkBadgesToSiteResourcesMutation.isLoading,
    linkBadgesToSite: linkBadgesToSiteMutation.mutateAsync,
    linkBadgesToSiteIsLoading: linkBadgesToSiteMutation.isLoading,
    unlinkBadgeFromSite: unlinkBadgesToSiteMutation.mutateAsync,
    siteResources,
    siteResourcesIsLoading: getSiteResourcesQuery.isFetching,
    siteResourcesCount: siteRegisteredResourcesCount,
    siteResourcesHasNextPage: getSiteResourcesQuery.hasNextPage,
    siteResourcesFetchNextPage: getSiteResourcesQuery.fetchNextPage,
    siteResourcesFilters,
    updateSiteResourcesFilter,
    siteResourcesSort,
    setSiteResourcesSort,
    setResourceType,
    setBadgesInterval,
    badgesInterval,
    resourceType,
    badgeSites,
    badgeSitesFetching: getBadgeSitesQuery.isLoading,
    refetchBadgeSiteFromInterval: getSiteBadgeFromInterval.refetch,
    setBadgeId,
    importLinkResourcesBadges: importLinkResourceBadgesMutation.mutateAsync,
    importLinkError,
    setImportLinkError,
    search,
    setSearch,
    selectAllPaginatedBadges,
    setSelectAllPaginatedBadges,
    print: {
      allBadges: getAllSiteBadgesQuery.data,
      allBadgesLoading: getAllSiteBadgesQuery.isLoading || getAllSiteBadgesQuery.isFetching,
      printModalOpen,
      setPrintModalOpen,
    },
    getSiteData: { data: getSiteDataQuery.data,
      loading: getSiteDataQuery.isFetching,
      hasSiteImage,
      setHasSiteImage },
      successImport,
      setSuccessImport
  };
  
};

export { useSiteBadgesListViewModel };
