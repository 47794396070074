import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";

interface Props {
  selected: number;
  steps: { title: string }[];
  onSelect?: (number) => void;
  disableClick?: boolean;
}

const Steps: FC<Props> = ({ steps, selected, onSelect,disableClick  }) => {
  const { t } = useTranslation();
  return (
    <Stepper index={selected}>
      {steps.map((step, index) => (
        <Step
          key={`step.${index}`}
          style={{ cursor: !disableClick ? "pointer" : "default" }}
          onClick={() => {
            return !disableClick &&(
              onSelect(index),
              onSelect && onSelect(index))
          }}
        >
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber>{index + 1}</StepNumber>}
              active={<StepNumber>{index + 1}</StepNumber>}
            />
          </StepIndicator>

          <Box flexShrink="0">
            <StepDescription>
              {t("stepNumber", { index: index + 1 })}
            </StepDescription>
            <StepTitle>{step.title}</StepTitle>
          </Box>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

export default Steps;
