import {
  Box,
  Flex,
  FormControl,
  IconButton,
  InputGroup,
  InputRightElement,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useClipboard,
  useMediaQuery
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiLink } from "react-icons/bi";
import { FaRegSave } from "react-icons/fa";
import {
  FiAlertTriangle,
  FiCheckSquare,
  FiCopy,
  FiInfo,
  FiPlus,
} from "react-icons/fi";
import { MdCancel, MdClose, MdEdit } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AllowedTimezones } from "../../../domain/entities/AllowedTimezones.enum";
import {
  BadgeLogEntry,
  BadgeLogEntryAction,
  BadgeLogEntryActionResult,
} from "../../../domain/entities/badgeLogEntry";
import BadgeReader from "../../../domain/entities/badgeReader";
import { BadgeReaderDirection } from "../../../domain/entities/badgeReaderDirection.enum";
import { BadgeReaderGate } from "../../../domain/entities/badgeReaderGate.enum";
import { BadgeReaderState } from "../../../domain/entities/badgeReaderState.enum";
import { GetBadgeReaderHistoryFilters } from "../../../domain/repositories/badgeReaderRepository";
import { formatDateBasedOnLanguage, getBadgeReaderPath } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import BaseModal from "../../components/Common/alerts/BaseModal";
import DataBox from "../../components/Common/DataBox";
import FormDateField from "../../components/Common/forms/FormDateField";
import FormSelectField from "../../components/Common/forms/FormSelectField";
import FormTextField from "../../components/Common/forms/FormTextField";
import StatusToggle from "../../components/Common/StatusToggle";
import ActionButton from "../../components/Common/table/ActionButton";
import ColumnFilterComponent, { FilterComponentProps } from "../../components/Common/table/ColumnFilterComponent";
import DeleteButton from "../../components/Common/table/DeleteButton";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import CreateUpdateStampingModal from "../../components/Views/Badge/CreateUpdateStampingModal";
import SelectSitesView from "../../components/Views/common/SelectSitesView";
import useSitesBadgeReaderDetailViewModel from "../../hooks/Site/useSiteBadgeReaderDetailViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import { Alert } from "../Common/Alert";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import { ForcingDetailsModal } from "../Common/ForcingDetailModal";
import { BadgeReadersType } from "../../hooks/Site/useSiteBadgeReadersViewModel";

interface Props {
  permissions: {
    detailPhysicalPermissions: {
      showState: Permission,
      setState: Permission,
      edit: Permission,
      showRecords: Permission,
      addManualRecord: Permission,
      deleteManualRecord: Permission,
    },
    detailVirtualPermissions: {
      showState: Permission,
      setState: Permission,
      edit: Permission,
      showRecords: Permission,
      addManualRecord: Permission,
      deleteManualRecord: Permission,
    }
  };
}

const SiteBadgeReaderDetail: FC<Props> = ({ permissions }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("badgeReaders");
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { badgeReaderId, siteId } = useParams();
  const [askConfirmDelete, setAskConfirmDelete] = useState<boolean>(false);
  const [showCreateUpdateStamp, setShowCreateUpdateStamp] =
    useState<boolean>(false);
  const [badgeLogEntryToDelete, setBadgeLogEntryToDelete] =
    useState<BadgeLogEntry>(undefined);
  const [selectedBadgeLogEntry, setSelectedBadgeLogEntry] =
    useState<BadgeLogEntry>(undefined);
  const [showModalLink, setShowModalLink] = useState(false);
  const [showForcedDetails, setShowForcedDetails] = useState(false);
  const [confirmChangeStateModal, setConfirmChangeStateModal] = useState<
    BadgeReaderState | false
    >(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { companyId } = useAuth();
  const {
    badgeReader,
    badgesAvailable,
    fetchingBadgeReader,
    filters,
    sort,
    history,
    historyIsLoading,
    linkBadgeReaderToSites,
    linkBadgeReaderToSitesIsLoading,
    fetchingBadgeReaderSites,
    selectedSiteIds,
    setSelectedSiteIds,
    setEnableGetSites,
    sites,
    updateFilter,
    updateSitesFilter,
    filtersSites,
    createBadgeLogEntry,
    deleteBadgeLogEntry,
    deleteBadgeLogEntryIsLoading,
    setSort,
    setStampingMgmtActive,
    updateBadgeReaderStatus,
    updateBadgeReader,
    error,
    setError,
    hasNextPage,
    fetchNextPage,
  } = useSitesBadgeReaderDetailViewModel(siteId, badgeReaderId);
  const type = (localStorage.getItem('data'));
  const badgeReaderType: BadgeReadersType = JSON.parse(type);

  const methods = useForm<BadgeReader>({ mode: "onBlur",});
  useEffect(() => {
    if(!fetchingBadgeReader && badgeReader) methods.reset(badgeReader);
  }, [badgeReader, fetchingBadgeReader, methods])

  const navigateToBadgeReadersList = () => {
    navigate(`/sites/${siteId}/access/badge-readers`);
  };
  const logStateOptions = [
    {
      option: BadgeLogEntryActionResult.GRANTED,
      label: t("actionResults.accepted", {ns: 'badges'}),
    },
    {
      option: BadgeLogEntryActionResult.DENIED,
      label: t("actionResults.denied", {ns: 'badges'}),
    },
  ];
  const logEntryOptions = [
    {
      option: BadgeLogEntryAction.ENTER,
      label: t("actionTypes.incoming", {ns: 'badges'}),
    },
    {
      option: BadgeLogEntryAction.EXIT,
      label: t("actionTypes.outgoing", {ns: 'badges'}),
    },
  ];

  const columns: {
    field: keyof GetBadgeReaderHistoryFilters;
    filterType: FilterComponentProps<null>["type"];
    width: number;
    options?: Record<string, string> | {option: string, label: string}[];
  }[] = [
    { field: "actionDate", filterType: "dateTime-range", width: 200 },
    { field: "actionType", filterType: "select", options: logEntryOptions, width: 150 },
    {
      field: "actionResult",
      filterType: "select",
      options: logStateOptions,
      width: 150
    },
    { field: "serial", filterType: "text", width: 230 },
    { field: "code", filterType: "text", width: 150 },
    { field: "resource", filterType: "text", width: 200 },
    { field: "company", filterType: "text", width: 150 },
  ];

  const permissionType = badgeReaderType === 'physical'
    ? permissions.detailPhysicalPermissions
    : permissions.detailVirtualPermissions;

  const canDelete = useHasPermissions(permissionType.deleteManualRecord);
  const canSetStatus = useHasPermissions(permissionType.setState);
  const requiredRule = { required: t("requiredField", { ns: "common" }) };

  const onSubmit = (data) => {
    
    const formData = {
      ...data,
      delay: parseInt(data.delay),
      notificationDelay: parseInt(data.notificationDelay),
    }
    updateBadgeReader({
      ...badgeReader,
      ...formData
    });
    setIsEditing(false);
  };
  const toggleIsEditing = () => {
    if (!isEditing) {
      methods.reset(badgeReader);
    }

    setIsEditing(!isEditing);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            bgColor={COLORS.sikuroBlue}
            color="white"
            icon={MdClose}
            description={t("close", { ns: "common" })}
            onClick={() => navigateToBadgeReadersList()}
          />
          <RenderIf permissions={permissionType.edit}  check={PermissionCheck.All}>
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={toggleIsEditing}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          </RenderIf>
          {isEditing && methods.formState.isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={methods.handleSubmit(onSubmit)}
            />
          )}
          <RenderIf permissions={permissionType.addManualRecord}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addStamping", { ns: "badges" })}
              onClick={() => {
                setStampingMgmtActive(true);
                setShowCreateUpdateStamp(true);
              }}
            />
          </RenderIf>
          {badgeReaderType === "physical" &&
            <RenderIf permissions={permissionType.edit}  check={PermissionCheck.All}>
              <ActionBarItem
                  icon={BiLink}
                  description={t("linkBadgeReaderToSites")}
                  onClick={() => {
                    setEnableGetSites(true);
                    setShowModalLink(true);
                  }}
              />
            </RenderIf>
          }
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        padding={10}
        flexDirection="column"
      >
        {badgeReader && (
          <Flex
            pb={5}
            gap="20px"
            width={"100%"}
            flexWrap={isTablet ? "wrap" : "nowrap"}
          >
            <Box
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              width={"100%"}
              flex={"1 1 auto"}
            >
              <FormProvider {...methods}>
                <DataBox
                  title={t("details", { ns: "common" })}
                  isEditing={isEditing}
                  fields={[
                    ...(badgeReaderType === "physical"
                      ? [
                        <InputGroup key="">
                          <BadgeReaderLink
                            valueReader={
                              getBadgeReaderPath(
                                companyId,
                                siteId,
                                badgeReader.id,
                              )}
                          />
                        </InputGroup>
                      ] : []),
                    <FormTextField
                      key="name"
                      name="name"
                      label={t("columns.name")}
                      rules={requiredRule}
                    />,
                    ...(badgeReaderType === "virtual"
                      ? [
                        <FormTextField
                          key="userName"
                          name="userName"
                          label={t("columns.userName")}
                          readOnly
                          rules={requiredRule}
                        />
                      ] : []),
                    ...(badgeReaderType === "physical"
                      ? [
                        <FormTextField
                          key="serial"
                          name="serial"
                          label={t("columns.serial")}
                        />
                      ] : []),
                    <FormSelectField
                      key="direction"
                      name="direction"
                      label={t("columns.readerDirection")}
                      displayValue={
                        badgeReader.direction ?
                          t(badgeReader.direction) :
                          "-"
                      }
                      options={[
                        {
                          id: BadgeReaderDirection.BIDIRECTIONAL,
                          name: t(BadgeReaderDirection.BIDIRECTIONAL),
                        },
                        {
                          id: BadgeReaderDirection.ENTRY,
                          name: t(BadgeReaderDirection.ENTRY),
                        },
                        {
                          id: BadgeReaderDirection.EXIT,
                          name: t(BadgeReaderDirection.EXIT),
                        },
                      ]}
                    />,
                    ...((methods.watch("direction") === BadgeReaderDirection.BIDIRECTIONAL ||
                      badgeReader.direction === BadgeReaderDirection.BIDIRECTIONAL) &&
                      badgeReaderType === "physical" ?
                      [
                        <FormSelectField
                          key="releConfiguration"
                          name="releConfiguration"
                          label={t("columns.entranceGate")}
                          displayValue={
                            badgeReader.releConfiguration ?
                              t(badgeReader.releConfiguration) :
                              "-"
                          }
                          options={
                            [
                              { id: "", name: t("selectEntranceGate") },
                              {
                                id: BadgeReaderGate.R1ER2U,
                                name: t(BadgeReaderGate.R1ER2U),
                              },
                              {
                                id: BadgeReaderGate.R1UR2E,
                                name: t(BadgeReaderGate.R1UR2E),
                              },
                            ]
                          }
                        />,
                      ] : []),
                    <FormSelectField
                      key="timeZone"
                      name="timeZone"
                      label={t("columns.timezone")}
                      displayValue={
                        badgeReader.timeZone ?
                          t(badgeReader.timeZone) :
                          "-"
                      }
                      options={Object.keys(AllowedTimezones).map(
                        (timezone) => ({
                          id: timezone,
                          name: AllowedTimezones[timezone],
                        }),
                      )}
                    />,
                    ...(badgeReaderType === "physical"
                      ? [
                        <FormDateField
                          key="lastPing"
                          name="lastPing"
                          label={t("columns.lastSync")}
                          withTime
                          readOnly
                        />,
                        <FormTextField
                          key="delay"
                          name="delay"
                          label={t("columns.delay")}
                          type="number"
                        />,
                        <FormTextField
                          key="notificationDelay"
                          name="notificationDelay"
                          label={t("columns.notificationDelay")}
                          type="number"
                          rules={{
                            validate:
                              (value) => value >= 5 || t("errors.notificationDelayMinValue")
                          }}
                        />
                      ] : []),
                  ]}
                />
              </FormProvider>
            </Box>
            <RenderIf permissions={permissionType.showState} check={PermissionCheck.All}>
            <Flex
              p="10px 20px"
              flexDirection="column"
              gap="10px"
              width="300px"
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              flex="1 1 300px"
            >
              <Box textStyle="h2">{t("columns.status")}</Box>
              <StatusToggle
                disabled={!canSetStatus}
                status={badgeReader.status}
                type="badgeReader"
                onChange={(status) =>
                  setConfirmChangeStateModal(status as BadgeReaderState)
                }
                isTablet={isTablet}
                isMobile={isMobile}
              />
            </Flex>
            </RenderIf>

          </Flex>
        )}
        <RenderIf permissions={permissionType.showRecords}  check={PermissionCheck.All}>
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={"calc(100vw - 264px)"}
          marginTop={3}
          position="relative"
          overflow="auto"
          id="table-container"
        >
          <InfiniteTable
            autosize
            maxHeight="calc(100vh - 300px)"
            height={"100%"}
            tableId="site-badge-reader-history-table"
            infiniteScroll={{
              dataLength: history.length,
              hasNextPage: hasNextPage,
              fetchNextPage: fetchNextPage,
            }}
            showEmptyText={history?.length === 0}
            emptyText={t("noHistoryBadgeReader", { ns: "badges" })}
            isLoading={historyIsLoading}
          >
            <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th
                      key={column.field}
                      width={column.width}
                    >
                      <TableColumnHeader
                        text={t(`historyColumns.${column.field}`, {
                          ns: "badgeReaders",
                        })}
                        filter={{
                          isActive:
                            !!filters[column.field] &&
                            (!Array.isArray(filters[column.field]) ||
                              !!filters[column.field][0]),
                          component: (
                            <ColumnFilterComponent
                              selectOptions={column.options}
                              value={filters[column.field]}
                              namespace="badges"
                              type={column.filterType}
                              updateFilter={(value) =>
                                updateFilter(column.field, value)
                              }
                            />
                          ),
                        }}
                        sort={{
                          handler: (direction) =>
                            setSort({ field: column.field, direction }),
                          direction:
                            sort?.field === column.field
                              ? sort.direction
                              : null,
                        }}
                      />
                    </Th>
                  ))}
                  {canDelete && <Th width={100} />}
                </Tr>
            </Thead>
            <Tbody borderRadius="xl">
                {history?.map((log) => (
                  <Tr key={log.id}>
                    <Td width={200}>
                      {formatDateBasedOnLanguage(log?.actionDate, true)}
                    </Td>
                    <Td textAlign="center" width={150}>
                      {t(`actionTypes.${log?.actionType}`, { ns: "badges" })}
                    </Td>
                    <Td
                      width={150}
                      textAlign="center"
                    >
                      <Flex flexDirection={"row"}>
                        {t(`actionResults.${log?.actionResult}`, {
                          ns: "badges",
                        })}
                        {log?.anomaly && (
                          <Tooltip label={t("anomaly", { ns: "badges" })}>
                            <span>
                              <FiAlertTriangle
                                style={{ marginLeft: "5px" }}
                                color={COLORS.darkYellow}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Flex>
                    </Td>
                    <Td width={230}>
                      {log?.badge?.serial}
                    </Td>
                    <Td
                      textAlign="center"
                      width={150}
                    >
                      {log?.badge?.code}
                    </Td>
                    <Td width={200}>{log?.resource}</Td>
                    <Td width={150}>{log?.company}</Td>
                    <Td
                      width={100}
                      p={0}
                      paddingLeft={3}
                    >
                      {canDelete && log.isManual ?
                        <DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setBadgeLogEntryToDelete(log);
                            setAskConfirmDelete(true);
                          }}
                        /> : null}
                      {log.isForced && (
                        <Tooltip
                          label={t("showDetails", { ns: "common" })}
                          shouldWrapChildren={true}
                        >
                          <ActionButton
                            aria-label="infoForced"
                            icon={<FiInfo color="red" />}
                            onClick={() => {
                              setShowForcedDetails(true);
                              setSelectedBadgeLogEntry(log);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
        </RenderIf>
      </Flex>
      <ForcingDetailsModal
        forcedDetails={{
          expiresAt: selectedBadgeLogEntry?.forcing?.expiresAt,
          note: selectedBadgeLogEntry?.forcing?.note,
          userName: selectedBadgeLogEntry?.forcing?.userName,
          codeBadge: selectedBadgeLogEntry?.badge?.code,
          resource: selectedBadgeLogEntry?.resource,
        }}
        setShowForcedDetails={setShowForcedDetails}
        showForcedDetails={showForcedDetails}
        title={t("forcedAccessInfoTitle")}
      />
      {confirmChangeStateModal && (
        <ConfirmAlert
          onCancel={() => setConfirmChangeStateModal(false)}
          onConfirm={async () => {
            await updateBadgeReaderStatus(confirmChangeStateModal);
            setConfirmChangeStateModal(false);
          }}
          title={t("updateMessage")}
          variant="question"
        />
      )}

      {showCreateUpdateStamp && (
        <CreateUpdateStampingModal
          onCancel={() => {
            setStampingMgmtActive(false);
            setShowCreateUpdateStamp(false);
            setSelectedBadgeLogEntry(undefined);
          }}
          onConfirm={(badgeLog) => {
            createBadgeLogEntry(badgeLog);
            setStampingMgmtActive(false);
            setShowCreateUpdateStamp(false);
            setSelectedBadgeLogEntry(undefined);
          }}
          badges={badgesAvailable}
          stampFromBadge={false}
          currentBadgeReader={badgeReader}
          badgeLogEntryToUpdate={selectedBadgeLogEntry}
        />
      )}

      {askConfirmDelete && (
        <DeleteActionAlert
          isLoading={deleteBadgeLogEntryIsLoading}
          onConfirm={async () => {
            await deleteBadgeLogEntry(badgeLogEntryToDelete);
            setAskConfirmDelete(false);
            setBadgeLogEntryToDelete(undefined);
          }}
          onCancel={() => {
            setAskConfirmDelete(false);
            setBadgeLogEntryToDelete(undefined);
          }}
          mainTitle={t("warning", { ns: "common" })}
          title={t("confirmLogEntryDelete", { ns: "badges" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!askConfirmDelete}
        />
      )}
      {error && (
        <Alert
          title={t("warning", {ns: 'common'})}
          message={t("logEntryErrors." + error, { ns: "badgeReaders" })}
          onClose={() => {
            methods.clearErrors();
            setError(undefined);
          }}
          variant="warning"
        />
      )}
      {showModalLink && (
        <BaseModal
          onClose={() => setShowModalLink(false)}
          onConfirm={() => linkBadgeReaderToSites()}
          onConfirmDisabled={selectedSiteIds.length <= 0}
          isLoading={linkBadgeReaderToSitesIsLoading}
          onCancel={() => setShowModalLink(false)}
        >
          <SelectSitesView
            autosize
            title={t("selectSites", { ns: "common" })}
            reminderText={t("noSiteSelected", { ns: "common" })}
            alertText={t("siteSelectionAlert", { ns: "common" })}
            siteSelectedAction={setSelectedSiteIds}
            siteList={sites}
            includeFooterButton={false}
            showSelectAll={false}
            hideSubtitles={true}
            updateFilterResourceSites={updateSitesFilter}
            setSortResourceSites={setSort}
            filterResourceSites={filtersSites}
            isFetchingSites={fetchingBadgeReaderSites}
          />
        </BaseModal>
      )}
    </ContentLayout>
  );
};

export default SiteBadgeReaderDetail;

const BadgeReaderLink = ({ valueReader }: { valueReader: string }) => {
  const { onCopy, hasCopied } = useClipboard("");
  const { t } = useTranslation("badgeReaders");
  return (
    <FormControl variant="floating">
      <FormTextField name="" label={t("configurationLink")} displayValue={valueReader} readOnly />
      <InputRightElement width="5rem">
        <IconButton
          variant="link"
          colorScheme="blue"
          aria-label="copy link"
          fontSize="15px"
          onClick={onCopy}
          icon={hasCopied ? <FiCheckSquare /> : <FiCopy />}
        />
      </InputRightElement>
    </FormControl>
  );
};
