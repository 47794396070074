import { FC } from "react";
import { useTranslation } from "react-i18next";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { Permission } from "../../Permissions/Permissions";
import { HStack, Spacer, Spinner, Switch, Text } from "@chakra-ui/react";
import { SupplierStatus as Status } from "../../../../domain/entities/supplierStatus.enum";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface Props {
  status: Status;
  onChange: (newStatus: Status) => void;
  permissions?: Permission[] | Permission;
  statusIsLoading?: boolean;
}

const SupplierStatus: FC<Props> = ({
  status,
  onChange,
  permissions,
  statusIsLoading,
}) => {
  const { t } = useTranslation("supplier");
  const canSetState = useHasPermissions(permissions)

  return (
    <HStack
      rounded="md"
      borderWidth="1px"
      borderColor="gray.300"
      p="10px 40px"
      width="100%"
    >
      <Text textStyle="h2">
        {t(status === Status.ACTIVE ? "active" : "blocked")}
      </Text>
      <Spacer />
       <RenderIf permissions={permissions} check={PermissionCheck.All}>
        {statusIsLoading ? (
          <Spinner />
        ) : (
          <Switch
            isChecked={status === Status.ACTIVE}
            onChange={(e) =>
              onChange(e.target.checked ? Status.ACTIVE : Status.BLOCKED)
            }
            isDisabled={!canSetState}
          />
        )}
       </RenderIf>
    </HStack>
  );
};

export default SupplierStatus;
