import { Tag } from "@chakra-ui/react";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceListView from "../ResourceListView";
import Worker from "../../../../domain/entities/worker";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import FormSelectField from "../../../components/Common/forms/FormSelectField";
import { EditableFormFieldProps } from "../../../components/Common/forms/FormField";
import { WorkersListViewModel } from "../../../viewmodels/workers/WorkersListViewModel";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import {
  fiscalCodeCountries,
  validateIdentityCode,
} from "../../../../infrastructure/utilities/validator";
import FormImageField from "../../../components/Common/forms/FormImageField";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import FormDateField from "../../../components/Common/forms/FormDateField";


const WorkersView = () => {
  const { t } = useTranslation("workers");
  const render = (column: string, value: string): React.ReactNode => {
    if (column !== "status") {
      return <>{value}</>;
    }

    return (
      <Tag colorScheme={value === "active" ? "green" : "red"}>{t(value)}</Tag>
    );
  };
  const columns = [
    { field: "lastName", type: "text", label: t("lastName") },
    { field: "firstName", type: "text", label: t("firstName") },
    { field: "fiscalCode", type: "text", label: t("fiscalCode") },
    { field: "jobTitle", type: "text", label: t("jobTitle") },
  ] as {
    field: keyof GetResourcesFilter;
    type: "text" | "tags";
    label: string;
  }[];

  const formMethods = useForm<Worker>({ mode: "all" });
  const { watch, setError, clearErrors, setValue, reset } = formMethods;
  const requiredRule = { required: t("requiredField", { ns: "common" }) };

  const fiscalCodeCountryCode = (
    watch("fiscalCodeCountryCode") ?? "-"
  ).toUpperCase();
  const isForeignFiscalCode = fiscalCodeCountryCode === "-";
  const validateFiscalCode = (code: string) => {
    let message = undefined;

    if (isForeignFiscalCode && code?.trim() === "") {
      message = t("fiscalCodeIsRequired", { ns: "companies" });
    } else if (!isForeignFiscalCode && !validateIdentityCode(code)) {
      message = t("notItalianFiscalCode", { ns: "companies" });
    }

    if (message) {
      setError("fiscalCode", { message });
      return message;
    }

    clearErrors("fiscalCode");
    return true;
  };

  const resetFiscalCode = () => {
    setValue("fiscalCode", "");
  };

  const emailRegex = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  );

  const handleChangeEmail = (email?: string) => {
    let message = undefined;
    if (email && !emailRegex.test(email)) {
      message = t("errors.invalidEmail", { ns: "supplier" });
    }

    if (message) {
      setError("email", { message });
      return message;
    }

    clearErrors("email");
    return true;
  };

  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormTextField
        key="firstName"
        label={t("firstName")}
        name="firstName"
        rules={requiredRule}
      />,
      <FormTextField
        key="lastName"
        label={t("lastName")}
        name="lastName"
        rules={requiredRule}
      />,
      <FormTextField
        key="email"
        label={t("email")}
        name="email"
        rules={{ validate: handleChangeEmail }}
        hideRequiredMark
      />,
      <FormTextField
        key="phone"
        label={t("phone")}
        name="phone"
        type="number"
      />,
      <FormSelectField
        key="fiscalCodeCountryCode"
        onChange={resetFiscalCode}
        name="fiscalCodeCountryCode"
        label={t("fiscalCodeCountryCode.title", { ns: "companies" })}
        displayValue={t(
          `fiscalCodeCountryCode.${watch("fiscalCodeCountryCode")}`,
          { ns: "companies" },
        )}
        options={fiscalCodeCountries.map((c) => {
          return {
            id: c.id,
            name: t(`fiscalCodeCountryCode.${c.name}`, { ns: "companies" }),
          };
        })}
      />,
      <FormTextField
        key="fiscalCode"
        label={t("fiscalCode")}
        name="fiscalCode"
        rules={{ validate: validateFiscalCode }}
      />,
      <FormDateField
        key="dateOfBirth"
        name="dateOfBirth"
        label={t("dateOfBirth")}
        rules={requiredRule}
      />,
      <FormTextField
        key="placeOfBirth"
        label={t("placeOfBirth")}
        name="placeOfBirth"
      />,
      <FormTextField key="jobTitle" label={t("jobTitle")} name="jobTitle" />,
      <FormDateField
        key="dateOfEmployment"
        name="dateOfEmployment"
        label={t("dateOfEmployment")}
      />,
      <FormImageField
        key="photo"
        label={t("photo")}
        name="photo"
        colSpan={2}
      />,
    ];

  return (
    <ResourceListView<Worker>
      type={DocumentTypeCategory.WORKER}
      viewModel={new WorkersListViewModel()}
      columns={columns}
      cellRender={render}
      namespace="workers"
      permissions={{
        add: Permission.Resources_AddWorker,
        delete: Permission.Resources_DeleteWorker,
        import: Permission.Resources_ImportWorker,
        showResourceSites: Permission.Resources_ShowWorkerSites,
        download: Permission.Resources_DownloadWorkerDocs,
        showCopyLastEvaluation: Permission.Resources_EvalWorkerDocs,
      }}
      formMethods={formMethods}
      createFields={fields}
      viewDetailsPermissions={[Permission.Resources_ShowWorkersDetails]}
    />
  );
};

export default WorkersView;
