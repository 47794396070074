import { FC } from "react";
import { FiPlus } from "react-icons/fi";
import ActionBar from "../../Common/ActionBar";
import { useTranslation } from "react-i18next";
import RenderIf from "../../Permissions/RenderIf";
import ActionBarItem from "../../Common/ActionBarItem";
import { Permission } from "../../Permissions/Permissions";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface Props {
  onAdd?: () => void;
  onDownload?: () => void;
  isDisabled?: boolean;
}

const SiteListActionBar: FC<Props> = ({ onAdd, onDownload, isDisabled }) => {
  const { t } = useTranslation("sites");

  return (
    <ActionBar>
      <RenderIf permissions={[Permission.Sites_Add]} check={PermissionCheck.All}>
        <ActionBarItem
          description={t("actions.newSite")}
          icon={FiPlus}
          onClick={onAdd}
        />
      </RenderIf>
      <RenderIf permissions={[Permission.Sites_Show]} check={PermissionCheck.All}>
        <ActionBarItem
            description={t("siteSelectedForDownload", {ns: "sites"})}
            disabledDescription={t("noSitesSelected", {ns: "sites"})}
            icon={FaCloudDownloadAlt}
            onClick={onDownload}
            isDisabled={isDisabled}
          />
      </RenderIf>
    </ActionBar>
  );
};

export default SiteListActionBar;
