import { makeGetBadgeReadersNotPaginated } from "../../../core/app/factory/badgeReaders/usecases";
import {
  makeCreateBadgeLogEntry,
  makeDeleteBadgeLogEntry,
  makeGetBadge,
  makeGetBadgeHistory,
  makeGetBadgeSites,
  makeLinkBadgeResource,
  makeLinkBadgeTag,
  makeLinkBadgeToSite,
  makeUnlinkBadgeResource,
  makeUnlinkBadgeTag,
  makeUpdateBadge,
} from "../../../core/app/factory/badges/usecases";
import { makeGetMachines } from "../../../core/app/factory/machines/usecases";
import {
  makeGetMachineSites,
  makeGetVehicleSites,
  makeGetWorkerSites,
} from "../../../core/app/factory/sites/usecases";
import {
  makeGetTags,
  makeUpdateTag,
} from "../../../core/app/factory/tags/usecases";
import { makeGetVehicles } from "../../../core/app/factory/vehicles/usecases";
import { makeGetWorkers } from "../../../core/app/factory/workers/usecases";
import Badge from "../../../domain/entities/badge";
import { BadgeLogEntry } from "../../../domain/entities/badgeLogEntry";
import BadgeReader from "../../../domain/entities/badgeReader";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import Machine from "../../../domain/entities/machine";
import { ResourceSites } from "../../../domain/entities/site";
import Tag from "../../../domain/entities/tag";
import Vehicle from "../../../domain/entities/vehicle";
import Worker from "../../../domain/entities/worker";
import {
  GetBadgeHistoryFilters
} from "../../../domain/repositories/badgeRepository";
import { GetResourceSitesFilter } from "../../../domain/repositories/siteRepository";
import { BadgeStatusSite } from "../../hooks/Badge/useBadgeDetailViewModel";

class BadgeDetailViewModel<T> {
  private getBadgeUseCase = makeGetBadge();
  private updateBadgeUseCase = makeUpdateBadge();

  private getWorkerSitesUseCase = makeGetWorkerSites();
  private getMachineSitesUseCase = makeGetMachineSites();
  private getVehicleSitesUseCase = makeGetVehicleSites();
  private getWorkerUseCase = makeGetWorkers();
  private getVehicleUseCase = makeGetVehicles();
  private getMachineUseCase = makeGetMachines();
  private getBadgeSitesUseCase = makeGetBadgeSites();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();
  private linkBadgeResourceUseCase = makeLinkBadgeResource();
  private getSiteBadgeReadersUseCase = makeGetBadgeReadersNotPaginated();
  private getTagsUseCase = makeGetTags();
  private linkBadgeTagUseCase = makeLinkBadgeTag();
  private unlinkBadgeTagUseCase = makeUnlinkBadgeTag();

  private getBadgeHistoryUseCase = makeGetBadgeHistory();
  private createBadgeLogEntryUseCase = makeCreateBadgeLogEntry();

  private deleteBadgeLogEntryUseCase = makeDeleteBadgeLogEntry();

  private linkBadgeToSiteUseCase = makeLinkBadgeToSite();
  private updateTag = makeUpdateTag();

  async getBadge(
    companyId: string,
    badgeId: string,
    siteId?: string
  ): Promise<Badge> {
    return await this.getBadgeUseCase.Call({ companyId, badgeId, siteId });
  }

  async getBadgeSites(
    companyId: string,
    badgeId: string
  ): Promise<BadgeStatusSite[]> {
    return await this.getBadgeSitesUseCase.Call({ companyId, badgeId });
  }

  async updateBadge(
    companyId: string,
    badge: Partial<Badge>,
    siteId?: string
  ): Promise<void> {
    return await this.updateBadgeUseCase.Call({ companyId, badge, siteId });
  }
  async updateTagBadge(companyId: string, tag: Tag): Promise<void> {
    return await this.updateTag.Call({ companyId, tag });
  }

  async createBadge(
    companyId: string,
    siteId: string,
    badgeId: string
  ): Promise<void> {
    await this.linkBadgeToSiteUseCase.Call({
      companyId,
      siteId,
      badgeIds: [badgeId],
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }
  async linkBadgeResource(
    companyId: string,
    badgeId: string,
    resourceType: BadgeResourceType,
    resourceId: string
  ): Promise<void> {
    return await this.linkBadgeResourceUseCase.Call({
      companyId,
      badgeId,
      resourceType,
      resourceId,
    });
  }

  async getTags(companyId: string): Promise<Tag[]> {
    return await this.getTagsUseCase.Call({ companyId });
  }

  async linkBadgeTag(
    companyId: string,
    siteId: string,
    badgeId: string,
    tag: Tag
  ): Promise<void> {
    return await this.linkBadgeTagUseCase.Call({
      companyId,
      siteId,
      badgeId,
      tag,
    });
  }

  async unlinkBadgeTag(
    companyId: string,
    siteId: string,
    badgeId: string,
    tag: Tag
  ): Promise<void> {
    return await this.unlinkBadgeTagUseCase.Call({
      companyId,
      siteId,
      badgeId,
      tag,
    });
  }

  async getSiteBadgeReaders(
    companyId: string,
    siteId: string
  ): Promise<BadgeReader[]> {
    return await this.getSiteBadgeReadersUseCase.Call({ companyId, siteId });
  }

  async getBadgeHistory(
    companyId: string,
    badgeId: string,
    sort: SortMeta,
    filter: GetBadgeHistoryFilters,
    pageParam: number,
    siteId?: string
  ): Promise<BadgeLogEntry[]> {
    return await this.getBadgeHistoryUseCase.Call({
      companyId,
      badgeId,
      filter,
      sort,
      pageParam,
      siteId
    });
  }

  async createBadgeLogEntry(
    companyId: string,
    siteId: string,
    badgeLogEntry: BadgeLogEntry,
    badgeId: string
  ): Promise<void> {
    return await this.createBadgeLogEntryUseCase.Call({
      companyId,
      siteId,
      badgeLogEntry,
      badgeId,
    });
  }

  async deleteBadgeLogEntry(
    companyId: string,
    siteId: string,
    badgeLogEntry: BadgeLogEntry,
  ): Promise<void> {
    return await this.deleteBadgeLogEntryUseCase.Call({
      companyId,
      siteId,
      badgeLogEntry,
    });
  }

  async getResourceSites(
    companyId: string,
    resourceType: BadgeResourceType,
    resourceId: string,
    filter?: GetResourceSitesFilter,
    sort?: SortMeta,
    pageParam = 1
  ): Promise<ResourceSites> {
    switch (resourceType) {
      case BadgeResourceType.MACHINE:
        return await this.getMachineSitesUseCase.Call({
          companyId,
          machineId: resourceId,
          filter,
          sort,
          pageParam,
        });
      case BadgeResourceType.VEHICLE:
        return await this.getVehicleSitesUseCase.Call({
          companyId,
          vehicleId: resourceId,
          filter,
          sort,
          pageParam,
        });
      case BadgeResourceType.WORKER:
        return await this.getWorkerSitesUseCase.Call({
          companyId,
          workerId: resourceId,
          filter,
          sort,
          pageParam,
        });
    }
  }
  async getCompanyResources(
    companyId: string,
    resourceType: BadgeResourceType,
    filter?: GetResourceSitesFilter,
    sort?: SortMeta,
    pageParam?: number
  ): Promise<PaginatedResults<Worker | Vehicle | Machine>> {
    switch (resourceType) {
      case BadgeResourceType.MACHINE:
        return await this.getMachineUseCase.Call({
          companyId,
          filter,
          sort,
          pageParam,
        });
      case BadgeResourceType.VEHICLE:
        return await this.getVehicleUseCase.Call({
          companyId,
          filter,
          sort,
          pageParam,
        });
      case BadgeResourceType.WORKER:
        return await this.getWorkerUseCase.Call({
          companyId,
          filter,
          sort,
          pageParam,
        });
    }
  }
}

export { BadgeDetailViewModel };
