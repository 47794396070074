import UseCase from '../../../core/app/usecase';
import {ConversionType} from "../../../presentation/hooks/Resources/useResourceConversionHook";
import ResourceConversionRepository from "../../repositories/resourceConversionRepository";

type MigrateToVehicleParameters = {
	companyId: string;
  baseType: ConversionType,
  twinType: ConversionType,
  resourceIds: string[];
	typologyId: string;
  allResources: boolean;
};

class ConvertResources implements UseCase<MigrateToVehicleParameters, string|string[]> {
	constructor(private repository: ResourceConversionRepository) {}

	async Call({ companyId, baseType, twinType, resourceIds, typologyId, allResources }: MigrateToVehicleParameters): Promise<string|string[]> {
		return await this.repository.convertResource(companyId, baseType, twinType, resourceIds, typologyId, allResources);
	}
}

export default ConvertResources;
