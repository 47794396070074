import React, { ChangeEvent, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../assets/theme/colors";
import {
  Box,
  Flex,
  Select,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery
} from "@chakra-ui/react";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import Site, { SiteState } from "../../../domain/entities/site";
import StateTag from "../../components/Common/StateTag";
import { GetSitesWithBadgesFilter } from "../../../domain/repositories/siteRepository";
import { ResourceEvaluationState } from "../../../domain/entities/resourceEvaluationState.enum";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { BiUnlink } from "react-icons/bi";
import { Alert } from "../Common/Alert";
import { BadgeType } from "../../../domain/entities/badgeType.enum";

type ResourceSitesListProps = {
    sites: Site[];
    sitesFilter: GetSitesWithBadgesFilter;
    updateSitesFilter: (field: any, value: any)=>void; //TODO fix types
    getSitesHasNextPage: boolean;
    getSitesFetchNextPage: () => void;
    sitesLoading: boolean;
    sitesSort: SortMeta;
    setSitesSort: any;
    showBadgeColumns?: boolean;
    navigateAction?: (siteId: string)=>void;
    showUnlink?: boolean;
    unlinkBadgeAction?: (badgeId: string) => void;
    tableId?: string;
    deltaWidth?: number;
}

const ResourceSitesList = ({
    sites, sitesFilter, 
    sitesSort, setSitesSort,
    updateSitesFilter, sitesLoading, 
    getSitesHasNextPage, getSitesFetchNextPage,
    showBadgeColumns, navigateAction,
    showUnlink, unlinkBadgeAction,
    tableId,
    deltaWidth
}: ResourceSitesListProps) => {
  const [isTablet] = useMediaQuery("(max-width: 1140px)");
  const [showPermissionAlert, setShowPermissionAlert] = useState(false);
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const baseColumns: ({ field: string; width: string; label: string; type: "text" | "tags" } )[] = [
      {
        field: "name",
        type: "text",
        label: t("name"),
        width: "150px",
      },
      {
        field: "resourceEvaluation",
        type: "text",
        label: t("evaluation", { ns: "requirements" }),
        width: "200px",
      },
      {
        field: "state",
        type: "text",
        label: t("state", { ns: "sites" }),
        width: "150px",
      },
    ];

    if (showBadgeColumns) {
      baseColumns.push(
        {
          field: "badgeCode",
          type: "text",
          label: t("columns.code", { ns: "badges" }),
          width: "130px",
        },
        {
          field: "badgeSerial",
          type: "text",
          label: t("columns.serial", { ns: "badges" }),
          width: "250px",
        },
        {
          field: "badgeType",
          type: "text",
          label: t("columns.type", { ns: "badges" }),
          width: "100px",
        },
      );
    }
    return baseColumns;
  }, [isTablet, showBadgeColumns, showUnlink, t]);


  const excludeSort = new Set(["location"]);
  const fSites = sitesFilter ?? {
    name: "",
    resourceEvaluation: "",
    state: "",
    badgeType: "",
  };

  const updateFilterState = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case SiteState.ACTIVE:
        updateSitesFilter("state", SiteState.ACTIVE);
        break;
      case SiteState.CLOSED:
        updateSitesFilter("state", SiteState.CLOSED);
        break;
      case SiteState.SUSPENDED:
        updateSitesFilter("state", SiteState.SUSPENDED);
        break;
      default:
        updateSitesFilter("state", undefined);
        break;
    }
  };

  const updateFilterEvaluation = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case ResourceEvaluationState.ELIGIBLE:
        updateSitesFilter("resourceEvaluation", ResourceEvaluationState.ELIGIBLE);
        break;
      case ResourceEvaluationState.NOT_ELIGIBLE:
        updateSitesFilter("resourceEvaluation", ResourceEvaluationState.NOT_ELIGIBLE);
        break;
      case ResourceEvaluationState.ELIGIBLE_WITH_INTEGRATION:
        updateSitesFilter("resourceEvaluation", ResourceEvaluationState.ELIGIBLE_WITH_INTEGRATION);
        break;
      default:
        updateSitesFilter("resourceEvaluation", undefined);
        break;
    }
  };

  const updateFilterBadgeType = (e: ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case BadgeType.NFC:
        updateSitesFilter("badgeType",BadgeType.NFC);
        break;
      case BadgeType.QR:
        updateSitesFilter("badgeType",BadgeType.QR);
        break;
      default:
        updateSitesFilter("badgeType", undefined);
        break;
    }
  };
  const renderFilterComponent = (column: {
    field: string,
    type: "text" | "tags",
    label: string,
    width: string,
  }, fSites: any) => {
    switch (column.field) {
      case "state":
        return (
          <Select value={fSites?.state} onChange={updateFilterState}>
            <option value={undefined}>{t("all", { ns: "common" })}</option>
            <option value={SiteState.ACTIVE}>
              {t("status.site.active", { ns: "common" })}
            </option>
            <option value={SiteState.CLOSED}>
              {t("status.site.closed", { ns: "common" })}
            </option>
            <option value={SiteState.SUSPENDED}>
              {t("status.site.suspended", { ns: "common" })}
            </option>
          </Select>
        );

      case "resourceEvaluation":
        return (
          <Select
            value={fSites?.resourceEvaluation}
            onChange={updateFilterEvaluation}
          >
            <option value={undefined}>{t("all", { ns: "common" })}</option>
            <option value={ResourceEvaluationState.ELIGIBLE}>
              {t("combinedEvaluation.eligible", { ns: "enum" })}
            </option>
            <option value={ResourceEvaluationState.NOT_ELIGIBLE}>
              {t("combinedEvaluation.not_eligible", { ns: "enum" })}
            </option>
            <option value={ResourceEvaluationState.ELIGIBLE_WITH_INTEGRATION}>
              {t("combinedEvaluation.eligible_with_integration", { ns: "enum" })}
            </option>
          </Select>
        );

      case "badgeType":
        return (
          <Select value={fSites?.badgeType} onChange={updateFilterBadgeType}>
            <option value={undefined}>{t("all", { ns: "common" })}</option>
            <option value={BadgeType.NFC}>NFC</option>
            <option value={BadgeType.QR}>QRCode</option>
          </Select>
        );

      default:
        return (
          <ColumnFilterComponent
            type={column.type}
            value={fSites[column.field]}
            updateFilter={(value) =>
              updateSitesFilter(column.field, value as string | string[])
            }
          />
        );
    }
  };

  return (
    <>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width={deltaWidth ? `calc(100vw - ${deltaWidth}px)` : isTablet ? "calc(100vw - 230px)" : "calc(100vw - 264px)"}
        marginTop={3}
        position="relative"
        overflow="auto"
        id="resource-sites-table"
      >
        <InfiniteTable
          tableId="siteList"
          autosize
          infiniteScroll={{
          dataLength: sites?.length,
          hasNextPage: getSitesHasNextPage,
          fetchNextPage: getSitesFetchNextPage,
          }}
          isLoading={sitesLoading}
          emptyText={t("noSiteFound", { ns: "sites" })}
          showEmptyText={sites?.length === 0}
        >
          <Thead>
          <Tr bg={COLORS.table.headerBg}>
              {columns.map((column) => (
              <Th width={column.width} key={column.field}>
                  <TableColumnHeader
                    text={column.label}
                    filter={{
                          isActive:
                          !!fSites[column.field] &&
                          (!Array.isArray(fSites[column.field]) ||
                              !!fSites[column.field][0]),
                          component:
                          <>
                            {renderFilterComponent(column, fSites)}
                          </>
                          ,
                    }}
                    sort={
                        excludeSort.has(column.field)
                        ? undefined
                        : {
                              handler: (direction) =>
                              setSitesSort({ field: column.field, direction }),
                              direction:
                              sitesSort?.field === column.field
                                  ? sitesSort.direction
                                  : null,
                        }
                    }
                    tableId="resource-sites-table"
                  />
              </Th>
              ))}
             <Th width="60px" />
          </Tr>
          </Thead>
          {sites?.length > 0 && (
          <Tbody
              className={sites?.length > 0 ? "real-row" : "skeleton-row"}
          >
            {sites.map((site) => (
              <SiteRow
                key={site?.id}
                site={site}
                columns={columns}
                showBadgeColumns={showBadgeColumns}
                showUnlink={showUnlink}
                navigateAction={navigateAction}
                setShowPermissionAlert={setShowPermissionAlert}
                unlinkBadgeAction={unlinkBadgeAction}
              />
            ))}
          </Tbody>
          )}
        </InfiniteTable>
      </Flex>
      {showPermissionAlert &&
        <Alert
          title={t('accessDenied.title', { ns: 'permissions' })}
          message={t('accessDenied.messageNoSiteRole', {ns: 'permissions'})}
          onClose={() => setShowPermissionAlert(false)} variant={"warning"} />
      }
    </>
  );
};

export default ResourceSitesList;

interface SiteRowProps {
  site: Site;
  columns: Array<{
    field: string,
    type: "text" | "tags",
    label: string,
    width: string,
  }>;
  showBadgeColumns: boolean;
  showUnlink: boolean;
  navigateAction: (id: string) => void;
  setShowPermissionAlert: (value: boolean) => void;
  unlinkBadgeAction: (id: string) => void;
}

const SiteRow = memo(
  ({ site, columns, showBadgeColumns, showUnlink, navigateAction, setShowPermissionAlert, unlinkBadgeAction }: SiteRowProps) => {
  const hasRole = site.userHasSiteRole;
  const { t } = useTranslation();
  const handleRowClick = useCallback(() => {
   if (hasRole) {
      navigateAction(site?.id);
      } else {
        setShowPermissionAlert(true);
      }
   }, [hasRole, site?.id, navigateAction, setShowPermissionAlert]);

  const handleUnlinkClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    unlinkBadgeAction(site?.badge?.id);
  }, [site?.badge?.id, unlinkBadgeAction]);

  return (
    <Tr key={site?.id} style={{ cursor: "pointer" }} onClick={handleRowClick}>
      <Td width={columns[0].width}>{site?.name}</Td>
      <Td width={columns[1].width}>
        {hasRole && t(`combinedEvaluation.${site?.evaluation?.result}`, { ns: "enum" })}
      </Td>
      <Td width={columns[2].width}>
          {hasRole && <StateTag value={site?.state} type="siteStatus" />}
      </Td>
      {showBadgeColumns && (
        <>
          <Td width={columns[3].width}>
            <Flex justifyContent="center">
              {hasRole && site?.badge?.code}
            </Flex>
          </Td>
          <Td width={columns[4].width}>{hasRole && site?.badge?.serial}</Td>
          <Td width={columns[5].width}>{hasRole && site?.badge?.type}</Td>
        </>
      )}
      <Td width={"60px"}>
        {showUnlink && site?.badge?.id && (
          <Box onClick={handleUnlinkClick}>
            <Tooltip label={t("unlinkResource", { ns: "badges" })}>
              <span>
                <BiUnlink cursor="pointer" style={{ cursor: "pointer" }} />
              </span>
            </Tooltip>
          </Box>
        )}
      </Td>
    </Tr>
  );
});

SiteRow.displayName = "SiteRow";
