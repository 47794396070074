import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useSiteVehicleDetailViewModel from "../../hooks/Site/useSiteVehicleDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteVehicleDetailView = () => {
  const { siteId, vehicleId } = useParams();
  const resourceColumns = ["name", "plate", "model", "badge", "companyName"];

  const downloader = useResourceFilesDownloader(siteId, vehicleId, "vehicles");

  return (
    <SiteResourceDetailView<SiteVehicleParams>
      downloader={downloader}
      resourceId={vehicleId}
      type={DocumentTypeCategory.VEHICLE}
      useViewModel={useSiteVehicleDetailViewModel(
        siteId,
        vehicleId,
        resourceColumns,
        DocumentTypeCategory.VEHICLE,
        AiTaskSectionCode.SITE_RESOURCES_VEHICLES
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadVehicleReq,
        showGlobalEvaluation: Permission.Sites_ShowGlobalVehicleEvaluation,
        setGlobalEvaluation: Permission.Sites_SetGlobalVehicleEvaluation,
        addRequirement: Permission.Sites_AddVehicleReq,
        viewRequirements: Permission.Sites_ShowVehicleReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteVehicleReq,
          edit: Permission.Sites_EditVehicleReq,
          editVariantSpecializations: Permission.Sites_EditVehicleVariantSpecializations,
          evaluate: Permission.Sites_EvalVehicleReq,
          viewEvaluations: Permission.Sites_ShowVehicleReqEvaluation,
          addFile: Permission.Sites_EditVehicleReq,
          downloadAll: Permission.Sites_DownloadVehicleReq,
          chat: Permission.Sites_AccessVehicleChat,
          setExpiration: Permission.Sites_EvalVehicleReq,
          viewEvaluationExpiration: Permission.Sites_ShowVehicleReqEvaluationExpiration,
          report: Permission.Sites_AddReport,
        },
      }}
    />
  );
};

export default SiteVehicleDetailView;
