import { FiPlus } from "react-icons/fi";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useMemo, useState } from "react";
import { COLORS } from "../../assets/theme/colors";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import { InfoBanner } from "../../components/Common/alerts/InfoBanner";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import {
  Box,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import DocumentCommunicationModal from "../../components/Views/common/DocumentCommunicationModal";
import { useSiteSupplierDetailViewModel } from "../../hooks/Site/useSiteSupplierDetailViewModel";
import { MdOutlineHistory } from "react-icons/md";
import { EvaluationButton } from "../../components/Common/ResourceEvaluationToggle";
import ResourceEvaluationHistoryModal from "../../components/Views/common/ResourceEvaluationHistoryModal";
import LoadingView from "../Common/LoadingView";
import SiteResourceDocuments, {
  ResourceDocumentsPermissions,
} from "../../components/Views/common/SiteResourceDocuments";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { AiTaskStatus } from "../../../domain/entities/aiTask";
import { FaWandMagicSparkles } from "react-icons/fa6";
import AddDocumentTypesModal from "../../components/Views/common/AddDocumentTypesModal";
import { useSupplierOrSiteFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import RejectionSummary, {
} from "../../components/Views/Documents/RejectionSummary";

interface Props {
  permissions: {
    documents: ResourceDocumentsPermissions;
    downloadAll: Permission;
    addRequirement: Permission;
    showGlobalEvaluation: Permission;
    manageDocumentWithAi: Permission;
  };
}

const WorkingSiteDocumentView: FC<Props> = ({ permissions }) => {
  const { t } = useTranslation("supplier");
  const { siteId } = useParams();
  const { companyId } = useAuth();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const location = useLocation();
  const { fromPrevUrl } = location.state || {};
  const isWorkingSite = location.pathname.includes("workingsites");

  const [documentTypeCategory, setDocumentTypeCategory] = useState<DocumentTypeCategory>(
    DocumentTypeCategory.COMPANY,
  );
  const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);
  const [showCommunicationModal, setShowCommunicationmodal] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(true);
  const [showManageCompanyTaskWithAI, setShowManageCompanyTaskWithAI] =
    useState<boolean>(false);
  const [showManageSiteTaskWithAI, setShowManageSiteTaskWithAI] =
    useState<boolean>(false);
  const [
    showResourceEvaluationHistoryModal,
    setShowResourceEvaluationHistoryModal,
  ] = useState(false);
  const [addRequirementsIsLoading, setAddRequirementsIsLoading] =
    useState<boolean>(false);
  const downloader = useSupplierOrSiteFilesDownloader(siteId, "site");
  const canAddRequirements = useHasPermissions(permissions.addRequirement)
  const {
    siteResource,
    siteResourceEvaluations,
    getSiteResourceEvaluations,
    getSiteResourceEvaluationsIsLoading,
    siteResourceEvaluationsHasNextPage,
    siteResourceEvaluationsFetchNextPage,
    setEnableGetEvaluationsHistory,
    filterResourceEvaluations,
    updateFilterResourceEvaluations,
    sortResourceEvaluations,
    updateSortResourceEvaluations,
    communicationProps,
    documentAICompanyProps,
    documentAISiteProps,

    setHookDocumentIdAndType,
    documentEvaluations,
    documentEvaluationsFetching,
    filterDocumentEvaluations,
    updateFilterDocumentEvaluations,
    updateSortDocumentEvaluations,
    sortDocumentEvaluations,

    isCompanyDocumentsFetching,
    isSiteDocumentsFetching,

    evaluateDocument,

    addRequirementsToSiteResource,
    documentTypesProps,
    documentInfoProps,

    siteResourceDocumentsRejected,
    siteResourceDocuments,
    siteResourceDocumentsFetchNextPage,
    siteResourceDocumentsHasNextPage,
    siteResourceDocumentsRefetch,

    sortCompanyDocuments,
    updateSortCompanyDocuments,
    updateFilterCompanyDocuments,
    filterCompanyDocuments,

    sortDocuments,
    updateSortDocuments,
    updateFilterDocuments,
    filterDocuments,

    siteResourceSiteDocumentsRejected,
    siteResourceSiteDocuments,
    siteResourceSiteDocumentsFetchNextPage,
    siteResourceSiteDocumentsHasNextPage,
    siteResourceSiteDocumentsRefetch,
    getRequirementTemplateUrl,
  } = useSiteSupplierDetailViewModel(
    siteId,
    companyId,
    isWorkingSite,
    AiTaskSectionCode.WORKING_SITE_RESOURCES_COMPANY,
    AiTaskSectionCode.WORKING_SITE_RESOURCES_SITE,
  );

  const availableCompanyDocumentTypes = useMemo(() => {
    return documentTypesProps.documentTypes.filter((documentType) => {
      return !siteResourceDocuments.find(
        (document) => document.documentTypeId === documentType.id,
      );
    });
  }, [documentTypesProps.documentTypes, siteResourceDocuments]);

  const availableSiteDocumentTypes = useMemo(() => {
    return documentTypesProps.documentTypes.filter((documentType) => {
      return !siteResourceSiteDocuments.find(
        (document) => document.documentTypeId === documentType.id,
      );
    });
  }, [documentTypesProps.documentTypes, siteResourceSiteDocuments]);

  const onTabSelect = (t) => {
    setDocumentTypeCategory(
      t === 0 ? DocumentTypeCategory.COMPANY : DocumentTypeCategory.SITE,
    );
  };

  const refetchCompanyTasks = () => {
    documentAICompanyProps.aiTasksRefetch();
    setShowManageCompanyTaskWithAI(true);
  };

  const refetchSiteTasks = () => {
    documentAISiteProps.aiTasksRefetch();
    setShowManageSiteTaskWithAI(true);
  };

  const handleAddRequirements = async (documentTypes) => {
    setAddRequirementsIsLoading(true);
    await addRequirementsToSiteResource(documentTypes);
    setShowAddRequirementModal(false);
    setAddRequirementsIsLoading(false);
  };

  useEffect(() => {
    communicationProps?.refetchResourceDocumentComments();
  }, []);

  const refetchCommentsAndNotes = () => {
      communicationProps.refetchMissingDocuments();
      communicationProps.refetchResourceDocumentComments();
      communicationProps.refetchResourceDocumentNotes();
      communicationProps.refetchNotificationStatus();
  };

  if (!siteResource) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.addRequirement}>
            <ActionBarItem
              icon={FiPlus}
              description={t("addDocument", { ns: "documents" })}
              onClick={() => {
                setShowAddRequirementModal(true);
                documentTypesProps?.setEnableGetTags(true);
              }}
            />
          </RenderIf>
          <RenderIf
            check={PermissionCheck.All}
            permissions={[
              permissions.documents.addFile,
              permissions.manageDocumentWithAi,
            ]}
          >
            <ActionBarItem
              icon={FaWandMagicSparkles}
              description={t("manageDocumentWithAi", { ns: "documents" })}
              onClick={() => {
                documentTypeCategory === DocumentTypeCategory.COMPANY
                  ? refetchCompanyTasks()
                  : refetchSiteTasks();
                documentAICompanyProps.setEnableGetAiTasksQuery(true);
              }
              }
              isAnimated={
                documentTypeCategory === DocumentTypeCategory.COMPANY
                  ? documentAICompanyProps.aiTasks.some(
                      (task) => task.status === AiTaskStatus.RUNNING,
                    )
                  : documentAISiteProps.aiTasks.some(
                      (task) => task.status === AiTaskStatus.RUNNING,
                    )
              }
            />
          </RenderIf>
          <RenderIf permissions={[permissions.documents.chat]}>
            <ActionBarItem
              bgColor={COLORS.yellow}
              color="black"
              icon={HiOutlineChatAlt2}
              description={t("chat", { ns: "communication" })}
              onClick={() => {
                refetchCommentsAndNotes();
                setShowCommunicationmodal(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        marginLeft={10}
        marginRight={5}
        marginTop={10}
        flexDirection={"column"}
      >
        <Text textColor={COLORS.sikuroBlue} fontSize={20} fontWeight={"bold"}>
          {t("workingSiteDocuments", { ns: "documents" })}
        </Text>

          <RenderIf permissions={permissions.showGlobalEvaluation}>
            <Flex
              justifyContent="center"
              gap={5}
              alignItems="center"
              w="100%"
              marginTop={4}
            >
              {siteResource?.evaluationResult ? (
                <EvaluationButton
                  value={siteResource?.evaluationResult}
                  evaluationType={"resource"}
                />
              ) : (
                <Flex
                  borderWidth="1px"
                  borderRadius={"10px"}
                  backgroundColor={"white"}
                  padding={2}
                >
                  <Flex
                    borderWidth="1px"
                    borderRadius={"10px"}
                    backgroundColor={"gray.200"}
                    color={"black"}
                    padding={3}
                    fontSize={"16px"}
                  >
                    {t("supplierNotEvaluated", { ns: "supplier" })}
                  </Flex>
                </Flex>
              )}

              {siteResource?.evaluationResult && (
                <IconButton
                  fontSize="3xl"
                  backgroundColor="transparent"
                  onClick={() => {
                    setEnableGetEvaluationsHistory(true);
                    setShowResourceEvaluationHistoryModal(true);
                  }}
                  aria-label="document-history"
                >
                  <MdOutlineHistory />
                </IconButton>
              )}
            </Flex>
          </RenderIf>
        <Tabs
          width={"100%"}
          height="100%"
          defaultIndex={
            fromPrevUrl && fromPrevUrl === "company"
              ? 0
              : fromPrevUrl === "site"
                ? 1
                : 0
          }
          marginTop={2}
          marginBottom={5}
          onChange={onTabSelect}
        >
          <TabList>
            <Tab width="50%">{t("companyDocuments", { ns: "documents" })}</Tab>
            <Tab width="50%">{t("siteDocuments", { ns: "documents" })}</Tab>
          </TabList>
          <TabPanels>
            {["company", "site"].map((type: "company" | "site") => (
              <TabPanel padding={0} marginTop={6} key={type}>
                <Box my={2}>
                    <RejectionSummary
                        groups={type === "company"
                                ? siteResourceDocumentsRejected
                                : siteResourceSiteDocumentsRejected}
                      />
                </Box>
                <RenderIf permissions={permissions.documents.addFile}>
                  <>
                    {showInfoBanner && (
                      <RenderIf permissions={permissions.documents.addFile ?? []}>
                        <InfoBanner
                          text={t("dragAndDropInfo", { ns: "documents" })}
                          onClose={() => setShowInfoBanner(false)}
                        />
                      </RenderIf>
                    )}
                  </>
                </RenderIf>
                <SiteResourceDocuments
                  downloader={downloader}
                  evaluateDocument={evaluateDocument}
                  {...(type === "company"
                    ? {
                        documents: siteResourceDocuments,
                        documentsRefetch: siteResourceDocumentsRefetch,
                        documentsHasNextPage: siteResourceDocumentsHasNextPage,
                        documentsFetchNextPage:
                          siteResourceDocumentsFetchNextPage,
                        sortDocuments: sortCompanyDocuments,
                        filterDocuments: filterCompanyDocuments,
                        updateFilterDocuments: updateFilterCompanyDocuments,
                        updateSortDocuments: updateSortCompanyDocuments,
                        requirementSubject: RequirementSubject.COMPANY,
                        resourceCategory: DocumentTypeCategory.COMPANY,
                        aiTaskSectionCode:
                          AiTaskSectionCode.WORKING_SITE_RESOURCES_COMPANY,
                        documentsAiHook: documentAICompanyProps,
                        showManageTaskWithAi: showManageCompanyTaskWithAI,
                        setShowManageTaskWIthAi: setShowManageCompanyTaskWithAI,
                      }
                    : {
                        documents: siteResourceSiteDocuments,
                        documentsRefetch: siteResourceSiteDocumentsRefetch,
                        documentsHasNextPage:
                          siteResourceSiteDocumentsHasNextPage,
                        documentsFetchNextPage:
                          siteResourceSiteDocumentsFetchNextPage,
                        sortDocuments: sortDocuments,
                        filterDocuments: filterDocuments,
                        updateFilterDocuments: updateFilterDocuments,
                        updateSortDocuments: updateSortDocuments,
                        requirementSubject: RequirementSubject.SITE,
                        resourceCategory: DocumentTypeCategory.SITE,
                        aiTaskSectionCode:
                          AiTaskSectionCode.WORKING_SITE_RESOURCES_SITE,
                        documentsAiHook: documentAISiteProps,
                        showManageTaskWithAi: showManageSiteTaskWithAI,
                        setShowManageTaskWIthAi: setShowManageSiteTaskWithAI,
                      })}
                  isWorkingSite={true}
                  setSiteResourceHookDocumentId={setHookDocumentIdAndType}
                  documentEvaluations={documentEvaluations}
                  documentEvaluationsFetching={documentEvaluationsFetching}
                  filterDocumentEvaluations={filterDocumentEvaluations}
                  updateFilterDocumentEvaluations={
                    updateFilterDocumentEvaluations
                  }
                  sortDocumentEvaluations={sortDocumentEvaluations}
                  updateSortDocumentEvaluations={updateSortDocumentEvaluations}
                  {...documentInfoProps}
                  isAddingFile={
                    isCompanyDocumentsFetching || isSiteDocumentsFetching
                  }
                  isFetchingDocuments={
                    isCompanyDocumentsFetching || isSiteDocumentsFetching
                  }
                  canAddFile={companyId === siteResource.company.id}
                  isReadOnly={isWorkingSite}
                  communicationProps={communicationProps}
                  permissions={permissions.documents}
                  isTablet={isTablet}
                  getRequirementTemplateUrl={getRequirementTemplateUrl}
                  showCommunications={true}
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        {showAddRequirementModal && (
          <AddDocumentTypesModal
            {...documentTypesProps}
            isRequirement
            documentTypes={
              documentTypesProps.hookDocumentCategory ===
              DocumentTypeCategory.COMPANY
                ? availableCompanyDocumentTypes
                : availableSiteDocumentTypes
            }
            onClose={() => setShowAddRequirementModal(false)}
            onConfirm={handleAddRequirements}
            isAddingDocumentsApi={addRequirementsIsLoading}
            isFetching={documentTypesProps.documentTypesIsFetching}
            showAvailableDocuments={
              documentTypesProps.hookDocumentCategory ===
              DocumentTypeCategory.COMPANY
            }
            canViewSiteRequirements={canAddRequirements}
            canViewCompanyRequirements={canAddRequirements}
            showSourceSelection={true}
            isWorkingSite={true}
            documentTypeCategory={documentTypeCategory}
          />
        )}

        {showCommunicationModal && (
          <DocumentCommunicationModal
            onClose={() => setShowCommunicationmodal(false)}
            hook={communicationProps}
            permissions={permissions.documents}
          />
        )}

        {showResourceEvaluationHistoryModal && (
          <ResourceEvaluationHistoryModal
            onClose={() => {
              setEnableGetEvaluationsHistory(false);
              setShowResourceEvaluationHistoryModal(false);
            }}
            evaluations={siteResourceEvaluations}
            getEvaluations={getSiteResourceEvaluations}
            getEvaluationsIsLoading={getSiteResourceEvaluationsIsLoading}
            evaluationsHasNextPage={siteResourceEvaluationsHasNextPage}
            evaluationsFetchNextPage={siteResourceEvaluationsFetchNextPage}
            filterResourceEvaluations={filterResourceEvaluations}
            updateFilterResourceEvaluations={updateFilterResourceEvaluations}
            sortResourceEvaluations={sortResourceEvaluations}
            updateSortResourceEvaluations={updateSortResourceEvaluations}
          />
        )}
      </Flex>
    </ContentLayout>
  );
};

export default WorkingSiteDocumentView;
