import Vehicle from "../../../domain/entities/vehicle";
import {
  makeCreateVehicle,
  makeDeleteVehicle,
  makeGetVehicles,
  makeRestoreVehicle,
  makeUploadVehicles,
} from "../../../core/app/factory/vehicles/usecases";
import { ResourceListViewModel } from "../../hooks/Resources/useResourceListViewModel";
import {
  PaginatedResults,
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import ImportInfo from "../../../domain/entities/importInfo";
import FileEntity from "../../../domain/entities/file";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import {
  GetSitesFilter,
  LinkResourcesToSiteWarning,
} from "../../../domain/repositories/siteRepository";
import Site from "../../../domain/entities/site";
import {
  makeAddResourcesToSites,
  makeGetLinkableSites,
} from "../../../core/app/factory/sites/usecases";
import { ResourceType } from "../../screens/Site/ResourceSelectableTable";
import {ResourceConversionViewModel} from "../resourceConversion/ResourceConversionViewModel";

class VehiclesListViewModel extends ResourceConversionViewModel implements ResourceListViewModel<Vehicle> {
  private getVehiclesUseCase = makeGetVehicles();
  private restoreVehicleUseCase = makeRestoreVehicle();
  private deleteVehicleUseCase = makeDeleteVehicle();
  private crateVehicleUseCase = makeCreateVehicle();
  private uploadVehicles = makeUploadVehicles();
  private getLinkableSitesUseCase = makeGetLinkableSites();
  private addResourcesToSitesUseCase = makeAddResourcesToSites();

  async get(
    companyId: string,
    filter?: GetResourcesFilter,
    archived?: boolean,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<PaginatedResults<Vehicle>> {
    return await this.getVehiclesUseCase.Call({
      companyId,
      archived,
      filter,
      sort,
      pageParam,
    });
  }

  async delete(companyId: string, resourceId: string): Promise<void> {
    await this.deleteVehicleUseCase.Call({ companyId, vehicleId: resourceId });
  }

  async restore(companyId: string, resourceId: string): Promise<void> {
    await this.restoreVehicleUseCase.Call({ companyId, vehicleId: resourceId });
  }

  async create(
    companyId: string,
    resource: Vehicle,
    photo: File,
  ): Promise<Vehicle> {
    const newVehicle = await this.crateVehicleUseCase.Call({
      companyId,
      vehicle: resource,
      photo,
    });

    return newVehicle;
  }
  async uploadResources(
    companyId: string,
    file: FileEntity,
  ): Promise<ImportInfo> {
    return await this.uploadVehicles.Call({ companyId, file });
  }
  async getResourceLinkableSites(
    companyId: string,
    type: string,
    filters?: GetSitesFilter,
    sort?: SortMeta,
    pageParam?: number,
  ): Promise<{results: Site[] , count: number }> {
    return await this.getLinkableSitesUseCase.Call({
      companyId,
      resourceType: type,
      filters,
      sort,
      pageParam,
    });
  }
  async addResourcesToSites(
    companyId: string,
    resourceIds: string[],
    siteIds: string[],
    copyLastEvaluation: boolean,
    selectAllSites?: boolean,
    selectAllResources?: boolean,
    resourceFilters?: GetResourcesFilter,
    siteFilters?: GetSitesFilter
  ): Promise<LinkResourcesToSiteWarning> {
    return await this.addResourcesToSitesUseCase.Call({
      companyId,
      resourceType: ResourceType.VEHICLE,
      resourceIds,
      siteIds,
      copyLastEvaluation,
      selectAllSites,
      selectAllResources,
      resourceFilters,
      siteFilters,
    });
  }
}

export { VehiclesListViewModel };
