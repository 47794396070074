import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import BadgeReader from "../../../domain/entities/badgeReader";
import { getBadgeReaderPath } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import BadgeReadersTable from "../../components/Views/BadgeReader/BadgeReadersTable";
import CreateBadgeReaderModal from "../../components/Views/SiteDetails/CreateBadgeReaderModal";
import CreateVirtualBadgeReaderModal from "../../components/Views/SiteDetails/CreateVirtualBadgeReaderModal";
import useSitesBadgeReadersViewModel from "../../hooks/Site/useSiteBadgeReadersViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";
import { Alert } from "../Common/Alert";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import SearchInput from "../Common/SearchInput";
import useOpenNewTab from "../../hooks/Common/useOpenNewTab";

interface Props {
  permissions: {
    viewPhysicalBadgeReader: Permission;
    viewVirtualBadgeReader:Permission;
    createPhysicalBadgeReader: Permission;
    createVirtualBadgeReader:Permission;
    editPhysicalBadgeReader: Permission;
    editVirtualBadgeReader:Permission;
    deletePhysicalBadgeReader: Permission;
    deleteVirtualBadgeReader: Permission;
    showPhysicalState: Permission,
    showVirtualState:Permission,
    }
}

const SiteBadgeReadersView: FC<Props> = ({permissions}) => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("badgeReaders");
  const { companyId } = useAuth();

  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showCreateVirtual, setShowCreateVirtual] = useState<boolean>(false);

  const canViewPhysical = useHasPermissions(permissions.viewPhysicalBadgeReader);
  const canViewVirtual = useHasPermissions(permissions.viewVirtualBadgeReader);
  const {
    filteredBadgeReaders,
    filteredVirtualBadgeReaders,
    badgeReadersFetching,
    virtualBadgeReadersFetching,
    hasNextPage,
    fetchNextPage,
    virtualHasNextPage,
    virtualFetchNextPage,
    createBadgeReader,
    deleteBadgeReader,
    filters,
    addFilter,
    sorting,
    setSorting,
    virtualFilters,
    addVirtualFilter,
    virtualSorting,
    setVirtualSorting,
    createVirtualBadgeReader,
    filteredVirtualBadgeReadersAvailableUsers,
    virtualUsersHasNextPage,
    virtualUsersFetchNextPage,
    error,
    setError,
    setSearchPhysical,
    setSearchVirtual,
    virtualUsersFilters,
    addVirtualUsersFilter,
    virtualUsersSorting,
    setVirtualUsersSorting,
    unlinkBadgeReader,
    badgeReaderType,
    setBadgeReaderType,
  } = useSitesBadgeReadersViewModel(siteId);
  const [rowPermissions, setRowPermissions] = useState({
    delete: permissions.deletePhysicalBadgeReader,
    edit: permissions.editPhysicalBadgeReader,
    show: permissions.showPhysicalState,
  });

  const [showConfirmDelete, setShowConfirmDelete] = useState<{
    badgeReaderId: any;
}>()

  useEffect(() => {
    setRowPermissions(
      badgeReaderType === "physical"
        ? { delete: permissions.deletePhysicalBadgeReader, edit: permissions.editPhysicalBadgeReader, show: permissions.showPhysicalState }
        : { delete: permissions.deleteVirtualBadgeReader, edit: permissions.editVirtualBadgeReader, show: permissions.showVirtualState }
    );
  }, [badgeReaderType, permissions]);

  const onTabSelect = (t) => {
    setBadgeReaderType(
      t === 0 ? "physical" : "virtual"
    );
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf
            permissions={
              badgeReaderType === "physical"
                ? permissions.createPhysicalBadgeReader
                : permissions.createVirtualBadgeReader
            }
            check={PermissionCheck.All}
          >
            <ActionBarItem
              icon={FiPlus}
              description={
                badgeReaderType == "physical"
                  ? t("addPhysical", { ns: "badgeReaders" })
                  : t("addVirtual", { ns: "badgeReaders" })
              }
              onClick={() => {
                badgeReaderType === "physical"
                  ? setShowCreate(true)
                  : setShowCreateVirtual(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Text
        fontSize="xl"
        textColor={COLORS.sikuroBlue}
        fontWeight="bold"
        marginLeft={10}
        marginTop={10}
      >
        {t("badgeReaders", { ns: "badgeReaders" })}
      </Text>

      <Text textColor={"black"} fontSize={16} marginLeft={10} marginBottom={5}>
        {t("siteBadgeReaders", { ns: "badgeReaders" })}
      </Text>
      <RenderIf
        permissions={[permissions.viewPhysicalBadgeReader, permissions.viewVirtualBadgeReader]}
        check={PermissionCheck.Some}
      >
        <Box
        width="100%"
        mt={4}
        px={10}
        >        
          <Tabs
            height="100%"
            defaultIndex={0}
            onChange={onTabSelect}
            width={"100%"}
          >
            <TabList>
              {canViewPhysical && <Tab width="50%">{t("physical", { ns: "badgeReaders" })}</Tab>}
              {canViewVirtual && <Tab width="50%">{t("virtual", { ns: "badgeReaders" })}</Tab>}
            </TabList>
            <TabPanels>
              {["physical", "virtual"]
                .filter((type) => {
                  return (type === "physical" && canViewPhysical) || (type === "virtual" && canViewVirtual);
                })
                .map((type: "physical" | "virtual") => (
                 <TabPanel key={type} paddingLeft={0} paddingRight={0}>
                    <Flex
                      justifyContent={"end"}
                      alignItems="center"
                      w={"100%"}
                      marginBottom={4}
                    >
                      <SearchInput
                        onSearch={(query) => {
                          if (type === "physical") {
                            setSearchPhysical(query);
                          } else {
                            setSearchVirtual(query);
                          }
                        }}
                      />
                    </Flex>
                    <BadgeReadersTable
                      permissions={rowPermissions}
                      badgeReaders={
                        type == "physical"
                          ? filteredBadgeReaders
                          : filteredVirtualBadgeReaders
                      }
                      badgeReadersIsFetching={
                        type == "physical"
                          ? badgeReadersFetching
                          : virtualBadgeReadersFetching
                      }
                      badgeReadersFilters={
                        type == "physical"
                          ? filters
                          : virtualFilters
                      }
                      updateBadgeReadersFilters={
                        type == "physical"
                          ? addFilter
                          : addVirtualFilter
                      }
                      badgeReadersSorting={
                        type == "physical"
                          ? sorting
                          : virtualSorting
                      }
                      updateBadgeReadersSorting={
                        type == "physical"
                          ? setSorting
                          : setVirtualSorting
                      }
                      hasNextPage={
                        type == "physical"
                          ? hasNextPage
                          : virtualHasNextPage
                      }
                      fetchNextPage={
                        type == "physical"
                          ? fetchNextPage
                          : virtualFetchNextPage
                      }
                      isPhysical={type == "physical"}
                      deleteBadgeReader={(badgeReader) => setShowConfirmDelete(badgeReader)}
                      unlinkBadgeReader={unlinkBadgeReader}
                    />
                 </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </RenderIf>
      {showCreate && (
        <CreateBadgeReaderModal
          onClose={() => setShowCreate(false)}
          onConfirm={createBadgeReader}
          getPath={(badgeReaderId) =>
            getBadgeReaderPath(companyId, siteId, badgeReaderId)
          }
        />
      )}

      {showCreateVirtual && (
        <CreateVirtualBadgeReaderModal
          onClose={() => setShowCreateVirtual(false)}
          onConfirm={createVirtualBadgeReader}
          availableUsers={filteredVirtualBadgeReadersAvailableUsers}
          availableUsersFetching={false}
          virtualBadgeReaderUsersFilters={virtualUsersFilters}
          updateVirtualBadgeReadersUsersFilters={addVirtualUsersFilter}
          virtualBadgeReadersUsersSorting={virtualUsersSorting}
          updateVirtualBadgeReaderUsersSorting={setVirtualUsersSorting}
          hasNextPage={virtualUsersHasNextPage}
          fetchNextPage={virtualUsersFetchNextPage}
        />
      )}

      {showConfirmDelete && 
       <ConfirmAlert
        onConfirm={async() => { await deleteBadgeReader(showConfirmDelete), setShowConfirmDelete(undefined)}}
        onCancel={() => setShowConfirmDelete(undefined)}
        variant={"warning"}
        title={t("warning", { ns: "common" })}
        message={t("confirmDeleteBadgeReader", { ns: "badgeReaders" })}
     />
      }

      {error && (
        <Alert
          message={t(error, { ns: "errors" })}
          onClose={() => setError(undefined)}
          title={t("warning", { ns: "common" })}
          variant="warning"
        ></Alert>
      )}
    </ContentLayout>
  );
};

export default SiteBadgeReadersView;
