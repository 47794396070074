import UseCase from '../../../core/app/usecase';
import ResourceConversionRepository from "../../repositories/resourceConversionRepository";
import {ConversionType} from "../../../presentation/hooks/Resources/useResourceConversionHook";

type Args = {
	companyId: string;
  baseType: ConversionType,
  twinType: ConversionType,
	records: { baseDocumentTypeId: string; twinDocumentTypeId: string }[];
};
/**
 * Returns the rename model object.
 */

class UpsertDocumentTypeMapRecords implements UseCase<Args, void> {
	constructor(private repository: ResourceConversionRepository) {}

	async Call({ companyId, baseType, twinType, records }: Args): Promise<void> {
		await this.repository.upsertDocumentTypeMapRecords(companyId, baseType, twinType, records);
	}
}

export default UpsertDocumentTypeMapRecords;
