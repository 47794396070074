import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteWorkerDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteWorkerDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const WorkingSiteWorkerDetailView = () => {
  const { siteId, workerId } = useParams();
  const resourceColumns = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "dateOfEmployment",
    "email",
    "fiscalCode",
    "placeOfBirth",
    "jobTitle",
    "phone",
  ];

  const downloader = useResourceFilesDownloader(siteId, workerId, "workers");
  return (
    <SiteResourceDetailView<SiteWorkerParams>
      downloader={downloader}
      resourceId={workerId}
      type={DocumentTypeCategory.WORKER}
      useViewModel={useWorkingSiteWorkerDetailViewModel(
        siteId,
        workerId,
        resourceColumns,
        DocumentTypeCategory.WORKER,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_WORKERS
      )}
      resourceColumns={resourceColumns}
      isWorkingSite={true}
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadWorkerReq,
        showGlobalEvaluation: Permission.Worksite_ShowGlobalWorkerEvaluation,
        setGlobalEvaluation: Permission.Worksite_SetGlobalWorkerEvaluation,
        addRequirement: Permission.Worksite_AddWorkerReq,
        viewRequirements: Permission.Worksite_ShowWorkerReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        deleteResource: Permission.Worksite_DeleteWorker,
        documents: {
          delete: Permission.Worksite_Worker_DeleteFile,
          edit: Permission.Worksite_Worker_EditFile,
          evaluate: Permission.Worksite_EvalWorkerReq,
          viewEvaluations: Permission.Worksite_ShowWorkerReqEvaluation,
          viewEvaluationExpiration: Permission.Worksite_ShowWorkerReqEvaluationExpiration,
          downloadAll: Permission.Worksite_DownloadWorkerReq,
          addFile: Permission.Worksite_EditWorkerReq,
          chat: Permission.Worksite_WorkerChat,
          report: Permission.Worksite_Reports,
          setExpiration: Permission.Worksite_Worker_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteWorkerDetailView;
