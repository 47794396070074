import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { PermissionCheck, useAuth } from "../../providers/Auth0JWTProvider";

interface Props {
  routeName?: string;
  element?: ReactNode;
  permissions: string[];
  check?: PermissionCheck;
  fallbackRoutes?: { path: string; permissions: string[] }[];
}

const RequirePermissions: FC<Props> = ({
  routeName,
  element,
  permissions,
  check,
  fallbackRoutes = [],
}) => {
 

  const { hasPermissions, companyUser } = useAuth();
  const allow =
    hasPermissions(permissions, check) && companyUser.state === "enabled";
  if (!allow && fallbackRoutes.length > 0) {
    for (const { path, permissions: fallbackPermissions } of fallbackRoutes) {
      if (hasPermissions(fallbackPermissions, check)) {
        return <Navigate to={`/${path}`} replace />;
      }
    }
  }

  return allow ? <>{element}</> : <Navigate to="/access-denied" replace />;
};

export default RequirePermissions;
