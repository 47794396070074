import { useNavigate } from 'react-router-dom';

const useOpenNewTab = () => {
  const navigate = useNavigate();

  const handleCustomClick = (event: React.MouseEvent | React.KeyboardEvent, url: string , data?: any) => {
    event.stopPropagation();
    data && localStorage.setItem('data', JSON.stringify(data))
    if (event.ctrlKey || event.metaKey) {
        window.open(url , '_blank');
      } else {

        navigate(url);
      }
  };

  const handleCustomOnMouse = (event: React.MouseEvent, url: string) => {
    event.stopPropagation();
    if (event.button === 1) {
      window.open(url, '_blank');
    }
  };

  return { handleCustomClick, handleCustomOnMouse };
};

export default useOpenNewTab;
