import { Permission } from "../components/Permissions/Permissions";

export const commonCompanyFallbackRoutes = [
  {
    path: "company/records",
    permissions: [Permission.Company_ShowDetails],
  },
  {
    path: "company/general-documents",
    permissions: [Permission.Company_ShowCompanyDocs],
  },
  {
    path: "company/documents-presets",
    permissions: [Permission.Company_ShowPreset],
  },
  {
    path: "company/document-types",
    permissions: [Permission.Company_ShowDocType],
  },
  {
    path: "company/requirements",
    permissions: [Permission.Company_ShowReqGroup],
  },
  {
    path: "company/badges",
    permissions: [Permission.Company_ShowBadgeDetails],
  },
];

export const getCompanyFallbackRoutes = (currentRoute) => {
    let fallbackRoutes = [...commonCompanyFallbackRoutes];
  
    fallbackRoutes = fallbackRoutes.filter(route => route.path !== `company/${currentRoute}`);
    return fallbackRoutes;
  };

  