import { UserEmail } from "../../../domain/entities/userEmail";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import { Box, Button, Flex, Tag, Tbody, Td, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import RenderIf from "../../components/Permissions/RenderIf";
import DeleteButton from "../../components/Common/table/DeleteButton";
import React, { useState } from "react";
import { t } from "i18next";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import { UserEmailsFilters } from "../../../domain/repositories/usersRepository";
import CreateUserEmailModal from "./CreateUserEmail";
import { COLORS } from "../../assets/theme/colors";
import ActionButton from "../../components/Common/table/ActionButton";
import { FaRegStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import LoadingView from "../Common/LoadingView";

type UserSettingsEmailsProps = {
  emails: UserEmail[],
  hasNextPage: boolean,
  fetchNextPage: () => void,
  isLoading: boolean,
  filterEmails: Record<string, string | string[]>,
  updateFilterEmails: (column: string, value: (string | string[])) => void,
  setSort: (sort: SortMeta) => void;
  sort: SortMeta;
  deleteEmail: (id: string) => void;
  upsertEmail: (email: UserEmail) => void;
}
export const UserSettingsEmails = ({emails,hasNextPage, fetchNextPage,isLoading, filterEmails, updateFilterEmails, sort, setSort, deleteEmail,  upsertEmail}: UserSettingsEmailsProps) => {
  const { t } = useTranslation("userSettings");
  const [showDeleteModal , setShowDeleteModal] = useState<string>(null);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showConfirmDefaultEmail, setShowConfirmDefaultEmail] = useState<UserEmail>();
  const columns = [
    { field: "email", type: "text", label: t("columns.email") },
    { field: "type", type: "select", label: t("columns.state") },
  ] as {
    field: keyof UserEmailsFilters;
    type: "text";
    label: string;
  }[];
  return (
    <Box>
      <Flex justifyContent={'right'}>
        <Button
          onClick={() => setShowCreateModal(true)}
          borderRadius="10px"
          bgColor={COLORS.sikuroBlue}
          textColor={'white'}
          textAlign={'right'}
        >
          {t('addNewMailBtn', { ns: 'userSettings' })}
        </Button>
      </Flex>
      <Flex
        flexDirection={"column"}
        alignItems={"start"}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width="calc(100vw - 220px)"
        position="relative"
        overflow={"hidden"}
        marginTop={6}
        id="table-container"
      >
        <InfiniteTable
          tableId="emails-table"
          autosize
          infiniteScroll={{
            dataLength: emails.length,
            hasNextPage: hasNextPage,
            fetchNextPage: fetchNextPage,
          }}
          emptyText={t("noActiveResource", { ns: "siteResources" })}
          showEmptyText={emails.length === 0}
          isLoading={isLoading}
        >
          <Thead>
            <Tr>
              {Array.isArray(columns) &&
                columns.map((column) => (
                  <Th width="auto" key={column?.field}>
                    <TableColumnHeader
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            type={column?.type}
                            value={filterEmails && filterEmails[column?.field]}
                            updateFilter={(value) =>
                              updateFilterEmails(
                                column?.field,
                                value as string,
                              )
                            }
                            selectOptions={[
                              {option: 'primary', label: t("emailState.primary")},
                              {option: 'pending', label: t("emailState.pending")},
                              {option: 'secondary', label: t("emailState.secondary")}
                            ]}
                            namespace="siteResources"
                          />
                        ),
                        isActive: filterEmails && !!(Array.isArray(filterEmails[column?.field])
                          ? filterEmails[column?.field][0]
                          : filterEmails[column?.field]),
                      }}
                      text={t(column?.label, { ns: '' })}
                      sort={{
                        handler: (direction) =>
                          setSort({
                            field: column?.field,
                            direction,
                          }),
                        direction:
                          sort &&
                          sort.field === column?.field
                            ? sort.direction
                            : null,
                      }
                      }
                    />
                  </Th>
                ))}
              <RenderIf>
                <Th w={20} />
              </RenderIf>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(emails) && !isLoading ? emails.map((email) => (
              <Tr key={email?.id}>
                <Td width="100%">{email?.email}</Td>
                <Td width="100%">
                  <Tag variant="outline">{t(`emailState.${email?.state}`)}</Tag>
                </Td>

                <Td p={0} w={20}>
                  <Flex gap={2}>
                    {email?.state !== 'primary' &&
                      <DeleteButton
                        tooltipLabel={t("delete", { ns: "common" })}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(email?.id);
                        }}
                      />
                    }
                    {email?.state === 'secondary' &&
                      <Tooltip label={t('setDefaultEmail', {ns: 'userSettings'})} shouldWrapChildren={true}>
                        <ActionButton
                          aria-label="delete"
                          onClick={()=> setShowConfirmDefaultEmail(email)}
                          icon={<FaRegStar />}
                        />
                      </Tooltip>
                    }
                  </Flex>
                </Td>
              </Tr>
            )) : <LoadingView/>}
          </Tbody>
        </InfiniteTable>
      </Flex>
      {showDeleteModal && (
        <DeleteActionAlert
          isLoading={false}
          mainTitle={t("warning", { ns: 'common' })}
          title={t("confirmDeleteEmail", { ns: 'userSettings' })}
          isOpen={true}
          onCancel={() => setShowDeleteModal(null)}
          onConfirm={async () => {
            await deleteEmail(showDeleteModal);
            setShowDeleteModal(null);
          }}
        />
      )}
      {showConfirmDefaultEmail && (
        <DeleteActionAlert
          isLoading={false}
          mainTitle={t("warning", { ns: 'common' })}
          title={t("confirmUpdateEmailState", { ns: 'userSettings', email: showConfirmDefaultEmail.email })}
          isOpen={true}
          onCancel={() => setShowConfirmDefaultEmail(null)}
          onConfirm={async () => {
            await upsertEmail(showConfirmDefaultEmail);
            setShowConfirmDefaultEmail(null);
          }}
        />
      )}
      {showCreateModal && (
        <CreateUserEmailModal
          isLoading={false}
          onClose={() => setShowCreateModal(false)}
          onConfirm={async (email: UserEmail) => {
            await upsertEmail(email);
            setShowDeleteModal(null);
          }}
          errorMessage={''}
        />
      )}
    </Box>

  );
};
