import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Steps from "../../Common/stepper/Steps";
import Tag from "../../../../domain/entities/tag";
import TagList from "../../../screens/Common/TagList";
import { COLORS } from "../../../assets/theme/colors";
import BaseModal from "../../Common/alerts/BaseModal";
import Company from "../../../../domain/entities/company";
import Document from "../../../../domain/entities/document";
import CellPlusButton from "../../Common/table/CellPlusButton";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { UpdateFilter } from "../../../hooks/Site/useSiteSupplierDetailViewModel";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentsFilter } from "../../../../domain/repositories/documentRepository";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Spinner,
  Tag as ChakraTag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useSteps,
} from "@chakra-ui/react";
import {
  AvailableSuppliersFilter,
  UnlistedCompany,
} from "../../../../domain/repositories/supplierRepository";
import TextField from "../../Common/TextField";
import TagSelect from "../../Common/TagSelect";
import Variant from "../../../../domain/entities/variant";
import SimpleTable from "../../Common/table/SimpleTable";
import InfiniteTable from "../../Common/table/InfiniteTable";
import CellMinusButton from "../../Common/table/CellMinusButton";
import SearchInput from "../../../screens/Common/SearchInput";
import useSiteRequirementsViewModel from "../../../hooks/Site/useSiteRequirementsViewModel";
import { useAuth } from "../../../providers/Auth0JWTProvider";
import { BlobProvider } from "@react-pdf/renderer";
import { ImSpinner6 } from "react-icons/im";
import { FaCloudDownloadAlt } from "react-icons/fa";
import RequirementsPdf from "../../Common/RequirementsPdf";
import Requirement from "../../../../domain/entities/requirement";
import ActionBarItem from "../../Common/ActionBarItem";
import { AiOutlinePrinter } from "react-icons/ai";
import { RequirementSubject } from "../../../../domain/entities/requirementSubject.enum";

// Props.
interface InviteSupplierWithVariantProps {
  documents: Document[];
  filterDocuments: GetDocumentsFilter;
  updateFilterDocuments: UpdateFilter;
  sortDocuments: SortMeta;
  setSortDocuments: (sort: SortMeta) => void;
  availableSuppliers: Company[];
  availableSuppliersHasNextPage: boolean;
  availableSuppliersFetchNextPage: () => void;
  availableSuppliersLoading: boolean;
  filterAvailable: AvailableSuppliersFilter;
  updateFilterAvailable: UpdateFilter;
  sortAvailable: SortMeta;
  setSortAvailable: (sort: SortMeta) => void;
  inviteSuppliers: (params: {
    supplierIds: string[];
    documentIds?: string[];
    companyVariant: string;
    unlistedCompany?: UnlistedCompany;
  }) => void;
  onClose: () => void;
  tags: Tag[];
  isUserEmailAvailable: (email: string) => Promise<boolean>;
  companyVariants: Variant[];
  isLoading: boolean;
  setSearch?: (query: string) => void;
}

type UnlistedCompanyState = UnlistedCompany & {
  error: { name: string | false; email: string | false };
};

const unlistedCompanyDefaultState: UnlistedCompanyState = {
  name: "",
  email: "",
  error: { name: false, email: false },
};

const InviteSupplierWithVariantPageContainer: FC<
  InviteSupplierWithVariantProps
> = ({
  documents,
  filterDocuments,
  updateFilterDocuments,
  onClose,
  updateFilterAvailable,
  filterAvailable,
  sortAvailable,
  isUserEmailAvailable,
  setSortAvailable,
  availableSuppliers,
  availableSuppliersLoading,
  availableSuppliersHasNextPage,
  availableSuppliersFetchNextPage,
  sortDocuments,
  setSortDocuments,
  inviteSuppliers,
  tags,
  companyVariants,
  isLoading,
  setSearch,
}) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const { siteId } = useAuth();

  // steps description:
  // 1 - supplier list or external supplier invitation
  // 2 - variants selection
  // 3 - documents selection
  const { activeStep, setActiveStep } = useSteps({ index: 1, count: 3 });
  const [invitingExternalSupplier, setInvitingExternalSupplier] =
    useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
  const [unlistedCompany, setUnlistedCompany] = useState<UnlistedCompanyState>(
    unlistedCompanyDefaultState
  );
  const [selectedCompanyVariant, setSelectedCompanyVariant] =
    useState<Variant>(undefined);
  const [checkedItems, setCheckedItems] = useState({
    worker: false,
    chemical: false,
    vehicle: false,
    tool: false,
    supplier: false,
    site: false,
    company: false,
  });
  const [resourceType, setResourceType] = useState<RequirementSubject>();

  const useRequirementsViewModel = useSiteRequirementsViewModel(resourceType);
  const { requirements, requirementsLoading } = useRequirementsViewModel(
    siteId,
    resourceType as RequirementSubject
  );
  const [requirementsByResource, setRequirementsByResource] = useState<
    Record<string, Requirement[]>
  >({});
  const [isOpen, setIsOpen] = useState(false);
  const pdfExport = useRef();

  const handleSelection = (
    e: ChangeEvent<HTMLInputElement>,
    value: RequirementSubject
  ) => {
    setCheckedItems((prev) => ({
      ...prev,
      [value]: e.target.checked,
    }));
    if (e.target.checked && value) {
      setResourceType(value);
    } else if (
      Object.keys(requirementsByResource).includes(value) &&
      !e.target.checked
    ) {
      delete requirementsByResource[value];
    }
  };

  useEffect(() => {
    if (
      resourceType &&
      !Object.keys(requirementsByResource).includes(resourceType) &&
      requirements?.length > 0
    ) {
      setRequirementsByResource((prevState) => {
        return {
          ...prevState,
          [resourceType]: requirements,
        };
      });
    }
  }, [requirements, resourceType]);

  let results: Requirement[] = [];
  for (const [key, value] of Object.entries(requirementsByResource)) {
    results = [...results, ...value];
  }

  useEffect(() => {
    if (isOpen) {
      setCheckedItems({
        worker: false,
        chemical: false,
        vehicle: false,
        tool: false,
        supplier: false,
        site: false,
        company: false,
      });
    }
  }, [isOpen]);

  const onAddDocument = (d: Document) => {
    setSelectedDocuments([...selectedDocuments, d]);
  };

  const onRemoveDocument = (d: string) => {
    setSelectedDocuments(selectedDocuments.filter((s) => s.id !== d));
  };

  const onAddCompany = (c: Company) => {
    setSelectedCompanies([...selectedCompanies, c]);
  };

  const onRemoveCompany = (c: string) => {
    setSelectedCompanies(selectedCompanies.filter((s) => s.id !== c));
  };
  const selectedCompanyTags = selectedCompanies.map((c) => ({
    id: c.id,
    name: c.name,
    color: "#39b2ff",
  }));
  const selectedDocumentTags = selectedDocuments.map((d) => ({
    id: d.id,
    name: d.type.name,
    color: "#39b2ff",
  }));
  const supplierIds = selectedCompanies.map((c) => c.id);
  const documentIds = selectedDocuments.map((d) => d.id);

  const updateCompanyVariant = (ids: string[]) => {
    setSelectedCompanyVariant(companyVariants.find((t) => t.id === ids[0]));
  };

  const proceed = async () => {
    switch (activeStep) {
      case 1:
        if (invitingExternalSupplier) {
          if (unlistedCompany.error.name || unlistedCompany.error.email) {
            return;
          }
          if (!unlistedCompany.name) {
            setUnlistedCompany({
              ...unlistedCompany,
              error: {
                email: false,
                name: t("errors.required", { ns: "supplier" }),
              },
            });
            return;
          }
          if (!unlistedCompany.email) {
            setUnlistedCompany({
              ...unlistedCompany,
              error: {
                name: false,
                email: t("errors.required", { ns: "supplier" }),
              },
            });
            return;
          }
          if (
            !unlistedCompany.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
          ) {
            setUnlistedCompany({
              ...unlistedCompany,
              error: {
                name: false,
                email: t("errors.invalidEmail", { ns: "supplier" }),
              },
            });
            return;
          }
          const isAvailable = await isUserEmailAvailable(unlistedCompany.email);
          if (!isAvailable) {
            setUnlistedCompany({
              ...unlistedCompany,
              error: {
                name: false,
                email: t("errors.emailAlreadyExists", { ns: "supplier" }),
              },
            });
            return;
          }
        }
        setActiveStep(2);

        break;
      case 2:
        setActiveStep(3);
        break;
      case 3:
        await inviteSuppliers({
          supplierIds,
          documentIds,
          companyVariant: selectedCompanyVariant?.id,
          unlistedCompany,
        });
        onClose();
        break;
    }
  };

  const cancel = () => {
    switch (activeStep) {
      case 1:
        onClose();
        break;
      case 2:
        // reset possibly set states
        setInvitingExternalSupplier(false);
        setUnlistedCompany(unlistedCompanyDefaultState);
        setSelectedCompanyVariant(undefined);

        setActiveStep(1);
        break;
      case 3:
        setSelectedDocuments([]);
        setActiveStep(2);
        break;
    }
  };

  const changeStep = (step: number) => {
    if (activeStep !== 1 || selectedCompanies.length > 0) {
      setActiveStep(step + 1);
    }
  };

  const selectAllDocs = () => {
    setSelectedDocuments(documents);
  };
  const deselectAllDocs = () => {
    setSelectedDocuments([]);
  };

  const content = [
    <Flex
      key="company"
      flexDirection="column"
      alignItems="start"
      borderColor="gray.300"
      borderRadius={invitingExternalSupplier ? "0px" : "10px"}
      width={isMobile ? "calc(100vw - 120px)" : "100%"}
      position="relative"
      overflow="hidden"
      marginTop={2}
      marginBottom={10}
      id="table-container-modal"
    >
      {!invitingExternalSupplier && (
        <Flex
          justifyContent={"end"}
          alignItems="center"
          w={"100%"}
          marginBottom={4}
        >
          <SearchInput onSearch={setSearch} />
        </Flex>
      )}

      {invitingExternalSupplier ? (
        <Flex
          key="unlisted"
          direction="column"
          overflow={"auto"}
          width={"100%"}
        >
          <TextField
            id="name"
            width={"50%"}
            defaultValue={unlistedCompany.name}
            onChange={(id, value) =>
              setUnlistedCompany({
                ...unlistedCompany,
                name: value,
                error: { ...unlistedCompany.error, name: false },
              })
            }
            label={t("columns.businessName", { ns: "supplier" })}
            errorMessages={unlistedCompany.error.name}
          />
          <TextField
            marginTop={5}
            id="email"
            width={"50%"}
            defaultValue={unlistedCompany.email}
            onChange={(id, value) =>
              setUnlistedCompany({
                ...unlistedCompany,
                email: value,
                error: { ...unlistedCompany.error, email: false },
              })
            }
            label={t("columns.email", { ns: "supplier" })}
            type="email"
            errorMessages={unlistedCompany.error.email}
          />
        </Flex>
      ) : (
        <>
          <InfiniteTable
            autosize
            infiniteScroll={{
              dataLength: availableSuppliers.length,
              hasNextPage: availableSuppliersHasNextPage,
              fetchNextPage: availableSuppliersFetchNextPage,
            }}
            emptyText={t("noSuppliers", { ns: "supplier" })}
            showEmptyText={availableSuppliers?.length === 0}
            isLoading={availableSuppliersLoading}
            marginTop={8}
            tableId="invite-supplier-with-variant-available-suppliers-table"
          >
            <Thead>
              <Tr>
                {["name", "vat"].map((c) => (
                  <Th key={c}>
                    <TableColumnHeader
                      text={t(`columns.${c}`, { ns: "supplier" })}
                      filter={{
                        isActive: filterAvailable[c],
                        component: (
                          <ColumnFilterComponent
                            type="text"
                            value={filterAvailable[c]}
                            updateFilter={(v) => updateFilterAvailable(c, v)}
                          />
                        ),
                      }}
                      sort={{
                        direction:
                          sortAvailable && sortAvailable.field === c
                            ? sortAvailable.direction
                            : undefined,
                        handler: (direction) =>
                          setSortAvailable({ field: c, direction }),
                      }}
                      isInModal
                    />
                  </Th>
                ))}
                <Th width={90} />
              </Tr>
            </Thead>
            <Tbody>
              {availableSuppliers
                .filter((c) => !supplierIds.includes(c.id))
                .map((s) => (
                  <Tr key={s.id}>
                    <Td>{s.name}</Td>
                    <Td>{s.vat}</Td>
                    <Td isNumeric width={90}>
                      <Button
                        onClick={() => onAddCompany(s)}
                        borderRadius="8px"
                        bg={COLORS.yellow}
                        fontSize="12px"
                        height="24px"
                        p="10px"
                      >
                        {t("invite", { ns: "supplier" })}
                      </Button>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </InfiniteTable>
        </>
      )}
    </Flex>,

    <Flex minHeight={200} key="variants" direction="column" marginTop={-5}>
      <TagSelect
        isMulti={false}
        setSelectedTags={updateCompanyVariant}
        selectedTags={selectedCompanyVariant ? [selectedCompanyVariant.id] : []}
        defaultMenuIsOpen={false}
        tags={companyVariants}
      />
    </Flex>,

    <Flex
      key="document"
      flexDirection={"column"}
      alignItems={"start"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width="100%"
      position="relative"
      overflow={"hidden"}
      marginTop={2}
      id="table-container"
    >
      <SimpleTable
        autosize
        marginTop={8}
        showEmptyText={
          documents.filter((d) => !documentIds.includes(d.id)).length === 0
        }
        tableId="invite-supplier-with-variant-documents-table"
      >
        <Thead>
          <Tr>
            {["name", "tags"].map((c) => (
              <Th key={c}>
                <TableColumnHeader
                  text={t(`columns.${c}`, { ns: "supplier" })}
                  filter={{
                    isActive:
                      filterDocuments && Array.isArray(filterDocuments[c])
                        ? filterDocuments[c].length > 0
                        : filterDocuments[c],
                    component: (
                      <ColumnFilterComponent
                        type={c === "name" ? "text" : "tags"}
                        value={filterDocuments[c]}
                        tags={tags}
                        updateFilter={(v) => {
                          updateFilterDocuments(c, v);
                        }}
                      />
                    ),
                  }}
                  sort={{
                    direction:
                      sortDocuments && sortDocuments.field === c
                        ? sortDocuments.direction
                        : undefined,
                    handler: (direction) =>
                      setSortDocuments({ field: c, direction }),
                  }}
                  isInModal
                />
              </Th>
            ))}
            {selectedDocuments.length < documents.length ? (
              <Th width="90px">
                {" "}
                <CellPlusButton
                  tooltip={t("selectAllDocs", { ns: "supplier" })}
                  onClick={() => selectAllDocs()}
                />
              </Th>
            ) : (
              <Th width="90px">
                {" "}
                <CellMinusButton
                  tooltip={t("deselectAllDocs", { ns: "supplier" })}
                  onClick={() => deselectAllDocs()}
                />
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {documents
            .filter((d) => !documentIds.includes(d.id))
            .map((d) => (
              <Tr key={d.id}>
                <Td>{d.type.name}</Td>
                <Td>
                  {d.type.tags.map((t) => (
                    <ChakraTag key={t.name} mx={1}>
                      {t.name}
                    </ChakraTag>
                  ))}
                </Td>
                <Td width="90px">
                  <CellPlusButton onClick={() => onAddDocument(d)} />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </SimpleTable>
    </Flex>,
  ];

  const textKeys = [
    {
      title: invitingExternalSupplier
        ? "inviteExternalSupplier"
        : "inviteSupplier",
      subtitle: invitingExternalSupplier
        ? "inviteExternalSupplierSub"
        : "inviteSupplierSubtitle",
    },
    { title: "supplierVariants", subtitle: "supplierVariantsSub" },
    { title: "supplierDocuments", subtitle: "supplierDocumentsSub" },
    { title: "inviteExternalSupplier", subtitle: "inviteExternalSupplierSub" },
  ];
  return (
    <BaseModal
      bodyOverflow={activeStep == 2 ? "unset" : "auto"}
      size={activeStep === 4 ? "xl" : "6xl"}
      onClose={onClose}
      onConfirm={proceed}
      onCancel={cancel}
      isLoading={isLoading}
      onConfirmDisabled={
        (activeStep === 1 &&
          !invitingExternalSupplier &&
          selectedCompanies.length <= 0) ||
        (activeStep === 1 &&
          invitingExternalSupplier &&
          (unlistedCompany.name === "" || unlistedCompany.email === "")) ||
        (activeStep === 2 && !selectedCompanyVariant)
      }
      disableOnClose
      title={
        <Box
          border="1px"
          padding={3}
          borderRadius={8}
          borderColor="gray.400"
          overflowX={"auto"}
        >
          <Steps
            selected={activeStep - 1}
            steps={[
              { title: t("supplier", { ns: "supplier" }) },
              { title: t("supplierVariants", { ns: "supplier" }) },
              { title: t("documents", { ns: "documents" }) },
            ]}
            onSelect={changeStep}
          />
        </Box>
      }
    >
      <Flex direction="column" ref={pdfExport}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box>
            <Text fontWeight="semibold" fontSize="20px">
              {t(textKeys[activeStep - 1].title, { ns: "supplier" })}
            </Text>
            <Text mb={4}>
              {t(textKeys[activeStep - 1].subtitle, { ns: "supplier" })}
            </Text>
          </Box>
          {activeStep === 2 && (
            <Popover isOpen={isOpen}>
              <PopoverTrigger>
                <ActionBarItem
                  icon={AiOutlinePrinter}
                  description={t("pdf.tooltipExportPdf", { ns: "common" })}
                  onClick={() => {
                      setIsOpen(true);
                      setRequirementsByResource({});
                      setResourceType(null);
                    }
                  }
                />
              </PopoverTrigger>
              <Portal containerRef={pdfExport}>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton
                    cursor={"pointer"}
                    onClick={() => setIsOpen(false)}
                  />
                  <PopoverBody>
                    <Flex flexDirection={"column"} gap={2} my={4}>
                      {[
                        {
                          label: t("sub.suppliers", { ns: "navbar" }),
                          key: "supplier",
                        }
                      ].map((menuItem) => {
                        return (
                          <Checkbox
                            isChecked={checkedItems[menuItem.key]}
                            isDisabled={requirementsLoading}
                            key={menuItem.key}
                            onChange={(e) =>
                              handleSelection(
                                e,
                                menuItem.key as RequirementSubject
                              )
                            }
                          >
                            {menuItem.label}
                          </Checkbox>
                        );
                      })}
                    </Flex>
                  </PopoverBody>
                  <PopoverFooter onClick={() => setIsOpen(false)}>
                    <Tooltip
                      shouldWrapChildren
                      label={t("pdf.tooltipExportPdf", { ns: "common" })}
                    >
                      <BlobProvider
                        document={
                          <RequirementsPdf
                            data={results}
                            columnsVariantTable={[
                              t("pdf.variants", { ns: "common" }),
                              t("pdf.documents", { ns: "common" }),
                            ]}
                            columnsSpecializationTable={[
                              t("pdf.specializations", { ns: "common" }),
                              t("pdf.documents", { ns: "common" }),
                            ]}
                            title={t("pdf.tooltipExportPdf", {
                              ns: "common",
                            })}
                            subTitle={t("pdf.subTitle", {
                              ns: "common",
                            })}
                          />
                        }
                      >
                        {({ url, loading }) => (
                          <Box
                            backgroundColor={COLORS.sikuroBlue}
                            width={"18rem"}
                            color={COLORS.white}
                            padding={2}
                            borderRadius={"5px"}
                            cursor={"pointer"}
                          >
                            <Link
                              onClick={(e) => {
                                setIsOpen(false);
                              }}
                              href={url}
                              _hover={{ textDecoration: "none" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Flex
                                gap={4}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                {!requirementsLoading ? (
                                  <>
                                    {t("pdf.download", { ns: "common" })}
                                    {loading ? (
                                      <ImSpinner6 />
                                    ) : (
                                      <FaCloudDownloadAlt />
                                    )}
                                  </>
                                ) : (
                                  <Spinner />
                                )}
                              </Flex>
                            </Link>
                          </Box>
                        )}
                      </BlobProvider>
                    </Tooltip>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
          )}
        </Flex>
        <TagList
          tags={
            activeStep === 1 && !invitingExternalSupplier
              ? selectedCompanyTags
              : selectedDocumentTags
          }
          onDelete={(t) =>
            activeStep === 1 ? onRemoveCompany(t.id) : onRemoveDocument(t.id)
          }
          marginTop={4}
          marginBottom={
            (activeStep === 1 && !invitingExternalSupplier
              ? selectedCompanyTags
              : selectedDocumentTags
            ).length > 0
              ? 4
              : 0
          }
        />

        {content[activeStep - 1]}
        {activeStep === 1 &&
          selectedCompanies.length === 0 &&
          !invitingExternalSupplier && (
            <Text mt={4}>
              {t("supplierNotListed", { ns: "supplier" })}
              <Button
                mt="10px"
                ml="10px"
                colorScheme="blue"
                variant="link"
                onClick={() => setInvitingExternalSupplier(true)}
              >
                {t("inviteUnlistedSupplier", { ns: "supplier" })}
              </Button>
            </Text>
          )}
        {activeStep === 1 && invitingExternalSupplier && (
          <Text mt={4}>
            {t("fromSupplierList", { ns: "supplier" })}
            <Button
              mt="10px"
              ml="10px"
              colorScheme="blue"
              variant="link"
              onClick={() => {
                setUnlistedCompany(unlistedCompanyDefaultState);
                setInvitingExternalSupplier(false);
              }}
            >
              {t("goToList", { ns: "supplier" })}
            </Button>
          </Text>
        )}
      </Flex>
    </BaseModal>
  );
};

export { InviteSupplierWithVariantPageContainer };
