import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import {Permission} from "../../Permissions/Permissions";

const SettingsTopBar: FC<{ title?: string; backPath?: string }> = ({
  title,
  backPath,
}) => {
  const { t } = useTranslation();

  const items: TopBarItem[] = [
    {
      title: t("access", { ns: "settings" }),
      path: "/settings/access",
      permissions: [Permission.Settings_ShowRoles, Permission.Settings_ShowUsers],
    },
    {
      title: t("webhooks", { ns: "settings" }),
      path: "/settings/webhooks",
      permissions: [Permission.Settings_EditWebHooks],
    },
    {
      title: t("customFields.title", { ns: "settings" }),
      path: "/settings/custom-fields",
      permissions: [Permission.Settings_EditCustomFields],
    },
  ];

  return (
    <TopBar
      title={title ?? t("title", { ns: "settings" })}
      items={items}
      backPath={backPath}
    />
  );
};

export default SettingsTopBar;
