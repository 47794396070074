import UseCase from '../../../../core/app/usecase';
import ToolRepository from '../../../repositories/toolRepository';
import { SortMeta } from '../../../entities/interfaces/paginatedResults';
import {GetResourcesFilter} from "../../../repositories/workerRepository";

type ARGS = {
	companyId: string;
	filter?: GetResourcesFilter;
	sort?: SortMeta;
};

class GetAvailableToolsCount implements UseCase<ARGS, number> {
	constructor(private repository: ToolRepository) {}

	async Call({ companyId, filter, sort }: ARGS): Promise<number> {
		return await this.repository.getAvailableToolsCount(companyId, filter, sort);
	}
}

export default GetAvailableToolsCount;
