import { useParams } from "react-router-dom";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useWorkingSiteChemicalDetailViewModel from "../../hooks/WorkingSite/useWorkingSiteChemicalDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { Permission } from "../../components/Permissions/Permissions";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const WorkingSiteChemicalDetailView = () => {
  const { siteId, chemicalId } = useParams();
  const resourceColumns = ["name"];

  const downloader = useResourceFilesDownloader(
    siteId,
    chemicalId,
    "chemicals"
  );
  return (
    <SiteResourceDetailView<SiteChemicalParams>
      downloader={downloader}
      resourceId={chemicalId}
      type={DocumentTypeCategory.CHEMICAL}
      useViewModel={useWorkingSiteChemicalDetailViewModel(
        siteId,
        chemicalId,
        resourceColumns,
        DocumentTypeCategory.CHEMICAL,
        AiTaskSectionCode.WORKING_SITE_RESOURCES_CHEMICALS
      )}
      resourceColumns={resourceColumns}
      isWorkingSite
      isReadOnly={true}
      permissions={{
        downloadDocuments: Permission.Worksite_DownloadChemicalReq,
        showGlobalEvaluation: Permission.Worksite_ShowGlobalChemicalEvaluation,
        setGlobalEvaluation: Permission.Worksite_SetGlobalChemicalEvaluation,
        addRequirement: Permission.Worksite_AddChemicalReq,
        viewRequirements: Permission.Worksite_ShowChemicalReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        deleteResource: Permission.Worksite_DeleteChemical,
        documents: {
          delete: Permission.Worksite_Chemical_DeleteFile,
          edit: Permission.Worksite_Chemical_EditFile,
          evaluate: Permission.Worksite_EvalChemicalReq,
          viewEvaluations: Permission.Worksite_ShowChemicalReqEvaluation,
          viewEvaluationExpiration: Permission.Worksite_ShowChemicalReqEvaluationExpiration,
          downloadAll: Permission.Worksite_DownloadChemicalReq,
          addFile: Permission.Worksite_EditChemicalReq,
          chat: Permission.Worksite_ChemicalChat,
          report: Permission.Worksite_Reports,
          setExpiration: Permission.Worksite_Chemical_SetExpiration,
        },
      }}
    />
  );
};

export default WorkingSiteChemicalDetailView;
