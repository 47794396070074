import React, { useState } from "react";
import TextInput from "../../Common/TextInput";
import { useTranslation } from "react-i18next";
import BaseModal from "../../Common/alerts/BaseModal";
import BadgeReader from "../../../../domain/entities/badgeReader";
import InputAnimatedSelect from "../../Common/InputAnimatedSelect";
import { BadgeReaderDirection } from "../../../../domain/entities/badgeReaderDirection.enum";
import {
  SimpleGrid,
  Input,
  InputRightElement,
  InputGroup,
  useClipboard,
  Button,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { VirtualBadgeReaderUsersFilters } from "../../../../domain/repositories/badgeReaderRepository";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { UserMini } from "../../../../domain/entities/user";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import { AllowedTimezones } from "../../../../domain/entities/AllowedTimezones.enum";

interface CreateVirtualBadgeReaderModalProps {
  onClose: () => void;
  onConfirm: (badge: Partial<BadgeReader>) => Promise<BadgeReader>;

  availableUsers: UserMini[];
  availableUsersFetching: boolean;
  virtualBadgeReaderUsersFilters: VirtualBadgeReaderUsersFilters;
  updateVirtualBadgeReadersUsersFilters: (
    field: keyof VirtualBadgeReaderUsersFilters,
    value: string,
  ) => void;
  virtualBadgeReadersUsersSorting: SortMeta;
  updateVirtualBadgeReaderUsersSorting: (sort: SortMeta) => void;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}
const CreateVirtualBadgeReaderModal = ({
  onClose,
  onConfirm,
  availableUsers,
  virtualBadgeReaderUsersFilters,
  updateVirtualBadgeReadersUsersFilters,
  virtualBadgeReadersUsersSorting,
  updateVirtualBadgeReaderUsersSorting,
  hasNextPage,
  fetchNextPage,
}: CreateVirtualBadgeReaderModalProps) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const TIMEZONE_OPTIONS = Object.keys(AllowedTimezones).map((timezone) => ({
    id: timezone,
    name: AllowedTimezones[timezone],
  }));

  const { t } = useTranslation("badgeReaders");
  const [selectedUser, setSelectedUser] = useState<UserMini>(undefined);
  const [customName, setCustomName] = useState<string>();

  const [direction, setDirection] = useState<BadgeReaderDirection | undefined>(
    undefined,
  );
  const [timezone, setTimezone] = useState<AllowedTimezones>(
    Object.keys(AllowedTimezones).find(
      (t) => t === "Europe/Rome",
    ) as AllowedTimezones,
  );
  const [creationCalled, setCreationCalled] = useState(false);
  const { onCopy, value, hasCopied } = useClipboard("");

  const canSubmit = (): boolean => {
    if(!selectedUser || selectedUser?.email?.trim() === "") {
      return false;
    }
    if (!selectedUser || selectedUser?.name?.trim() === "") {
      return false;
    }
    if(!customName || customName?.trim() === ""){
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    setCreationCalled(true);
    await onConfirm({
      name: customName ? customName : selectedUser?.name,
      userId: selectedUser?.id,
      direction,
    });
    setCreationCalled(false);
    onClose();
  };

  const tableColumns: {
    field: keyof VirtualBadgeReaderUsersFilters;
    type: "text";
    options?: Record<string, string>;
  }[] = [
    { field: "name", type: "text" },
    { field: "email", type: "text" },
  ];

  return (
    <BaseModal
      title={t("createBadgeReader")}
      onClose={onClose}
      onConfirm={value === "" ? handleConfirm : undefined}
      footer={
        <>
          {canSubmit() && (
            <Button
              colorScheme="blue"
              onClick={handleConfirm}
              isLoading={creationCalled}
            >
              {t("confirm", { ns: "common" })}
            </Button>
          )}
          <Button colorScheme="red" onClick={onClose}>
            {t(value === "" ? "cancel" : "close", { ns: "common" })}
          </Button>
        </>
      }
    >
      <Text marginBottom={5} width={"100%"} display="flex" color={"black"}>
        {t("selectUser", { ns: "badgeReaders" })}
      </Text>

      {!value && (
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width={isMobile ? "calc(100vw - 120px)" : "100%"}
          position="relative"
          overflow={"auto"}
          marginTop={3}
          id="table-container"
        >
          <InfiniteTable
            autosize
            tableId="virtual-badge-reader-users-table"
            bottomThreshold={300}
            infiniteScroll={{
              dataLength: availableUsers.length,
              hasNextPage,
              fetchNextPage,
            }}
            emptyText={t("noUsersAvailable", { ns: "badgeReaders" })}
          >
            <Thead>
              <Tr>
                {tableColumns.map((column) => (
                  <Th key={column.field}>
                    <TableColumnHeader
                      text={t(`columns.${column.field}`)}
                      filter={
                        column.field !== "name" &&
                        column.field !== "email" && {
                          component: (
                            <ColumnFilterComponent
                              type={column.type}
                              value={
                                virtualBadgeReaderUsersFilters[column.field]
                              }
                              updateFilter={(value) =>
                                updateVirtualBadgeReadersUsersFilters(
                                  column.field,
                                  value as string,
                                )
                              }
                              selectOptions={column.options}
                              namespace="badgeReaders"
                            />
                          ),
                          isActive: !!(Array.isArray(
                            virtualBadgeReaderUsersFilters[column.field],
                          )
                            ? virtualBadgeReaderUsersFilters[column.field][1]
                            : virtualBadgeReaderUsersFilters[column.field]),
                        }
                      }
                      sort={{
                        handler: (direction) =>
                          updateVirtualBadgeReaderUsersSorting({
                            field: column.field,
                            direction,
                          }),
                        direction:
                          virtualBadgeReadersUsersSorting &&
                          virtualBadgeReadersUsersSorting.field === column.field
                            ? virtualBadgeReadersUsersSorting.direction
                            : null,
                      }}
                    />
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {availableUsers.map((user) => (
                <Tr key={user?.id} sx={{ cursor: "pointer" }}>
                  <Td>
                    <Checkbox
                      isChecked={user === selectedUser}
                      onChange={() => setSelectedUser(user)}
                    >
                      {user?.name}
                    </Checkbox>
                  </Td>
                  <Td>{user?.email}</Td>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      )}

      {value === "" ? (
        <SimpleGrid columns={1} spacing={4} marginTop={6}>
          <TextInput
            label={t("badgeReaderName")}
            value={customName}
            onChange={(value) => {
              setCustomName(value);
            }}
            disableClear={false}
          />
        </SimpleGrid>
      ) : (
        <InputGroup marginTop={6}>
          <Input pr="5rem" value={value} readOnly />
          <InputRightElement width="5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={onCopy}
              sx={{ textTransform: "uppercase", fontSize: "12px" }}
            >
              {t(hasCopied ? "copied" : "copy", { ns: "common" })}
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
      {!value && (
        <InputAnimatedSelect
          isRequired
          marginTop={5}
          id="timezone"
          label={t("columns.timezone")}
          selectedValue={timezone}
          onSelect={(option) => {
            const timezone = option as AllowedTimezones;
            setTimezone(timezone);
          }}
          items={TIMEZONE_OPTIONS}
        />
      )}
    </BaseModal>
  );
};

export default CreateVirtualBadgeReaderModal;
