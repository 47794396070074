import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteVehicleParams } from "../../../domain/entities/siteVehicle";
import useSiteVehiclesViewModel from "../../hooks/Site/useSiteVehiclesViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteVehiclesView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "plate", "model"];
  return (
    <SiteResourcesListView<SiteVehicleParams>
      type={DocumentTypeCategory.VEHICLE}
      useViewModel={useSiteVehiclesViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.VEHICLE,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[
        Permission.Sites_ShowVehicles,
        Permission.Sites_AddVehicle,
      ]}
      evaluatePermissions={Permission.Sites_EvalVehicleReq}
      removeFromSitePermissions={[
        Permission.Sites_ShowVehicles,
        Permission.Sites_DeleteVehicle,
      ]}
      showStatePermission={[Permission.Sites_ShowGlobalVehicleEvaluation]}
    />
  );
};

export default SiteVehiclesView;
