import { Permission } from "../components/Permissions/Permissions";

export const commonWorkingSiteFallbackRoutes = [
  {
    path: "workingsites",
    permissions: [Permission.Worksite_Show],
  },
  {
    path: "workingsites/:siteId",
    permissions: [Permission.Sites_ShowDetails],
  },
  {
    path: "workingsites/:siteId/site",
    permissions: [Permission.Sites_ShowDetails, Permission.Worksite_ShowSettings ],
  },
  {
    path: "workingsites/:siteId/site/details",
    permissions: [Permission.Worksite_ShowDetails],
  },
  {
    path: "workingsites/:siteId/site/options",
    permissions: [Permission.Worksite_ShowSettings],
  },
  {
    path: "workingsites/:siteId/documents",
    permissions: [Permission.Worksite_ShowReq],
  },
  {
    path: "workingsites/:siteId/resources",
    permissions:
      [Permission.Worksite_ShowWorkers,
      Permission.Worksite_ShowVehicles,
      Permission.Worksite_ShowMachines,
      Permission.Worksite_ShowChemicals,
      Permission.Worksite_ShowTools ],
  },
  {
    path: "workingsites/:siteId/subcontractors",
    permissions: [Permission.Worksite_ShowSubcontractors],
  }
];

export const getWorkingSiteFallbackRoutes = (currentRoute: string) => {
  let fallbackRoutes = [...commonWorkingSiteFallbackRoutes];
  fallbackRoutes = fallbackRoutes.filter(
    (route) => route.path !== `workingsites/:siteId/${currentRoute}`,
  );
  return fallbackRoutes;
};


