import {
  Button,
  Checkbox,
  Flex,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { SiteMachine } from "../../../../domain/entities/siteMachine";
import { SiteVehicle } from "../../../../domain/entities/siteVehicle";
import { SiteWorker } from "../../../../domain/entities/siteWorker";
import { COLORS } from "../../../assets/theme/colors";
import { AllResourcesFilters, GetSiteResourcesFilters } from "../../../hooks/Badge/useSiteBadgesListViewModel";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import InfiniteTable from "../../Common/table/InfiniteTable";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import { TableColumn } from "./LinkBadgesToSiteResourcesModal";

type SelectSiteResourcesTableProps = {
  siteResources: (SiteWorker | SiteMachine | SiteVehicle)[];
  siteResourcesIsLoading: boolean;
  siteResourcesHasNextPage: boolean;
  siteResourcesFetchNextPage: () => void;
  siteResourcesFilters: { [p: string]: any };
  siteResourcesSort: SortMeta;
  updateSiteResourcesFilter: (
    field: AllResourcesFilters,
    value: string | string[],
  ) => void;
  setSiteResourcesSort: (sort: SortMeta) => void;
  setSiteResourceIds: (resourceIds: string[]) => void;
  siteResourceIds: string[];
  columns: TableColumn[];
  selectAllAvailable?: boolean;
  parentSelectAllVisible?: boolean;
  setParentSelectAllVisible?: (selectAllVisibleParent: boolean) => void;
  parentSelectAllMatching?: boolean;
  setParentSelectAllMatching?: (selectAllParent: boolean) => void;
  selectedResourcesNumber: number;
  setSelectedResourcesNumber?: (selectedResourcesNumber: number) => void;
  totalSelectableResourcesNumber?: number;
  autosize?: boolean;
  setWarningMultipleLinking?: (val: boolean) => void;
  warningMultipleLinking?: boolean;
};
const SelectSiteResourcesTable = ({
  setSiteResourceIds,
  siteResourcesHasNextPage,
  siteResourcesFetchNextPage,
  updateSiteResourcesFilter,
  siteResourceIds,
  siteResourcesSort,
  siteResources,
  siteResourcesIsLoading,
  setSiteResourcesSort,
  siteResourcesFilters,
  columns,
  selectAllAvailable,
  parentSelectAllVisible,
  setParentSelectAllVisible,
  parentSelectAllMatching,
  setParentSelectAllMatching,
  selectedResourcesNumber,
  setSelectedResourcesNumber,
  totalSelectableResourcesNumber,
  autosize,
  setWarningMultipleLinking,
  warningMultipleLinking,
}: SelectSiteResourcesTableProps) => {
  
  const { t } = useTranslation("siteResources");

  const [insertedResourcesNumber, setInsertedResourcesNumber] =
    useState<number>(0);
  const [storedSelectedNumber, setStoredSelectedNumber] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false)

  const toggleItem = (id: string) => {
    if(parentSelectAllVisible || setParentSelectAllMatching){
      setParentSelectAllMatching(false);
      setParentSelectAllVisible(false);
    }
    let updatedIds;

    if (siteResourceIds && !siteResourceIds.includes(id)) {
      updatedIds = [...siteResourceIds, id];
    } else {
      updatedIds = siteResourceIds.filter((i) => i !== id);
    }

    setSiteResourceIds(updatedIds);

    if (updatedIds.length === siteResources.length) {
      setParentSelectAllMatching(true);
      setParentSelectAllVisible(true);
    } else {
      setParentSelectAllMatching(false);
      setParentSelectAllVisible(false);
    }
  };

  const toggleSelectAll = (value: boolean) => {
    setParentSelectAllVisible(value);
    if (!value) {
      setSiteResourceIds([]);
      setParentSelectAllMatching(false);
      setStoredSelectedNumber(false);
      setSelectedResourcesNumber(0);
    } else if (value && insertedResourcesNumber >= 0) {
      setSiteResourceIds(siteResources.map((resource) => resource.id));
      setSelectedResourcesNumber(0);
      !warningMultipleLinking && setWarningMultipleLinking(true);
    } else {
      setSiteResourceIds(siteResources.map((resource) => resource.id));
      setSelectedResourcesNumber(selectedResourcesNumber);
      !warningMultipleLinking && setWarningMultipleLinking(true);
    }
  };

  const handleSelectionButton = () => {
    setShowClearButton(!showClearButton)
    if (showClearButton) {
      setParentSelectAllVisible(false);
      setSelectedResourcesNumber(0);
      setStoredSelectedNumber(false);
      setParentSelectAllMatching(false);
      setSiteResourceIds([]);
      setInsertedResourcesNumber(0);
      return;
    }
    setParentSelectAllMatching(true);
    setSelectedResourcesNumber(totalSelectableResourcesNumber);
  };

  const [isMobile] = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    if (
      siteResources.length > 0 &&
      (parentSelectAllMatching || parentSelectAllVisible) &&
      !insertedResourcesNumber && !showClearButton
    ) {
      const ids = siteResources.map((badge) => badge.id);
      const allIds = [...ids, ...siteResourceIds];
      const uniqueBadgeIds = Array.from(new Set(allIds));
      setSiteResourceIds(uniqueBadgeIds);
    }
  },
    [
      siteResources,
      parentSelectAllVisible,
      parentSelectAllMatching,
      insertedResourcesNumber,
      showClearButton,
      siteResourceIds,
      setSiteResourceIds
    ]);


  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width={isMobile ? "calc(100vw - 120px)" : "100%"}
      position="relative"
      overflow="auto"
      marginTop={3}
      id="table-container-modal"
    >
      <InfiniteTable
        autosize={autosize}
        isLoading={siteResourcesIsLoading}
        isCheckboxTable={true}
        bottomThreshold={220}
        infiniteScroll={{
          dataLength: siteResources.length,
          hasNextPage: siteResourcesHasNextPage,
          fetchNextPage: siteResourcesFetchNextPage,
        }}
        tableId="select-site-resources-table"
      >
        <Thead>
          <Tr>
            {selectAllAvailable && (
              <Th key={"selectAllCheckbox"} width={10}>
                <Checkbox
                  borderColor={"gray.500"}
                  isChecked={parentSelectAllVisible}
                  onChange={() => toggleSelectAll(!parentSelectAllVisible)}
                />
              </Th>
            )}
            {columns.map(({ field, width }) => (
              <Th width={width} key={field}>
                <TableColumnHeader
                  text={t(`columns.${field}`, { ns: "badges" })}
                  filter={{
                    component: (
                      <ColumnFilterComponent
                        type="text"
                        value={siteResourcesFilters[field]}
                        updateFilter={(value) =>
                          updateSiteResourcesFilter(
                            field,
                            value as string | string[],
                          )
                        }
                        namespace="badges"
                      />
                    ),
                    isActive: !!siteResourcesFilters[field],
                  }}
                  sort={{
                    handler: (direction) =>
                      setSiteResourcesSort({ field, direction }),
                    direction:
                      siteResourcesSort && siteResourcesSort.field === field
                        ? siteResourcesSort.direction
                        : null,
                  }}
                  isInModal
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr width="100%">
            {parentSelectAllVisible && (
              <Th colSpan={columns.length + 1} backgroundColor={"gray.100"}>
                <Text color={COLORS.red}>
                  {insertedResourcesNumber > totalSelectableResourcesNumber &&
                    t("selectionTooltip", {
                      ns: "badges",
                      count: totalSelectableResourcesNumber,
                    })}
                </Text>

                <Text textAlign="center" mx="auto">
                  {!parentSelectAllMatching && t("resourcesSelectedVisible")}
                  {insertedResourcesNumber < totalSelectableResourcesNumber &&
                    parentSelectAllMatching &&
                    !storedSelectedNumber &&
                    t("resourcesSelectedNotVisible", {
                      count: totalSelectableResourcesNumber,
                    })}
                  {insertedResourcesNumber < totalSelectableResourcesNumber &&
                    parentSelectAllMatching &&
                    storedSelectedNumber &&
                    t("resourcesSelectedManuallyNotVisible", {
                      countSelected: selectedResourcesNumber,
                      total: totalSelectableResourcesNumber,
                    })}
                  {insertedResourcesNumber < totalSelectableResourcesNumber &&
                    siteResourcesHasNextPage && (
                      <Button
                        mt="10px"
                        ml="4px"
                        colorScheme="blue"
                        variant="link"
                        onClick={() => handleSelectionButton()}
                      >
                        {t(
                          showClearButton
                            ? "clearSelection"
                            : "resourcesSelectAll",
                          { ns: "siteResources" },
                        )}
                      </Button>
                    )}
                </Text>
              </Th>
            )}
          </Tr>

            {siteResources.map((siteResource) => (
              <Tr key={siteResource.id} width={"100%"}>
                <Td width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={siteResourceIds.includes(siteResource.id)}
                    onChange={() => {
                      toggleItem(siteResource.id);
                      !siteResourceIds.includes(siteResource.id) &&
                        siteResourceIds?.length === 1 &&
                        !warningMultipleLinking &&
                        setWarningMultipleLinking(true);
                    }}
                  ></Checkbox>
                </Td>
                {columns.map(({field, width}) => (
                  <Td width={width} key={field}>{siteResource[field]}</Td>
                ))}
              </Tr>
            ))}
        </Tbody>
      </InfiniteTable>
    </Flex>
  );
};

export default SelectSiteResourcesTable;
