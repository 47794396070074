import { LicenseType } from "../../../domain/interactors/auth/args";

export const CompanyPermission =
  [
    //Dashboard starts
    {
      "id": "dashboard:show-owned-sites-count",
      "name": "dashboard:show-owned-sites-count",
      "action": "show-owned-sites-count",
      "resource": "dashboard",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "dashboard:show-working-sites-count",
      "name": "dashboard:show-working-sites-count",
      "action": "show-working-sites-count",
      "resource": "dashboard",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "dashboard:show-suppliers-count",
      "name": "dashboard:show-suppliers-count",
      "action": "show-suppliers-count",
      "resource": "dashboard",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "dashboard:show-unevaluated-documents",
      "name": "dashboard:show-unevaluated-documents",
      "action": "show-unevaluated-documents",
      "resource": "dashboard",
      "license": [LicenseType.Master,],
      "enabled": false
    },
    {
      "id": "dashboard:show-expiring-evaluations",
      "name": "dashboard:show-expiring-evaluations",
      "action": "show-expiring-evaluations",
      "resource": "dashboard",
      "license": [LicenseType.Master,],
      "enabled": false
    },
    {
      "id": "dashboard:show-expiring-resources-documents",
      "name": "dashboard:show-expiring-resources-documents",
      "action": "show-expiring-resources-documents",//show-expiring-documents (nome precedente)
      "resource": "dashboard",
      "license": [LicenseType.Master, LicenseType.Standard,LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "dashboard:show-expiring-company-documents",
      "name": "dashboard:show-expiring-company-documents",
      "action": "show-expiring-company-documents",
      "resource": "dashboard",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "dashboard:show-expiring-resources-evaluations",
      "name": "dashboard:show-expiring-resources-evaluations",
      "action": "show-expiring-resources-evaluations",
      "resource": "dashboard",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "dashboard:show-expiring-company-or-site-evaluations",
      "name": "dashboard:show-expiring-company-or-site-evaluations",
      "action": "show-expiring-company-or-site-evaluations",
      "resource": "dashboard",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "dashboard:show-pending-evaluations",
      "name": "dashboard:show-pending-evaluations",
      "action": "show-pending-evaluations",
      "resource": "dashboard",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "dashboard:show-invited-suppliers",
      "name": "dashboard:show-invited-suppliers",
      "action": "show-invited-suppliers",
      "resource": "dashboard",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    //Dashboard ends
    //My Sites starts
    {
      "id": "company_sites:show",
      "name": "company_sites:show",
      "action": "show",
      "resource": "company_sites",
      "license":[LicenseType.Master],
      "enabled": true
    },
    {
      "id": "company_sites:show | company_sites:add",
      "name": "company_sites:add",
      "action": "add",
      "resource": "company_sites",
      "license":[LicenseType.Master],
      "enabled": true
    },
    //My Sites end
    //Working Sites starts
    {
      "id": "company_workingsites:show", //worksite:show (nome precedente)
      "name": "company_workingsites:show",//worksite:show 
      "action": "show",
      "resource": "company_workingsites",
      "license":[LicenseType.Master],
      "enabled": false
    },
    //Working Sites end
    //Company starts
    {
      "id": "company:ai",
      "name": "company:ai",
      "action": "ai",
      "resource": "company",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-details",
      "name": "company:show-details",
      "action": "show-details",
      "resource": "company",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-details | company:edit-details",
      "name": "company:edit-details",
      "action": "edit-details",
      "resource": "company",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-staff",
      "name": "company:show-staff",
      "action": "show-staff",
      "resource": "company",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-staff | company:edit-staff",
      "name": "company:edit-staff",
      "action": "edit-staff",
      "resource": "company",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //GeneralDocuments
    {
      "id": "company:show-company-docs",
      "name": "company:show-company-docs",
      "action": "show-company-docs",
      "resource": "documents",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:add-new-company-doc",
      "name": "company:add-new-company-doc",
      "action": "add-new-company-doc",
      "resource": "documents",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:edit-company-docs",
      "name": "company:edit-company-docs",
      "action": "edit-company-docs",
      "resource": "documents",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:delete-company-doc",
      "name": "company:delete-company-doc",
      "action": "download-docs",
      "resource": "documents",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:download-docs",
      "name": "company:download-docs",
      "action": "download-docs",
      "resource": "documents",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:show-company-docs-evaluation",
      "name": "company:show-company-docs-evaluation",
      "action": "show-company-docs-evaluation",
      "resource": "documents",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:show-company-docs-evaluation | company:eval-company-docs",
      "name": "company:eval-company-docs",
      "action": "eval-company-docs",
      "resource": "documents",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:show-company-doc-expiration",
      "name": "company:show-company-doc-expiration",
      "action": "show-company-doc-expiration",
      "resource": "documents",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-company-docs | company:show-company-doc-expiration | company:set-company-doc-expiration",
      "name": "company:set-company-doc-expiration",
      "action": "set-company-doc-expiration",
      "resource": "documents",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //End general documents
    //Preset
    {
      "id": "company:show-preset",
      "name": "company:show-preset",
      "action": "show-preset",
      "resource": "document-presets",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-preset | company:edit-preset",
      "name": "company:edit-preset",
      "action": "edit-preset",
      "resource": "document-presets",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-preset | company:delete-preset",
      "name": "company:delete-preset",
      "action": "delete-preset",
      "resource": "document-presets",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //End preset
    //Document types
    {
      "id": "company:show-doc-type",
      "name": "company:show-doc-type",
      "action": "show-doc-type",
      "resource": "document-types",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-doc-type | company:edit-doc-type",
      "name": "company:edit-doc-type",
      "action": "edit-doc-type",
      "resource": "document-types",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "company:show-doc-type | company:delete-doc-type",
      "name": "company:delete-doc-type",
      "action": "delete-doc-type",
      "resource": "document-types",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //Document types end
    //Requirements
    {
      "id": "company:show-req-group",
      "name": "company:show-req-group",
      "action": "show-req-group",
      "resource": "requirements-groups",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-req-group | company:add-req-group",
      "name": "company:add-req-group",
      "action": "add-req-group",
      "resource": "requirements-groups",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-req-group | company:edit-req-group",
      "name": "company:edit-req-group",
      "action": "edit-req-group",
      "resource": "requirements-groups",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-req-group | company:edit-req-group | company:add-req-to-group",
      "name": "company:add-req-to-group",
      "action": "add-req-to-group",
      "resource": "requirements-groups",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-req-group | company:edit-req-group | company:delete-req-from-group",
      "name": "company:delete-req-from-group",
      "action": "delete-req-from-group",
      "resource": "requirements-groups",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-req-group | company:delete-req-group",
      "name": "company:delete-req-group",
      "action": "delete-req-group",
      "resource": "requirements-groups",
      "license":[LicenseType.Master],
      "enabled": false
    },
    //Requirements end
    //Badges
    {
      "id": "company:show-badges",
      "name": "company:show-badges",
      "action": "show-badges",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-badges | company:add-badge",
      "name": "company:add-badge",
      "action": "add-badge",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-badges | company:delete-badge",
      "name": "company:delete-badge",
      "action": "delete-badge",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-badges | company:import-badge",
      "name": "company:import-badge",
      "action": "delete-badge",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-badges | company:set-badge-status",
      "name": "company:set-badge-status",
      "action": "set-badge-status",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-badges | company:link-badge-resource",
      "name": "company:link-badge-resource",
      "action": "link-badge-resource",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "company:show-badges | company:unlink-badge-resource",
      "name": "company:unlink-badge-resource",
      "action": "unlink-badge-resource",
      "resource": "badge",
      "license":[LicenseType.Master],
      "enabled": false
    },
    //Company ends
    //My resources starts
    //Workers
    {
      "id": "resources:show-workers",
      "name": "resources:show-workers",
      "action": "show-workers",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:add-worker",
      "name": "resources:add-worker",
      "action": "add-worker",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:import-worker",
      "name": "resources:import-worker",
      "action": "import-worker",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:delete-worker",
      "name": "resources:delete-worker",
      "action": "archive-worker",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-sites",
      "name": "resources:show-worker-sites",
      "action": "show-worker-sites",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs",
      "name": "resources:show-worker-docs",
      "action": "show-worker-docs",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:add-worker-doc",
      "name": "resources:add-worker-doc",
      "action": "add-worker-doc",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:edit-worker-docs",
      "name": "resources:edit-worker-docs",
      "action": "edit-worker-docs",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:delete-worker-doc",
      "name": "resources:delete-worker-doc",
      "action": "delete-worker-doc",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:download-worker-docs",
      "name": "resources:download-worker-docs",
      "action": "download-worker-docs",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-doc-expiration",
      "name": "resources:show-worker-doc-expiration",
      "action": "show-worker-doc-expiration",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-doc-expiration | resources:set-worker-doc-expiration",
      "name": "resources:set-worker-doc-expiration",
      "action": "set-worker-doc-expiration",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-typology",
      "name": "resources:show-worker-typology",
      "action": "show-worker-typology",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-typology | resources:edit-worker-typology",
      "name": "resources:edit-worker-typology",
      "action": "edit-worker-typology",
      "resource": "workers",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-docs-evaluation",
      "name": "resources:show-worker-docs-evaluation",
      "action": "show-worker-docs-evaluation",
      "resource": "workers",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-docs-evaluation | resources:show-worker-docs-evaluation-expiration",
      "name": "resources:show-worker-docs-evaluation-expiration",
      "action": "show-worker-docs-evaluation-expiration",
      "resource": "workers",
      "license":[LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-workers | resources:show-worker-docs | resources:show-worker-docs-evaluation | resources:show-worker-docs-evaluation-expiration | resources:eval-worker-docs",
      "name": "resources:eval-worker-docs",
      "action": "eval-worker-docs",
      "resource": "workers",
      "license":[LicenseType.Master],
      "enabled": false
    },
    //Vehicles
    {
      "id": "resources:show-vehicles",
      "name": "resources:show-vehicles",
      "action": "show-vehicles",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:add-vehicle",
      "name": "resources:add-vehicle",
      "action": "add-vehicle",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:import-vehicle",
      "name": "resources:import-vehicle",
      "action": "import-vehicle",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:delete-vehicle",
      "name": "resources:delete-vehicle",
      "action": "archive-vehicle",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-sites",
      "name": "resources:show-vehicle-sites",
      "action": "show-vehicle-sites",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard,],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs",
      "name": "resources:show-vehicle-docs",
      "action": "show-vehicle-docs",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:add-vehicle-doc",
      "name": "resources:add-vehicle-doc",
      "action": "add-vehicle-doc",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:edit-vehicle-docs",
      "name": "resources:edit-vehicle-docs",
      "action": "edit-vehicle-docs",
      "resource": "vehicles",
      "license":[LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:delete-vehicle-doc",
      "name": "resources:delete-vehicle-doc",
      "action": "delete-vehicle-doc",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:download-vehicle-docs",
      "name": "resources:download-vehicle-docs",
      "action": "download-vehicle-docs",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-doc-expiration",
      "name": "resources:show-vehicle-doc-expiration",
      "action": "show-vehicle-doc-expiration",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-doc-expiration | resources:set-vehicle-doc-expiration",
      "name": "resources:set-vehicle-doc-expiration",
      "action": "set-vehicle-doc-expiration",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-typology",
      "name": "resources:show-vehicle-typology",
      "action": "show-vehicle-typology",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-typology | resources:edit-vehicle-typology",
      "name": "resources:edit-vehicle-typology",
      "action": "edit-vehicle-typology",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-docs-evaluation",
      "name": "resources:show-vehicle-docs-evaluation",
      "action": "show-vehicle-docs-evaluation",
      "resource": "vehicles",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-docs-evaluation | resources:show-vehicle-docs-evaluation-expiration",
      "name": "resources:show-vehicle-docs-evaluation-expiration",
      "action": "show-vehicle-docs-evaluation-expiration",
      "resource": "vehicles",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:show-vehicle-docs | resources:show-vehicle-docs-evaluation | resources:show-vehicle-docs-evaluation-expiration | resources:eval-vehicle-docs",
      "name": "resources:eval-vehicle-docs",
      "action": "eval-vehicle-docs",
      "resource": "vehicles",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-vehicles | resources:convert-vehicle-to-machine",
      "name": "resources:convert-vehicle-to-machine",
      "action": "convert-vehicle-to-machine",
      "resource": "vehicles",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //Machines
    {
      "id": "resources:show-machines",
      "name": "resources:show-machines",
      "action": "show-machines",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:add-machine",
      "name": "resources:add-machine",
      "action": "add-machine",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:import-machine",
      "name": "resources:import-machine",
      "action": "import-machine",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:delete-machine",
      "name": "resources:delete-machine",
      "action": "archive-machine",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-sites",
      "name": "resources:show-machine-sites",
      "action": "show-machine-sites",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs",
      "name": "resources:show-machine-docs",
      "action": "show-machine-docs",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:add-machine-doc",
      "name": "resources:add-machine-doc",
      "action": "add-machine-doc",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:edit-machine-docs",
      "name": "resources:edit-machine-docs",
      "action": "edit-machine-docs",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:delete-machine-doc",
      "name": "resources:delete-machine-doc",
      "action": "delete-machine-doc",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:download-machine-docs",
      "name": "resources:download-machine-docs",
      "action": "download-machine-docs",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-doc-expiration",
      "name": "resources:show-machine-doc-expiration",
      "action": "show-machine-doc-expiration",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-doc-expiration | resources:set-machine-doc-expiration",
      "name": "resources:set-machine-doc-expiration",
      "action": "set-machine-doc-expiration",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-typology",
      "name": "resources:show-machine-typology",
      "action": "show-machine-typology",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-typology | resources:edit-machine-typology",
      "name": "resources:edit-machine-typology",
      "action": "edit-machine-typology",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-docs-evaluation",
      "name": "resources:show-machine-docs-evaluation",
      "action": "show-machine-docs-evaluation",
      "resource": "machines",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-docs-evaluation | resources:show-machine-docs-evaluation-expiration",
      "name": "resources:show-machine-docs-evaluation-expiration",
      "action": "show-machine-docs-evaluation-expiration",
      "resource": "machines",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:show-machine-docs | resources:show-machine-docs-evaluation | resources:show-machine-docs-evaluation-expiration | resources:eval-machine-docs",
      "name": "resources:eval-machine-docs",
      "action": "eval-machine-docs",
      "resource": "machines",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:convert-machine-to-vehicle",
      "name": "resources:convert-machine-to-vehicle",
      "action": "convert-machine-to-vehicle",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-machines | resources:convert-machine-to-tool",
      "name": "resources:convert-machine-to-tool",
      "action": "convert-machine-to-tool",
      "resource": "machines",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //Tools
    {
      "id": "resources:show-tools",
      "name": "resources:show-tools",
      "action": "show-tools",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:add-tool",
      "name": "resources:add-tool",
      "action": "add-tool",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:import-tool",
      "name": "resources:import-tool",
      "action": "import-tool",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:delete-tool",
      "name": "resources:delete-tool",
      "action": "archive-tool",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-sites",
      "name": "resources:show-tool-sites",
      "action": "show-tool-sites",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs",
      "name": "resources:show-tool-docs",
      "action": "show-tool-docs",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:add-tool-doc",
      "name": "resources:add-tool-doc",
      "action": "add-tool-doc",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:edit-tool-docs",
      "name": "resources:edit-tool-docs",
      "action": "edit-tool-docs",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:delete-tool-doc",
      "name": "resources:delete-tool-doc",
      "action": "delete-tool-doc",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:download-tool-docs",
      "name": "resources:download-tool-docs",
      "action": "download-tool-docs",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-doc-expiration",
      "name": "resources:show-tool-doc-expiration",
      "action": "show-tool-doc-expiration",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-doc-expiration | resources:set-tool-doc-expiration",
      "name": "resources:set-tool-doc-expiration",
      "action": "set-tool-doc-expiration",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-typology",
      "name": "resources:show-tool-typology",
      "action": "show-tool-typology",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-typology | resources:edit-tool-typology",
      "name": "resources:edit-tool-typology",
      "action": "edit-tool-typology",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-docs-evaluation",
      "name": "resources:show-tool-docs-evaluation",
      "action": "show-tool-docs-evaluation",
      "resource": "tools",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-docs-evaluation | resources:show-tool-docs-evaluation-expiration",
      "name": "resources:show-tool-docs-evaluation-expiration",
      "action": "show-tool-docs-evaluation-expiration",
      "resource": "tools",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:show-tool-docs | resources:show-tool-docs-evaluation | resources:show-tool-docs-evaluation-expiration | resources:eval-tool-docs",
      "name": "resources:eval-tool-docs",
      "action": "eval-tool-docs",
      "resource": "tools",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-tools | resources:convert-tool-to-machine",
      "name": "resources:convert-tool-to-machine",
      "action": "convert-tool-to-machine",
      "resource": "tools",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //Chemicals
    {
      "id": "resources:show-chemicals",
      "name": "resources:show-chemicals",
      "action": "show-chemicals",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:add-chemical",
      "name": "resources:add-chemical",
      "action": "add-chemical",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:import-chemical",
      "name": "resources:import-chemical",
      "action": "import-chemical",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:delete-chemical",
      "name": "resources:delete-chemical",
      "action": "archive-chemical",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-sites",
      "name": "resources:show-chemical-sites",
      "action": "show-chemical-sites",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs",
      "name": "resources:show-chemical-docs",
      "action": "show-chemical-docs",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:add-chemical-doc",
      "name": "resources:add-chemical-doc",
      "action": "add-chemical-doc",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:edit-chemical-docs",
      "name": "resources:edit-chemical-docs",
      "action": "edit-chemical-docs",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:delete-chemical-doc",
      "name": "resources:delete-chemical-doc",
      "action": "delete-chemical-doc",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:download-chemical-docs",
      "name": "resources:download-chemical-docs",
      "action": "download-chemical-docs",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-doc-expiration",
      "name": "resources:show-chemical-doc-expiration",
      "action": "show-chemical-doc-expiration",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-doc-expiration | resources:set-chemical-doc-expiration",
      "name": "resources:set-chemical-doc-expiration",
      "action": "set-chemical-doc-expiration",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-typology",
      "name": "resources:show-chemical-typology",
      "action": "show-chemical-typology",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-typology | resources:edit-chemical-typology",
      "name": "resources:edit-chemical-typology",
      "action": "edit-chemical-typology",
      "resource": "chemicals",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-docs-evaluation",
      "name": "resources:show-chemical-docs-evaluation",
      "action": "show-chemical-docs-evaluation",
      "resource": "chemicals",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-docs-evaluation | resources:show-chemical-docs-evaluation-expiration",
      "name": "resources:show-chemical-docs-evaluation-expiration",
      "action": "show-chemical-docs-evaluation-expiration",
      "resource": "chemicals",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "resources:show-chemicals | resources:show-chemical-docs | resources:show-chemical-docs-evaluation | resources:show-chemical-docs-evaluation-expiration | resources:eval-chemical-docs",
      "name": "resources:eval-chemical-docs",
      "action": "eval-chemical-docs",
      "resource": "chemicals",
      "license": [LicenseType.Master],
      "enabled": false
    },
    //My resources end
    //Suppliers starts
    {
      "id": "supplier:show",
      "name": "supplier:show",
      "action": "show",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:create-derived-tenants",
      "name": "supplier:create-derived-tenants",
      "action": "create-derived-tenants",
      "resource": "supplier",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-details",
      "name": "supplier:show-details",
      "action": "create-derived-tenants",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-details | supplier:show-documents",
      "name": "supplier:show-documents",
      "action": "show-documents",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-details | supplier:show-documents | supplier:download-all",
      "name": "supplier:download-all",
      "action": "download-all",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-details | supplier:show-documents | supplier:show-company-req-evaluation",
      "name": "supplier:show-company-req-evaluation",
      "action": "show-company-req-evaluation",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-details | supplier:show-documents | supplier:show-company-req-evaluation | supplier:eval-company-req",
      "name": "supplier:eval-company-req",
      "action": "eval-company-req",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-workers",
      "name": "supplier:show-workers",
      "action": "show-workers",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-workers | supplier:show-worker-docs",
      "name": "supplier:show-worker-docs",
      "action": "show-worker-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-workers | supplier:show-worker-docs | supplier:download-worker-docs",
      "name": "supplier:download-worker-docs",
      "action": "download-worker-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-workers | supplier:show-worker-docs | supplier:show-worker-req-evaluation",
      "name": "supplier:show-worker-req-evaluation",
      "action": "show-worker-req-evaluation",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-workers | supplier:show-worker-docs | supplier:show-worker-req-evaluation | supplier:eval-worker-req",
      "name": "supplier:eval-worker-req",
      "action": "eval-worker-req",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-vehicles",
      "name": "supplier:show-vehicles",
      "action": "show-vehicles",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-vehicles | supplier:show-vehicle-docs",
      "name": "supplier:show-vehicle-docs",
      "action": "show-vehicle-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-vehicles | supplier:show-vehicle-docs | supplier:download-vehicle-docs",
      "name": "supplier:download-vehicle-docs",
      "action": "download-vehicle-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-vehicles | supplier:show-vehicle-docs | supplier:show-vehicle-req-evaluation",
      "name": "supplier:show-vehicle-req-evaluation",
      "action": "show-vehicle-req-evaluation",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-vehicles | supplier:show-vehicle-docs | supplier:show-vehicle-req-evaluation | supplier:eval-vehicle-req",
      "name": "supplier:eval-vehicle-req",
      "action": "eval-vehicle-req",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-machines",
      "name": "supplier:show-machines",
      "action": "show-machines",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-machines | supplier:show-machine-docs",
      "name": "supplier:show-machine-docs",
      "action": "show-machine-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-machines | supplier:show-machine-docs | supplier:download-machine-docs",
      "name": "supplier:download-machine-docs",
      "action": "download-machine-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-machines | supplier:show-machine-docs | supplier:show-machine-req-evaluation",
      "name": "supplier:show-machine-req-evaluation",
      "action": "show-machine-req-evaluation",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-machines | supplier:show-machine-docs | supplier:show-machine-req-evaluation | supplier:eval-machine-req",
      "name": "supplier:eval-machine-req",
      "action": "eval-machine-req",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-tools",
      "name": "supplier:show-tools",
      "action": "show-tools",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-tools | supplier:show-tool-docs",
      "name": "supplier:show-tool-docs",
      "action": "show-tool-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-tools | supplier:show-tool-docs | supplier:download-tool-docs",
      "name": "supplier:download-tool-docs",
      "action": "download-tool-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-tools | supplier:show-tool-docs | supplier:show-tool-req-evaluation",
      "name": "supplier:show-tool-req-evaluation",
      "action": "show-tool-req-evaluation",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-tools | supplier:show-tool-docs | supplier:show-tool-req-evaluation | supplier:eval-tool-req",
      "name": "supplier:eval-tool-req",
      "action": "eval-tool-req",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-chemicals",
      "name": "supplier:show-chemicals",
      "action": "show-chemicals",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-chemicals | supplier:show-chemical-docs",
      "name": "supplier:show-chemical-docs",
      "action": "show-chemical-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-chemicals | supplier:show-chemical-docs | supplier:download-chemical-docs",
      "name": "supplier:download-chemical-docs",
      "action": "download-chemical-docs",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-chemicals | supplier:show-chemical-docs | supplier:show-chemical-req-evaluation",
      "name": "supplier:show-chemical-req-evaluation",
      "action": "show-chemical-req-evaluation",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "supplier:show | supplier:show-chemicals | supplier:show-chemical-docs | supplier:show-chemical-req-evaluation | supplier:eval-chemical-req",
      "name": "supplier:eval-chemical-req",
      "action": "eval-chemical-req",
      "resource": "supplier",
      "license": [LicenseType.Master],
      "enabled": false
    },
    //Suppliers ends
    //Calendar starts
    {
      "id": "calendar:show-expiration",
      "name": "calendar:show-expiration",
      "action": "show-expiration",
      "resource": "calendar",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    //Calendar ends
    //Settings starts
    {
      "id": "settings:show-roles",
      "name": "settings:show-roles",
      "action": "show-roles",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-roles | settings:add-role",
      "name": "settings:add-role",
      "action": "add-role",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-roles | settings:edit-role",
      "name": "settings:edit-role",
      "action": "edit-role",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-roles | settings:delete-role",
      "name": "settings:delete-role",
      "action": "delete-role",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users",
      "name": "settings:show-users",
      "action": "show-users",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:invite-user",
      "name": "settings:invite-user",
      "action": "invite-user",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:delete-user",
      "name": "settings:delete-user",
      "action": "delete-user",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-general-role",
      "name": "settings:show-user-general-role",
      "action": "show-user-general-role",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-general-role | settings:edit-user-general-role",
      "name": "settings:edit-user-general-role",
      "action": "edit-user-general-role",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-site-roles",
      "name": "settings:show-user-site-roles",
      "action": "show-user-site-roles",
      "resource": "settings",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-site-roles | settings:add-user-site-roles",
      "name": "settings:add-user-site-roles",
      "action": "add-user-site-roles",
      "resource": "settings",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-site-roles | settings:delete-user-site-roles",
      "name": "settings:delete-user-site-roles",
      "action": "delete-user-site-roles",
      "resource": "settings",
      "license": [LicenseType.Master],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-working-site-roles",
      "name": "settings:show-user-working-site-roles",
      "action": "show-user-working-site-roles",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-working-site-roles | settings:add-user-working-site-roles",
      "name": "settings:add-user-working-site-roles",
      "action": "add-user-working-site-roles",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-working-site-roles | settings:delete-user-working-site-roles",
      "name": "settings:delete-user-working-site-roles",
      "action": "delete-user-working-site-roles",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-state",
      "name": "settings:show-user-state",
      "action": "show-user-state",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:show-users | settings:show-user-state | settings:edit-user-state",
      "name": "settings:edit-user-state",
      "action": "edit-user-state",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:edit-webhooks",
      "name": "settings:edit-webhooks",
      "action": "edit-webhooks",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "settings:edit-custom-fields",
      "name": "settings:edit-custom-fields",
      "action": "edit-custom-fields",
      "resource": "settings",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": false
    },
    {
      "id": "other:export",
      "name": "other:export",
      "action": "export",
      "resource": "other",
      "license": [LicenseType.Master, LicenseType.Standard, LicenseType.Smart],
      "enabled": true
    }
    //Settings ends
  ];

export const SitePermissions = [
  //Guardiania starts
  {
    "id": "sites:guardianship",
    "name": "sites:guardianship",
    "action": "guardianship",
    "resource": "guardianship",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Guardiania ends
  //Site details starts
  {
    "id": "sites:show-details",
    "name": "sites:show-details",
    "action": "show-details",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-details | sites:edit-details",
    "name": "sites:edit-details",
    "action": "edit-details",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-details | sites:show-state",
    "name": "sites:show-state",
    "action": "show-state",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-details | sites:show-state | sites:edit-state",
    "name": "sites:edit-state",
    "action": "edit-state",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-details | sites:show-staff",
    "name": "sites:show-staff",
    "action": "show-staff",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-details | sites:show-staff | sites:edit-staff",
    "name": "sites:edit-staff",
    "action": "edit-staff",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-settings",
    "name": "sites:show-settings",
    "action": "show-settings",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-settings | sites:edit-general-settings",
    "name": "sites:edit-general-settings",
    "action": "edit-general-settings",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-settings | sites:edit-users-role",
    "name": "sites:edit-users-role",
    "action": "edit-users-role",
    "resource": "details",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site details ends
  //General docs starts
  {
    "id": "sites:show-general-docs",
    "name": "sites:show-general-docs",
    "action": "show-general-docs",
    "resource": "documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-general-docs | sites:add-general-docs",
    "name": "sites:add-general-docs",
    "action": "add-general-docs",
    "resource": "documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-general-docs | sites:edit-general-docs",
    "name": "sites:edit-general-docs",
    "action": "edit-general-docs",
    "resource": "documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-general-docs | sites:delete-general-docs",
    "name": "sites:delete-general-docs",
    "action": "delete-general-docs",
    "resource": "documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //General docs ends
  //My self as supplier starts
  {
    "id": "sites:show-owner-req",
    "name": "sites:show-owner-req",
    "action": "show-owner-req",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:add-owner-req",
    "name": "sites:add-owner-req",
    "action": "add-owner-req",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:edit-owner-req",
    "name": "sites:edit-owner-req",
    "action": "edit-owner-req",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:delete-owner-req",
    "name": "sites:delete-owner-req",
    "action": "delete-owner-req",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:download-owner-req",
    "name": "sites:download-owner-req",
    "action": "download-owner-req",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:show-owner-req-evaluation",
    "name": "sites:show-owner-req-evaluation",
    "action": "show-owner-req-evaluation",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:show-owner-req-evaluation | sites:eval-owner-req",
    "name": "sites:eval-owner-req",
    "action": "eval-owner-req",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:edit-owner-variant",
    "name": "sites:edit-owner-variant",
    "action": "edit-owner-variant",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:owner-chat",
    "name": "sites:owner-chat",
    "action": "owner-chat",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:show-global-owner-evaluation",
    "name": "sites:show-global-owner-evaluation",
    "action": "show-global-owner-evaluation",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:show-global-owner-evaluation | sites:set-global-owner-evaluation",
    "name": "sites:set-global-owner-evaluation",
    "action": "set-global-owner-evaluation",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-owner-req | sites:set-owner-state",
    "name": "sites:set-owner-state",
    "action": "set-owner-state",
    "resource": "company-documents",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //My self as supplier ends
  //Overview evaluation starts
  {
    "id": "sites:evaluations-overview",
    "name": "sites:evaluations-overview",
    "action": "evaluations-overview",
    "resource": "overview-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Overview evaluation ends
  //Site workers starts
  {
    "id": "sites:show-workers",
    "name": "sites:show-workers",
    "action": "show-workers",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:add-worker",
    "name": "sites:add-worker",
    "action": "add-worker",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:delete-worker",
    "name": "sites:delete-worker",
    "action": "delete-worker",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req",
    "name": "sites:show-worker-req",
    "action": "show-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:add-worker-req",
    "name": "sites:add-worker-req",
    "action": "add-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:edit-worker-req",
    "name": "sites:edit-worker-req",
    "action": "edit-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:delete-worker-req",
    "name": "sites:delete-worker-req",
    "action": "delete-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:download-worker-req",
    "name": "sites:download-worker-req",
    "action": "download-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:edit-worker-variant-specializations",
    "name": "sites:edit-worker-variant-specializations",
    "action": "edit-worker-variant-specializations",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:show-worker-req-evaluation",
    "name": "sites:show-worker-req-evaluation",
    "action": "show-worker-req-evaluation",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:show-worker-req-evaluation | sites:show-worker-req-evaluation-expiration",
    "name": "sites:show-worker-req-evaluation-expiration",
    "action": "show-worker-req-evaluation-expiration",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-worker-req | sites:show-worker-req-evaluation | sites:show-worker-req-evaluation-expiration | sites:eval-worker-req",
    "name": "sites:eval-worker-req",
    "action": "eval-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-global-worker-evaluation",
    "name": "sites:show-global-worker-evaluation",
    "action": "show-global-worker-evaluation",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:show-global-worker-evaluation | sites:set-global-worker-evaluation",
    "name": "sites:set-global-worker-evaluation",
    "action": "set-global-worker-evaluation",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-workers | sites:worker-chat",
    "name": "sites:worker-chat",
    "action": "worker-chat",
    "resource": "workers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site workers ends
  //Site vehicles starts
  {
    "id": "sites:show-vehicles",
    "name": "sites:show-vehicles",
    "action": "show-vehicles",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:add-vehicle",
    "name": "sites:add-vehicle",
    "action": "add-vehicle",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:delete-vehicle",
    "name": "sites:delete-vehicle",
    "action": "delete-vehicle",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req",
    "name": "sites:show-vehicle-req",
    "action": "show-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:add-vehicle-req",
    "name": "sites:add-vehicle-req",
    "action": "add-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:edit-vehicle-req",
    "name": "sites:edit-vehicle-req",
    "action": "edit-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:delete-vehicle-req",
    "name": "sites:delete-vehicle-req",
    "action": "delete-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:download-vehicle-req",
    "name": "sites:download-vehicle-req",
    "action": "download-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:edit-vehicle-variant-specializations",
    "name": "sites:edit-vehicle-variant-specializations",
    "action": "edit-vehicle-variant-specializations",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:show-vehicle-req-evaluation",
    "name": "sites:show-vehicle-req-evaluation",
    "action": "show-vehicle-req-evaluation",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:show-vehicle-req-evaluation | sites:show-vehicle-req-evaluation-expiration",
    "name": "sites:show-vehicle-req-evaluation-expiration",
    "action": "show-vehicle-req-evaluation-expiration",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-vehicle-req | sites:show-vehicle-req-evaluation | sites:show-vehicle-req-evaluation-expiration | sites:eval-vehicle-req",
    "name": "sites:eval-vehicle-req",
    "action": "eval-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-global-vehicle-evaluation",
    "name": "sites:show-global-vehicle-evaluation",
    "action": "show-global-vehicle-evaluation",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:show-global-vehicle-evaluation | sites:set-global-vehicle-evaluation",
    "name": "sites:set-global-vehicle-evaluation",
    "action": "set-global-vehicle-evaluation",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicles | sites:vehicle-chat",
    "name": "sites:vehicle-chat",
    "action": "vehicle-chat",
    "resource": "vehicles",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site vehicles ends
  //Site machines starts
  {
    "id": "sites:show-machines",
    "name": "sites:show-machines",
    "action": "show-machines",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:add-machine",
    "name": "sites:add-machine",
    "action": "add-machine",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:delete-machine",
    "name": "sites:delete-machine",
    "action": "delete-machine",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req",
    "name": "sites:show-machine-req",
    "action": "show-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:add-machine-req",
    "name": "sites:add-machine-req",
    "action": "add-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:edit-machine-req",
    "name": "sites:edit-machine-req",
    "action": "edit-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:delete-machine-req",
    "name": "sites:delete-machine-req",
    "action": "delete-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:download-machine-req",
    "name": "sites:download-machine-req",
    "action": "download-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:edit-machine-variant-specializations",
    "name": "sites:edit-machine-variant-specializations",
    "action": "edit-machine-variant-specializations",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:show-machine-req-evaluation",
    "name": "sites:show-machine-req-evaluation",
    "action": "show-machine-req-evaluation",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:show-machine-req-evaluation | sites:show-machine-req-evaluation-expiration",
    "name": "sites:show-machine-req-evaluation-expiration",
    "action": "show-machine-req-evaluation-expiration",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-machine-req | sites:show-machine-req-evaluation | sites:show-machine-req-evaluation-expiration | sites:eval-machine-req",
    "name": "sites:eval-machine-req",
    "action": "eval-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-global-machine-evaluation",
    "name": "sites:show-global-machine-evaluation",
    "action": "show-global-machine-evaluation",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:show-global-machine-evaluation | sites:set-global-machine-evaluation",
    "name": "sites:set-global-machine-evaluation",
    "action": "set-global-machine-evaluation",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machines | sites:machine-chat",
    "name": "sites:machine-chat",
    "action": "machine-chat",
    "resource": "machines",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site workers ends
  //Site tools starts
  {
    "id": "sites:show-tools",
    "name": "sites:show-tools",
    "action": "show-tools",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:add-tool",
    "name": "sites:add-tool",
    "action": "add-tool",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:delete-tool",
    "name": "sites:delete-tool",
    "action": "delete-tool",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req",
    "name": "sites:show-tool-req",
    "action": "show-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:add-tool-req",
    "name": "sites:add-tool-req",
    "action": "add-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:edit-tool-req",
    "name": "sites:edit-tool-req",
    "action": "edit-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:delete-tool-req",
    "name": "sites:delete-tool-req",
    "action": "delete-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:download-tool-req",
    "name": "sites:download-tool-req",
    "action": "download-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:edit-tool-variant-specializations",
    "name": "sites:edit-tool-variant-specializations",
    "action": "edit-tool-variant-specializations",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:show-tool-req-evaluation",
    "name": "sites:show-tool-req-evaluation",
    "action": "show-tool-req-evaluation",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:show-tool-req-evaluation | sites:show-tool-req-evaluation-expiration",
    "name": "sites:show-tool-req-evaluation-expiration",
    "action": "show-tool-req-evaluation-expiration",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-tool-req | sites:show-tool-req-evaluation | sites:show-tool-req-evaluation-expiration | sites:eval-tool-req",
    "name": "sites:eval-tool-req",
    "action": "eval-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-global-tool-evaluation",
    "name": "sites:show-global-tool-evaluation",
    "action": "show-global-tool-evaluation",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:show-global-tool-evaluation | sites:set-global-tool-evaluation",
    "name": "sites:set-global-tool-evaluation",
    "action": "set-global-tool-evaluation",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tools | sites:tool-chat",
    "name": "sites:tool-chat",
    "action": "tool-chat",
    "resource": "tools",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site tools ends
  //Site chemicals starts
  {
    "id": "sites:show-chemicals",
    "name": "sites:show-chemicals",
    "action": "show-chemicals",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:add-chemical",
    "name": "sites:add-chemical",
    "action": "add-chemical",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:delete-chemical",
    "name": "sites:delete-chemical",
    "action": "delete-chemical",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req",
    "name": "sites:show-chemical-req",
    "action": "show-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:add-chemical-req",
    "name": "sites:add-chemical-req",
    "action": "add-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:edit-chemical-req",
    "name": "sites:edit-chemical-req",
    "action": "edit-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:delete-chemical-req",
    "name": "sites:delete-chemical-req",
    "action": "delete-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:download-chemical-req",
    "name": "sites:download-chemical-req",
    "action": "download-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:edit-chemical-variant-specializations",
    "name": "sites:edit-chemical-variant-specializations",
    "action": "edit-chemical-variant-specializations",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:show-chemical-req-evaluation",
    "name": "sites:show-chemical-req-evaluation",
    "action": "show-chemical-req-evaluation",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:show-chemical-req-evaluation | sites:show-chemical-req-evaluation-expiration",
    "name": "sites:show-chemical-req-evaluation-expiration",
    "action": "show-chemical-req-evaluation-expiration",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-chemical-req | sites:show-chemical-req-evaluation | sites:show-chemical-req-evaluation-expiration | sites:eval-chemical-req",
    "name": "sites:eval-chemical-req",
    "action": "eval-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-global-chemical-evaluation",
    "name": "sites:show-global-chemical-evaluation",
    "action": "show-global-chemical-evaluation",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:show-global-chemical-evaluation | sites:set-global-chemical-evaluation",
    "name": "sites:set-global-chemical-evaluation",
    "action": "set-global-chemical-evaluation",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemicals | sites:chemical-chat",
    "name": "sites:chemical-chat",
    "action": "chemical-chat",
    "resource": "chemicals",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site chemicals ends
  //Site supplier starts
  {
    "id": "sites:show-suppliers",
    "name": "sites:show-suppliers",
    "action": "show-suppliers",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:invite-supplier",
    "name": "sites:invite-supplier",
    "action": "invite-supplier",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:delete-supplier",
    "name": "sites:delete-supplier",
    "action": "delete-supplier",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-state",
    "name": "sites:show-supplier-state",
    "action": "show-supplier-state",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-state | sites:set-supplier-state",
    "name": "sites:set-supplier-state",
    "action": "set-supplier-state",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:supplier-chat",
    "name": "sites:supplier-chat",
    "action": "supplier-chat",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-global-supplier-evaluation",
    "name": "sites:show-global-supplier-evaluation",
    "action": "show-global-supplier-evaluation",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-global-supplier-evaluation | sites:set-global-supplier-evaluation",
    "name": "sites:set-global-supplier-evaluation",
    "action": "set-global-supplier-evaluation",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:edit-supplier-variant",
    "name": "sites:edit-supplier-variant",
    "action": "edit-supplier-variant",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req",
    "name": "sites:show-supplier-company-req",
    "action": "show-supplier-company-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:add-supplier-company-req",
    "name": "sites:add-supplier-company-req",
    "action": "add-supplier-company-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:edit-supplier-company-req",
    "name": "sites:edit-supplier-company-req",
    "action": "edit-supplier-company-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:delete-supplier-company-req",
    "name": "sites:delete-supplier-company-req",
    "action": "delete-supplier-company-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:download-supplier-company-req",
    "name": "sites:download-supplier-company-req",
    "action": "download-supplier-company-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:show-supplier-company-req-evaluation",
    "name": "sites:show-supplier-company-req-evaluation",
    "action": "show-supplier-company-req-evaluation",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:show-supplier-company-req-evaluation | sites:show-supplier-company-req-evaluation-expiration",
    "name": "sites:show-supplier-company-req-evaluation-expiration",
    "action": "show-supplier-company-req-evaluation-expiration",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-company-req | sites:show-supplier-company-req-evaluation | sites:show-supplier-company-req-evaluation-expiration | sites:eval-supplier-company-req",
    "name": "sites:eval-supplier-company-req",
    "action": "eval-supplier-company-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req",
    "name": "sites:show-supplier-site-req",
    "action": "show-supplier-site-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:add-supplier-site-req",
    "name": "sites:add-supplier-site-req",
    "action": "add-supplier-site-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  ////
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:edit-supplier-site-req",
    "name": "sites:edit-supplier-site-req",
    "action": "edit-supplier-site-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:delete-supplier-site-req",
    "name": "sites:delete-supplier-site-req",
    "action": "delete-supplier-site-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:download-supplier-site-req",
    "name": "sites:download-supplier-site-req",
    "action": "download-supplier-site-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:show-supplier-site-req-evaluation",
    "name": "sites:show-supplier-site-req-evaluation",
    "action": "show-supplier-site-req-evaluation",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:show-supplier-site-req-evaluation | sites:show-supplier-site-req-evaluation-expiration",
    "name": "sites:show-supplier-site-req-evaluation-expiration",
    "action": "show-supplier-site-req-evaluation-expiration",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-suppliers | sites:show-supplier-site-req | sites:show-supplier-site-req-evaluation | sites:show-supplier-site-req-evaluation-expiration | sites:eval-supplier-site-req",
    "name": "sites:eval-supplier-site-req",
    "action": "eval-supplier-site-req",
    "resource": "suppliers",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site supplier ends
  //Site requirements starts
  {
    "id": "sites:show-requirements",
    "name": "sites:show-requirements",
    "action": "show-requirements",
    "resource": "requirements",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-requirements | sites:add-requirement",
    "name": "sites:add-requirement",
    "action": "add-requirement",
    "resource": "requirements",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-requirements | sites:edit-requirement",
    "name": "sites:edit-requirement",
    "action": "edit-requirement",
    "resource": "requirements",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-requirements | sites:delete-requirement",
    "name": "sites:delete-requirement",
    "action": "delete-requirement",
    "resource": "requirements",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site requirements ends
  //Site access starts
  {
    "id": "sites:show-badges",
    "name": "sites:show-badges",
    "action": "show-badges",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:link-badge",
    "name": "sites:link-badge",
    "action": "link-badge",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:unlink-badge",
    "name": "sites:unlink-badge",
    "action": "unlink-badge",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:link-resource-to-badge",
    "name": "sites:link-resource-to-badge",
    "action": "link-resource-to-badge",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:print-badges",
    "name": "sites:print-badges",
    "action": "print-badges",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:show-badge-state",
    "name": "sites:show-badge-state",
    "action": "show-badge-state",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:show-badge-state | sites:set-badge-state",
    "name": "sites:set-badge-state",
    "action": "set-badge-state",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:show-badge-records",
    "name": "sites:show-badge-records",
    "action": "show-badge-records",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:show-badge-records | sites:add-badge-manual-record",
    "name": "sites:add-badge-manual-record",
    "action": "add-badge-manual-record",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-badges | sites:show-badge-records | sites:delete-badge-record",
    "name": "sites:delete-badge-record",
    "action": "delete-badge-record",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers",
    "name": "sites:show-physical-badge-readers",
    "action": "show-physical-badge-readers",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:create-physical-badge-reader",
    "name": "sites:create-physical-badge-reader",
    "action": "create-physical-badge-reader",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:delete-physical-badge-reader",
    "name": "sites:delete-physical-badge-reader",
    "action": "delete-physical-badge-reader",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:edit-physical-badge-reader",
    "name": "sites:edit-physical-badge-reader",
    "action": "edit-physical-badge-reader",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:show-physical-badge-reader-state",
    "name": "sites:show-physical-badge-reader-state",
    "action": "show-physical-badge-reader-state",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:show-physical-badge-reader-state | sites:set-physical-badge-reader-state",
    "name": "sites:set-physical-badge-reader-state",
    "action": "set-physical-badge-reader-state",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:show-physical-badge-reader-records",
    "name": "sites:show-physical-badge-reader-records",
    "action": "show-physical-badge-reader-records",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:show-physical-badge-reader-records | sites:add-physical-badge-reader-manual-record",
    "name": "sites:add-physical-badge-reader-manual-record",
    "action": "add-physical-badge-reader-manual-record",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-physical-badge-readers | sites:show-physical-badge-reader-records | sites:delete-physical-badge-reader-record",
    "name": "sites:delete-physical-badge-reader-record",
    "action": "delete-physical-badge-reader-record",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers",
    "name": "sites:show-virtual-badge-readers",
    "action": "show-virtual-badge-readers",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:create-virtual-badge-reader",
    "name": "sites:create-virtual-badge-reader",
    "action": "create-virtual-badge-reader",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:delete-virtual-badge-reader",
    "name": "sites:delete-virtual-badge-reader",
    "action": "delete-virtual-badge-reader",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:edit-virtual-badge-reader",
    "name": "sites:edit-virtual-badge-reader",
    "action": "edit-virtual-badge-reader",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:show-virtual-badge-reader-state",
    "name": "sites:show-virtual-badge-reader-state",
    "action": "show-virtual-badge-reader-state",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:show-virtual-badge-reader-state | sites:set-virtual-badge-reader-state",
    "name": "sites:set-virtual-badge-reader-state",
    "action": "set-virtual-badge-reader-state",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:show-virtual-badge-reader-records",
    "name": "sites:show-virtual-badge-reader-records",
    "action": "show-virtual-badge-reader-records",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:show-virtual-badge-reader-records | sites:add-virtual-badge-reader-manual-record",
    "name": "sites:add-virtual-badge-reader-manual-record",
    "action": "add-virtual-badge-reader-manual-record",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-virtual-badge-readers | sites:show-virtual-badge-reader-records | sites:delete-virtual-badge-reader-record",
    "name": "sites:delete-virtual-badge-reader-record",
    "action": "delete-virtual-badge-reader-record",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:view-clocking-history-exactly",
    "name": "sites:view-clocking-history-exactly",
    "action": "view-clocking-history-exactly",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:view-clocking-history-rounded",
    "name": "sites:view-clocking-history-rounded",
    "action": "view-clocking-history-rounded",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:view-clocking-history-total",
    "name": "sites:view-clocking-history-total",
    "action": "view-clocking-history-total",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:view-clocking-history-day",
    "name": "sites:view-clocking-history-day",
    "action": "view-clocking-history-day",
    "resource": "access",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Site access ends
  //Pending evaluations starts
  {
    "id": "sites:show-company-pending-evaluations",
    "name": "sites:show-company-pending-evaluations",
    "action": "show-company-pending-evaluations",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-company-pending-evaluations | sites:show-company-pending-evaluation-expiration",
    "name": "sites:show-company-pending-evaluation-expiration",
    "action": "show-company-pending-evaluation-expiration",
    "resource": "pending-evaluations",
  "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-company-pending-evaluations | sites:download-company-pending-req",
    "name": "sites:download-company-pending-req",
    "action": "download-company-pending-req",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-company-pending-evaluations | sites:approve-company-pending-evaluation",
    "name": "sites:approve-company-pending-evaluation",
    "action": "approve-company-pending-evaluation",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-worker-pending-evaluations",
    "name": "sites:show-worker-pending-evaluations",
    "action": "show-worker-pending-evaluations",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-worker-pending-evaluations | sites:show-worker-pending-evaluation-expiration",
    "name": "sites:show-worker-pending-evaluation-expiration",
    "action": "show-worker-pending-evaluation-expiration",
    "resource": "pending-evaluations",
  "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-worker-pending-evaluations | sites:download-worker-pending-req",
    "name": "sites:download-worker-pending-req",
    "action": "download-worker-pending-req",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-worker-pending-evaluations | sites:approve-worker-pending-evaluation",
    "name": "sites:approve-worker-pending-evaluation",
    "action": "approve-worker-pending-evaluation",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicle-pending-evaluations",
    "name": "sites:show-vehicle-pending-evaluations",
    "action": "show-vehicle-pending-evaluations",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicle-pending-evaluations | sites:show-vehicle-pending-evaluation-expiration",
    "name": "sites:show-vehicle-pending-evaluation-expiration",
    "action": "show-vehicle-pending-evaluation-expiration",
    "resource": "pending-evaluations",
  "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicle-pending-evaluations | sites:download-vehicle-pending-req",
    "name": "sites:download-vehicle-pending-req",
    "action": "download-vehicle-pending-req",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-vehicle-pending-evaluations | sites:approve-vehicle-pending-evaluation",
    "name": "sites:approve-vehicle-pending-evaluation",
    "action": "approve-vehicle-pending-evaluation",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machine-pending-evaluations",
    "name": "sites:show-machine-pending-evaluations",
    "action": "show-machine-pending-evaluations",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machine-pending-evaluations | sites:show-machine-pending-evaluation-expiration",
    "name": "sites:show-machine-pending-evaluation-expiration",
    "action": "show-machine-pending-evaluation-expiration",
    "resource": "pending-evaluations",
  "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machine-pending-evaluations | sites:download-machine-pending-req",
    "name": "sites:download-machine-pending-req",
    "action": "download-machine-pending-req",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-machine-pending-evaluations | sites:approve-machine-pending-evaluation",
    "name": "sites:approve-machine-pending-evaluation",
    "action": "approve-machine-pending-evaluation",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tool-pending-evaluations",
    "name": "sites:show-tool-pending-evaluations",
    "action": "show-tool-pending-evaluations",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tool-pending-evaluations | sites:show-tool-pending-evaluation-expiration",
    "name": "sites:show-tool-pending-evaluation-expiration",
    "action": "show-tool-pending-evaluation-expiration",
    "resource": "pending-evaluations",
  "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tool-pending-evaluations | sites:download-tool-pending-req",
    "name": "sites:download-tool-pending-req",
    "action": "download-tool-pending-req",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-tool-pending-evaluations | sites:approve-tool-pending-evaluation",
    "name": "sites:approve-tool-pending-evaluation",
    "action": "approve-tool-pending-evaluation",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemical-pending-evaluations",
    "name": "sites:show-chemical-pending-evaluations",
    "action": "show-chemical-pending-evaluations",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemical-pending-evaluations | sites:show-chemical-pending-evaluation-expiration",
    "name": "sites:show-chemical-pending-evaluation-expiration",
    "action": "show-chemical-pending-evaluation-expiration",
    "resource": "pending-evaluations",
  "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemical-pending-evaluations | sites:download-chemical-pending-req",
    "name": "sites:download-chemical-pending-req",
    "action": "download-chemical-pending-req",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-chemical-pending-evaluations | sites:approve-chemical-pending-evaluation",
    "name": "sites:approve-chemical-pending-evaluation",
    "action": "approve-chemical-pending-evaluation",
    "resource": "pending-evaluations",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Pending evaluations ends
  //Reports starts
  {
    "id": "sites:show-reports",
    "name": "sites:show-reports",
    "action": "show-reports",
    "resource": "reports",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-reports | sites:add-report",
    "name": "sites:add-report",
    "action": "add-report",
    "resource": "reports",
    "license": [LicenseType.Master],
    "enabled": false
  },
  {
    "id": "sites:show-reports | sites:delete-report",
    "name": "sites:delete-report",
    "action": "delete-report",
    "resource": "reports",
    "license": [LicenseType.Master],
    "enabled": false
  },
  //Reports ends
]

export const WorkingSitePermissions = [
  //Details
  {
    "id": "worksite:show-details",
    "name": "worksite:show-details",
    "action": "show-details",
    "resource": "details",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-details | worksite:show-general-docs",
    "name": "worksite:show-general-docs",
    "action": "show-general-docs",
    "resource": "details",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-details | worksite:show-staff",
    "name": "worksite:show-staff",
    "action": "show-staff",
    "resource": "details",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Settings
  {
    "id": "worksite:show-settings",
    "name": "worksite:show-settings",
    "action": "show-settings",
    "resource": "details",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-settings | worksite:edit-users-role",
    "name": "worksite:edit-users-role",
    "action": "edit-users-role",
    "resource": "details",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Workers
  {
    "id": "worksite:show-workers",
    "name": "worksite:show-workers",
    "action": "show-workers",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:add-worker",
    "name": "worksite:add-worker",
    "action": "add-worker",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:delete-worker",
    "name": "worksite:delete-worker",
    "action": "delete-worker",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:show-worker-req",
    "name": "worksite:show-worker-req",
    "action": "show-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:show-worker-req | worksite:add-worker-req",
    "name": "worksite:add-worker-req",
    "action": "add-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:show-worker-req | worksite:edit-worker-req",
    "name": "worksite:edit-worker-req",
    "action": "edit-worker-req",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //TODO: Download when download on working site is implemented
  // {
  //   "id": "worksite:show-workers | worksite:show-worker-req | worksite:download-worker-req",
  //   "name": "worksite:download-worker-req",
  //   "action": "download-worker-req",
  //   "resource": "workers",
  //   "license": [LicenseType.Master, LicenseType.Standard],
  //   "enabled": false
  // },
  {
    "id": "worksite:show-workers | worksite:show-worker-req | worksite:show-worker-req-evaluation",
    "name": "worksite:show-worker-req-evaluation",
    "action": "show-worker-req-evaluation",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:show-worker-req | worksite:show-worker-req-evaluation | worksite:show-worker-req-evaluation-expiration",
    "name": "worksite:show-worker-req-evaluation-expiration",
    "action": "show-worker-req-evaluation-expiration",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:show-global-worker-evaluation",
    "name": "worksite:show-global-worker-evaluation",
    "action": "show-global-worker-evaluation",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-workers | worksite:worker-chat",
    "name": "worksite:worker-chat",
    "action": "worker-chat",
    "resource": "workers",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Vehicles
  {
    "id": "worksite:show-vehicles",
    "name": "worksite:show-vehicles",
    "action": "show-vehicles",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:add-vehicle",
    "name": "worksite:add-vehicle",
    "action": "add-vehicle",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:delete-vehicle",
    "name": "worksite:delete-vehicle",
    "action": "delete-vehicle",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:show-vehicle-req",
    "name": "worksite:show-vehicle-req",
    "action": "show-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:show-vehicle-req | worksite:add-vehicle-req",
    "name": "worksite:add-vehicle-req",
    "action": "add-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:show-vehicle-req | worksite:edit-vehicle-req",
    "name": "worksite:edit-vehicle-req",
    "action": "edit-vehicle-req",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  // {
  //   "id": "worksite:show-vehicles | worksite:show-vehicle-req | worksite:download-vehicle-req",
  //   "name": "worksite:download-vehicle-req",
  //   "action": "download-vehicle-req",
  //   "resource": "vehicles",
  //   "license": [LicenseType.Master, LicenseType.Standard],
  //   "enabled": false
  // },
  {
    "id": "worksite:show-vehicles | worksite:show-vehicle-req | worksite:show-vehicle-req-evaluation",
    "name": "worksite:show-vehicle-req-evaluation",
    "action": "show-vehicle-req-evaluation",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:show-vehicle-req | worksite:show-vehicle-req-evaluation | worksite:show-vehicle-req-evaluation-expiration",
    "name": "worksite:show-vehicle-req-evaluation-expiration",
    "action": "show-vehicle-req-evaluation-expiration",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:show-global-vehicle-evaluation",
    "name": "worksite:show-global-vehicle-evaluation",
    "action": "show-global-vehicle-evaluation",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-vehicles | worksite:vehicle-chat",
    "name": "worksite:vehicle-chat",
    "action": "vehicle-chat",
    "resource": "vehicles",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Machines
  {
    "id": "worksite:show-machines",
    "name": "worksite:show-machines",
    "action": "show-machines",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:add-machine",
    "name": "worksite:add-machine",
    "action": "add-machine",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:delete-machine",
    "name": "worksite:delete-machine",
    "action": "delete-machine",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:show-machine-req",
    "name": "worksite:show-machine-req",
    "action": "show-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:show-machine-req | worksite:add-machine-req",
    "name": "worksite:add-machine-req",
    "action": "add-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:show-machine-req | worksite:edit-machine-req",
    "name": "worksite:edit-machine-req",
    "action": "edit-machine-req",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  // {
  //   "id": "worksite:show-machines | worksite:show-machine-req | worksite:download-machine-req",
  //   "name": "worksite:download-machine-req",
  //   "action": "download-machine-req",
  //   "resource": "machines",
  //   "license": [LicenseType.Master, LicenseType.Standard],
  //   "enabled": false
  // },
  {
    "id": "worksite:show-machines | worksite:show-machine-req | worksite:show-machine-req-evaluation",
    "name": "worksite:show-machine-req-evaluation",
    "action": "show-machine-req-evaluation",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:show-machine-req | worksite:show-machine-req-evaluation | worksite:show-machine-req-evaluation-expiration",
    "name": "worksite:show-machine-req-evaluation-expiration",
    "action": "show-machine-req-evaluation-expiration",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:show-global-machine-evaluation",
    "name": "worksite:show-global-machine-evaluation",
    "action": "show-global-machine-evaluation",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-machines | worksite:machine-chat",
    "name": "worksite:machine-chat",
    "action": "machine-chat",
    "resource": "machines",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Tools
  {
    "id": "worksite:show-tools",
    "name": "worksite:show-tools",
    "action": "show-tools",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:add-tool",
    "name": "worksite:add-tool",
    "action": "add-tool",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:delete-tool",
    "name": "worksite:delete-tool",
    "action": "delete-tool",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:show-tool-req",
    "name": "worksite:show-tool-req",
    "action": "show-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:show-tool-req | worksite:add-tool-req",
    "name": "worksite:add-tool-req",
    "action": "add-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:show-tool-req | worksite:edit-tool-req",
    "name": "worksite:edit-tool-req",
    "action": "edit-tool-req",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  // {
  //   "id": "worksite:show-tools | worksite:show-tool-req | worksite:download-tool-req",
  //   "name": "worksite:download-tool-req",
  //   "action": "download-tool-req",
  //   "resource": "tools",
  //   "license": [LicenseType.Master, LicenseType.Standard],
  //   "enabled": false
  // },
  {
    "id": "worksite:show-tools | worksite:show-tool-req | worksite:show-tool-req-evaluation",
    "name": "worksite:show-tool-req-evaluation",
    "action": "show-tool-req-evaluation",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:show-tool-req | worksite:show-tool-req-evaluation | worksite:show-tool-req-evaluation-expiration",
    "name": "worksite:show-tool-req-evaluation-expiration",
    "action": "show-tool-req-evaluation-expiration",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:show-global-tool-evaluation",
    "name": "worksite:show-global-tool-evaluation",
    "action": "show-global-tool-evaluation",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-tools | worksite:tool-chat",
    "name": "worksite:tool-chat",
    "action": "tool-chat",
    "resource": "tools",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Chemicals
  {
    "id": "worksite:show-chemicals",
    "name": "worksite:show-chemicals",
    "action": "show-chemicals",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:add-chemical",
    "name": "worksite:add-chemical",
    "action": "add-chemical",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:delete-chemical",
    "name": "worksite:delete-chemical",
    "action": "delete-chemical",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:show-chemical-req",
    "name": "worksite:show-chemical-req",
    "action": "show-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:show-chemical-req | worksite:add-chemical-req",
    "name": "worksite:add-chemical-req",
    "action": "add-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:show-chemical-req | worksite:edit-chemical-req",
    "name": "worksite:edit-chemical-req",
    "action": "edit-chemical-req",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  // {
  //   "id": "worksite:show-chemicals | worksite:show-chemical-req | worksite:download-chemical-req",
  //   "name": "worksite:download-chemical-req",
  //   "action": "download-chemical-req",
  //   "resource": "chemicals",
  //   "license": [LicenseType.Master, LicenseType.Standard],
  //   "enabled": false
  // },
  {
    "id": "worksite:show-chemicals | worksite:show-chemical-req | worksite:show-chemical-req-evaluation",
    "name": "worksite:show-chemical-req-evaluation",
    "action": "show-chemical-req-evaluation",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:show-chemical-req | worksite:show-chemical-req-evaluation | worksite:show-chemical-req-evaluation-expiration",
    "name": "worksite:show-chemical-req-evaluation-expiration",
    "action": "show-chemical-req-evaluation-expiration",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:show-global-chemical-evaluation",
    "name": "worksite:show-global-chemical-evaluation",
    "action": "show-global-chemical-evaluation",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-chemicals | worksite:chemical-chat",
    "name": "worksite:chemical-chat",
    "action": "chemical-chat",
    "resource": "chemicals",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  //Documents
  {
    "id": "worksite:show-req",
    "name": "worksite:show-req",
    "action": "show-req",
    "resource": "company-documents",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-req | worksite:add-req",
    "name": "worksite:add-req",
    "action": "add-req",
    "resource": "company-documents",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-req | worksite:edit-req",
    "name": "worksite:edit-req",
    "action": "edit-req",
    "resource": "company-documents",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  // {
  //   "id": "worksite:show-req | worksite:download-req",
  //   "name": "worksite:download-req",
  //   "action": "download-req",
  //   "resource": "documents",
  //   "license": [LicenseType.Master, LicenseType.Standard],
  //   "enabled": false
  // },
  {
    "id": "worksite:show-req | worksite:show-req-evaluation",
    "name": "worksite:show-req-evaluation",
    "action": "show-req-evaluation",
    "resource": "company-documents",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-req | worksite:show-global-evaluation",
    "name": "worksite:show-global-evaluation",
    "action": "show-global-evaluation",
    "resource": "company-documents",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-req | worksite:chat",
    "name": "worksite:chat",
    "action": "chat",
    "resource": "company-documents",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-subcontractors",
    "name": "worksite:show-subcontractors",
    "action": "show-subcontractors",
    "resource": "subcontractors",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
  {
    "id": "worksite:show-subcontractors | worksite:invite-subcontractor",
    "name": "worksite:invite-subcontractor",
    "action": "show-subcontractors",
    "resource": "subcontractors",
    "license": [LicenseType.Master, LicenseType.Standard],
    "enabled": false
  },
]