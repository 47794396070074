import { COLORS } from "../../assets/theme/colors";
import { TbStatusChange } from "react-icons/tb";
import ActionBarItem from "../../components/Common/ActionBarItem";
import React, {forwardRef, useState} from "react";
import { useTranslation } from "react-i18next";
import BaseModal from "../../components/Common/alerts/BaseModal";
import { Flex, Text, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import Select from "react-select";
import Typology from "../../../domain/entities/typology";
import TagSelect from "../../components/Common/TagSelect";
import { FaCircleArrowRight } from "react-icons/fa6";
import useResourceConversionHook, {ConversionType} from "../../hooks/Resources/useResourceConversionHook";
import LoadingView from "../Common/LoadingView";
import {FieldType} from "../../../domain/entities/customFields";
import {RequirementSubject} from "../../../domain/entities/requirementSubject.enum";


type ResourceConversionButtonProps = ReturnType<typeof useResourceConversionHook>

const ResourceConversionButton = ({
  hasSites,
  loading,
  openMigrationModal,
  setOpenMigrationModal,
  upsertMapRecords,
  upsertCustomFieldMapRecords,
  twinTypes,
  disabled,
  conversionBaseFields,
  conversionTwinFields,
  baseCustomFields: baseCFields,
  twinCustomFields: twinCFields,
  ...props
}: ResourceConversionButtonProps) => {
  const { baseType, conversionTarget, setConversionTarget, documentTypes, records, customFieldRecords, init } = props;
  const { t } = useTranslation(baseType+"s");
  const [step, setStep] = React.useState(0);
  const [typologyId, setTypologyId] = React.useState<string>();
  const [conflicts, setConflicts] = useState<string[]>()
  const baseCustomFields = [...conversionBaseFields.map(field => ({
    key: field,
    subject: baseType as RequirementSubject,
    type: FieldType.TEXT,
    label: t(field)
  })), ...baseCFields];

  const twinCustomFields = [...conversionTwinFields.map(field => ({
    key: field,
    subject: baseType as RequirementSubject,
    type: FieldType.TEXT,
    label: t(field, { ns: conversionTarget+"s" })
  })), ...twinCFields,];

  const handleConfirm = async () => {
    if (step === 0) {
      setConflicts(undefined);
      await upsertMapRecords(records);
      // setStep(baseCustomFields.length > 0 ? 1 : 2);
      setStep(2);

    } else if (step === 1) {
      await upsertCustomFieldMapRecords(customFieldRecords);
      setStep(2);

    } else {
      const conflicts = await props.convert({ typologyId });
      setConflicts(conflicts);
    }
  }
  const handleCancel = () =>
    (step === 0 || hasSites) ? setOpenMigrationModal(false) : setStep(step-1);

  const canContinue = () => {
    if (hasSites) {
      return false;
    }
    if (step === 0) {
      if (records.length != documentTypes.length) {
        return false;
      }
      return records.every(
        (item) => item.baseDocumentTypeId && item.twinDocumentTypeId,
      );
    }
    if (step === 1) {
      return customFieldRecords.every(
        (item) => item.baseCustomFieldKey && item.twinCustomFieldKey,
      );
    }
    if (step === 2) {
      return !!typologyId;
    }
    return true;
  };

  return (
    <>
      <ActionButton
        baseType={baseType}
        twinTypes={twinTypes}
        setOpenMigrationModal={setOpenMigrationModal}
        setConversionTarget={setConversionTarget}
        disabled={disabled}
      />
      {openMigrationModal && (
        <BaseModal
          onClose={() => {
            setStep(0);
            setTypologyId(undefined);
            setOpenMigrationModal(false);
          }}
          title={t(`migrateModal.${conversionTarget}.title`)}
          onConfirm={!hasSites && handleConfirm}
          onConfirmLabel={t(`migrateModal.${step === 2 ? "confirm" : "next"}`)}
          onConfirmDisabled={!canContinue()}
          onCancel={handleCancel}
          onCancelLabel={t(`migrateModal.${step === 0 ? "cancel" : "back"}`)}
          onCancelDisabled={!hasSites && loading}
          type={hasSites ? "warning" : undefined}
          isLoading={loading}
          avoidCloseOnOnConfirm
          disableOnClose
        >
          {hasSites ? (
            <Text variant="body1" color="textSecondary">
              {t("migrateModal.hasSites")}
            </Text>
          ) : (init ? (
            <MigrationWizard
              step={step}
              typologyId={typologyId}
              setTypologyId={setTypologyId}
              records={records}
              twinType={conversionTarget}
              conflicts={conflicts}
              baseCustomFields={baseCustomFields}
              twinCustomFields={twinCustomFields}
              {...props}
            />
          ) : (
           <LoadingView />
          ))}
        </BaseModal>
      )}
    </>
  );
};

interface ActionButtonProps {
  baseType: ConversionType;
  twinTypes: ConversionType[];
  setOpenMigrationModal: (open: boolean) => void;
  setConversionTarget: (target: ConversionType) => void,
  disabled?: boolean;
}

const ActionButton = ({baseType, twinTypes, setOpenMigrationModal, setConversionTarget, disabled}: ActionButtonProps) => {
  const { t } = useTranslation(baseType+"s");
  const Button = forwardRef<HTMLButtonElement>((props, ref) => (
    <ActionBarItem
      ref={ref}
      icon={TbStatusChange}
      description={t(twinTypes.length === 1 ? `migrate.${twinTypes[0]}` : "migrateSelect")}
      disabledDescription={t("noMigrationResourcesSelected", { ns: "sites" })}
      onClick={() => twinTypes.length === 1 && setOpenMigrationModal(true)}
      isDisabled={disabled}
      {...props}
    />
  ));
  Button.displayName = "ActionButtonInner";
  return twinTypes.length === 1 ? <Button /> : (
    <Menu>
      <MenuButton as={Button} />
      <MenuList>
        {twinTypes.map((twinType) => (
          <MenuItem key={twinType} value={twinType} onClick={() => {
            setConversionTarget(twinType);
          }}>
            {t(`migrate.${twinType}`)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

interface MigrationWizardProps {
  targetTypologies: Typology[];
  step: number;
  baseType: ConversionType;
  twinType: ConversionType;
  typologyId?: string;
  setTypologyId: (typologyId?: string) => void;
  conflicts?: string[];
  isMulti: boolean;
  documentTypes: ResourceConversionButtonProps["documentTypes"];
  records: ResourceConversionButtonProps["records"];
  setRecords: ResourceConversionButtonProps["setRecords"];
  targetDocumentTypes: ResourceConversionButtonProps["documentTypes"];
  baseCustomFields: ResourceConversionButtonProps["baseCustomFields"];
  twinCustomFields: ResourceConversionButtonProps["twinCustomFields"];
  customFieldRecords: ResourceConversionButtonProps["customFieldRecords"];
  setCustomFieldRecords: ResourceConversionButtonProps["setCustomFieldRecords"];
}

const MigrationWizard = ({
  targetTypologies,
  step,
  baseType,
  twinType,
  setTypologyId,
  typologyId,
  documentTypes,
  targetDocumentTypes,
  records,
  setRecords,
  baseCustomFields,
  twinCustomFields,
  customFieldRecords,
  setCustomFieldRecords,
  conflicts,
  isMulti
}: MigrationWizardProps) => {
  const { t } = useTranslation(baseType+"s");

  const typeOptions = targetDocumentTypes.map((targetType) => ({
    value: targetType.id,
    label: targetType.name,
  }));

  const customFieldTypeOptions = twinCustomFields.map((targetType) => ({
    value: targetType.key,
    label: targetType.label,
  }));

  const handleSelectType = (originTypeId: string, targetTypeId: string) => {
    setRecords(
      records.map((record) => {
        if (record.baseDocumentTypeId === originTypeId) {
          return {
            baseDocumentTypeId: originTypeId,
            twinDocumentTypeId: targetTypeId,
          };
        }
        return record;
      }),
    );
  };

  const handleSelectCustomField = (originFieldKey: string, targetFieldKey: string) => {
    setCustomFieldRecords(
      customFieldRecords.map((record) => {
        if (record.baseCustomFieldKey === originFieldKey) {
          return {
            baseCustomFieldKey: record.baseCustomFieldKey,
            twinCustomFieldKey: twinCustomFields.find(cf => cf.key === targetFieldKey)?.key,
          };
        }
        return record;
      }),
    );
  };

  const getValue = (originTypeId: string) => {
    return typeOptions.find(
      (o) =>
        o.value ===
        records.find((r) => r.baseDocumentTypeId === originTypeId)
          ?.twinDocumentTypeId,
    )
  };

  const getCfValue = (originCfKey: string) => {
    return customFieldTypeOptions.find(
      (o) =>
        o.value ===
        customFieldRecords.find((r) => r.baseCustomFieldKey === originCfKey)
          ?.twinCustomFieldKey,
    )
  };

  const selectedTargetIds = records.map((r) => r.twinDocumentTypeId);
  const selectedTargetCfKeys = customFieldRecords.map((r) => r.twinCustomFieldKey);

  if (step === 0)
    return (
      <div>
        <Text variant="body1" color="textSecondary">
          {t(`migrateModal.${twinType}.${isMulti ? "introMulti" : "intro"}`)}
        </Text>
        {documentTypes.map((documentType) => (
          <Flex
            gap={5}
            key={documentType.id}
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            <Text variant="body1" color="textSecondary">
              {documentType.name}
            </Text>
            <Flex
              gap={5}
              justifyContent="space-between"
              alignItems="center"
            >
              <FaCircleArrowRight color={COLORS.sikuroBlue} fontSize="20px" />
              <Select
                options={typeOptions.filter(
                  (o) => !selectedTargetIds.includes(o.value),
                )}
                onChange={(option) =>
                  handleSelectType(documentType.id, option?.value)
                }
                value={getValue(documentType.id)}
                name="document-type-id"
                menuShouldScrollIntoView={false}
                menuPosition="fixed"
                menuPlacement={"auto"}
                placeholder={t("migrateModal.documentPlaceholder")}
                isClearable
                styles={{
                  control: (styles) => ({
                    ...styles,
                    width: "300px",
                    fontSize: "14px",
                    borderRadius: 2,
                  }),
                  noOptionsMessage: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    ":hover": {
                      backgroundColor: "rgba(0,95,250,0.25)",
                    },
                  }),
                }}
              />
            </Flex>
          </Flex>
        ))}
      </div>
    );

  if (step === 1)
    return (
      <div>
        <Text variant="body1" color="textSecondary">
          {t(`migrateModal.${twinType}.cfIntro`)}
        </Text>
        {baseCustomFields.map((cf) => (
          <Flex
            gap={5}
            key={cf.key}
            justifyContent="space-between"
            alignItems="center"
            my={5}
          >
            <Text variant="body1" color="textSecondary">
              {cf.label}
            </Text>
            <Flex
              gap={5}
              key={cf.key}
              justifyContent="space-between"
              alignItems="center"
            >
              <FaCircleArrowRight color={COLORS.sikuroBlue} fontSize="20px" />
              <Select
                options={customFieldTypeOptions.filter(
                  (o) => !selectedTargetCfKeys.includes(o.value),
                )}
                onChange={(option) =>
                  handleSelectCustomField(cf.key, option?.value)
                }
                value={getCfValue(cf.key)}
                name="custom-field-key"
                menuShouldScrollIntoView={false}
                menuPosition="fixed"
                menuPlacement={"auto"}
                placeholder={t("customFields.errors.noFieldsAvailable", {ns: "settings"})}
                isClearable
                styles={{
                  control: (styles) => ({
                    ...styles,
                    width: "300px",
                    fontSize: "14px",
                    borderRadius: 2,
                  }),
                  noOptionsMessage: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontSize: "14px",
                    ":hover": {
                      backgroundColor: "rgba(0,95,250,0.25)",
                    },
                  }),
                }}
              />
            </Flex>
          </Flex>
        ))}
      </div>
    );
  if (Array.isArray(conflicts)) {
    return (
      <div>
        <Text variant="body1" color="textSecondary" mb={2}>
          {t(`migrateModal.${twinType}.${conflicts.length > 0 ? "hasConflicts" : "noConflicts"}`)}
        </Text>
        <ul>
          {conflicts.map((conflict, index) => (
            <li key={index}>{conflict}</li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <div>
      <Text variant="body1" color="textSecondary" mb={2}>
        {t(`migrateModal.${twinType}.introFinal`)}
      </Text>
      <TagSelect
        editable
        tags={targetTypologies}
        selectedTags={[typologyId]}
        setSelectedTags={(tags) => setTypologyId(tags[0] ?? undefined)}
        defaultMenuIsOpen={false}
        isMulti={false}
        style={{ width: 300 }}
        label={t("typologies", { ns: "documents" })}
        usePortal
      />
    </div>
  );
};

export default ResourceConversionButton;
