import { useTranslation } from "react-i18next";
import BaseModal from "../../Common/alerts/BaseModal";
import React, { useState } from "react";
import { Checkbox, Tbody, Td, Th, Thead, Tr, Text } from "@chakra-ui/react";
import Site from "../../../../domain/entities/site";
import { GetResourceSitesFilter } from "../../../../domain/repositories/siteRepository";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import ColumnFilterComponent, {
  FilterComponentProps,
} from "../../Common/table/ColumnFilterComponent";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import InfiniteTable from "../../Common/table/InfiniteTable";

type CreateBadgeModalProps = {
  onClose: () => void;
  onConfirm: (siteId: string) => void;
  sites: Site[];
  updateSiteFilters: (
    field: keyof GetResourceSitesFilter,
    value: string,
  ) => void;
  siteFilters: GetResourceSitesFilter;
  siteSort: SortMeta;
  setSiteSort: (sort: SortMeta) => void;
  sitesFetchNextPage: () => void;
  sitesHasNextPage: boolean;
};
const CloneBadgeModal = ({
  onClose,
  onConfirm,
  sites,
  setSiteSort,
  siteSort,
  siteFilters,
  updateSiteFilters,
  sitesHasNextPage,
  sitesFetchNextPage,
}: CreateBadgeModalProps) => {
  const { t } = useTranslation("badges");
  const [siteId, setSiteId] = useState<string>();

  const handleCreate = async () => {
    if (!siteId) {
      throw new Error("Site is required");
    } else await onConfirm(siteId);
  };

  const columns: {
    field: keyof GetResourceSitesFilter;
    type: FilterComponentProps<null>["type"];
    options?: Record<string, string>;
  }[] = [
    { field: "name", type: "text" },
    { field: "protocol", type: "text" },
  ];

  return (
    <BaseModal
      title={t("cloneTitle")}
      onClose={onClose}
      onConfirm={handleCreate}
    >
      <Text mb={2}>{t("cloneSubtitle")}</Text>
      <InfiniteTable
        autosize
        tableId="clone-badge-modal-table"
        infiniteScroll={{
          dataLength: sites.length,
          hasNextPage: sitesHasNextPage,
          fetchNextPage: sitesFetchNextPage,
        }}
        showEmptyText={sites?.length === 0}
        id="table-container-modal"
      >
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={column.field}>
                <TableColumnHeader
                  text={t(`columns.${column.field}`)}
                  filter={{
                    component: (
                      <ColumnFilterComponent
                        type={column.type}
                        value={siteFilters[column.field]}
                        updateFilter={(value) =>
                          updateSiteFilters(column.field, value as string)
                        }
                        namespace="badges"
                      />
                    ),
                    isActive: !!siteFilters[column.field],
                  }}
                  sort={{
                    handler: (direction) =>
                      setSiteSort({ field: column.field, direction }),
                    direction:
                      siteSort && siteSort.field === column.field
                        ? siteSort.direction
                        : null,
                  }}
                  isInModal
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {sites?.map((site) => (
            <Tr key={site.id}>
              <Td>
                <Checkbox
                  isChecked={siteId === site.id}
                  onChange={() => setSiteId(site.id)}
                >
                  {site.name}
                </Checkbox>
              </Td>
              <Td>{site.protocol}</Td>
            </Tr>
          ))}
        </Tbody>
      </InfiniteTable>
    </BaseModal>
  );
};

export default CloneBadgeModal;
