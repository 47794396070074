import { FiAlertTriangle, FiInfo, FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import { COLORS } from "../../assets/theme/colors";
import ContentLayout from "../../layout/ContentLayout";
import ActionBar from "../../components/Common/ActionBar";
import { useParams } from "react-router-dom";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { Permission } from "../../components/Permissions/Permissions";
import {
  Flex,
  Icon,
  IconButton,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useMediaQuery,
  useToast
} from "@chakra-ui/react";
import RenderIf from "../../components/Permissions/RenderIf";
import useSitesAuditsViewModel from "../../hooks/Site/useSiteAuditsViewModel";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import DeleteButton from "../../components/Common/table/DeleteButton";
import { GetAuditFilter } from "../../../domain/repositories/auditRepository";
import { AuditType } from "../../../domain/entities/audit";
import CreateAuditModal from "./CreateAuditModal";
import { formatDateBasedOnLanguage } from "../../../utils";
import { IoMdDownload } from "react-icons/io";
import { downloadFileFromURL } from "../../../infrastructure/utilities/fileDownloader";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import SearchInput from "../Common/SearchInput";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

interface Props {
  create: Permission[];
  remove: Permission[];
}

const SiteAuditsView: FC<Props> = ({ create, remove }) => {
  const { siteId } = useParams();
  const { t } = useTranslation("audit");
  const toast = useToast();


  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

  const [siteRequirementsToExclude, setSiteRequirementsToExclude] = useState<
    string[]
  >([]);
  const [companyRequirementsToExclude, setCompanyRequirementsToExclude] =
    useState<string[]>([]);
  const [workersRequirementsToExclude, setWorkersRequirementsToExclude] =
    useState<string[]>([]);
  const [machinesRequirementsToExclude, setMachinesRequirementsToExclude] =
    useState<string[]>([]);
  const [vehiclesRequirementsToExclude, setVehiclesRequirementsToExclude] =
    useState<string[]>([]);
  const [toolsRequirementsToExclude, setToolsRequirementsToExclude] = useState<
    string[]
  >([]);
  const [chemicalsRequirementsToExclude, setChemicalsRequirementsToExclude] =
    useState<string[]>([]);
  const [siteWorkersToInclude, setSiteWorkersToInclude] = useState<string[]>(
    [],
  );
  const [siteMachinesToInclude, setSiteMachinesToInclude] = useState<string[]>(
    [],
  );
  const [siteVehiclesToInclude, setSiteVehiclesToInclude] = useState<string[]>(
    [],
  );
  const [siteToolsToInclude, setSiteToolsToInclude] = useState<string[]>([]);
  const [siteChemicalsToInclude, setSiteChemicalsToInclude] = useState<
    string[]
  >([]);

  const [auditToDelete, setAuditToDelete] = useState<string>();

  const {
    audits,
    auditsFetching,
    auditsHasNextPage,
    auditsFetchNextPage,
    createAudit,
    createAuditLoading,
    deleteAudit,
    deleteAuditLoading,
    filters,
    addFilter,
    sorting,
    setSorting,
    companyRequirements,
    companyRequirementsHasNextPage,
    companyRequirementsFetchNextPage,
    companyRequirementsIsLoading,
    workersRequirements,
    workersRequirementsHasNextPage,
    workersRequirementsFetchNextPage,
    workersRequirementsIsLoading,
    machinesRequirements,
    machineRequirementsHasNextPage,
    machineRequirementsFetchNextPage,
    machinesRequirementsIsLoading,
    vehiclesRequirements,
    vehiclesRequirementsHasNextPage,
    vehiclesRequirementsFetchNextPage,
    vehiclesRequirementsIsLoading,
    toolsRequirements,
    toolsRequirementsHasNextPage,
    toolsRequirementsFetchNextPage,
    toolsRequirementsIsLoading,
    chemicalsRequirements,
    chemicalRequirementsHasNextPage,
    chemicalRequirementsFetchNextPage,
    chemicalsRequirementsIsLoading,
    siteChemicals,
    siteChemicalsHasNextPage,
    siteChemicalsFetchNextPage,
    siteChemicalsIsLoading,
    siteMachines,
    siteMachinesFetchNextPage,
    siteMachinesHasNextPage,
    siteMachinesIsLoading,
    siteTools,
    siteToolsFetchNextPage,
    siteToolsHasNextPage,
    siteToolsIsLoading,
    siteVehicles,
    siteVehiclesFetchNextPage,
    siteVehiclesHasNextPage,
    siteVehiclesIsLoading,
    siteWorkers,
    siteWorkersFetchNextPage,
    siteWorkersHasNextPage,
    siteWorkersIsLoading,
    selectableSuppliers,
    setSelectedSupplier,
    setEnableGetCompanyRequirements,
    setEnableGetWorkersRequirements,
    setEnableGetVehiclesRequirements,
    setEnableGetMachinesRequirements,
    setEnableGetToolsRequirements,
    setEnableGetChemicalsRequirements,
    setEnableGetChemicals,
    setEnableGetMachines,
    setEnableGetTools,
    setEnableGetVehicles,
    setEnableGetWorkers,
    setFilterSiteWorkers,
    setFilterSiteChemicals,
    setFilterSiteMachines,
    setFilterSiteVehicles,
    setFilterTools,
    filterSiteChemicals,
    filterSiteMachines,
    filterSiteTools,
    filterSiteVehicles,
    filterSiteWorkers,
    setSearch,
  } = useSitesAuditsViewModel(siteId);

  const downloadFile = (fileUrl: string) => {
    downloadFileFromURL(fileUrl);
  };

  const tableColumns: {
    field: keyof GetAuditFilter;
    type: "text" | "select" | "date-range";
    width: string;
    options?: Record<string, string>;
  }[] = [
    { field: "date", type: "date-range", width: "150px" },
    { field: "sequence", type: "text", width: "150px" },
    { field: "type", type: "select", width: "150px", options: AuditType },
    { field: "supplier", type: "text", width: "200px" },
  ];

  const onCreateComplete = (ok: boolean) => {
    toast({
      duration: 5000,
      isClosable: true,
      colorScheme: ok ? "green" : "red",
      icon: ok ? <FiInfo /> : <FiAlertTriangle />,
      description: ok
        ? t("auditCreationOk", { ns: "audit" })
        : t("auditCreationError", { ns: "audit" }),
    });
  };

  const handleCreateAudit = async (
    supplierId: string,
    auditType: AuditType,
  ) => {
    await createAudit({
      audit: auditType,
      supplierId: supplierId,
      siteRequirementsToExclude,
      companyRequirementsToExclude,
      workersRequirementsToExclude,
      machinesRequirementsToExclude,
      vehiclesRequirementsToExclude,
      toolsRequirementsToExclude,
      chemicalsRequirementsToExclude,
      workerResourceIds: siteWorkersToInclude,
      machineResourceIds: siteMachinesToInclude,
      vehicleResourceIds: siteVehiclesToInclude,
      toolResourceIds: siteToolsToInclude,
      chemicalResourceIds: siteChemicalsToInclude,
    })
      .then((ok) => onCreateComplete(ok))
      .catch(() => onCreateComplete(false));
    setShowCreate(false);
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={create}  check={PermissionCheck.All}>
            <ActionBarItem
              icon={FiPlus}
              description={t("createAudit", { ns: "audit" })}
              onClick={() => {
                setShowCreate(true);
              }}
            />
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        w="100%"
        bg="white"
        alignItems="start"
        flexDirection="column"
        paddingLeft={10}
        paddingTop={2}
        paddingBottom={10}
        paddingRight={4}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems="center"
          w={"100%"}
          marginTop={10}
        >
          <Text fontSize="xl" textColor={COLORS.sikuroBlue} fontWeight="bold">
            {t("auditsList", { ns: "audit" })}
          </Text>
          <SearchInput onSearch={setSearch} />
        </Flex>
        <Flex
          flexDirection={"column"}
          alignItems={"start"}
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width="calc(100vw - 180px)"
          marginTop={3}
          overflow="auto"
          position="relative"
          id="table-container"
        >
          <InfiniteTable
            autosize={true}
            bottomThreshold={100}
            infiniteScroll={{
              dataLength: audits.length,
              hasNextPage: auditsHasNextPage,
              fetchNextPage: auditsFetchNextPage,
            }}
            tableId="audits-table"
            isLoading={auditsFetching}
            emptyText={t("emptyAuditList", { ns: "audit" })}
          >
            <Thead>
              <Tr>
                {tableColumns.map((column) => (
                  <Th
                    key={column.field}
                    width={column.width}
                  >
                    <TableColumnHeader
                      text={t(`columns.${column.field}`)}
                      filter={{
                        component: (
                          <ColumnFilterComponent
                            type={column.type}
                            value={filters[column.field]}
                            updateFilter={(value) =>
                              addFilter(
                                column.field,
                                value as string | string[],
                              )
                            }
                            selectOptions={column.options}
                            namespace="audit"
                          />
                        ),
                        isActive: !!(Array.isArray(filters[column.field])
                          ? filters[column.field][1]
                          : filters[column.field]),
                      }}
                      sort={{
                        handler: (direction) =>
                          setSorting({ field: column.field, direction }),
                        direction:
                          sorting && sorting.field === column.field
                            ? sorting.direction
                            : null,
                      }}
                    />
                  </Th>
                ))}
                <Th width={120}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {audits.map((currentAudit) => (
                <Tr key={currentAudit?.id}>
                  <Td width={150}>
                    {currentAudit?.createdAt
                      ? formatDateBasedOnLanguage(currentAudit?.createdAt, true)
                      : "--/--/----"}
                  </Td>
                  <Td width={150}>{currentAudit?.sequence}</Td>
                  <Td width={150}>{t(currentAudit?.type, { ns: "audit" })}</Td>
                  <Td width={200}>{currentAudit?.supplier?.name}</Td>
                  <Td width={120}>
                    <Flex gap={"3"}>
                      <Tooltip label={t("downloadFile", { ns: "documents" })}>
                        <IconButton
                          aria-label="comment"
                          variant="ghost"
                          sx={{ color: "#767676" }}
                          fill={"gray.300"}
                          minWidth="22px"
                          onClick={() => downloadFile(currentAudit.uri)}
                        >
                          <div style={{ position: "relative" }}>
                            <Icon
                              as={IoMdDownload}
                              fontSize={"24px"}
                              marginTop={1.5}
                            />
                          </div>
                        </IconButton>
                      </Tooltip>
                      <RenderIf permissions={remove} check={PermissionCheck.All}>
                        <DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setAuditToDelete(currentAudit?.id);
                            setShowDeleteAlert(true);
                          }}
                        />
                      </RenderIf>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </Flex>

      {showCreate && (
        <CreateAuditModal
          onClose={() => setShowCreate(false)}
          onConfirm={handleCreateAudit}
          createAuditLoading={createAuditLoading}
          companyRequirements={companyRequirements}
          companyRequirementsHasNextPage={companyRequirementsHasNextPage}
          companyRequirementsFetchNextPage={companyRequirementsFetchNextPage}
          companyRequirementsIsLoading={companyRequirementsIsLoading}
          workersRequirements={workersRequirements}
          workersRequirementsHasNextPage={workersRequirementsHasNextPage}
          workersRequirementsFetchNextPage={workersRequirementsFetchNextPage}
          workersRequirementsIsLoading={workersRequirementsIsLoading}
          machinesRequirements={machinesRequirements}
          machinesRequirementsHasNextPage={machineRequirementsHasNextPage}
          machinesRequirementsFetchNextPage={machineRequirementsFetchNextPage}
          machinesRequirementsIsLoading={machinesRequirementsIsLoading}
          vehiclesRequirements={vehiclesRequirements}
          vehiclesRequirementsHasNextPage={vehiclesRequirementsHasNextPage}
          vehiclesRequirementsFetchNextPage={vehiclesRequirementsFetchNextPage}
          vehiclesRequirementsIsLoading={vehiclesRequirementsIsLoading}
          toolsRequirements={toolsRequirements}
          toolsRequirementsHasNextPage={toolsRequirementsHasNextPage}
          toolsRequirementsFetchNextPage={toolsRequirementsFetchNextPage}
          toolsRequirementsIsLoading={toolsRequirementsIsLoading}
          chemicalsRequirements={chemicalsRequirements}
          chemicalsRequirementsHasNextPage={chemicalRequirementsHasNextPage}
          chemicalsRequirementsFetchNextPage={chemicalRequirementsFetchNextPage}
          siteChemicals={siteChemicals}
          siteChemicalsHasNextPage={siteChemicalsHasNextPage}
          siteChemicalsFetchNextPage={siteChemicalsFetchNextPage}
          siteChemicalsIsLoading={siteChemicalsIsLoading}
          siteMachines={siteMachines}
          siteMachinesFetchNextPage={siteMachinesFetchNextPage}
          siteMachinesHasNextPage={siteMachinesHasNextPage}
          siteMachinesIsLoading={siteMachinesIsLoading}
          siteTools={siteTools}
          siteToolsFetchNextPage={siteToolsFetchNextPage}
          siteToolsHasNextPage={siteToolsHasNextPage}
          siteToolsIsLoading={siteToolsIsLoading}
          siteVehicles={siteVehicles}
          siteVehiclesFetchNextPage={siteVehiclesFetchNextPage}
          siteVehiclesHasNextPage={siteVehiclesHasNextPage}
          siteVehiclesIsLoading={siteVehiclesIsLoading}
          siteWorkers={siteWorkers}
          siteWorkersFetchNextPage={siteWorkersFetchNextPage}
          siteWorkersHasNextPage={siteWorkersHasNextPage}
          siteWorkersIsLoading={siteWorkersIsLoading}
          chemicalsRequirementsIsLoading={chemicalsRequirementsIsLoading}
          siteRequirementsToExclude={siteRequirementsToExclude}
          companyRequirementsToExclude={companyRequirementsToExclude}
          workersRequirementsToExclude={workersRequirementsToExclude}
          machinesRequirementsToExclude={machinesRequirementsToExclude}
          vehiclesRequirementsToExclude={vehiclesRequirementsToExclude}
          toolsRequirementsToExclude={toolsRequirementsToExclude}
          chemicalsRequirementsToExclude={chemicalsRequirementsToExclude}
          setSiteRequirementToExclude={setSiteRequirementsToExclude}
          setCompanyRequirementToExclude={setCompanyRequirementsToExclude}
          setSWorkersRequirementToExclude={setWorkersRequirementsToExclude}
          setVehiclesRequirementToExclude={setVehiclesRequirementsToExclude}
          setMachinesRequirementToExclude={setMachinesRequirementsToExclude}
          setToolsRequirementToExclude={setToolsRequirementsToExclude}
          setChemicalsRequirementToExclude={setChemicalsRequirementsToExclude}
          siteWorkersToInclude={siteWorkersToInclude}
          siteMachinesToInclude={siteMachinesToInclude}
          siteVehiclesToInclude={siteVehiclesToInclude}
          siteToolsToInclude={siteToolsToInclude}
          siteChemicalsToInclude={siteChemicalsToInclude}
          setSiteWorkersToInclude={setSiteWorkersToInclude}
          setSiteMachinesToInclude={setSiteMachinesToInclude}
          setSiteVehiclesToInclude={setSiteVehiclesToInclude}
          setSiteToolsToInclude={setSiteToolsToInclude}
          setSiteChemicalsToInclude={setSiteChemicalsToInclude}
          setFilterSiteWorkers={setFilterSiteWorkers}
          setFilterSiteChemicals={setFilterSiteChemicals}
          setFilterSiteMachines={setFilterSiteMachines}
          setFilterSiteVehicles={setFilterSiteVehicles}
          setFilterTools={setFilterTools}
          filterSiteChemicals={filterSiteChemicals}
          filterSiteMachines={filterSiteMachines}
          filterSiteTools={filterSiteTools}
          filterSiteVehicles={filterSiteVehicles}
          filterSiteWorkers={filterSiteWorkers}
          selectableSupplier={selectableSuppliers}
          setHookSelectedSupplier={setSelectedSupplier}
          setEnableGetCompanyRequirements={setEnableGetCompanyRequirements}
          setEnableGetWorkersRequirements={setEnableGetWorkersRequirements}
          setEnableGetVehiclesRequirements={setEnableGetVehiclesRequirements}
          setEnableGetMachinesRequirements={setEnableGetMachinesRequirements}
          setEnableGetToolsRequirements={setEnableGetToolsRequirements}
          setEnableGetChemicalsRequirements={setEnableGetChemicalsRequirements}
          setEnableGetChemicals={setEnableGetChemicals}
          setEnableGetMachines={setEnableGetMachines}
          setEnableGetTools={setEnableGetTools}
          setEnableGetVehicles={setEnableGetVehicles}
          setEnableGetWorkers={setEnableGetWorkers}
        />
      )}

      {showDeleteAlert && (
        <DeleteActionAlert
          isLoading={deleteAuditLoading}
          onConfirm={async () => {
            await deleteAudit({ auditId: auditToDelete });
            setShowDeleteAlert(false);
            setAuditToDelete(undefined);
          }}
          onCancel={() => setShowDeleteAlert(false)}
          mainTitle={t("warning", { ns: "common" })}
          title={t("sureToDelete", { ns: "audit" })}
          leftButtonText={t("confirm", { ns: "common" })}
          rightButtonText={t("cancel", { ns: "common" })}
          isOpen={!!showDeleteAlert}
        />
      )}
    </ContentLayout>
  );
};

export default SiteAuditsView;
