import { NavItemProps } from "../../Common/NavItem";
import SubNavBar from "../../Common/SubNavBar";
import { useParams } from "react-router-dom";
import WorkerIcon from "../../Common/icons/WorkerIcon";
import VehicleIcon from "../../Common/icons/VehicleIcon";
import MachineIcon from "../../Common/icons/MachineIcon";
import ToolIcon from "../../Common/icons/ToolIcon";
import ChemicalIcon from "../../Common/icons/ChemicalIcon";
import { Permission } from "../../Permissions/Permissions";

const WorkingSiteResourcesSubNav = () => {
  const { siteId } = useParams();

  const items: NavItemProps[] = [
    {
      Icon: WorkerIcon,
      path: `/workingsites/${siteId}/resources/workers`,
      name: "Lavoratori",
      permissions: [Permission.Worksite_ShowWorkers],
    },
    {
      Icon: VehicleIcon,
      path: `/workingsites/${siteId}/resources/vehicles`,
      name: "Veicoli",
      permissions: [Permission.Worksite_ShowVehicles],
    },
    {
      Icon: MachineIcon,
      path: `/workingsites/${siteId}/resources/machines`,
      name: "Macchinari",
      permissions: [Permission.Worksite_ShowMachines],
    },
    {
      Icon: ToolIcon,
      path: `/workingsites/${siteId}/resources/tools`,
      name: "Attrezzature",
      permissions: [Permission.Worksite_ShowTools],
    },
    {
      Icon: ChemicalIcon,
      path: `/workingsites/${siteId}/resources/chemicals`,
      name: "P. Chimici",
      permissions: [Permission.Worksite_ShowChemicals],
    },
  ];

  return <SubNavBar items={items} />;
};

export default WorkingSiteResourcesSubNav;
