import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useCompanyRequirementsGroupsViewModel } from "../../../hooks/Company/useCompanyRequirementsGroupViewModel";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";
import TopBar, { TopBarItem } from "../../Common/TopBar";
import { getSectionPermissions, Permission } from "../../Permissions/Permissions";

const CompanyTopBar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { id } = useParams();
  const { requirementsGroups } = useCompanyRequirementsGroupsViewModel();

  const findRequirementGroupName = (id: string) => {
    const requirementGroup = requirementsGroups.find((p) => p.id === id);
    return requirementGroup ? requirementGroup.name : null;
  };

  const isRequirementPage = location.pathname.includes("company/requirements/");
  const pageTitle = isRequirementPage
    ? findRequirementGroupName(id)
    : t('exportSections.myCompany', {ns: 'common'});
  const backPath = isRequirementPage ? "/company/requirements" : "";

  const items: TopBarItem[] = [
    {
      title: t("details"),
      path: "/company/records",
      permissions: [Permission.Company_ShowDetails, Permission.Company_ShowStaff],
    },
    {
      title: t("generalDocuments", { ns: "companies" }),
      path: "/company/general-documents",
      permissions: [Permission.Company_ShowCompanyDocs]
    },
    {
      title: t("navbar.title", { ns: "documentsPresets" }),
      path: "/company/documents-presets",
      permissions: [Permission.Company_ShowPreset]
    },
    {
      title: t("docTypes"),
      path: "/company/document-types",
      permissions: [Permission.Company_ShowDocType]
    },
    {
      title: t("requirementGroups"),
      path: "/company/requirements",
      permissions: [Permission.Company_ShowReqGroup]
    },
    {
      title: t("badge"),
      path: "/company/badges",
      permissions: [Permission.Company_ShowBadgeDetails]
    },
  ];

  return <TopBar title={pageTitle} items={items} backPath={backPath} />;
};

export default CompanyTopBar;
