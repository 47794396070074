import FileEntity, { FileFeedback, FileFeedbackEnum } from "../../../../domain/entities/file";
import { Checkbox, Flex, Input, Text, Tooltip, useToast } from "@chakra-ui/react";
import { COLORS } from "../../../assets/theme/colors";
import { FaRegSave, FaUndo } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import RenderIf, { useHasPermissions } from "../../Permissions/RenderIf";
import { MdModeEdit } from "react-icons/md";
import { DownloadFile } from "./DownloadFile";
import CustomInputDate from "./CustomDateInput/CustomDateInput";
import { formatDateBasedOnLanguage } from "../../../../utils";
import { startOfDay } from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";
import { ResourceDocumentsPermissions } from "./SiteResourceDocuments";
import Document, { SiteResourceDocument } from "../../../../domain/entities/document";
import PendingEvaluation from "../../../../domain/entities/pending-evaluation";
import { UseDownloader } from "../../../hooks/Document/useDocumentsFilesDownloader";
import { BiDislike, BiLike, BiTrash } from "react-icons/bi";
import "./styles/likeButton.css";
import "./styles/unlikeButton.css";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

interface FileRowProps {
    file: FileEntity;
    activeFile: FileEntity;
    setActiveFile: (file: FileEntity) => void;
    editFileNameAndDate: string;
    updatedFile: { name: string; date: string };
    setEditFileNameAndDate: (value: string | undefined) => void;
    setUpdatedFile: Dispatch<
      SetStateAction<{
        name: string;
        date: string;
        feedback: FileFeedback;
      }>
    >;
    docDetailPermissions: ResourceDocumentsPermissions;
    renameModels: string[];
    type: "document" | "requirements" | "evaluation";
    document: Document | SiteResourceDocument | PendingEvaluation;
    downloader: UseDownloader;
    editFileDetails: (fileId?: string, fileName?: string, expireDate?: Date, feedbackToUpdate? : FileFeedbackEnum) => void;
    canAddFile: boolean;
    deleteFileFromDoc: (file: FileEntity) => void;
    setAction: (action: null) => void;
    siteGeneralDocument: boolean;
    selectAllFiles: boolean;
    showSelectAllFiles: boolean;
    handleCheckItem: (value: string) => void;
    fileIds: string[];
    didRenameDocument: (name: string) => void
    setShowErrorDate: (value: boolean) => void
    setInvalidDate: (value: string) => void
    showRepropagate: boolean
    editFileIsLoading?: boolean
  }

export const FileRow = ({
    file,
    activeFile,
    setActiveFile,
    editFileNameAndDate,
    updatedFile,
    setEditFileNameAndDate,
    setUpdatedFile,
    docDetailPermissions,
    document,
    downloader,
    renameModels,
    type,
    editFileDetails,
    canAddFile,
    deleteFileFromDoc,
    showSelectAllFiles,
    handleCheckItem,
    fileIds,
    didRenameDocument,
    setShowErrorDate,
    setInvalidDate,
    showRepropagate,
    editFileIsLoading,
}: FileRowProps)=>{

    const { t } = useTranslation("documents");
    const [isHovered, setIsHovered] = useState<boolean>();
    const canGiveFeedback = useHasPermissions(docDetailPermissions.evaluate);
    const isWorkingSite = location.pathname.includes("/workingsites");
    const toast = useToast();

    return (
        <>
          <Flex
            margin={1}
            border={"1px solid"}
            borderColor={activeFile?.id === file.id ? COLORS.sikuroBlue : "gray.300"}
            borderRadius="5px"
            key={file.id}
            position="relative"
            color={activeFile?.id === file.id ? "white" : "unset"}
            backgroundColor={
              (activeFile?.id === file.id || isHovered) ? COLORS.lightBlue : "unset"
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
              setActiveFile(file);
              if(editFileNameAndDate && editFileNameAndDate !== file.id) {
                setEditFileNameAndDate(undefined)
              }
            }}
            cursor={"pointer"}
          > 
            <Flex padding={2} width={"80%"} >
                {showRepropagate && docDetailPermissions.addFile && showSelectAllFiles && (
                <Checkbox
                    borderColor={"gray.500"}
                    isChecked={
                    fileIds && fileIds.length > 0
                        ? fileIds?.includes(file?.id)
                        : false
                    }
                    onChange={() => handleCheckItem(file.id)}
                    mr={2}
                />
                )}
                {editFileNameAndDate === file.id && activeFile?.id === file.id && (
                    <Flex
                        position="absolute"
                        top="0px"
                        bottom="10px"
                        right="3"
                        zIndex={1}
                    >
                        <Tooltip 
                            label={t("save", { ns: "common" })} 
                            aria-label="Save"
                            placement="top">
                            <Flex>
                                <FaRegSave
                                    className="hover-icon"
                                    onClick={() => {
                                        editFileDetails();
                                        setEditFileNameAndDate(undefined);
                                    }}
                                    fontSize="1.2rem"
                                    style={{marginTop: '7', marginRight: '8'}}
                                />
                            </Flex>
                        </Tooltip>
                        <Tooltip label={t("discardChanges", { ns: "common" })} aria-label="discard" placement="top">
                            <Flex>
                                <FaUndo
                                    className="hover-icon"
                                    onClick={() => {
                                        setEditFileNameAndDate(undefined);
                                    }}
                                    style={{marginTop: '8', fontSize:'1.1rem'}}
                                />
                            </Flex>
                        </Tooltip>
                    </Flex>
                )}
                <Flex
                flexDirection={"column"}
                gap={1}
                width={"100%"}
                position='relative'
                >
                    <>
                        {editFileNameAndDate !== file.id ? (
                            <Text color={"gray.600"}>
                            {`${file.name}.${file.ext}`}
                            </Text>
                        ) : (
                            <>
                                <Text color={"gray.600"}>{t("fileNameLabel", { ns: "documents" })}</Text>
                                <Input
                                    type="text"
                                    size={"md"}
                                    color={"gray.600"}
                                    backgroundColor="white"
                                    width={"100%"}
                                    onChange={(e) => didRenameDocument(e.target.value)}
                                    value={updatedFile?.name}
                                />
                            </>
                        )}
                    </>


                    <Text color={"gray.600"} fontSize={14}>
                        {t("expireDate")}:{" "}
                        {editFileNameAndDate !== file.id ? (
                        file.expiresAt ? (
                            formatDateBasedOnLanguage(file.expiresAt)
                        ) : (
                            "-"
                        )
                        ) : (
                        <CustomInputDate
                            value={updatedFile?.date}
                            onChange={(val) =>
                                setUpdatedFile((prev) => ({
                                    ...prev,
                                    date: val,
                                }))
                            }
                            minDate={startOfDay(new Date())}
                            maxDate={new Date("9999-12-31")}
                            hasError={setShowErrorDate}
                            setValue={setInvalidDate}
                        />
                        )}
                    </Text>
                </Flex>
            </Flex>
            {editFileNameAndDate !== file.id && !showSelectAllFiles &&  (
              <Flex flexDirection={"column"} width={"20%"}>
                <RenderIf permissions={[docDetailPermissions.edit, docDetailPermissions.downloadAll]} check={PermissionCheck.Some}>
                    <Flex align={"center"} justifyContent={"center"} height={"50%"} 
                        border="1px solid"
                        borderColor={(activeFile?.id === file.id || isHovered) ? "white" : "gray.300"}
                        borderTopRightRadius="10px"
                        borderBottomWidth="0.5px"
                        borderTopWidth="0px"
                        borderRightWidth="0px">
                        <RenderIf permissions={docDetailPermissions.edit}>
                            <Tooltip label={t("edit", { ns: "common" })} aria-label="Save" placement="auto">
                                <Flex>
                                    <MdModeEdit
                                        className="hover-icon"
                                        onClick={() => setEditFileNameAndDate(file.id)}
                                        fontSize="1.2rem"
                                        />
                                </Flex>
                            </Tooltip>

                        </RenderIf> 
                        <RenderIf permissions={docDetailPermissions.downloadAll}>
                            <DownloadFile
                                renameModels={renameModels}
                                type={type}
                                document={document}
                                downloader={downloader}
                                fileId={file.id}
                            />
                        </RenderIf>
                        <RenderIf permissions={docDetailPermissions.edit}>
                        {canAddFile && (
                            <Tooltip label={t("deleteFile", { ns: "documents" })} aria-label="Save" placement="auto">
                                <Flex>
                                    <BiTrash
                                    className="hover-icon"
                                    fontSize="1.2rem"
                                    onClick={() => deleteFileFromDoc(file)}
                                    />
                                </Flex>
                            </Tooltip>
                        )}
                        </RenderIf>
                    </Flex>
                </RenderIf>
                <RenderIf permissions={isWorkingSite ? docDetailPermissions.evaluate : docDetailPermissions.viewEvaluations}>
                    <Flex alignItems={"center"} height={"50%"}>
                        <Flex width={"50%"} 
                            height={"100%"}
                            align={"center"} 
                            justifyContent={"center"}
                            border="1px solid"
                            borderRightWidth="0.5px"
                            borderTopWidth="0.5px"
                            borderBottomWidth="0px"
                            backgroundColor={file.feedback?.type === FileFeedbackEnum.OK ? "green.300" : undefined}
                            borderColor={(activeFile?.id === file.id || isHovered) ? "white" : "gray.300"}>
                            <RenderIf permissions={ docDetailPermissions.viewEvaluations}>
                                <Tooltip label={docDetailPermissions.viewEvaluations ? 
                                t(file.feedback?.type === FileFeedbackEnum.OK ? "positiveFeedback" : "siteRequirementsDocument.eligible", 
                                    {user: file.feedback?.userName, date: formatDateBasedOnLanguage(file.feedback?.date), ns: "documents" }) : 
                                t("evaluationsDisabled", { ns: "documents" })} aria-label="Approve" placement="auto">
                                    <Flex>
                                        <BiLike
                                            className={file.feedback?.type === FileFeedbackEnum.OK ? "like-button selected" : "like-button"}
                                            onClick={() => {
                                                if (canGiveFeedback){
                                                    editFileDetails(file.id, file.name, file.expiresAt, FileFeedbackEnum.OK);
                                                }else{
                                                    toast({
                                                        id: "uploadToast",
                                                        description: t("noPermission", { ns: "documents" }),
                                                        status: "error",
                                                    });
                                                }
                                            }}
                                            fontSize="1.2rem"
                                        />
                                    </Flex>
                                </Tooltip>
                            </RenderIf> 
                        </Flex>
                        <Flex width={"50%"} 
                            height={"100%"}
                            align={"center"} justifyContent={"center"}
                            border="1px solid"
                            borderLeftWidth="0.5px"
                            borderTopWidth="0.5px"
                            borderBottomWidth="0px"
                            borderRightWidth="0px"
                            borderColor={(activeFile?.id === file.id || isHovered) ? "white" : "gray.300"}
                            backgroundColor={file.feedback?.type === FileFeedbackEnum.KO ? "red.300" : undefined}
                            borderBottomRightRadius="10px"
                            paddingTop={1}>
                            <RenderIf permissions={docDetailPermissions.viewEvaluations}>
                                <Tooltip label={docDetailPermissions.viewEvaluations ? 
                                t(file.feedback?.type === FileFeedbackEnum.KO ? "negativeFeedback" : "siteRequirementsDocument.not_eligible", 
                                    {user: file.feedback?.userName, date: formatDateBasedOnLanguage(file.feedback?.date), ns: "documents" }) :
                                t("evaluationsDisabled", { ns: "documents" })} aria-label="Disapprove" placement="auto">
                                    <Flex>
                                        <BiDislike
                                            className={file.feedback?.type === FileFeedbackEnum.KO ? "unlike-button selected" : "unlike-button"}
                                            fontSize="1.2rem"
                                            onClick={() => {
                                                if (canGiveFeedback){
                                                    editFileDetails(file.id, file.name, file.expiresAt, FileFeedbackEnum.KO);
                                                }else{
                                                    toast({
                                                        id: "uploadToast",
                                                        description: t("noPermission", { ns: "documents" }),
                                                        status: "error",
                                                    });
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Tooltip>
                            </RenderIf>
                        </Flex>
                    </Flex>
                </RenderIf> 
              </Flex>
            )}

          </Flex>
        </>
    )
}