import { useTranslation } from "react-i18next";
import TopBar from "../../Common/TopBar";
import { useParams } from "react-router-dom";
import { getSectionPermissions, Permission } from "../../Permissions/Permissions";

const SupplierTopBar = () => {
  const { t } = useTranslation("sites");
  const { supplierId } = useParams();

  return (
    <TopBar
      title={""}
      items={[
        {
          path: `/suppliers/${supplierId}/details`,
          title: t("summary"),
          permissions: getSectionPermissions("supplier", "records")
        },
        {
          path: `/suppliers/${supplierId}/resources`,
          title: t("resources"),
          permissions: [
            Permission.Supplier_ShowWorkers,
            Permission.Supplier_ShowChemicals,
            Permission.Supplier_ShowVehicles,
            Permission.Supplier_ShowTools,
            Permission.Supplier_ShowMachines,
          ],
        },
      ]}
    />
  );
};

export default SupplierTopBar;
