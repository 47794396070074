import UseCase from '../../../core/app/usecase';
import ResourceConversionRepository from "../../repositories/resourceConversionRepository";
import {
  ConversionType,
  CustomFieldMapRecordInput
} from "../../../presentation/hooks/Resources/useResourceConversionHook";

type Args = {
  companyId: string;
  baseType: ConversionType;
  twinType: ConversionType;
};

class GetCustomFieldMapRecords implements UseCase<Args, CustomFieldMapRecordInput[]> {
  constructor(private repository: ResourceConversionRepository) {}

  async Call({ companyId, baseType, twinType }: Args): Promise<CustomFieldMapRecordInput[]> {
    return await this.repository.getCustomFieldMapRecords(companyId, baseType, twinType);
  }
}

export default GetCustomFieldMapRecords;
