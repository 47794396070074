import { injectable } from 'tsyringe';
import Document from '../../domain/entities/document';
import Tool from '../../domain/entities/tool';
import ToolRepository from '../../domain/repositories/toolRepository';
import { GetDocumentsFilter } from '../../domain/repositories/documentRepository';
import { PaginatedResults, SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import { ApiService } from '../utilities/apiService';
import { dateIntervals } from '../utilities/filters';
import ImportInfo from '../../domain/entities/importInfo';
import FileEntity from '../../domain/entities/file';
import DocumentTypeWithPublic from '../../domain/entities/documentTypeWithPublic';
import { ResourceDocumentEvaluationState } from '../../domain/entities/resourceDocumentEvaluationState.enum';
import { dateToRFC3339 } from '../../utils';
import { mapApiResponseToDocument } from '../adapters/getResourceDocuments';
import { GetSitesFilter } from "../../domain/repositories/siteRepository";
import {GetResourcesFilter} from "../../domain/repositories/workerRepository";

@injectable()
class ServerToolRepository implements ToolRepository {
	constructor(private apiService: ApiService) {}

	async getToolById(companyId: string, toolId: string): Promise<Tool | undefined> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}`);
		if (!response.ok) {
			return undefined;
		}
		return await response.json();
	}

	async getTools(
		companyId: string,
		filter?: GetResourcesFilter,
		archived?: boolean,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<Tool>> {
		const params = new URLSearchParams({
			page: pageParam.toString(),
			perPage: String(25),
			...filter,
			...sort,
		});

		if (archived) {
			params.append('archived', 'true');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools?${params.toString()}`,
		);
		const tools = await response.json();
		return tools;
	}

	async restoreTool(companyId: string, toolId: string): Promise<void> {
		await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/restore`, {
			method: 'PUT',
		});
	}

	async getAvailableToolsCount(companyId: string, filter?: GetResourcesFilter, sort?: SortMeta): Promise<number> {
		const params = new URLSearchParams({
			...filter,
			...sort,
		});
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools-count?${params.toString()}`,
		);
		const tools = await response.json();
		return tools.results.length;
	}

	async createTool(companyId: string, tool: Tool, photo?: File): Promise<Tool> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (photo) {
			formData.append('photo', photo);
			appendedParameters.add('photo');
		}

		Object.keys(tool).map((parameter) => {
			if (!appendedParameters.has(parameter) && parameter !== 'photo') {
				formData.append(parameter, tool[parameter]);
				appendedParameters.add(parameter);
			}
		});

		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools`, {
			method: 'POST',
			body: formData,
		});
		return await response.json();
	}

	async uploadTools(companyId: string, file: FileEntity): Promise<ImportInfo> {
		const formData = new FormData();
		const appendedParameters = new Set();

		formData.append('file', file.binaries[0]);
		appendedParameters.add('file');
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/import`, {
			method: 'POST',
			body: formData,
		});
		return response.ok ? await response.json() : Promise.reject(new Error('error.conflict'));
	}

	async updateTool(companyId: string, parameters: Tool, imageFile?: File): Promise<Tool> {
		const formData = new FormData();
		const appendedParameters = new Set();

		if (imageFile) {
			formData.append('photo', imageFile);
			appendedParameters.add('photo');
		}

		Object.keys(parameters).map((parameter) => {
			parameters[parameter] = parameters[parameter] ?? '';
			if (!appendedParameters.has(parameter)) {
				formData.append(parameter, parameters[parameter]);
				appendedParameters.add(parameter);
			}
		});

		if (!imageFile) {
			formData.delete('photo');
		}

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${parameters.id}`,
			{
				method: 'POST',
				body: formData,
			},
		);

		return await response.json();
	}

	async deleteTool(companyId: string, toolId: string): Promise<void> {
		const response = await this.apiService.fetchWithToken(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}`, {
			method: 'DELETE',
		});
		if (!response.ok) {
			throw new Error('Failed to delete tool');
		}
	}

	async evaluateToolDocument(
		companyId: string,
		toolId: string,
		documentId: string,
		evaluationState?: ResourceDocumentEvaluationState,
		expirationDate?: Date,
		noEvaluationExpiration?: boolean,
		siteIds?: string[],
		selectAll?: boolean,
		filters?: GetSitesFilter
	): Promise<void> {
		const requestBody = {
			sitesIds: siteIds,
			selectAll,
			...(evaluationState !== null && { result: evaluationState }),
			...(expirationDate && (expirationDate !== null ? { expiresAt: dateToRFC3339(expirationDate, true) } : { expiresAt: '' })),
			noEvaluationExpiration,
		};
		for (const key in filters) {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				requestBody[key] = filters[key];
			}
		}
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/documents/${documentId}/evaluations`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(requestBody),
			},
		);
	}

	async repropagateEvaluationToolDocument(companyId: string, toolId: string, documentId: string, siteIds?: string[], selectAll?: boolean): Promise<void> {
		const requestBody = {
			sitesIds: siteIds,
			selectAll
		};
		 await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/documents/${documentId}/evaluations`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(requestBody),
			},
		);
	}

	async getToolDocuments(companyId: string, toolId: string, filter?: GetDocumentsFilter, sort?: SortMeta, pageParam?: number): Promise<Document[]> {
		const { tags, expiresAt, ...restFilter } = filter || {};
    const params = new URLSearchParams({
      ...(pageParam ? { page: pageParam.toString(), perPage: String(25) } : {}),
      ...restFilter,
      ...dateIntervals(expiresAt),
      ...sort,
    });
		tags?.length > 0 && tags.forEach((tag) => params.append('tagIds[]', tag));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/documents?${params.toString()}`,
		);
		const documents = await response.json();
		return documents.results ? Object.values(documents.results).map(mapApiResponseToDocument) : [];
	}

	async createToolDocument(companyId: string, toolId: string, documents: string[]): Promise<void> {
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documentTypesIds: documents }),
			},
		);
		return await response.json();
	}

	async createPropagableToolDocument(companyId: string, toolId: string, documents: DocumentTypeWithPublic[]): Promise<void> {
		const transformedDocuments = documents.map((doc) => ({
			documentTypeId: doc.id,
			isPublic: doc.isPublic,
		}));

		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/documents`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ documents: transformedDocuments }),
			},
		);
		return await response.json();
	}

	async addTypologyToTool(companyId: string, toolId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/typologies/${typologyId}`,
			{
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
			},
		);
	}

	async removeTypologyFromTool(companyId: string, toolId: string, typologyId: string): Promise<void> {
		await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/tools/${toolId}/typologies/${typologyId}`,
			{
				method: 'DELETE',
			},
		);
	}
}

export default ServerToolRepository;
