import { Accordion, Text, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer } from "@chakra-ui/react";
import { FC, useState } from "react";
import { FormProvider, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { vatCodeValidators } from "../../../infrastructure/utilities/validator";
import { COLORS } from "../../assets/theme/colors";
import FormInput from "../../components/Common/FormInput";
import { SYSTEM_STAFF_ROLES } from "../../hooks/Onboarding/useOnboardingViewModel";
import { SignUpCompany as SignUpCompanyType } from "../../../domain/interactors/auth/args";
import { vatCountryCodes } from "../../../constants";
import useCreateDerivedTenantViewModel from "../../hooks/Company/useCreateDerivedTenant";

interface Props {
    onCancel: () => void;
    onConfirm: () => void;
}

const CreateDerivedTenantModal: FC<Props> = ({onCancel, onConfirm}) => {

    const { t } = useTranslation("onboarding");
    const [staffIndex, setStaffIndex] = useState<number>(0);

    const handleSubmitError = (error: any) => {
      if (SYSTEM_STAFF_ROLES.some((role) => error[role])) {
        setStaffIndex(
          SYSTEM_STAFF_ROLES.indexOf(
            SYSTEM_STAFF_ROLES.find((role) => error[role])
          )
        );
      }
    };

    const {
      methods,
      createBusinessAccount,
      isLoading,
      vatCountries,
      businessTypes,
      businessSizes,
    } = useCreateDerivedTenantViewModel({onValidationError: handleSubmitError, hideModal: onConfirm});

    const selectedCountry = methods.watch("vatCountry");
    const countrySelectProps = methods.register("vatCountry");
    const selectedBusinessType = methods.watch("businessType");

    const countries = vatCountries ? vatCountryCodes(t, vatCountries) : [];

    const types = businessTypes?.map((type) => ({
      name: t("ui.companyTypes." + type.value),
      id: type.value,
    }));

    const sizes = businessSizes?.map((size) => ({
      name: t(`ui.company sizes.${size.value}`),
      id: size.value,
    }));

    return (
      <Modal isOpen onClose={onCancel} size="3xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
            <Text fontWeight={600} flexGrow={1} marginTop={5} mr={1}>
              {t("createDerivedTenant", {ns: "supplier"})}
            </Text>
        </ModalHeader>

          <ModalBody
            id="modal-body"
            zIndex={"10"} 
            overflow={"auto"} 
            overflowX={"hidden"}
          >
          <FormProvider {...methods}>
              <FormInput
                inputProps={methods.register("businessName", {
                  required: t("errors.company.name is required"),
                })}
                label={t("ui.company name")}
                isRequired
                error={methods.formState.errors.businessName?.message ?? false}
                type="text"
              />
              <FormInput
                selectProps={{
                  ...countrySelectProps,
                  onChange: (e) => {
                    countrySelectProps.onChange(e);
                    methods.setValue("vat", "");
                  },
                }}
                items={countries}
                label={t("ui.vatCountry")}
                isRequired
                type="select"
              />
              <FormInput
                inputProps={methods.register("vat", {
                  required: t("errors.vat is required"),
                  pattern: {
                    value: vatCodeValidators.get(selectedCountry),
                    message: t(`vatFormatError.${selectedCountry}`, {
                      ns: "companies",
                    }),
                  },
                })}
                label={t("ui.vat")}
                isRequired
                error={methods.formState.errors.vat?.message ?? false}
                type={selectedCountry === "IT" ? "number" : "text"}
              />
              <FormInput
                selectProps={methods.register("businessSize")}
                items={sizes}
                label={t("ui.company size")}
                isRequired
                type="select"
              />

              <FormInput
                selectProps={methods.register("businessType")}
                placeholder={t("ui.companyTypePlaceholder")}
                items={types}
                label={t("ui.companyType")}
                isRequired
                type="select"
              />
              <FormInput
                inputProps={methods.register("pec", {
                  required: t("errors.pec is required"),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t("errors.insert a valid pec"),
                  },
                })}
                label={t("ui.pec")}
                isRequired
                error={methods.formState.errors.pec?.message ?? false}
                type="email"
              />
              <FormInput
                inputProps={methods.register("ccnl", {
                  required: t("errors.company.ccnl is required"),
                })}
                label={t("ui.ccnl")}
                isRequired
                error={methods.formState.errors.ccnl?.message ?? false}
                type="text"
              />

              {selectedBusinessType !== "self_employed" &&
              <Accordion
                    allowToggle
                    index={staffIndex}
                    onChange={(index: number) => setStaffIndex(index)}
                  >
                    {SYSTEM_STAFF_ROLES.map((role) => (
                      <AccordionItem key={role} mt={2}>
                        <h2>
                          <AccordionButton>
                            <Box
                              as="span"
                              flex="1"
                              textAlign="left"
                              color={methods.formState.errors[role] && "red"}
                            >
                              {t("systemStaffRole." + role, { ns: "enum" })}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          <FormInput
                            inputProps={methods.register(
                              (role + ".name") as Path<SignUpCompanyType>,
                              {
                                required: t(`errors.staff.first name is required`),
                              },
                            )}
                            label={t("ui.first name")}
                            isRequired
                            error={methods.formState.errors[role]?.name?.message ?? false}
                            type="text"
                          />
                          <FormInput
                            inputProps={methods.register(
                              (role + ".surname") as Path<SignUpCompanyType>,
                              {
                                required: t(`errors.staff.last name is required`),
                              },
                            )}
                            label={t("ui.last name")}
                            isRequired
                            error={methods.formState.errors[role]?.surname?.message ?? false}
                            type="text"
                          />
                          <FormInput
                            inputProps={methods.register(
                              (role + ".email") as Path<SignUpCompanyType>,
                              {
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message: t("errors.insert a valid email"),
                                },
                              }
                            )}
                            label={t("ui.email")}
                            error={methods.formState.errors[role]?.email?.message ?? false}
                            type="email"
                          />
                          <FormInput
                            inputProps={methods.register(
                              (role + ".phone") as Path<SignUpCompanyType>,
                            )}
                            label={t("ui.phone")}
                            error={methods.formState.errors[role]?.phone?.message ?? false}
                            type="text"
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                }
          </FormProvider>
        </ModalBody>

        <ModalFooter zIndex={"1"}>
          <Spacer />
          <Button
            onClick={()=>createBusinessAccount()}
            bgColor={COLORS.sikuroBlue}
            size="sm"
            color="white"
            isLoading={isLoading}
          >
            {t("confirm", { ns: "common" })}
          </Button>
          <Button
            onClick={onCancel}
            bgColor={COLORS.red}
            size="sm"
            color="white"
            marginLeft={4}
            isDisabled={isLoading}
          >
            {t("cancel", { ns: "common" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
    )
}

export default CreateDerivedTenantModal;