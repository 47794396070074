import { Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import LoadingView from "../screens/Common/LoadingView";
import React, { useEffect, useState } from "react";
import VersionBanner from "../components/Common/VersionBanner";

interface LayoutConfig {
  templateAreas: string;
  rowsHeight: string;
  columnsWidth: string;
}

const getLayoutConfig = (hasAlert: boolean): LayoutConfig => ({
  templateAreas: hasAlert
    ? `
      "nav alert"
      "nav header"
      "nav main"
    `
    : `
      "nav header"
      "nav main"
    `,
  columnsWidth: "64px 1fr",
  rowsHeight: hasAlert ? "30px 56px 1fr" : "56px 1fr"
});
const MANIFEST_PATH = "/manifest.json";
const CHECK_INTERVAL = 60000;
const STORAGE_KEYS = {
  manifestVersion: "manifestVersion",
  wasRefreshed: "wasRefreshed"
} as const;

const MainLayout = ({ header, nav, children, isLoading }: MainLayoutProps) => {
  const [wasDeployedNewVersion, setWasDeployedNewVersion] = useState(false);
  const [previousVersion, setPreviousVersion] = useState<string | null>(
    localStorage.getItem(STORAGE_KEYS.manifestVersion)
  );
  const wasRefreshed = localStorage.getItem(STORAGE_KEYS.wasRefreshed) === 'true';
  const layoutConfig = getLayoutConfig(wasDeployedNewVersion);

  const handleManifestVersion = async () => {
    try {
      const response = await fetch(MANIFEST_PATH, {
        cache: "reload",
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
      if (!response.ok) {
        console.error(`Error in retrieving the manifest: ${response.status}`);
      }
      const { version } = await response.json();
      if (!version) {
        console.error('Manifest does not contain a "version" property');
        return;
      }
      const savedVersion = localStorage.getItem(STORAGE_KEYS.manifestVersion);
      if (!savedVersion) {
        localStorage.setItem(STORAGE_KEYS.manifestVersion, version);
        setPreviousVersion(version);
        return;
      }
      if (version !== savedVersion || (version === savedVersion && !wasRefreshed)) {
        setWasDeployedNewVersion(true);
        localStorage.setItem(STORAGE_KEYS.manifestVersion, version);
        localStorage.setItem(STORAGE_KEYS.wasRefreshed, 'false');
      }
    } catch (error) {
      console.error("Error fetching manifest version:", error);
    }
  };

  useEffect(() => {
    handleManifestVersion();
    const intervalId = setInterval(handleManifestVersion, CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [wasRefreshed]);

  const handleReload = async () => {
    try {
      setWasDeployedNewVersion(false);
      localStorage.setItem(STORAGE_KEYS.wasRefreshed, 'true');

      await fetch(window.location.href, {
        cache: "reload",
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      window.location.reload();
    } catch (error) {
      console.error("Error reloading page:", error);
    }
  };

  return (
    <Grid
      templateAreas={layoutConfig.templateAreas}
      gridTemplateRows={layoutConfig.rowsHeight}
      gridTemplateColumns={layoutConfig.columnsWidth}
      height="100vh"
    >
      <VersionBanner wasDeployedNewVersion={wasDeployedNewVersion} handleReload={handleReload}/>
      <GridItem zIndex={1350} bg="#32A2DB" area={"header"} overflowX={"auto"}>
        {header}
      </GridItem>
      <GridItem bg="#eece23" area={"nav"}>
        {nav}
      </GridItem>
      <GridItem bg="white" area={"main"} h="calc(100vh - 56px)">
        <div id="mainScrollArea" style={{overflowY: 'auto', height: '100%'}}>
          {isLoading ? <LoadingView /> : (children ?? <Outlet />)}
        </div>
      </GridItem>
    </Grid>
  );
};

interface MainLayoutProps {
  header: React.ReactNode;
  nav: React.ReactNode;
  children?: React.ReactNode;
  isLoading?: boolean;
}

export default MainLayout;
