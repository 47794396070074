import {
  makeGetMachineDocuments,
} from "../../../core/app/factory/machines/usecases";
import { makeGetTypologies } from "../../../core/app/factory/typologies/usecases";
import Typology from "../../../domain/entities/typology";
import DocumentType from "../../../domain/entities/documentType";
import { makeGetDocumentTypes } from "../../../core/app/factory/requirements/useCases";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import {
} from "../../../core/app/factory/documentTypes/usecases";
import {CustomField} from "../../../domain/entities/customFields";
import {makeGetCustomFields} from "../../../core/app/factory/companies/usecases";
import {ConversionType, CustomFieldMapRecordInput} from "../../hooks/Resources/useResourceConversionHook";
import {
  makeConvertResources,
  makeGetCustomFieldMapRecords,
  makeGetDocumentTypeMapRecords, makeUpsertCustomFieldMapRecords, makeUpsertDocumentTypeMapRecords
} from "../../../core/app/factory/resourceConversion/usecases";
import {
  GetDocumentTypeMapRecordsResult
} from "../../../domain/interactors/resourceConversion/getDocumentTypeMapRecords";
import {makeGetVehicleDocuments} from "../../../core/app/factory/vehicles/usecases";
import {makeGetToolDocuments} from "../../../core/app/factory/tools/usecases";

class ResourceConversionViewModel {
  private convertResourcesUseCase = makeConvertResources();// PD
  private getTypologiesUseCase = makeGetTypologies();
  private getMachineDocumentsUseCase = makeGetMachineDocuments();
  private getVehicleDocumentsUseCase = makeGetVehicleDocuments();
  private getToolDocumentsUseCase = makeGetToolDocuments();
  private getDocumentTypesUseCase = makeGetDocumentTypes();
  private getCustomFieldsUseCase = makeGetCustomFields();
  private getDocumentTypeMapRecordsUseCase = makeGetDocumentTypeMapRecords();
  private getCustomFieldMapRecordsUseCase = makeGetCustomFieldMapRecords();
  private upsertDocumentTypeMapRecordsUseCase = makeUpsertDocumentTypeMapRecords();
  private upsertCustomFieldMapRecordsUseCase = makeUpsertCustomFieldMapRecords();

  async convertResource(
    companyId: string,
    resourceIds: string[],
    typologyId: string,
    baseType: ConversionType,
    twinType: ConversionType,
    allResources = false
  ): Promise<string|string[]> {
    return await this.convertResourcesUseCase.Call({
      companyId,
      resourceIds,
      typologyId,
      baseType,
      twinType,
      allResources
    });
  }
  async getDocumentTypeMapRecords(
    companyId: string,
    baseType: ConversionType,
    twinType: ConversionType
  ): Promise<GetDocumentTypeMapRecordsResult> {
    return await this.getDocumentTypeMapRecordsUseCase.Call({
      companyId,
      baseType,
      twinType
    });
  }
  async getCustomFieldMapRecords(
    companyId: string,
    baseType: ConversionType,
    twinType: ConversionType
  ): Promise<CustomFieldMapRecordInput[]> {
    return await this.getCustomFieldMapRecordsUseCase.Call({
      companyId,
      baseType,
      twinType
    });
  }
  async upsertDocumentTypeMapRecords(
    companyId: string,
    baseType: ConversionType,
    twinType: ConversionType,
    records: { baseDocumentTypeId: string; twinDocumentTypeId: string }[],
  ): Promise<void> {
    return await this.upsertDocumentTypeMapRecordsUseCase.Call({
      companyId,
      baseType,
      twinType,
      records,
    });
  }
  async upsertCustomFieldMapRecords(
    companyId: string,
    baseType: ConversionType,
    twinType: ConversionType,
    records: { baseCustomFieldKey: string; twinCustomFieldKey: string }[],
  ): Promise<void> {
    return await this.upsertCustomFieldMapRecordsUseCase.Call({
      companyId,
      baseType,
      twinType,
      records,
    });
  }
  async getTypologies(companyId: string, type: DocumentTypeCategory): Promise<Typology[]> {
    return await this.getTypologiesUseCase.Call({
      companyId,
      type
    });
  }
  async getBaseDocumentTypes(
    companyId: string,
    baseType: ConversionType,
    resourceIds: string[],
    allResources = false
  ): Promise<DocumentType[]> {
    if (allResources || resourceIds.length > 1) {
      return await this.getDocumentTypes(companyId, baseType);
    }
    let useCase
    const resourceId = resourceIds[0];
    if (baseType === 'vehicle') {
      useCase = this.getVehicleDocumentsUseCase;
    }
    if (baseType === 'tool') {
      useCase = this.getToolDocumentsUseCase;
    }
    if (baseType === 'machine') {
      useCase = this.getMachineDocumentsUseCase;
    }
    if (!useCase) {
      throw new Error(`Invalid baseType: ${baseType}`);
    }
    const documents = await useCase.Call({
      companyId,
      resourceId,
    })
    return documents.map((document) => document.type);
  }
  async getDocumentTypes(companyId: string, subject: ConversionType): Promise<DocumentType[]> {
    return await this.getDocumentTypesUseCase.Call({
      companyId,
      filter: subject as DocumentTypeCategory,
      includePublic: true,
    });
  }
  async getCustomFields(companyId: string): Promise<CustomField[]> {
    return await this.getCustomFieldsUseCase.Call({ companyId });
  }
}

export { ResourceConversionViewModel };
