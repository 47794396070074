import Role, { RoleContext } from "../../../domain/entities/role";
import { makeGetRoles } from "../../../core/app/factory/users/usecases";
import { RolesFilter } from "../../../domain/interactors/roles/getRoles";
import {
  makeRoleLinkedUsers,
  makeCreateRole,
  makeDeleteRole,
  makeUpdateRoleName,
} from "../../../core/app/factory/roles/usecases";

class RolesListViewModel {
  private getUC = makeGetRoles();
  private createUC = makeCreateRole();
  private updateNameUC = makeUpdateRoleName();
  private deleteUC = makeDeleteRole();
  private roleLinkedUsersUC = makeRoleLinkedUsers();

  constructor(private companyId: string) {}

  // Public.
  async get(filter: RolesFilter): Promise<Role[]> {
    return await this.getUC.Call({ companyId: this.companyId, filter });
  }

  async create(name: string, context: RoleContext): Promise<Role> {
    return await this.createUC.Call({
      companyId: this.companyId,
      name,
      context,
    });
  }

  async update(roleId: string, name: string): Promise<void> {
    return await this.updateNameUC.Call({
      companyId: this.companyId,
      roleId,
      name,
    });
  }

  async roleLinkedUsers(roleId: string, context: string): Promise<number> {
    return await this.roleLinkedUsersUC.Call({
      companyId: this.companyId,
      roleId,
      context,
    });
  }

  async delete(
    roleId: string,
    contextRole: string,
    newRoleId?: string,
  ): Promise<boolean> {
    return await this.deleteUC.Call({
      companyId: this.companyId,
      roleId,
      contextRole,
      newRoleId,
    });
  }
}

export default RolesListViewModel;
