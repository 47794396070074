import { useMemo } from "react";
import Role from "../../../domain/entities/role";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useAuth } from "../../providers/Auth0JWTProvider";
import RoleDetailsViewModel from "../../viewmodels/roles/RoleDetailsViewModel";

export interface useRoleDetailsViewModelProps {
  role: Role;
  isLoading: boolean;
  isFetching: boolean;
  error: Error;
  updatePermissions: (
    args: UpdatePermissionsArgs,
    onComplete?: () => void,
  ) => Promise<void>;
  updateLoading: boolean;
}

interface UpdatePermissionsArgs {
  roleId: string;
  permissionIds: string[];
}

const useRoleDetailsViewModel = (
  roleId?: string,
): useRoleDetailsViewModelProps => {
  
  const { companyId } = useAuth();
  const viewModel = useMemo(
    () => new RoleDetailsViewModel(companyId, roleId),
    [roleId, companyId],
  );

  const {
    data: role,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery<Role, Error>(
    ["get-role", roleId],
    async () => {
      const response = await viewModel.get();
      return response;
    },
  );

  const updateMut = useMutation(
    ({ roleId, permissionIds }: UpdatePermissionsArgs) =>
      viewModel.update(roleId, permissionIds),
    { onError: (err) => console.error("cannot update role", err) },
  );

  const updatePermissions = async (
    args: UpdatePermissionsArgs,
    onComplete?: () => void,
  ) => {
    try {
      await updateMut.mutateAsync(args);
      refetch();
      onComplete?.();
    } catch {
      // Nothing to do: simple error logging already managed by upsertMut.
    }
  };

  return { role, isLoading, isFetching, error, updatePermissions, updateLoading: updateMut.isLoading };
};

export default useRoleDetailsViewModel;
