import { useTranslation } from "react-i18next";
import DocumentType, {
  RenameModel,
} from "../../../domain/entities/documentType";
import { useCallback, useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { DocumentTypesListViewModel } from "../../viewmodels/documentTypes/DocumentTypesListViewModel";
import { useAuth } from "../../providers/Auth0JWTProvider";
import TagType from "../../../domain/entities/tag";
import Tag from "../../../domain/entities/tag";
import { updateFilterWithDelete } from "../../../utils";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { GetDocumentTypeFilters } from "../../../domain/repositories/documentTypeRepository";

const useDocumentTypesListViewModels = (
  category?: DocumentTypeCategory,
  documentTypeId?: string,
) => {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const viewModel = useMemo(() => new DocumentTypesListViewModel(), []);
  const [tagRenameError, setTagRenameError] = useState<string>();
  const [enableRenameModels, setGetRenameModels] = useState<boolean>(false);
  const [filtersSystem, setFiltersSystem] = useState<GetDocumentTypeFilters>(
    {},
  );
  const [filters, setFilters] = useState<GetDocumentTypeFilters>({});
  const [sortSystem, setSortSystem] = useState<SortMeta>(null);
  const [sort, setSort] = useState<SortMeta>(null);
  const [target, setTarget] = useState<string>("system");
  const [searchSystem, setSearchSystem] = useState<string>();
  const [search, setSearch] = useState<string>();

  const updateFilter = (column: string, value: string) => {
    target === "system"
      ? updateFilterWithDelete(setFiltersSystem, column, value)
      : updateFilterWithDelete(setFilters, column, value);
  };
  
  const resetFilters = useCallback(() => {
    setFilters({});
    setFiltersSystem({});
  }, [])

  const {
    data: systemDocumentTypes,
    hasNextPage: systemDocumentTypesHasNextPage,
    fetchNextPage: systemDocumentTypesFetchNextPage,
    isLoading: systemDocumentTypesFetching,
    refetch: getSystemDocumentTypes,
  } = useInfiniteQuery<DocumentType[], Error>(
    [
      "system-document-types",
      category,
      filtersSystem,
      sortSystem,
      searchSystem,
    ],
    async ({ pageParam = 1 }) => {
      const f = searchSystem
        ? { ...filtersSystem, search: searchSystem }
        : filtersSystem;
      return await viewModel.list(
        companyId,
        f,
        sortSystem,
        true,
        category,
        pageParam,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: target === "system",
      retry: 0,
    },
  );

  const {
    data: documentTypes,
    error,
    hasNextPage: documentTypesHasNextPage,
    fetchNextPage: documentTypesFetchNextPage,
    isLoading: documentTypeFetching,
    refetch: getDocumentTypes,
  } = useInfiniteQuery<DocumentType[], Error>(
    ["document-types", category, filters, sort, search],
    async ({ pageParam = 1 }) => {
      const f = search ? { ...filters, search } : filters;
      return await viewModel.list(
        companyId,
        f,
        sort,
        false,
        category,
        pageParam,
      );
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: target === "custom",
      retry: 0,
    },
  );
  const docTypes = documentTypes?.pages?.flat() ?? [];
  const systemDocTypes = systemDocumentTypes?.pages?.flat() ?? [];

  const { data: renameModels, isLoading: renameModelsFetching } = useQuery<
    RenameModel,
    Error
  >(
    ["rename-models", documentTypeId],
    async () => {
      return await viewModel.getRenameModel(companyId, documentTypeId);
    },
    {
      enabled: enableRenameModels,
      retry: false,
    },
  );

  const upsertRenameModel = async (
    renameModels: RenameModel,
    docTypeId?: string,
  ) => {
    try {
      await upsertRenameModelMutation.mutateAsync({ renameModels, docTypeId });
    } catch (err) {
      console.error(t("errors.cannotUpdateRenameModel", { ns: "documents" }));
    }
  };

  const upsertRenameModelMutation = useMutation(
    async (params: { renameModels: RenameModel; docTypeId: string }) => {
      await viewModel.upsertRenameModel(
        companyId,
        params.docTypeId,
        params.renameModels,
      );
    },
    {
      onSuccess: async () => {
        await getAllTags();
        target === "system"
          ? await getSystemDocumentTypes()
          : await getDocumentTypes();
      },
      onError: (e) => console.error(e),
    },
  );

  const { data: allTags, refetch: getAllTags } = useQuery<TagType[], Error>(
    ["tags"],
    async () => await viewModel.getTags(companyId),
    {
      initialData: [],
    },
  );

  const createMutation = useMutation(
    async (documentType: DocumentType) => {
      const newDocType = await viewModel.create(
        companyId,
        category,
        documentType,
      );
      if (documentType.tags.length > 0) {
        for (let tag of documentType.tags) {
          if (tag.id === tag.name) {
            // Implicitly new tag.
            tag = await viewModel.createTag(companyId, tag);
          }

          await viewModel.linkTagToDocumentType(
            companyId,
            newDocType.id,
            tag.id,
          );
        }
      }

      return newDocType;
    },
    {
      onSuccess: async () => {
        await getAllTags();
        target === "system"
          ? await getSystemDocumentTypes()
          : await getDocumentTypes();
      },
      onError: (e) => console.error(e),
    },
  );

  const updateTagMutation = useMutation(
    async (tag: Tag) => {
      return await viewModel.updateTag(companyId, tag);
    },
    {
      onSuccess: async () => {
        await getAllTags();
        target === "system"
          ? await getSystemDocumentTypes()
          : await getDocumentTypes();
      },
      onError: async (e) => {
        console.error(e);
        await getAllTags();
        target === "system"
          ? await getSystemDocumentTypes()
          : await getDocumentTypes();
      },
    },
  );

  const updateMutation = useMutation(
    async (newDocumentType: DocumentType) => {
      // Retrieve old documentType data
      const oldDocumentType = docTypes.find(
        (documentType) => documentType.id === newDocumentType.id,
      );
      if (!oldDocumentType) {
        throw new Error(`DocumentType with id ${newDocumentType.id} not found`);
      }

      // Update documentType
      const updatedDocType = await viewModel.update(companyId, newDocumentType);

      // Prepare arrays of new and old tag IDs for comparison
      const oldTagIds = oldDocumentType.tags.map((tag) => tag.id);
      const newTagIds = newDocumentType.tags.map((tag) => tag.id);

      // Find added and removed tag IDs
      const addedTagIds = newTagIds.filter((id) => !oldTagIds.includes(id));
      const removedTagIds = oldTagIds.filter((id) => !newTagIds.includes(id));

      // Link newly added tags
      for (const tagId of addedTagIds) {
        let tag = newDocumentType.tags.find((tag) => tag.id === tagId);
        if (tag.id === tag.name) {
          // Implicitly new tag.
          tag = await viewModel.createTag(companyId, tag);
        }
        if (tag) {
          await viewModel.linkTagToDocumentType(
            companyId,
            updatedDocType.id,
            tag.id,
          );
        }
      }

      // Unlink removed tags
      for (const tagId of removedTagIds) {
        await viewModel.unlinkTagFromDocumentType(
          companyId,
          updatedDocType.id,
          tagId,
        );
      }

      return updatedDocType;
    },
    {
      onSuccess: async () => {
        await getAllTags();
        target === "system"
          ? await getSystemDocumentTypes()
          : await getDocumentTypes();
      },
      onError: (e) => console.error(e),
    },
  );

  const deleteMutation = useMutation(
    (documentType: DocumentType) =>
      viewModel.delete(companyId, documentType.id),
    {
      onSuccess: async () => {
        await getAllTags();
        target === "system"
          ? await getSystemDocumentTypes()
          : await getDocumentTypes();
      },
      onError: (e) => console.error(e),
    },
  );

  const createDocumentType = async (documentType: DocumentType) => {
    try {
      await createMutation.mutateAsync(documentType);
    } catch (err) {
      console.error(t("errors.cannotCreateDocumentType", { ns: "documents" }));
    }
  };

  const updateTag = async (tag: Tag) => {
    try {
      await updateTagMutation.mutateAsync(tag);
    } catch (err) {
      setTagRenameError("existingTag");
    }
  };

  const updateDocumentType = async (documentTypeToUpdate: DocumentType) => {
    try {
      await updateMutation.mutateAsync(documentTypeToUpdate);
    } catch (err) {
      console.error(t("errors.cannotUpdateDocumentType", { ns: "documents" }));
    }
  };

  const deleteDocumentType = async (documentType: DocumentType) => {
    try {
      await deleteMutation.mutateAsync(documentType);
    } catch (err) {
      console.error(t("errors.cannotDeleteDocumentType", { ns: "documents" }));
    }
  };

  return {
    error,
    documentTypes: docTypes as DocumentType[],
    documentTypesHasNextPage,
    documentTypesFetchNextPage,
    documentTypeFetching,
    getDocumentTypes,
    deleteDocumentType,
    createDocumentType,
    updateTag,
    updateDocumentType,
    allTags,
    tagRenameError,
    renameModels,
    renameModelsFetching,
    setGetRenameModels,
    upsertRenameModel,
    updateFilter,
    resetFilters,
    filters,
    filtersSystem,
    sort,
    setSort,
    sortSystem,
    setSortSystem,
    systemDocumentTypes: systemDocTypes,
    systemDocumentTypesHasNextPage,
    systemDocumentTypesFetchNextPage,
    systemDocumentTypesFetching,
    target,
    setTarget,
    setSearchSystem,
    setSearch,
  };
};

export { useDocumentTypesListViewModels };
