import { Identifiable } from './interfaces/identifiable';

export enum CommunicationAction {
	PENDING_EVALUATION_APPROVED = 'evaluation.pending.approved',
	REQUIREMENT_EVALUATION_CREATED = 'evaluation.requirement.created',
	DOCUMENT_EVALUATION_CREATED = 'evaluation.document.created',
	RESOURCE_EVALUATION_CREATED = 'evaluation.resource.created',
	DOCUMENT_UPDATED = 'document.updated',
	FILE_ADDED = 'file.added',
	FILE_REMOVED = 'file.removed',
}

export enum ButtonCommandMessage {
	MISSING_DOCUMENTS = 'commands.buttons.missing',
	CLOSE_AND_SEND = 'commands.buttons.closed',
	SENT_TRUSTEE = 'commands.buttons.trustee',
	SENT_CSE = 'commands.buttons.sentCse',
	APPROVED_CSE = 'commands.buttons.approvedCse'
}

export enum ButtonCommandDisplay {
	MISSING_DOCUMENTS = 'commands.message.missing',
	CLOSE_AND_SEND = 'commands.message.closed',
	SENT_TRUSTEE = 'commands.message.trustee',
	SENT_CSE = 'commands.message.sentCse',
	APPROVED_CSE = 'commands.message.approvedCse'
}

export enum MessageType {
	COMMAND = 'command',
	ACTION = 'action',
	COMMENT = 'comment',
	GROUP = 'action_group',
}

export type Author = Identifiable & {
	name: string;
	email: string;
	photo: string;
	lastLogin?: Date;
};

export type ActionParameter = {
	document_id?: string;
	requirement_id?: string;
	mode?: string;
	result?: string;
	file_name?: string;
	document_type_name?: string;
};

type DocumentComment = Identifiable & {
	comment: string;
	createdAt?: Date;
	parameters?: ActionParameter;
	author?: Author;
	taggedUsers?: string[];
	taggedDocuments?: string[];
	commentUrl?: string;
	type?: MessageType;
	commandKey?: string;
};

export default DocumentComment;
