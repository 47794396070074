import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiSave } from "react-icons/fi";
import { MdGroupAdd } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useApp } from "../../../app";
import { BadgeType } from "../../../domain/entities/badgeType.enum";
import SiteOptionsType from "../../../domain/entities/siteOptions";
import { AddSiteUser } from "../../../domain/repositories/siteRepository";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { ErrorBanner } from "../../components/Common/alerts/ErrorBanner";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import TagEmailInput from "../../components/Views/common/TagEmailInput";
import { WeekdaysCheckboxGroup } from "../../components/Views/common/WeekdaysCheckboxGroup";
import useSiteOptionsViewModel from "../../hooks/Site/useSiteOptionsViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { Alert } from "../Common/Alert";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import LoadingView from "../Common/LoadingView";
import { AddUsersToSiteModal } from "./AddUsersToSiteModal";
import { SelectUserSiteRole } from "./SelectUserSiteRole";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";

interface SiteOptionsViewProps {
  isWorkingSite?: boolean;
  permissions: {
    editGeneralSettings?: Permission;
    editUsersRole?: Permission;
  };
}

const SiteOptionsView = ({ isWorkingSite, permissions }: SiteOptionsViewProps) => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const { context, updateContext } = useApp();
  const {
    options,
    updateOption,
    reload,
    isLoading,
    updateScheduledEmail,
    setEmails,
    emails,
    setTime,
    time,
    setSelectedDays,
    selectedDays,
    scheduleActive,
    setScheduleActive,
    getScheduleEmailQueryRefetch,
    error,
    setError,
    setSort,
    sort,
    siteUsers,
    setEnableGetSiteUsers,
    siteUsersFilter,
    isLoadingGetSiteUsers,
    usersFetchNextPage,
    usersHasNextPage,
    updateSiteUsersFilter,
    siteAvailableUsers,
    hasNextSiteAvailableUsers,
    fetchNextSiteAvailableUsers,
    isLoadingSiteAvailableUsers,
    setEnableGetSiteAvailableUsers,
    availableUsersFilter,
    availableUsersSort,
    setAvailableUsersSort,
    updateAvailableUsersFilter,
    siteRoles,
    upsertSiteUser,
    upsertSiteUserLoading,
    deleteSiteUser,
    setCurrentUserId,
  } = useSiteOptionsViewModel(siteId, isWorkingSite);

  
  const canEditGeneralSettings = useHasPermissions([permissions.editGeneralSettings])
  const [optionsDidChange, setOptionsDidChange] = useState(false);
  const [currentOptions, setCurrentOptions] =
    useState<SiteOptionsType>(options);
  const [showCseEnabledAlert, setShowCseEnabledAlert] =
    useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showAddUser, setShowAddUser] = useState(false);
  const [roleUser, setRoleUser] = useState<{
    [key: string]: string;
  }>({});

  const handleTimeChange = (e) => {
    setOptionsDidChange(true);
    setTime(e.target.value);
  };

  const updateCseOption = (flag: boolean) => {
    if (flag) {
      setShowCseEnabledAlert(true);
      return;
    }
    updateCurrentOptions({ isEvaluationApprovalRequired: flag });
  };

  const updateCurrentOptions = (options: Partial<SiteOptionsType>) => {
    setOptionsDidChange(true);
    setCurrentOptions({ ...currentOptions, ...options });
  };

  const enableCse = () => {
    setShowCseEnabledAlert(false);
    updateCurrentOptions({ isEvaluationApprovalRequired: true });
  };

  useEffect(() => {
    if (!showAddUser) {
      setEnableGetSiteAvailableUsers(false);
    }
  }, [showAddUser]);

  const saveOptions = async () => {
    setOptionsDidChange(false);
    updateOption(currentOptions);
    updateContext({
      ...context,
      site: { ...context.site, options: currentOptions },
    });
    updateScheduledEmail();
  };

  useEffect(() => {
    if (!isWorkingSite){
      reload(); 
    }
  }, []); // Forces reload of site options on component mount.

  if (isLoading && !error) {
    return <LoadingView />;
  }

  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let i = 0; i < 24; i++) {
      const hourString = i.toString().padStart(1, "0");
      timeOptions.push({
        value: hourString,
        name: `${hourString}:00`,
      });
    }
    return timeOptions;
  };

  const timeOptions = generateTimeOptions();

  const handleSave = (params: AddSiteUser) => {
    upsertSiteUser(params);
    setShowAddUser(false);
  };


  
  const renderUserSiteOptions = () => {
    return (
      <Flex
        flexDirection="column"
        alignItems="start"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width="calc(100vw - 230px)"
        marginTop={5}
        position="relative"
        overflow="auto"
        id="table-container"
      >
        <SelectUserSiteRole
          showSelectAll={false}
          showDeleteButton={true}
          setSort={setSort}
          updateFilterUsersSite={updateSiteUsersFilter}
          sort={sort}
          filter={siteUsersFilter}
          fetchNextPage={usersFetchNextPage}
          hasNextPage={usersHasNextPage}
          isLoading={isLoadingGetSiteUsers || upsertSiteUserLoading}
          showSelectRoles={true}
          setRoleUser={setRoleUser}
          users={siteUsers}
          deleteSiteUser={deleteSiteUser}
          upsertSiteUser={upsertSiteUser}
          roles={siteRoles}
          setCurrentUser={setCurrentUserId}
          canEdit={permissions.editUsersRole}
        />
      </Flex>
    );
  };

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.editGeneralSettings}  check={PermissionCheck.All}>
          {tabIndex === 0 && !isWorkingSite && (
            <ActionBarItem
              icon={FiSave}
              onClick={saveOptions}
              description={t("saveSettings")}
            />
          )}
          </RenderIf>
          <RenderIf permissions={permissions.editUsersRole}  check={PermissionCheck.All}>
            {((tabIndex === 1 && !isWorkingSite) || isWorkingSite) && (
              <ActionBarItem
                onClick={() => (
                  setShowAddUser(true), setEnableGetSiteAvailableUsers(true)
                )}
                icon={MdGroupAdd}
                description={t("userSiteRoles.add", { ns: "settings" })}
              />
            )}
          </RenderIf>
        </ActionBar>
      }
    >
      <Box p={4}>
        {!isWorkingSite ? (
          <Tabs
            onChange={(index) => {
              if (index === 1) {
                setEnableGetSiteUsers(true);
              }
              setTabIndex(index);
            }}
            width="calc(100vw - 230px)"
          >
            <TabList>
              <Tab width="50%">{t("general", { ns: "workers" })}</Tab>
              <Tab width="50%">
                {t("exportSections.users", { ns: "common" })}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel key="general" p={0}>
                <SiteOptionsContainer title={t("options.title")}>
                  {/** Pending evaluations (CSE), requires permissions */}
                    <SiteOptionComponent
                      title={t("options.validate")}
                      property="validateEvaluations"
                      description={t("options.validateSubtitle")}
                    >
                      <Switch
                        id="validateEvaluations"
                        isChecked={
                          currentOptions?.isEvaluationApprovalRequired ??
                          options?.isEvaluationApprovalRequired
                        }
                        isDisabled={ (!options.canDisableEvaluationApproval  && !canEditGeneralSettings) || !canEditGeneralSettings}
                        onChange={(e) => {
                          localStorage.setItem(
                            "isReviewEnabled",
                            e.target.checked.toString(),
                          );
                          updateCseOption(e.target.checked);
                        }}
                      />
                    </SiteOptionComponent>
                    {(currentOptions?.isEvaluationApprovalRequired ??
                    options?.isEvaluationApprovalRequired) ? (
                      <SiteOptionComponent
                        title={t("options.globalEvaluationQuestion")}
                        property="globalEvaluation"
                      >
                        <Switch
                          id="globalEvaluation"
                          isChecked={
                            currentOptions.isEvaluationApprovalRequiredForGlobalEvaluation ??
                            options.isEvaluationApprovalRequiredForGlobalEvaluation
                          }
                          isDisabled={
                            options.canDisableEvaluationApproval == false && !canEditGeneralSettings
                          }
                          onChange={(e) => {
                            setOptionsDidChange(true);
                            updateCurrentOptions({
                              isEvaluationApprovalRequiredForGlobalEvaluation:
                                e.target.checked,
                            });
                          }}
                        />
                      </SiteOptionComponent>
                    ) : null}
                    {options.canDisableEvaluationApproval === false && (
                      <GridItem colSpan={2}>
                        <ErrorBanner
                          borderRadius="10px"
                          text={t("options.cannotDisableValidate")}
                        />
                      </GridItem>
                    )}
                  {/** Safety induction */}
                  <SiteOptionComponent
                    property="safetyInduction"
                    title={t("options.safetyInduction")}
                    description={t("options.safetyInductionSubtitle")}
                  >
                    <Switch
                      id="safetyInduction"
                      isChecked={currentOptions?.isSafetyInduction}
                      onChange={(e) => {
                        setOptionsDidChange(true);
                        updateCurrentOptions({
                          isSafetyInduction: e.target.checked,
                        });
                      }}
                      isDisabled={!canEditGeneralSettings}
                    />
                  </SiteOptionComponent>
                  {/** Badge record max interval expressed in hours */}
                  <SiteOptionComponent
                    property="badgeRecordMaxHours"
                    title={t("options.badgeRecordMaxHours")}
                    description={t("options.badgeRecordMaxHoursSubtitle")}
                  >
                    <Slider
                      flex="1"
                      min={1}
                      max={24}
                      focusThumbOnChange={false}
                      value={currentOptions?.badgeRecordMaxHours}
                      onChange={(value) => {
                        setOptionsDidChange(true);
                        updateCurrentOptions({ badgeRecordMaxHours: value });
                      }}
                      isDisabled={!canEditGeneralSettings}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb fontSize="sm" boxSize="32px">
                        {currentOptions?.badgeRecordMaxHours}
                      </SliderThumb>
                    </Slider>
                  </SiteOptionComponent>
                  {/** Safety induction additional option (on site access) */}
                  {currentOptions?.isSafetyInduction && (
                    <SiteOptionComponent
                      property="safetyInductionOnSiteAccess"
                      title={t("options.safetyInductionOnSiteAccess")}
                      description={t(
                        "options.safetyInductionOnSiteAccessSubtitle",
                      )}
                    >
                      <Switch
                        id="safetyInductionOnSiteAccess"
                        isChecked={
                          currentOptions?.isSafetyInductionOnSiteAccess
                        }
                        onChange={(e) => {
                          setOptionsDidChange(true);
                          updateCurrentOptions({
                            isSafetyInductionOnSiteAccess: e.target.checked,
                          });
                        }}
                        isDisabled={!canEditGeneralSettings}
                      />
                    </SiteOptionComponent>
                  )}
                  <SiteOptionComponent
                    property="badgeAssociations"
                    title={t("options.badgeAssociations")}
                    description={t("options.badgeAssociationsSubtitle")}
                  >
                    <Switch
                      id="badgeAssociations"
                      isChecked={currentOptions?.automaticBadgeLinking}
                      onChange={(e) => {
                        setOptionsDidChange(true);
                        updateCurrentOptions({
                          automaticBadgeLinking: e.target.checked,
                        });
                      }}
                      isDisabled={!canEditGeneralSettings}
                    />
                  </SiteOptionComponent>
                  {currentOptions?.automaticBadgeLinking && (
                    <SiteOptionComponent
                      property="badgeTypeForAutomaticLinking"
                      title={t("options.badgeTypeForAutomaticLinking")}
                      description={t(
                        "options.badgeTypeForAutomaticLinkingSubtitle",
                      )}
                    >
                      <Select
                        value={currentOptions.badgeTypeForAutomaticLinking}
                        onChange={(e) => {
                          setOptionsDidChange(true);
                          updateCurrentOptions({
                            badgeTypeForAutomaticLinking: e.target
                              .value as BadgeType,
                          });
                        }}
                        isDisabled={!canEditGeneralSettings}
                      >
                        <option value="">
                          {t("selectBadge", { ns: "badges" })}
                        </option>
                        <option value={BadgeType.QR}>{BadgeType.QR}</option>
                        <option value={BadgeType.NFC}>{BadgeType.NFC}</option>
                      </Select>
                    </SiteOptionComponent>
                  )}
                  <SiteOptionComponent
                    property="maxSupplierNesting"
                    title={t("options.maxSupplierNesting")}
                    description={t("options.maxSupplierNestingSubtitle")}
                  >
                    <NumberInput
                      value={currentOptions.maxSupplierNesting}
                      onChange={(value) => {
                        setOptionsDidChange(true);
                        updateCurrentOptions({
                          maxSupplierNesting: value as unknown as number,
                        });
                      }}
                      min={0}
                      max={10}
                      isDisabled={!canEditGeneralSettings}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </SiteOptionComponent>
                </SiteOptionsContainer>
                {optionsDidChange && (
                  <Box mt={5} mx={3}>
                    <ErrorBanner
                      borderRadius="10px"
                      text={t("unsavedChanges")}
                    />
                  </Box>
                )}
                <Box
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="10px"
                  mt={5}
                  p={4}
                >
                  <Flex justify={"flex-start"} gap={4} justifyItems={"center"}>
                    <Text fontWeight={"bold"} fontSize={18}>
                      {t("options.scheduledEmail")}
                    </Text>
                    <Switch
                      mt={1}
                      isChecked={scheduleActive}
                      onChange={(e) => {
                        setOptionsDidChange(true),
                          setScheduleActive(e.target.checked);
                      }}
                      isDisabled={!canEditGeneralSettings}
                    />
                  </Flex>
                  {scheduleActive && (
                    <>
                      <TagEmailInput
                        scheduleEmails={emails ?? []}
                        onChange={(value) => {
                          setOptionsDidChange(true), setEmails(value);
                        }}
                        isDisabled={!canEditGeneralSettings}
                      />

                      <Flex
                        justifyContent={"space-between"}
                        gap={8}
                        mt={4}
                        flexWrap="wrap"
                      >
                        <Box mt={4}>
                          <WeekdaysCheckboxGroup
                            selectedDays={selectedDays ?? {}}
                            handleChange={(value) => {
                              setOptionsDidChange(true), setSelectedDays(value);
                            }}
                            isDisabled={!canEditGeneralSettings}
                          ></WeekdaysCheckboxGroup>
                        </Box>
                        <Select
                          onChange={handleTimeChange}
                          placeholder={t("selectTime", { ns: "common" })}
                          value={`${time}`}
                          width={"30%"}
                          isDisabled={!canEditGeneralSettings}
                        >
                          {timeOptions?.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.name}
                            </option>
                          ))}
                        </Select>
                      </Flex>
                    </>
                  )}
                </Box>
              </TabPanel>
              <TabPanel key="users" p={0}>
                {renderUserSiteOptions()}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          renderUserSiteOptions()
        )}
          <AddUsersToSiteModal
            users={siteAvailableUsers}
            roles={siteRoles}
            setRoleUser={setRoleUser}
            isOpen={showAddUser}
            handleSave={handleSave}
            onClose={() => setShowAddUser(false)}
            setSort={setAvailableUsersSort}
            updateFilterUsersSite={updateAvailableUsersFilter}
            sort={availableUsersSort}
            filter={availableUsersFilter}
            hasNextPage={hasNextSiteAvailableUsers}
            isLoading={isLoadingSiteAvailableUsers}
            fetchNextPage={fetchNextSiteAvailableUsers}
          />

        {showCseEnabledAlert && (
          <ConfirmAlert
            title={t("confirmEnableCse", { context: "title" })}
            message={t("confirmEnableCse", { context: "description" })}
            onCancel={() => setShowCseEnabledAlert(false)}
            onConfirm={enableCse}
            variant="warning"
          />
        )}

        {error && (
          <Alert
            variant="warning"
            title={t("warning", { ns: "common" })}
            message={t(error, { ns: "errors" })}
            onClose={() => setError(undefined)}
          />
        )}
      </Box>
    </ContentLayout>
  );
};

interface SiteOptionComponentProps {
  title: string;
  property: string;
  description?: string;
  error?: string;
  children: JSX.Element | JSX.Element[];
}

const SiteOptionComponent: FC<SiteOptionComponentProps> = ({
  title,
  error,
  property,
  description,
  children,
}) => {
  return (
    <Box height="max-content">
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor={property} mb={0}>
          {title}
        </FormLabel>
        {children}
      </FormControl>
      <Box mt={2} fontSize="small" fontStyle="italic">
        {description}
      </Box>
      {error && (
        <Box mt={2}>
          <ErrorBanner borderRadius="10px" text={error} />
        </Box>
      )}
    </Box>
  );
};

interface SiteOptionsContainerProps {
  title: string;
  children: JSX.Element | JSX.Element[];
}

const SiteOptionsContainer: FC<SiteOptionsContainerProps> = ({
  title,
  children,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <Box
      mt={3}
      pt={3}
      pb={5}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width={isMobile ? "500px" : undefined}
    >
      <Box textStyle="h2" width="100%" marginLeft={5}>
        {title}
      </Box>
      <SimpleGrid mt={3} gap={10} p="10px 20px" width="100%" columns={2}>
        {children}
      </SimpleGrid>
    </Box>
  );
};

export default SiteOptionsView;
