import { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import RenderIf from "../../Permissions/RenderIf";
import EditButton from "../../Common/table/EditButton";
import SimpleTable from "../../Common/table/SimpleTable";
import DeleteButton from "../../Common/table/DeleteButton";
import { Permission } from "../../Permissions/Permissions";
import CreateUpdateStaffModal from "../Staff/CreateStaffModal";
import {
  Flex,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ConfirmAlert } from "../../../screens/Common/ConfirmAlert";
import Staff, { StaffType } from "../../../../domain/entities/staff";
import TableColumnHeader from "../../Common/table/TableColumnHeader";
import ColumnFilterComponent from "../../Common/table/ColumnFilterComponent";
import { SortMeta } from "../../../../domain/entities/interfaces/paginatedResults";
import { PermissionCheck } from "../../../providers/Auth0JWTProvider";

export type GetStaffFilters = {
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
  role?: string;
};

export type Sorting = {
  name?: SortMeta;
  surname?: SortMeta;
  phone?: SortMeta;
  email?: SortMeta;
  role?: SortMeta;
};

const tableColumns: {
  field: keyof GetStaffFilters;
  type: "text";
  options?: Record<string, string>;
}[] = [
  { field: "surname", type: "text" },
  { field: "name", type: "text" },
  { field: "phone", type: "text" },
  { field: "email", type: "text" },
  { field: "role", type: "text" },
];

type StaffTableViewProps = {
  staffs: Staff[];
  isFetching?: boolean;
  sorting: SortMeta;
  filters: GetStaffFilters;
  updateStaffsSorting: (sort: SortMeta) => void;
  updateStaffsFilter: (
    field: keyof GetStaffFilters,
    value: string | string[],
  ) => void;
  updateStaffElement: (staffElement: Staff) => void;
  deleteStaffElement?: (staffId: string) => void;
  staffType: StaffType;
  canEditPermissions: Permission[];
  autosize?: boolean;
  deleteStaffIsLoading: boolean;
  staffIsLoading: boolean;
  tableId: string;
  from?: string;
  deltaWith?: number;
};

const StaffTableView = memo(({
  canEditPermissions,
  staffs,
  isFetching,
  sorting,
  filters,
  updateStaffsSorting,
  updateStaffsFilter,
  updateStaffElement,
  deleteStaffElement,
  staffType,
  autosize,
  deleteStaffIsLoading,
  staffIsLoading,
  from,
  deltaWith,
  tableId
}: StaffTableViewProps) => {
  const { t } = useTranslation("staff");

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<
    false | string
  >(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [selectedStaff, setSelectedStaff] = useState<Staff>(undefined);

  const updateStaffAction = async (staffElement: Staff) => {
    await updateStaffElement(staffElement);
    setShowUpdateModal(false);
  };

  const namespace = from === "site" ? "staff" : "companies";

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"start"}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="10px"
      width={`calc(100vw - ${deltaWith}px)`}
      position="relative"
      overflow="auto"
      id={tableId}
    >
      <SimpleTable
        autosize={autosize}
        tableId={"staff-table"}
        isLoading={isFetching}
        showEmptyText={staffs?.length === 0}
        emptyText={t("noStaffElements", { ns: "staff" })}
      >
        <Thead>
          <Tr>
            {tableColumns.map((column) => (
              <Th
                key={column.field}
                width={200}
              >
                <TableColumnHeader
                  text={t(`columns.${column.field}`)}
                  filter={{
                    component: (
                      <ColumnFilterComponent
                        type={column.type}
                        value={filters[column.field]}
                        updateFilter={(value) =>
                          updateStaffsFilter(
                            column.field,
                            value as string | string[],
                          )
                        }
                        selectOptions={column.options}
                        namespace="badges"
                      />
                    ),
                    isActive: !!(Array.isArray(filters[column.field])
                      ? filters[column.field][0]
                      : filters[column.field]),
                  }}
                  sort={{
                    handler: (direction) =>
                      updateStaffsSorting({ field: column.field, direction }),
                    direction:
                      sorting?.field === column.field
                        ? sorting.direction
                        : null,
                  }}
                  tableId={tableId}
                />
              </Th>
            ))}
            <Th w="100px" />
          </Tr>
        </Thead>
        <Tbody>
          {staffs?.map((staffElement) => (
            <Tr
              key={staffElement.id}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedStaff(staffElement);
              }}
            >
              <Td width={200}>
                {staffElement.surname}
              </Td>
              <Td width={200}>{staffElement.name}</Td>
              <Td width={200}>
                {t(staffElement.phone)}
              </Td>
              <Td width={200}>{staffElement.email}</Td>
              <Td width={200}>
                {staffElement.type === StaffType.SYSTEM
                  ? t("systemStaffRole." + staffElement.role, { ns: "enum" })
                  : staffElement.role}
              </Td>

              <Td p={0} paddingLeft={3} w="100px">
                <RenderIf permissions={canEditPermissions} check={PermissionCheck.All}>
                  <EditButton
                    onClick={() => {
                      setSelectedStaff(staffElement);
                      setShowUpdateModal(true);
                    }}
                  />
                  {deleteStaffElement && (
                    <DeleteButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowConfirmDeleteModal(staffElement.id);
                      }}
                    />
                  )}
                </RenderIf>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </SimpleTable>

      {showConfirmDeleteModal && (
        <ConfirmAlert
          isLoading={deleteStaffIsLoading}
          title={t("warning", { ns: "common" })}
          message={t("confirmDeleteStaff")}
          variant="warning"
          onCancel={() => setShowConfirmDeleteModal(false)}
          onConfirm={async () => {
            await deleteStaffElement(showConfirmDeleteModal);
            setShowConfirmDeleteModal(false);
          }}
        />
      )}

      {showUpdateModal && (
        <CreateUpdateStaffModal
          createUpdateStaffIsLoading={staffIsLoading}
          staffType={staffType}
          onCancel={() => {
            setShowUpdateModal(false);
            setSelectedStaff(undefined);
          }}
          onConfirm={async (staffEl) => {
            await updateStaffAction(staffEl);
            setShowUpdateModal(false);
            setSelectedStaff(undefined);
          }}
          staffToUpdate={selectedStaff}
          title={t("updateStaff", { ns: namespace })}
        />
      )}
    </Flex>
  );
});

StaffTableView.displayName = "StaffTableView";

export default StaffTableView;
