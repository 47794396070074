import RequirementsView from "../Common/RequirementsView";
import { useLocation, useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import useCompanyReqGroupDetailViewModel from "../../hooks/Company/useCompanyReqGroupDetailViewModel";

const CompanyReqGroupDetailView = () => {
  const { id } = useParams();

  // here we retrieve the last segment of the current path to understand which category of documentTypes the user is browsing
  const location = useLocation();
  const segments = location.pathname.split("/");
  const last = segments.pop();
  const requirementSubject = last as RequirementSubject;

  return (
    <RequirementsView
      useViewModel={useCompanyReqGroupDetailViewModel(requirementSubject)}
      requirementSubject={requirementSubject}
      groupId={id}
      namespace="groups"
      showSiteSelection={true}
      permissions={{
        add: Permission.Company_AddReq,
        edit: Permission.Company_EditReqGroup,
        delete: Permission.Company_DeleteReq,
      }}
      isPropagable={true}
    />
  );
};

export default CompanyReqGroupDetailView;
