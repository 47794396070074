import { useParams } from "react-router-dom";
import {
  getSectionPermissions
} from "../components/Permissions/Permissions";

const replaceSupplierId = (routes, supplierId) => {
  return routes.map((route) => ({
    ...route,
    path: route.path.replace(":supplierId", supplierId),
  }));
};

export const commonSupplierFallbackRoutes = [
  {
    path: "suppliers/:supplierId",
    permissions: getSectionPermissions("supplier", "records"),
  },
  {
    path: "suppliers/:supplierId/details",
    permissions: getSectionPermissions("supplier"),
  },
  {
    path: "suppliers/resources",
    permissions: getSectionPermissions("supplier", [
      "workers",
      "machines",
      "tools",
      "vehicles",
      "chemicals",
    ]),
  },
];

export const commonSupplierResourceFallbackRoutes = [
  {
    path: "suppliers/:supplierId/resources/workers",
    permissions: getSectionPermissions("supplier", "workers"),
  },
  {
    path: "suppliers/:supplierId/resources/vehicles",
    permissions: getSectionPermissions("supplier", "vehicles"),
  },
  {
    path: "suppliers/:supplierId/resources/machines",
    permissions: getSectionPermissions("supplier", "machines"),
  },
  {
    path: "suppliers/:supplierId/resources/tools",
    permissions: getSectionPermissions("supplier", "tools"),
  },
  {
    path: "suppliers/:supplierId/resources/chemicals",
    permissions: getSectionPermissions("supplier", "chemicals"),
  },
];

export const getSupplierFallbackRoutes = (currentRoute, supplierId) => {
  let fallbackRoutes = [...commonSupplierFallbackRoutes];

  fallbackRoutes = fallbackRoutes.filter(
    (route) => route.path !== `suppliers/:supplierId/${currentRoute}`,
  );
  return replaceSupplierId(fallbackRoutes, supplierId);
};

export const getSupplierResourceFallbackRoutes = (currentRoute, supplierId) => {
  let fallbackRoutes = [...commonSupplierResourceFallbackRoutes];

  fallbackRoutes = fallbackRoutes.filter(
    (route) => route.path !== `suppliers/:supplierId/resources/${currentRoute}`
  );

  return fallbackRoutes.map(route => ({
    ...route,
    path: route.path.replace(":supplierId", supplierId),
  }));
};


