import Worker from '../../../entities/worker';
import UseCase from '../../../../core/app/usecase';
import WorkerRepository, {GetResourcesFilter} from '../../../repositories/workerRepository';
import { PaginatedResults, SortMeta } from '../../../entities/interfaces/paginatedResults';

type ARGS = {
	companyId: string;
	filter?: GetResourcesFilter;
	sort?: SortMeta;
	pageParam?: number;
	archived?: boolean;
};

class GetWorkers implements UseCase<ARGS, PaginatedResults<Worker>> {
	constructor(private repository: WorkerRepository) {}

	async Call({ companyId, filter, archived, sort, pageParam }: ARGS): Promise<PaginatedResults<Worker>> {
		return await this.repository.getWorkers(companyId, filter, archived, sort, pageParam);
	}
}

export default GetWorkers;
