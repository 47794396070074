import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ResourceListView from "../ResourceListView";
import Tool from "../../../../domain/entities/tool";
import { Permission } from "../../../components/Permissions/Permissions";
import FormTextField from "../../../components/Common/forms/FormTextField";
import { ToolsListViewModel } from "../../../viewmodels/tools/ToolsListViewModel";
import { EditableFormFieldProps } from "../../../components/Common/forms/FormField";
import { DocumentTypeCategory } from "../../../../domain/entities/documentTypeCategory.enum";
import { GetResourcesFilter } from "../../../../domain/repositories/workerRepository";
import FormImageField from "../../../components/Common/forms/FormImageField";

const ToolsView = () => {
  const { t } = useTranslation("tools");
  const formMethods = useForm<Tool>({ mode: "all" });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const columns = [
    { field: "name", type: "text", label: t("name") },
    { field: "serial", type: "text", label: t("serial") },
  ] as {
    field: keyof GetResourcesFilter;
    type: "text" | "tags";
    label: string;
  }[];
  const fields: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>> =
    [
      <FormTextField
        key="name"
        label={t("name")}
        name="name"
        rules={requiredRule}
      />,
      <FormTextField
        key="serial"
        label={t("serial")}
        name="serial"
        rules={requiredRule}
      />,
      <FormImageField
        key="photo"
        label={t("photo")}
        name="photo"
        colSpan={2}
      />,
    ];

  return (
    <ResourceListView<Tool>
      formMethods={formMethods}
      type={DocumentTypeCategory.TOOL}
      viewModel={new ToolsListViewModel()}
      columns={columns}
      namespace="tools"
      permissions={{
        add: Permission.Resources_AddTool,
        delete: Permission.Resources_DeleteTool,
        import: Permission.Resources_ImportTool,
        showResourceSites: Permission.Resources_ShowToolSites,
        showCopyLastEvaluation: Permission.Resources_EvalToolDocs,
        download: Permission.Resources_DownloadToolDocs,
      }}
      createFields={fields}
      viewDetailsPermissions={[Permission.Resources_ShowToolDetails]}
      convertResourcePermissions={{
        machine: Permission.Resources_ConvertToolToMachine,
      }}
      // twinTypes={["machine"]}
    />
  );
};

export default ToolsView;
