import Vehicle from "../../../domain/entities/vehicle";
import {
  makeGetVehicle,
  makeUpdateVehicle,
} from "../../../core/app/factory/vehicles/usecases";
import {
  makeGetResourceLinkableSites,
  makeGetVehicleSites,
} from "../../../core/app/factory/sites/usecases";
import {
  SortMeta,
} from "../../../domain/entities/interfaces/paginatedResults";
import { GetSitesFilter } from "../../../domain/repositories/siteRepository";
import {
  makeGetAvailableBadgesUseCase,
  makeLinkBadgesResource,
} from "../../../core/app/factory/workers/usecases";
import {
  makeUnlinkBadgeResource,
} from "../../../core/app/factory/badges/usecases";
import {
  GetAvailableBadgesFilters,
} from "../../../domain/repositories/badgeRepository";
import Badge from "../../../domain/entities/badge";
import { BadgeResourceType } from "../../../domain/entities/badgeResourceType.enum";
import Site, { ResourceSites } from "../../../domain/entities/site";
import { makeAddResourceToSites } from "../../../core/app/factory/workers/usecases";
import {ResourceConversionViewModel} from "../resourceConversion/ResourceConversionViewModel";

class VehicleViewModel extends ResourceConversionViewModel {
  private getVehicleUseCase = makeGetVehicle();
  private updateVehicleUseCase = makeUpdateVehicle();
  private getVehicleSitesUseCase = makeGetVehicleSites();
  private getResourceLinkableSitesUseCase = makeGetResourceLinkableSites();
  private linkResourceToSitesUseCase = makeAddResourceToSites();
  private getAvailableBadgesUseCase = makeGetAvailableBadgesUseCase();
  private linkBadgesToResourceUseCase = makeLinkBadgesResource();
  private unlinkBadgeResourceUseCase = makeUnlinkBadgeResource();

  async get(companyId: string, vehicleId: string): Promise<Vehicle> {
    return await this.getVehicleUseCase.Call({ companyId, vehicleId });
  }
  async update(
    companyId: string,
    vehicle: Vehicle,
    imageFile?: File,
  ): Promise<Vehicle> {
    return await this.updateVehicleUseCase.Call({
      companyId,
      vehicle,
      imageFile,
    });
  }

  async getVehicleSites(
    companyId: string,
    vehicleId: string,
    sort?: SortMeta,
    filter?: GetSitesFilter,
    pageParam?: number,
    includeWorkingSites?: boolean,
    ignoreUserSiteRoleRestrictions?: boolean,
    documentTypeId?: string,
  ): Promise<ResourceSites> {
    return await this.getVehicleSitesUseCase.Call({
      companyId,
      vehicleId,
      sort,
      filter,
      pageParam,
      includeWorkingSites,
      ignoreUserSiteRoleRestrictions,
      documentTypeId
    });
  }

  async getAvailableBadges(
    companyId: string,
    resource: string,
    resourceId: string,
    sort: SortMeta,
    filter: GetAvailableBadgesFilters,
  ): Promise<Badge[]> {
    return await this.getAvailableBadgesUseCase.Call({
      companyId,
      resource,
      resourceId,
      sort,
      filter,
    });
  }

  async linkBadgesToResource(
    companyId: string,
    badgeIds: string[],
    resourceType: BadgeResourceType,
    resourceId: string,
  ): Promise<void> {
    return await this.linkBadgesToResourceUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      badgeIds,
    });
  }

  async unlinkBadgeResource(companyId: string, badgeId: string): Promise<void> {
    return await this.unlinkBadgeResourceUseCase.Call({ companyId, badgeId });
  }

  async getResourceLinkableSites(
    companyId: string,
    resourceType: string,
    resourceId: string,
    sort: SortMeta,
    filters: GetSitesFilter,
    pageParam: number,
  ): Promise<{results: Site[] , count: number }> {
    return await this.getResourceLinkableSitesUseCase.Call({
      companyId,
      resourceType,
      resourceId,
      sort,
      filters,
      pageParam,
    });
  }
  async linkResourceToSites(
    companyId: string,
    vehicleId: string,
    siteIds: string[],
    copyLastEvaluation: boolean,
    selectAll?: boolean,
    filters?: GetSitesFilter,
  ): Promise<void> {
    return await this.linkResourceToSitesUseCase.Call({
      companyId,
      resourceType: "vehicle",
      resourceId: vehicleId,
      siteIds,
      copyLastEvaluation,
      selectAll,
      filters
    });
  }
}

export { VehicleViewModel };
