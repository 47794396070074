import { injectable } from 'tsyringe';
import { SortMeta } from '../../domain/entities/interfaces/paginatedResults';
import Staff, { StaffType } from '../../domain/entities/staff';
import StaffRepository from '../../domain/repositories/staffRepository';
import { GetStaffFilters } from '../../presentation/components/Views/Company/StaffTableView';
import { ApiService } from '../utilities/apiService';

@injectable()
class ServerStaffRepository implements StaffRepository {
	constructor(private apiService: ApiService) {}

	async getStaff(companyId: string, siteId?: string, filter?: GetStaffFilters, sort?: SortMeta): Promise<Staff[]> {
		const response = await this.apiService.fetchWithToken(
			this.getStaffUrl(companyId, siteId, filter, sort, siteId ? StaffType.SITE_CUSTOM : StaffType.CUSTOM)
		);
		const { results } = await response.json();
		return results?.map((s: Staff) => ({ ...s, type: siteId ? StaffType.SITE_CUSTOM : StaffType.CUSTOM })) ?? [];
	}

	async createStaff(companyId: string, staffElement: Staff, siteId?: string): Promise<void> {
		const createStaffUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/staff`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/staff`;

		await this.apiService.fetchWithToken(createStaffUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(staffElement),
		});
	}

	async updateStaffElement(companyId: string, staffElement: Staff, siteId?: string): Promise<void> {
		return await this.updateStaff(companyId, staffElement, siteId);
	}

	async deleteStaffElement(companyId: string, staffId: string, siteId?: string): Promise<void> {
		const deleteStaffUrl = siteId
			? `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/staff/${staffId}`
			: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/staff/${staffId}`;

		await this.apiService.fetchWithToken(deleteStaffUrl, {
			method: 'DELETE',
		});
	}

	async getSystemStaff(companyId: string, filter?: GetStaffFilters, sort?: SortMeta): Promise<Staff[]> {
		const response = await this.apiService.fetchWithToken(this.getStaffUrl(companyId, null, filter, sort, StaffType.SYSTEM));
		const { results } = await response.json();
		return results?.map((s: Staff) => ({ ...s, type: StaffType.SYSTEM })) ?? [];
	}

	async updateSystemStaffElement(companyId: string, staffElement: Staff): Promise<void> {
		return await this.updateStaff(companyId, staffElement);
	}

	private async updateStaff(companyId: string, staffElement: Staff, siteId?: string): Promise<void> {
		const urlMap = {
			[StaffType.SYSTEM]: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/system-staff/${staffElement.id}`,
			[StaffType.CUSTOM]: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/staff/${staffElement.id}`,
			[StaffType.SITE_CUSTOM]: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/staff/${staffElement.id}`,
		};

		await this.apiService.fetchWithToken(urlMap[staffElement.type], {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(staffElement),
		});
	}

	private getStaffUrl(companyId: string, siteId?: string, filter?: GetStaffFilters, sort?: SortMeta, type: StaffType = StaffType.CUSTOM): string {
		const { ...restFilter } = filter || {};
		const params = new URLSearchParams({
			...restFilter,
			...sort,
		});
		const urlMap = {
			[StaffType.SYSTEM]: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/system-staff?${params.toString()}`,
			[StaffType.CUSTOM]: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/staff?${params.toString()}`,
			[StaffType.SITE_CUSTOM]: `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/staff?${params.toString()}`,
		};

		return urlMap[type];
	}
}

export default ServerStaffRepository;
