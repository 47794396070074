import { injectable } from 'tsyringe';

import { DocumentsDownloader, DownloadFileScope, DownloadResourceFileScope } from '../../domain/repositories/documentsDownloader';
import { ApiService } from '../utilities/apiService';

@injectable()
export class ServerDocumentsDownloader implements DocumentsDownloader {
	constructor(private apiService: ApiService) { }

	async downloadDocumentZippedFiles(companyId: string, documentId: string, renameModel: string): Promise<boolean> {
		// This method will call an API and will return a boolean set to true if everything is ok.
		const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files/download${renameModelParam}`,
			{ headers: { 'Content-Type': 'application/json' } },
		);

		if (!response.ok) {
			// FIXME: error messages should be managed better than this.
			console.error('cannot download file', response.statusText);
		}

		return Promise.resolve(response.ok);
	}
	async downloadResourceDocumentZippedFiles(
		companyId: string,
		siteId: string,
		requirementId: string,
		resourceId: string,
		resourceScope: DownloadResourceFileScope,
		renameModel: string,
	): Promise<boolean> {
		// This method will call an API and will return a boolean set to true if everything is ok.
		const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${resourceScope}/${resourceId}/requirements/${requirementId}/files/download${renameModelParam}`,
			{ headers: { 'Content-Type': 'application/json' } },
		);

		if (!response.ok) {
			// FIXME: error messages should be managed better than this.
			console.error('cannot download file', response.statusText);
		}

		return Promise.resolve(response.ok);
	}
	async downloadCompanyOrSiteDocumentZippedFiles(companyId: string, siteId: string, requirementId: string, renameModel: string): Promise<boolean> {
		const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
		// This method will call an API and will return a boolean set to true if everything is ok.
		const response = await this.apiService.fetchWithToken(
			`${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${requirementId}/files/download${renameModelParam}`,
			{ headers: { 'Content-Type': 'application/json' } },
		);
		if (!response.ok) {
			// FIXME: error messages should be managed better than this.
			console.error('cannot download file', response.statusText);
		}

		return Promise.resolve(response.ok);
	}

	async downloadDocumentFile(companyId: string, documentId: string, fileId: string, renameModel: string): Promise<string> {
		const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/documents/${documentId}/files/${fileId}/download${renameModelParam}`;
	}

	async downloadResourceDocumentFile(
		companyId: string,
		siteId: string,
		requirementId: string,
		resourceId: string,
		resourceScope: DownloadResourceFileScope,
		fileId: string,
		renameModel: string,
	): Promise<string> {
		const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/sites/${siteId}/${resourceScope}/${resourceId}/requirements/${requirementId}/files/${fileId}/download${renameModelParam}`;
	}

	async downloadCompanyOrSiteDocumentFile(
		companyId: string,
		siteId: string,
		requirementId: string,
		fileId: string,
		renameModel: string,
		scope: DownloadFileScope,
		supplierId?: string,
	): Promise<string> {
		const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
		const url =
			scope === 'site'
				? supplierId ? `/sites/${siteId}/suppliers/${supplierId}/requirements/${requirementId}/files/${fileId}/download`
					: `/sites/${siteId}/documents/${requirementId}/files/${fileId}/download`
				: `/documents/${requirementId}/files/${fileId}/download`;
		return `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}${url}${renameModelParam}`;
	}

	async downloadDocumentsFilesZipped(
		companyId: string,
		siteId: string,
		resourceId: string,
		resourceScope: DownloadResourceFileScope | DownloadFileScope,
		renameModel: string,
	): Promise<boolean> {
		const getResourcePath = (): string => {
			const renameModelParam = renameModel ? `?renameModel=${renameModel}` : '';
			const scope = 'requirements';

			if (!siteId) {
				if (resourceScope === 'company') {
					// Download company general documents
					return `documents/download`;
				} else {
					// Download documents from company resource detail
					return `${resourceScope}/${resourceId}/documents/download`;
				}
			} else {
			 	if(resourceScope === 'site' && !renameModel){
					//Download site general documents
					return `sites/${siteId}/documents/download`
				}	else if (resourceScope === 'site' || resourceScope === 'company') {
					// Download requirements from supplier or from myself as supplier
					return `sites/${siteId}/suppliers/${resourceId}/${scope}/download${renameModelParam}`;
				} else {
					// Download requirements from site resource detail
					return `sites/${siteId}/${resourceScope}/${resourceId}/${scope}/download${renameModelParam}`;
				}
			}
		};

		const fullUrl = `${process.env.REACT_APP_SERVER_API_ENDPOINT}/companies/${companyId}/${getResourcePath()}`;

		try {
			const response = await this.apiService.fetchWithToken(fullUrl, {
				headers: { 'Content-Type': 'application/json' },
			});
			if (!response.ok) {
				console.error(`Failed to download file: ${response.statusText} (Status: ${response.status})`);
				return false;
			}
			return true;
		} catch (error) {
			console.error('Error during file download:', error);
			return false;
		}
	}

}
