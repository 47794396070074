import { useMemo, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { updateFilterWithDelete } from "../../../utils";
import { AuditsViewModel } from "../../viewmodels/sites/AuditsViewModel";
import { AuditType } from "../../../domain/entities/audit";
import { GetAuditFilter } from "../../../domain/repositories/auditRepository";
import Supplier from "../../../domain/entities/supplier";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import DocumentComment from "../../../domain/entities/documentComment";
import { useAuth } from "../../providers/Auth0JWTProvider";

export type GetSiteResourcesAuditFilters = {
  fullName?: string;
  name?: string;
};

const useSitesAuditsViewModel = (
  siteId: string,
  requirementId?: string,
  requirementSubject?: DocumentTypeCategory,
  resourceId?: string,
  isWorkingSite?: boolean
) => {
  const { companyId } = useAuth();
  const viewModel = useMemo(() => new AuditsViewModel(), []);

  const [filters, setFilters] = useState<GetAuditFilter>({});
  const [sorting, setSorting] = useState<SortMeta>(null);

  const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();

  const [error, setError] = useState<string>();

  const [enableGetCompanyRequirements, setEnableGetCompanyRequirements] =
    useState(false);

  const [enableGetWorkersRequirements, setEnableGetWorkersRequirements] =
    useState(false);
  const [enableGetVehiclesRequirements, setEnableGetVehiclesRequirements] =
    useState(false);
  const [enableGetMachinesRequirements, setEnableGetMachinesRequirements] =
    useState(false);
  const [enableGetToolsRequirements, setEnableGetToolsRequirements] =
    useState(false);
  const [enableGetChemicalsRequirements, setEnableGetChemicalsRequirements] =
    useState(false);

  const [filterSiteWorkers, setFilterSiteWorkers] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteWorkersSort, setSiteWorkersSort] = useState<SortMeta>(null);

  const [filterSiteMachines, setFilterSiteMachines] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteMachinesSort, setSiteMachinesSort] = useState<SortMeta>(null);

  const [filterSiteVehicles, setFilterSiteVehicles] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteVehiclesSort, setSiteVehiclesSort] = useState<SortMeta>(null);

  const [filterSiteTools, setFilterTools] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteToolsSort, setSiteToolsSort] = useState<SortMeta>(null);

  const [filterSiteChemicals, setFilterSiteChemicals] =
    useState<GetSiteResourcesAuditFilters>({});
  const [siteChemicalsSort, setSiteChemicalsSort] = useState<SortMeta>(null);
  const [search, setSearch] = useState<string>(null);

  const [enableGetWorkers, setEnableGetWorkers] = useState(false);
  const [enableGetVehicles, setEnableGetVehicles] = useState(false);
  const [enableGetMachines, setEnableGetMachines] = useState(false);
  const [enableGetTools, setEnableGetTools] = useState(false);
  const [enableGetChemicals, setEnableGetChemicals] = useState(false);

  const [getAuditNoteEnabled, setGetAuditNoteEnabled] = useState(false);

  const getAuditsQuery = useInfiniteQuery(
    ["getAudits", companyId, filters, search, sorting],
    async ({ pageParam = 1 }) => {
      const auditFilters = search ? { ...filters, search } : filters;
      const { results } = await viewModel.getAudits(
        companyId,
        siteId,
        pageParam,
        auditFilters,
        sorting
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: !resourceId && !isWorkingSite,
    }
  );

  const createAuditMutation = useMutation(
    ["createAudit", companyId, siteId],
    async ({
      audit,
      supplierId,
      workerResourceIds,
      machineResourceIds,
      vehicleResourceIds,
      toolResourceIds,
      chemicalResourceIds,
      siteRequirementsToExclude,
      companyRequirementsToExclude,
      workersRequirementsToExclude,
      machinesRequirementsToExclude,
      vehiclesRequirementsToExclude,
      toolsRequirementsToExclude,
      chemicalsRequirementsToExclude,
    }: {
      audit: AuditType;
      supplierId: string;
      workerResourceIds?: string[];
      machineResourceIds?: string[];
      vehicleResourceIds?: string[];
      toolResourceIds?: string[];
      chemicalResourceIds?: string[];
      siteRequirementsToExclude?: string[];
      companyRequirementsToExclude?: string[];
      workersRequirementsToExclude?: string[];
      machinesRequirementsToExclude?: string[];
      vehiclesRequirementsToExclude?: string[];
      toolsRequirementsToExclude?: string[];
      chemicalsRequirementsToExclude?: string[];
    }) => {
      return await viewModel.createAudit(
        companyId,
        siteId,
        audit,
        supplierId,
        siteRequirementsToExclude,
        companyRequirementsToExclude,
        workersRequirementsToExclude,
        machinesRequirementsToExclude,
        vehiclesRequirementsToExclude,
        toolsRequirementsToExclude,
        chemicalsRequirementsToExclude,
        workerResourceIds,
        machineResourceIds,
        vehicleResourceIds,
        toolResourceIds,
        chemicalResourceIds
      );
    },
    {
      onSuccess: () => {
        getAuditsQuery.refetch();
      },
      onError: (error: Error) => {
        setError(error.message), getAuditsQuery.refetch();
      },
    }
  );

  const deleteAuditMutation = useMutation(
    async ({ auditId }: { auditId: string }) =>
      await viewModel.deleteAudit(companyId, siteId, auditId),
    {
      onSuccess: () => {
        getAuditsQuery.refetch();
      },
    }
  );

  // query to retrieve resources requirement

  const siteCompanyRequirementQuery = useInfiniteQuery(
    ["site-supplier-company-requirements", companyId, siteId, selectedSupplier],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResourceDocuments(
        companyId,
        siteId,
        DocumentTypeCategory.SUPPLIER,
        selectedSupplier?.company?.id,
        pageParam
      );

      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetCompanyRequirements,
    }
  );

  const siteWorkersDocumentsQuery = useInfiniteQuery(
    ["site-supplier-workers-requirements", companyId, siteId, selectedSupplier],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResourceDocuments(
        companyId,
        siteId,
        DocumentTypeCategory.WORKER,
        selectedSupplier?.company?.id,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetWorkersRequirements,
    }
  );

  const siteMachinesDocumentsQuery = useInfiniteQuery(
    [
      "site-supplier-machines-requirements",
      companyId,
      siteId,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResourceDocuments(
        companyId,
        siteId,
        DocumentTypeCategory.MACHINE,
        selectedSupplier?.company?.id,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMachinesRequirements,
    }
  );

  const siteVehicleDocumentsQuery = useInfiniteQuery(
    [
      "site-supplier-vehicles-requirements",
      companyId,
      siteId,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResourceDocuments(
        companyId,
        siteId,
        DocumentTypeCategory.VEHICLE,
        selectedSupplier?.company?.id,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetVehiclesRequirements,
    }
  );

  const siteToolDocumentsQuery = useInfiniteQuery(
    ["site-supplier-tools-requirements", companyId, siteId, selectedSupplier],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResourceDocuments(
        companyId,
        siteId,
        DocumentTypeCategory.TOOL,
        selectedSupplier?.company?.id,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetToolsRequirements,
    }
  );

  const siteChemicalDocumentsQuery = useInfiniteQuery(
    [
      "site-supplier-chemicals-requirements",
      companyId,
      siteId,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results } = await viewModel.getSiteSupplierResourceDocuments(
        companyId,
        siteId,
        DocumentTypeCategory.CHEMICAL,
        selectedSupplier?.company?.id,
        pageParam
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetChemicalsRequirements,
    }
  );

  //query to retrieve resources

  const siteWorkersQuery = useInfiniteQuery(
    [
      "site-workers",
      companyId,
      filterSiteWorkers,
      siteWorkersSort,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getSiteWorkers(
        companyId,
        siteId,
        pageParam,
        filterSiteWorkers,
        siteWorkersSort,
        selectedSupplier?.company?.id
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetWorkers,
    }
  );

  const siteMachinesQuery = useInfiniteQuery(
    [
      "site-machines",
      companyId,
      filterSiteMachines,
      siteMachinesSort,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getSiteMachines(
        companyId,
        siteId,
        pageParam,
        filterSiteMachines,
        siteMachinesSort,
        selectedSupplier?.company?.id
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetMachines,
    }
  );

  const siteVehiclesQuery = useInfiniteQuery(
    [
      "site-vehicles",
      companyId,
      filterSiteVehicles,
      siteVehiclesSort,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getSiteVehicles(
        companyId,
        siteId,
        pageParam,
        filterSiteVehicles,
        siteVehiclesSort,
        selectedSupplier?.company?.id
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetVehicles,
    }
  );

  const siteToolsQuery = useInfiniteQuery(
    ["site-tools", companyId, filterSiteTools, siteToolsSort, selectedSupplier],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getSiteTools(
        companyId,
        siteId,
        pageParam,
        filterSiteTools,
        siteToolsSort,
        selectedSupplier?.company?.id
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetTools,
    }
  );

  const siteChemicalsQuery = useInfiniteQuery(
    [
      "site-chemicals",
      companyId,
      filterSiteChemicals,
      siteChemicalsSort,
      selectedSupplier,
    ],
    async ({ pageParam = 1 }) => {
      const { results, count } = await viewModel.getSiteChemicals(
        companyId,
        siteId,
        pageParam,
        filterSiteChemicals,
        siteChemicalsSort,
        selectedSupplier?.company?.id
      );
      return results;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.length === 25) {
          return pages.length + 1;
        }
      },
      enabled: enableGetChemicals,
    }
  );

  //Note section

  const getAuditNoteQuery = useQuery<DocumentComment, Error>(
    ["get-audit-notes", companyId, siteId, requirementSubject],
    async () => {
      const response = await viewModel.getAuditNote(
        companyId,
        siteId,
        requirementId,
        requirementSubject,
        resourceId
      );
      return response;
    },
    {
      initialData: undefined,
      enabled: getAuditNoteEnabled,
    }
  );

  const createAuditNoteMutation = useMutation(
    ["createAuditNote", companyId, siteId],
    async (note: string) => {
      return await viewModel.createAuditNote(
        companyId,
        siteId,
        requirementId,
        note,
        requirementSubject,
        resourceId
      );
    },
    {
      onSuccess: () => getAuditsQuery.refetch(),
      onError: (error: Error) => {
        setError(error.message), getAuditsQuery.refetch();
      },
    }
  );

  const updateAuditNoteMutation = useMutation(
    ["createAuditNote", companyId, siteId],
    async (note: string) => {
      return await viewModel.updateAuditNote(
        companyId,
        siteId,
        requirementId,
        note,
        requirementSubject,
        resourceId
      );
    },
    {
      onSuccess: () => getAuditsQuery.refetch(),
      onError: (error: Error) => {
        setError(error.message), getAuditsQuery.refetch();
      },
    }
  );

  const addFilter = (key: keyof GetAuditFilter, value: string | string[]) => {
    updateFilterWithDelete(setFilters, key, value);
  };

  const activeSuppliersQuery = useQuery<Supplier[], Error>(
    ["active-suppliers", companyId, siteId],
    async () => {
      return  await viewModel.listActiveSupplier(companyId, siteId, true);
    },
    {
      enabled: !isWorkingSite
    }
  );

  const audits = getAuditsQuery.data?.pages?.flat() ?? [];

  const companyRequirements =
    siteCompanyRequirementQuery.data?.pages?.flat() ?? [];

  const workersRequirements =
    siteWorkersDocumentsQuery.data?.pages?.flat() ?? [];
  const machinesRequirements =
    siteMachinesDocumentsQuery.data?.pages?.flat() ?? [];
  const vehiclesRequirements =
    siteVehicleDocumentsQuery.data?.pages?.flat() ?? [];
  const toolsRequirements = siteToolDocumentsQuery.data?.pages?.flat() ?? [];
  const chemicalsRequirements =
    siteChemicalDocumentsQuery.data?.pages?.flat() ?? [];

  const siteWorkers = siteWorkersQuery.data?.pages?.flat() ?? [];
  const siteMachines = siteMachinesQuery.data?.pages?.flat() ?? [];
  const siteVehicles = siteVehiclesQuery.data?.pages?.flat() ?? [];
  const siteTools = siteToolsQuery.data?.pages?.flat() ?? [];
  const siteChemicals = siteChemicalsQuery.data?.pages?.flat() ?? [];

  return {
    audits,
    auditsFetching: getAuditsQuery.isLoading,
    auditsHasNextPage: getAuditsQuery.hasNextPage,
    auditsFetchNextPage: getAuditsQuery.fetchNextPage,
    filters,
    addFilter,
    sorting,
    setSorting,

    companyRequirements,
    companyRequirementsHasNextPage: siteCompanyRequirementQuery.hasNextPage,
    companyRequirementsFetchNextPage: siteCompanyRequirementQuery.fetchNextPage,
    companyRequirementsIsLoading: siteCompanyRequirementQuery.isLoading,

    workersRequirements,
    workersRequirementsHasNextPage: siteWorkersDocumentsQuery.hasNextPage,
    workersRequirementsFetchNextPage: siteWorkersDocumentsQuery.fetchNextPage,
    workersRequirementsIsLoading: siteWorkersDocumentsQuery.isLoading,

    machinesRequirements,
    machineRequirementsHasNextPage: siteMachinesDocumentsQuery.hasNextPage,
    machineRequirementsFetchNextPage: siteMachinesDocumentsQuery.fetchNextPage,
    machinesRequirementsIsLoading: siteMachinesDocumentsQuery.isLoading,

    vehiclesRequirements,
    vehiclesRequirementsHasNextPage: siteVehicleDocumentsQuery.hasNextPage,
    vehiclesRequirementsFetchNextPage: siteVehicleDocumentsQuery.fetchNextPage,
    vehiclesRequirementsIsLoading: siteVehicleDocumentsQuery.isLoading,

    toolsRequirements,
    toolsRequirementsHasNextPage: siteToolDocumentsQuery.hasNextPage,
    toolsRequirementsFetchNextPage: siteToolDocumentsQuery.fetchNextPage,
    toolsRequirementsIsLoading: siteToolDocumentsQuery.isLoading,

    chemicalsRequirements,
    chemicalRequirementsHasNextPage: siteChemicalDocumentsQuery.hasNextPage,
    chemicalRequirementsFetchNextPage: siteChemicalDocumentsQuery.fetchNextPage,
    chemicalsRequirementsIsLoading: siteChemicalDocumentsQuery.isLoading,

    siteWorkers,
    siteWorkersHasNextPage: siteWorkersQuery.hasNextPage,
    siteWorkersFetchNextPage: siteWorkersQuery.fetchNextPage,
    siteWorkersIsLoading: siteWorkersQuery.isLoading,

    siteMachines,
    siteMachinesHasNextPage: siteMachinesQuery.hasNextPage,
    siteMachinesFetchNextPage: siteMachinesQuery.fetchNextPage,
    siteMachinesIsLoading: siteMachinesQuery.isLoading,

    siteVehicles,
    siteVehiclesHasNextPage: siteVehiclesQuery.hasNextPage,
    siteVehiclesFetchNextPage: siteVehiclesQuery.fetchNextPage,
    siteVehiclesIsLoading: siteVehiclesQuery.isLoading,

    siteTools,
    siteToolsHasNextPage: siteToolsQuery.hasNextPage,
    siteToolsFetchNextPage: siteToolsQuery.fetchNextPage,
    siteToolsIsLoading: siteToolsQuery.isLoading,

    siteChemicals,
    siteChemicalsHasNextPage: siteChemicalsQuery.hasNextPage,
    siteChemicalsFetchNextPage: siteChemicalsQuery.fetchNextPage,
    siteChemicalsIsLoading: siteChemicalsQuery.isLoading,

    createAudit: createAuditMutation.mutateAsync,
    createAuditLoading: createAuditMutation.isLoading,
    deleteAudit: deleteAuditMutation.mutateAsync,
    deleteAuditLoading: deleteAuditMutation.isLoading,

    auditNote: getAuditNoteQuery.data,
    auditNoteFetching: getAuditNoteQuery.isLoading,
    setGetAuditNoteEnabled,
    createAuditNote: createAuditNoteMutation.mutateAsync,
    createAuditNoteIsLoading: createAuditNoteMutation.isLoading,
    updateAuditNote: updateAuditNoteMutation.mutateAsync,
    updateAuditNoteIsLoading: updateAuditNoteMutation.isLoading,

    selectableSuppliers: activeSuppliersQuery.data,
    setFilterSiteWorkers,
    setFilterSiteChemicals,
    setFilterSiteMachines,
    setFilterSiteVehicles,
    setFilterTools,
    filterSiteChemicals,
    filterSiteMachines,
    filterSiteTools,
    filterSiteVehicles,
    filterSiteWorkers,

    setSelectedSupplier,
    setEnableGetCompanyRequirements,
    setEnableGetWorkersRequirements,
    setEnableGetVehiclesRequirements,
    setEnableGetMachinesRequirements,
    setEnableGetToolsRequirements,
    setEnableGetChemicalsRequirements,
    setEnableGetChemicals,
    setEnableGetMachines,
    setEnableGetTools,
    setEnableGetVehicles,
    setEnableGetWorkers,
    setSearch,
  };
};

export default useSitesAuditsViewModel;
