import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../assets/theme/colors";
import { RoleContext } from "../../../../domain/entities/role";
import { Named } from "../../../../domain/entities/interfaces/named";
import InputAnimatedLabel from "../../../components/Common/InputAnimatedLabel";
import InputAnimatedSelect from "../../../components/Common/InputAnimatedSelect";
import { Identifiable } from "../../../../domain/entities/interfaces/identifiable";

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useAuth } from "../../../providers/Auth0JWTProvider";

interface Props {
  value?: string;
  update?: boolean;
  onCreate?: (name: string, context: RoleContext) => void;
  onUpdate?: (name: string) => void;
  onCancel?: () => void;
  isCreating?: boolean;
}

const CreateUpdateRoleView: FC<Props> = ({
  onCreate,
  onCancel,
  onUpdate,
  value,
  update,
  isCreating,
}) => {
  const { t } = useTranslation("settings");
  const { company } = useAuth();
  const [name, setName] = useState(value ? value : "");
  const [context, setContext] = useState<RoleContext>(RoleContext.GENERAL);

  const getContextsByCompanyType = (companyType) => {
    const allContexts: SelectableContext[] = [
      { id: RoleContext.GENERAL, name: t("roles.generalContext") },
      { id: RoleContext.SITE, name: t("roles.siteContext") },
      { id: RoleContext.WORKSITE, name: t("roles.worksiteContext") },
    ];

    const generalContext: SelectableContext[] = [
      { id: RoleContext.GENERAL, name: t("roles.generalContext") },
    ];

    const generalAndSiteContext: SelectableContext[] = [
      { id: RoleContext.GENERAL, name: t("roles.generalContext") },
      { id: RoleContext.WORKSITE, name: t("roles.worksiteContext") },
    ];

    switch (companyType) {
      case "master":
        return allContexts;
      case "smart":
        return generalContext;
      case "standard":
        return generalAndSiteContext;
      default:
        return [];
    }
  };

  type SelectableContext = Identifiable & Named;
  const contexts = getContextsByCompanyType(company?.type);

  const updateName = (n: string) => {
    setName(n);
  };

  return (
    <Modal isOpen onClose={onCancel} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {!update ? t("roles.add") : t("roles.update")}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody id="modal-body">
          <InputAnimatedLabel
            id="name"
            marginTop={8}
            isRequired
            defaultValue={name}
            label={t("roles.name")}
            handleOnChange={(_, value) => updateName(value)}
          />
          {!update && (
            <InputAnimatedSelect
              id="context"
              marginTop={8}
              label={t("roles.context")}
              selectedValue={context}
              onSelect={(id) => setContext(id as RoleContext)}
              items={contexts}
            />
          )}
        </ModalBody>

        <ModalFooter>
          {!update && (
            <Button
              disabled={!name}
              bg={COLORS.sikuroBlue}
              textColor="white"
              onClick={() => onCreate(name, context)}
              isLoading={isCreating}
            >
              {t("create", { ns: "common" })}
            </Button>
          )}
          {update && (
            <Button
              bg={COLORS.sikuroBlue}
              textColor="white"
              onClick={() => onUpdate(name)}
            >
              {t("update", { ns: "common" })}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateUpdateRoleView;
