import { Box, Flex, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaRegSave } from "react-icons/fa";
import { MdCancel, MdClose, MdEdit, MdGroupAdd } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { RoleContext } from "../../../../domain/entities/role";
import User, { UserState } from "../../../../domain/entities/user";
import i18n from "../../../../i18n";
import { languageCodes } from "../../../../infrastructure/utilities/validator";
import { COLORS } from "../../../assets/theme/colors";
import ActionBar from "../../../components/Common/ActionBar";
import ActionBarItem from "../../../components/Common/ActionBarItem";
import DataBox from "../../../components/Common/DataBox";
import FormSelectField from "../../../components/Common/forms/FormSelectField";
import FormTextField from "../../../components/Common/forms/FormTextField";
import InputAnimatedSelect from "../../../components/Common/InputAnimatedSelect";
import StatusToggle from "../../../components/Common/StatusToggle";
import RenderIf,{ useHasPermissions } from "../../../components/Permissions/RenderIf";
import useUpdateUserViewModel, {
  useUpdateUserViewModelProps,
} from "../../../hooks/Users/useUpdateUserViewModel";
import ContentLayout from "../../../layout/ContentLayout";
import { ConfirmAlert } from "../../Common/ConfirmAlert";
import LoadingView from "../../Common/LoadingView";
import AddUserSiteRoleView from "./AddUserSiteRoleView";
import UserSiteRoles from "./UserSiteRoles";
import { Permission } from "../../../components/Permissions/Permissions";
import { PermissionCheck, useAuth } from "../../../providers/Auth0JWTProvider";
import { LicenseType } from "../../../../domain/interactors/auth/args";

const UserDetailsView = () => {
  const { userId } = useParams();
  const { isLoading, user, ...rest } = useUpdateUserViewModel(userId);
  return isLoading || !user ? (
    <LoadingView />
  ) : (
    <UserDetail {...{ ...rest, user, isLoading }} />
  );
};

const UserDetail = ({
  user,
  roles,
  isLoading,
  refetchRoles,
  updateUser,
  updateUserImage,
  siteRolesProps,
  worksiteRolesProps,
}: useUpdateUserViewModelProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("settings");
  const { user: authUser, company } = useAuth();
  const [isTablet] = useMediaQuery("(max-width: 1300px)");
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const [userStateModal, setUserStateModal] = useState<UserState>();
  const [showAddUserSiteRole, setShowAddUserSiteRole] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();

  const [showConfirmUpdateDetails, setShowConfirmUpdateDetails] =
    useState(false);
  const canEditGeneralRole = useHasPermissions([Permission.Settings_EditUserGeneralRole])
  const canViewUsers = useHasPermissions([Permission.Settings_ShowUsers]);
  const canViewSiteRoles = useHasPermissions([Permission.Settings_ShowUserSiteRoles])
  const canViewWorkingSiteRoles = useHasPermissions([Permission.Settings_ShowUserWorkingSiteRoles])
  const canAddUserSiteRole = useHasPermissions([Permission.Settings_AddUserSiteRoles])
  const canAddUserWorkingSiteRole = useHasPermissions([Permission.Settings_AddUserWorkingSiteRoles])
  const canDeleteUserSiteRole = useHasPermissions([Permission.Settings_DeleteUserSiteRoles])
  const canDeleteUserWorkingSiteRole = useHasPermissions([Permission.Settings_DeleteUserWorkingSiteRoles])
  const canEditUserState = useHasPermissions([Permission.Settings_EditUserState])

  const close = () => navigate("/settings/access/users");
  const showAddRole = () => { refetchRoles(); setShowAddUserSiteRole(true)};
  const closeUserStateModal = () => setUserStateModal(undefined);

  const updateRole = (id: string) => {
    const role = roles.find((r) => r.id == id);
    updateUser({ ...user, role });
  };

  const methods = useForm<User>({ mode: "all", defaultValues: user });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    formState: { isValid },
    watch,
  } = methods;

  const confirmUpdateDetails = () => {
    const newEmail = watch("email", "").toLowerCase().trim();
    const oldEmail = user.email.trim().toLocaleLowerCase().trim();

    if (newEmail === oldEmail) {
      handleSubmit(onSubmit)();
    } else {
      setShowConfirmUpdateDetails(true);
    }
  };

  const onSubmit: SubmitHandler<User> = async (data) => {
    setIsEditing(false);
    setShowConfirmUpdateDetails(false);
    if (uploadedImageFile) {
      await updateUserImage(uploadedImageFile);
    }
    await updateUser({
      ...user,
      name: data.name,
      email: data.email,
      language: data.language,
    });
    i18n.changeLanguage(data.language);
  };

  const confirmAdd = async (siteId: string, roleId: string) => {
    const exists = siteRolesProps.userSiteRoles.find(
      (usr) => usr.role.id === roleId && usr.site.id === siteId
    );
    if (exists) {
      return;
    }

    await siteRolesProps.addUserSiteRole({ siteId, roleId });
    setShowAddUserSiteRole(false);
  };

  const roleSiteIds = [
    ...siteRolesProps.userSiteRoles,
    ...worksiteRolesProps.userSiteRoles,
  ].map((s) => s.site.id);

  const availableSites = siteRolesProps.sites.filter(
    (s) => !roleSiteIds.includes(s.id)
  );
  const availableWorksites = worksiteRolesProps.sites.filter(
    (s) => !roleSiteIds.includes(s.id)
  );

  const disabledActionDescription = () => {
    if (
      company?.type === LicenseType.Standard &&
      availableWorksites.length === 0
    ) {
      return t("userSiteRoles.noWorksite");
    } else {
      if (
        company?.type === LicenseType.Master &&
        availableSites.length === 0 &&
        availableWorksites.length === 0
      ) {
        return t("userSiteRoles.noSiteAndWorksite");
      }
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            onClick={close}
            icon={MdClose}
            description={t("close", { ns: "common" })}
            color="white"
            bgColor={COLORS.sikuroBlue}
          />
          <RenderIf permissions={[Permission.Settings_AddUserSiteRoles, Permission.Settings_AddUserWorkingSiteRoles]} check={PermissionCheck.Some}>
            {company?.type !== LicenseType.Smart && (
              <ActionBarItem
                onClick={showAddRole}
                icon={MdGroupAdd}
                description={t("userSiteRoles.add")}
                isDisabled={
                  (company?.type === LicenseType.Standard &&
                    availableWorksites.length === 0) ||
                  (company?.type === LicenseType.Master &&
                    availableSites.length === 0 &&
                    availableWorksites.length === 0)
                }
                disabledDescription={disabledActionDescription()}
              />
            )}
          </RenderIf>

          {canViewUsers && user.isCurrentUser && (
            <ActionBarItem
              icon={isEditing ? MdCancel : MdEdit}
              onClick={() => {
                setIsEditing(!isEditing);
                if (isEditing) {
                  setUploadedImageFile(undefined);
                }
              }}
              description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
            />
          )}

          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={confirmUpdateDetails}
            />
          )}
        </ActionBar>
      }
    >
      {user && (
        <Flex
          px={3}
          py={5}
          gap="20px"
          width={"100%"}
          flexWrap={"wrap"}
        >
          <Box
            py={8}
            border="1px solid"
            borderColor="gray.300"
            borderRadius="10px"
            width={"100%"}
            flex={"1 1 auto"}
            position="relative"
          >
            <FormProvider {...methods}>
              <DataBox
                title={t("details", { ns: "common" })}
                isEditing={isEditing}
                isLoading={isLoading}
                image={{
                  url: uploadedImageFile
                    ? URL.createObjectURL(uploadedImageFile)
                    : user.photo,
                  onUpdate: (f) => setUploadedImageFile(f),
                }}
                fields={[
                  <FormTextField
                    key="name"
                    name="name"
                    label={t("name", { ns: "userSettings" })}
                    rules={requiredRule}
                  />,
                  <FormTextField
                    key="email"
                    name="email"
                    label={t("email", { ns: "userSettings" })}
                    rules={requiredRule}
                  />,
                  <FormSelectField
                    key="language"
                    name="language"
                    label={t("language", { ns: "userSettings" })}
                    displayValue={t(`languages.${user.language}`, {
                      ns: "userSettings",
                    })}
                    options={languageCodes.map((s) => {
                      return {
                        id: s.id,
                        name: t(`languages.${s.id}`, { ns: "userSettings" }),
                      };
                    })}
                  />,
                ]}
              />
            </FormProvider>
          </Box>
          <Flex
            direction={isMobile ? "column" : "row"}
            width={"100%"}
            gap="20px"
          >
            <RenderIf permissions={[Permission.Settings_ShowUserState]}  check={PermissionCheck.All}>
            <Tooltip
              label={
                user.state === "invited"
                  ? t("statusInvitedDisabled", { ns: "userSettings" })
                  : user.role?.name === "owner" || authUser.id === user.id || !canEditUserState
                  ? t("statusDisabled", { ns: "userSettings" })
                  : ""
              }
            >
              <Flex
                p="10px 20px"
                flexDirection="column"
                gap="10px"
                flex="1 1 auto"
                width={isMobile ? "100%" : "50%"}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="10px"
                brightness={"80%"}
                shadow={5}
              >
                <Box textStyle="h2">{t("users.state")}</Box>
                <StatusToggle
                  status={user.state}
                  onChange={(state: UserState) => {
                    if (state !== UserState.INVITED) {
                      setUserStateModal(state); // Users cannot be "invited-back".
                    }
                  }}
                  type="user"
                  disabled={
                    user.role?.name === "owner" ||
                    authUser.id === user.id ||
                    user.state === "invited" || !canEditUserState
                  }
                  isTablet={isTablet}
                  isMobile={isMobile}
                />
              </Flex>
            </Tooltip>
            </RenderIf>
            <RenderIf permissions={[Permission.Settings_ShowUserGeneralRole]}  check={PermissionCheck.All}>
            <Flex
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              flex="1 1 auto"
              width={isMobile ? "100%" : "50%"}
              height="200px"
              px={3}
              py={3}
              direction="column"
            >
              <Box textStyle="h2">{t("generalRole")}</Box>
              <InputAnimatedSelect
                id="general-role"
              items={
                user.role?.name === "owner"
                  ? [user.role]
                  : (roles ?? []).filter(
                      (r) => r.context === RoleContext.GENERAL
                    )
              }
              label={t("roles.select")}
              noSelectionLabel=""
              selectedValue={user?.role?.id ?? ""}
              onSelect={updateRole}
                isDisabled={!canEditGeneralRole || user.role?.name === "owner"}
              />
            </Flex>
            </RenderIf>
          </Flex>
        </Flex>
      )}

      <Flex
        flexDirection={"row"}
        alignItems={"start"}
        position="relative"
        overflow={"hidden"}
        flexWrap="wrap"
        mx={3}
        mb={6}
      >
        {company?.type === LicenseType.Master && canViewSiteRoles && (
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            mr={2}
            flex="1 1 600px"
          >
            <Box textStyle="h2" marginTop="4" marginLeft={3} marginBottom={4}>
              {t("siteRole")}
            </Box>
            <UserSiteRoles siteRolesProps={siteRolesProps} canDelete={canDeleteUserSiteRole} canAdd={canAddUserSiteRole} />
          </Flex>
        )}
        {company?.type !== LicenseType.Smart && canViewWorkingSiteRoles && (
          <Flex
            flexDirection={"column"}
            alignItems={"start"}
            ml={2}
            flex="1 1 600px"
          >
            <Box textStyle="h2" marginTop="4" marginLeft={3} marginBottom={4}>
              {t("workisiteRole")}
            </Box>
            <UserSiteRoles siteRolesProps={worksiteRolesProps} canAdd={canAddUserWorkingSiteRole} canDelete={canDeleteUserWorkingSiteRole} />
          </Flex>
        )}
      </Flex>
      {userStateModal && (
        <ConfirmAlert
          onCancel={closeUserStateModal}
          onConfirm={() => {
            updateUser({ ...user, state: userStateModal as UserState });
            closeUserStateModal();
          }}
          title={
            userStateModal !== UserState.ENABLED
              ? t("users.confirmBlock")
              : t("users.confirmUnblock")
          }
          message=""
          variant="question"
        />
      )}

      {showAddUserSiteRole && (
        <AddUserSiteRoleView
          siteRoles={siteRolesProps.userSiteRoles}
          worksiteRoles={worksiteRolesProps.userSiteRoles}
          roles={roles}
          sites={siteRolesProps.sites}
          worksites={worksiteRolesProps.sites}
          onConfirm={confirmAdd}
          onCancel={() => setShowAddUserSiteRole(false)}
          isLoading={
            worksiteRolesProps.addUserSiteRoleIsLoading ||
            siteRolesProps.addUserSiteRoleIsLoading
          }
          canAddSite={canAddUserSiteRole}
          canAddWorkingSite={canAddUserWorkingSiteRole}
        />
      )}

      {showConfirmUpdateDetails && (
        <ConfirmAlert
          onCancel={() => setShowConfirmUpdateDetails(false)}
          onConfirm={handleSubmit(onSubmit)}
          title={t("confirmChangeUserEmailTitle")}
          message={t("confirmChangeUserEmailMessage")}
          variant="question"
        />
      )}
    </ContentLayout>
  );
};

export default UserDetailsView;
