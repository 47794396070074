import React from "react";
import { Flex, GridItem, Text } from "@chakra-ui/react";
import { COLORS } from "../../assets/theme/colors";
import UseAnimations from "react-useanimations";
import alertOctagon from "react-useanimations/lib/alertOctagon";
import { useTranslation } from "react-i18next";

type VersionProps = {
  wasDeployedNewVersion: boolean;
  handleReload: () => void;
}

const VersionBanner = ({ wasDeployedNewVersion, handleReload }: VersionProps) => {
  const { t } = useTranslation();

  if (!wasDeployedNewVersion) return null;

  return (
    <GridItem bg={COLORS.error} py={1} textColor="white" justifyContent="center" textAlign="center">
      <Flex justifyContent="center" gap={2} fontSize="sm">
        <UseAnimations animation={alertOctagon} size={25} autoplay strokeColor="white" />
        <Text>{t("newVersionAvailable", { ns: "common" })}</Text>
        <Text onClick={handleReload} textDecoration="underline" cursor="pointer">
          {t("getNewVersion", { ns: "common" })}
        </Text>
      </Flex>
    </GridItem>
  );
}

export default VersionBanner;