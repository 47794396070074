import { NewResource, SiteResourcesViewModel } from '../../hooks/Site/useSiteResourcesViewModel';
import {
	makeCreateSiteMachine,
	makeGetSiteMachines,
	makeDeleteSiteMachine,
	makeRestoreSiteMachine,
} from '../../../core/app/factory/siteMachines/usecases';
import { makeGetAvailableMachinesCount, makeGetMachines } from '../../../core/app/factory/machines/usecases';
import { makeGetSiteVariants } from '../../../core/app/factory/variants/usecases';
import { makeGetSiteSpecializations } from '../../../core/app/factory/specializations/usecases';
import { SiteMachine, SiteMachineParams } from '../../../domain/entities/siteMachine';
import { GetSiteMachinesFilters } from '../../../domain/repositories/siteMachineRepository';
import { PaginatedResults, SortMeta } from '../../../domain/entities/interfaces/paginatedResults';
import { DocumentTypeCategory } from '../../../domain/entities/documentTypeCategory.enum';
import {GetResourcesFilter} from "../../../domain/repositories/workerRepository";

class SiteMachinesViewModel implements SiteResourcesViewModel<SiteMachineParams> {
	async getSiteResources(
		companyId: string,
		siteId: string,
		page: number,
		archived?: boolean,
		filter?: GetSiteMachinesFilters,
		sort?: SortMeta,
		supplierId?: string,
	): Promise<PaginatedResults<SiteMachine>> {
		return await makeGetSiteMachines().Call({ companyId, siteId, page, perPage: 25, filter, sort, supplierId, archived });
	}

	async getOwnedResources(
		companyId: string,
		siteId: string,
		filter?: GetResourcesFilter,
		sort?: SortMeta,
		pageParam?: number,
	): Promise<PaginatedResults<SiteMachineParams>> {
		return (await makeGetMachines().Call({ companyId, filter: { siteAvailable: siteId, ...filter }, sort, pageParam })) as unknown as Promise<
			PaginatedResults<SiteMachineParams>
		>;
	}

	async getAvailableResourcesTotalCount(companyId: string, siteId: string, filter?: GetResourcesFilter, sort?: SortMeta): Promise<number> {
		return (await makeGetAvailableMachinesCount().Call({
			companyId,
			filter: { siteAvailable: siteId, ...filter },
			sort,
		})) as unknown as Promise<number>;
	}

	async getVariants(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteVariants().Call({ companyId, siteId, type: DocumentTypeCategory.MACHINE });
	}

	async getSpecializations(companyId: string, siteId: string): Promise<any[]> {
		return await makeGetSiteSpecializations().Call({ companyId, siteId, type: DocumentTypeCategory.MACHINE });
	}

	async createSiteResource(
		companyId: string,
		siteId: string,
		resources: NewResource[],
		selectAll: boolean,
		selectedResourceNumber: number,
		selectAllVariants: string,
		selectAllSpecializations: string[],
		copyLastEvaluation: boolean,
		filterSiteResources?: Record<string, string | string[]>,
	): Promise<boolean> {
		return await makeCreateSiteMachine().Call({
			companyId,
			siteId,
			resources,
			selectAll,
			selectedResourceNumber,
			selectAllVariants,
			selectAllSpecializations,
			copyLastEvaluation,
			filterSiteResources,
		});
	}

	async deleteSiteResource(companyId: string, machineId: string, siteId: string): Promise<void> {
		await makeDeleteSiteMachine().Call({ companyId, machineId, siteId });
	}

	async restoreSiteResource(companyId: string, machineId: string, siteId: string): Promise<void> {
		await makeRestoreSiteMachine().Call({ companyId, machineId, siteId });
	}
}

export default SiteMachinesViewModel;
