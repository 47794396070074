import {
  Button,
  Checkbox,
  HStack,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsPersonCircle } from "react-icons/bs";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import Role from "../../../domain/entities/role";
import User, { UserState } from "../../../domain/entities/user";
import { GetUsersSiteFilter } from "../../../domain/repositories/filters";
import { AddSiteUser } from "../../../domain/repositories/siteRepository";
import InputAnimatedSelect from "../../components/Common/InputAnimatedSelect";
import StateTag from "../../components/Common/StateTag";
import ColumnFilterComponent, {
  ColumnFilterType,
} from "../../components/Common/table/ColumnFilterComponent";
import DeleteButton from "../../components/Common/table/DeleteButton";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, { useHasPermissions } from "../../components/Permissions/RenderIf";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";
import { ConfirmAlert } from "../Common/ConfirmAlert";

interface SelectUserSiteRoleProps {
  users: User[];
  fetchNextPage: () => void;
  updateFilterUsersSite: (row: string, value: string) => void;
  filter: { [p: string]: any };
  sort: SortMeta;
  setSort: (sort: SortMeta) => void;
  roles: Role[];
  showSelectAll: boolean;
  setRoleUser: (value) => void;
  showSelectRoles: boolean;
  setSelectAllUsers?: React.Dispatch<React.SetStateAction<boolean>>;
  userSelectedAction?: (userIds: string[]) => void;
  hasNextPage?: boolean;
  userCount?: number;
  showDeleteButton?: boolean;
  userIds?: string[];
  isLoading?: boolean;
  isInModal?: boolean;
  setUserIds?: (userIds: string[]) => void;
  deleteSiteUser?: (userIds: string[]) => void;
  upsertSiteUser?: (params: AddSiteUser) => void;
  setCurrentUser?: (currentUserId: string) => void;
  canEdit?: Permission;
}

export const SelectUserSiteRole = ({
  users,
  hasNextPage,
  fetchNextPage,
  updateFilterUsersSite,
  filter,
  sort,
  setSort,
  roles,
  showSelectAll,
  setRoleUser,
  showSelectRoles,
  setSelectAllUsers,
  userSelectedAction,
  userCount,
  showDeleteButton,
  userIds,
  isInModal,
  isLoading,
  setUserIds,
  deleteSiteUser,
  upsertSiteUser,
  setCurrentUser,
  canEdit,
}: SelectUserSiteRoleProps) => {
  const { t } = useTranslation("sites");
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllPaginatedUsers, setSelectAllPaginatedUsers] =
    useState<boolean>(false);
  const [showAlertChangeRole, setShowAlertChangeRole] = useState<{
    userId: string;
    value: string;
    userName: string;
    isCurrentUser?: boolean;
  }>();
  const [showDeleteAlert, setShowDeleteAlert = useState] = useState<{
    userId: string;
    userName: string;
    isCurrentUser?: boolean;
  }>();

  const [showClearButton, setShowClearButton] = useState(false)

  const columns: {
    field: keyof GetUsersSiteFilter;
    type: ColumnFilterType;
    options?: Record<string, string>;
  }[] = [
    { field: "name", type: "text" },
    { field: "email", type: "text" },
    { field: "state", type: "select", options: UserState },
  ];

  const canDelete = useHasPermissions(canEdit)

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    setSelectAllUsers && setSelectAllUsers(value);
    if (!value) {
      setUserIds([]);
      setSelectAllPaginatedUsers(false);
    } else {
      setUserIds(users.map((site) => site.id));
    }
    if (hasNextPage) {
      setSelectAllPaginatedUsers(true);
    }
  };

  const toggleItem = (id: string) => {
    let updatedUserIds: string[];
    if (userIds && !userIds?.includes(id)) {
      updatedUserIds = [...userIds, id];
    } else {
      updatedUserIds = userIds?.filter((i) => i !== id);
    }

    setUserIds(updatedUserIds);
    if (updatedUserIds?.length === users?.length) {
      setSelectAll(true);
      setSelectAllUsers && setSelectAllUsers(true);
    } else {
      setSelectAll(false);
      setSelectAllUsers && setSelectAllUsers(false);
    }

    if (userSelectedAction) {
      userSelectedAction(updatedUserIds);
    }
  };

  const handleSelectionButton = () => {
    setShowClearButton(!showClearButton)
    if (showClearButton) {
      setUserIds([]);
      setSelectAll(false);
      setSelectAllUsers && setSelectAllUsers(false);
      setSelectAllPaginatedUsers(false);
    } else {
      setSelectAllPaginatedUsers(true);
      setSelectAllUsers && setSelectAllUsers(true);
    }
  };

  useEffect(() => {
    if (
      userIds?.length > 0 &&
      (selectAllPaginatedUsers || selectAll) && !showClearButton
    ) {
      const ids = userIds.map((user) => user);
      const allIds = [...ids, ...userIds];
      const uniqueIds = Array.from(new Set(allIds));
      setUserIds(uniqueIds);
    }
  }, [userIds, selectAllPaginatedUsers, selectAll]);

  return (
    <>
      <InfiniteTable
        autosize
        tableId={`select-user-site-role-table${isInModal ? "-modal" : ""}`}
        infiniteScroll={{
          dataLength: users?.length,
          hasNextPage: hasNextPage,
          fetchNextPage: fetchNextPage,
        }}
        showEmptyText={users?.length === 0}
        isCheckboxTable={true}
        bottomThreshold={200}
        isLoading={isLoading}
      >
        <Thead>
          <Tr>
            {showSelectAll && (
              <Th key={"selectAllCheckbox"} width={10}>
                <Checkbox
                  borderColor={"gray.500"}
                  isChecked={selectAll}
                  onChange={() => toggleSelectAll(!selectAll)}
                />
              </Th>
            )}
            {columns.map((column) => (
              <Th width={280} key={column.field}>
                <TableColumnHeader
                  text={t(`columns.${column.field}`)}
                  filter={{
                    component: (
                      <ColumnFilterComponent
                        type={column.type}
                        value={filter[column.field]}
                        updateFilter={(value) =>
                          updateFilterUsersSite(column.field, value as string)
                        }
                        optionsTranslationContext={"userState"}
                        namespace={column.field === "state" ? "enum" : "sites"}
                        selectOptions={column.options}
                      />
                    ),
                    isActive: !!filter[column.field],
                  }}
                  sort={{
                    handler: (direction) =>
                      setSort({ field: column.field, direction }),
                    direction:
                      sort && sort.field === column.field
                        ? sort.direction
                        : null,
                  }}
                  isInModal={isInModal}
                />
              </Th>
            ))}
            {showSelectRoles && (
              <Th width={300}>{t("roles.title", { ns: "settings" })}</Th>
            )}
            {showDeleteButton && <Th width={70}/>}
          </Tr>
        </Thead>
        <Tbody>
            {selectAll && (
              <Tr width="100%">
                <Th backgroundColor={"gray.100"}>
                  <Text textAlign="center" mx="auto">
                    {!selectAllPaginatedUsers && t("usersSelectedVisible")}
                    {selectAllPaginatedUsers &&
                      t("usersSelectedNotVisible", {
                        count: userCount,
                      })}
                    {hasNextPage && (
                      <Button
                        mt="10px"
                        ml="4px"
                        colorScheme="blue"
                        variant="link"
                        onClick={() => handleSelectionButton()}
                      >
                        {t(showClearButton ? "clearSelection" : "userSelectAll")}
                      </Button>
                    )}
                  </Text>
                </Th>
              </Tr>
            )}
          {users?.map((user) => {
            user?.isCurrentUser && setCurrentUser(user.id);
            return (
              <Tr width="100%" key={user?.id}>
                {showSelectAll && (
                  <Td width={10}>
                    <Checkbox
                      isChecked={
                        userIds && userIds?.length > 0
                          ? userIds?.includes(user?.id)
                          : false
                      }
                      onChange={() => {
                        return toggleItem(user?.id);
                      }}
                    ></Checkbox>
                  </Td>
                )}
                <Td width={280}>
                  <HStack gap={2}>
                    {user?.isCurrentUser && (
                      <div>
                        <BsPersonCircle />
                      </div>
                    )}
                    <div>{user.name}</div>
                  </HStack>
                </Td>
                <Td width={280}>{user?.email}</Td>
                <Td width={280}>
                  <StateTag value={user?.state} type={"userState"} />
                </Td>

                {showSelectRoles && (
                  <Td width={300} paddingBottom={10}>
                    <InputAnimatedSelect
                      id={"site-role"}
                      label={t("select", { ns: "common" })}
                      selectedValue={user?.role?.id}
                      isDisabled={!canDelete}
                      onSelect={(id: string) => {
                        setShowAlertChangeRole({
                          userId: user?.id,
                          value: id,
                          userName: user.name,
                          isCurrentUser: user?.isCurrentUser,
                        });
                      }}
                      items={roles.map((role) => {
                        return {
                          id: role.id,
                          name: role.name,
                        };
                      })}
                    />
                  </Td>
                )}
                <RenderIf permissions={canEdit} check={PermissionCheck.All}>
                  {showDeleteButton && (
                    <Td width={70}>
                      <DeleteButton
                        onClick={() =>
                          setShowDeleteAlert({
                            userId: user.id,
                            userName: user.name,
                            isCurrentUser: user?.isCurrentUser,
                          })
                        }
                        tooltipLabel={t("delete", { ns: "common" })}
                      />
                    </Td>
                  )}
                </RenderIf>
              </Tr>
            );
          })}
        </Tbody>
      </InfiniteTable>
      {showAlertChangeRole?.userId && (
        <ConfirmAlert
          message={t(
            showAlertChangeRole?.isCurrentUser
              ? "askChangeRoleCurrentUser"
              : "askChangeRole",
            {
              ns: "sites",
              user: showAlertChangeRole.userName,
            },
          )}
          onConfirm={() => {
            upsertSiteUser({
              userIds: [showAlertChangeRole.userId],
              roleId: showAlertChangeRole.value,
            });
            setRoleUser((prevState) => ({
              ...prevState,
              [showAlertChangeRole.userId]: showAlertChangeRole.value,
            }));
            setShowAlertChangeRole(undefined);
          }}
          onCancel={() => setShowAlertChangeRole(undefined)}
          title={t("warning", { ns: "common" })}
          variant="warning"
        ></ConfirmAlert>
      )}
      {showDeleteAlert?.userId && (
        <ConfirmAlert
          message={t(
            showDeleteAlert?.isCurrentUser
              ? "askDeleteCurrentUser"
              : "askDeleteUser",
            {
              ns: "sites",
              user: showDeleteAlert.userName,
            },
          )}
          onConfirm={() => {
            deleteSiteUser([showDeleteAlert?.userId]);
            setShowDeleteAlert(undefined);
          }}
          onCancel={() => setShowDeleteAlert(undefined)}
          title={t("warning", { ns: "common" })}
          variant="warning"
        ></ConfirmAlert>
      )}
    </>
  );
};
