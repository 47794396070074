import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { getSectionPermissions } from "../../components/Permissions/Permissions";
import RequirePermissions from "../../components/Permissions/RequirePermissions";
import SupplierResourcesView from "./SupplierResourcesView";
import {
  getSupplierResourceFallbackRoutes,
} from "../../router/SupplierFallbackRoutes";

const SupplierResources = () => {
  const { supplierId } = useParams();
  const fallbackRoutes = getSupplierResourceFallbackRoutes('workers', supplierId );

  return (
    <RequirePermissions
      permissions={getSectionPermissions("supplier", [
        "workers",
        "machines",
        "tools",
        "vehicles",
        "chemicals",
      ])}
      element={
        <>
          {<SupplierResourcesView /> ??
            <Outlet />}
        </>
      }
      fallbackRoutes={fallbackRoutes}
    />
  );
};

export default SupplierResources;
