import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteChemicalParams } from "../../../domain/entities/siteChemical";
import SiteResourceDetailView from "../../components/Views/Sites/SiteResourceDetailView";
import useSiteChemicalDetailViewModel from "../../hooks/Site/useSiteChemicalDetailViewModel";
import { useResourceFilesDownloader } from "../../hooks/Document/useDocumentsFilesDownloader";
import { AiTaskSectionCode } from "../../../domain/entities/aiTaskSectionCode";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteChemicalDetailView = () => {
  const { siteId, chemicalId } = useParams();
  const resourceColumns = ["name", "companyName"];

  const downloader = useResourceFilesDownloader(
    siteId,
    chemicalId,
    "chemicals"
  );

  return (
    <SiteResourceDetailView<SiteChemicalParams>
      downloader={downloader}
      resourceId={chemicalId}
      type={DocumentTypeCategory.CHEMICAL}
      useViewModel={useSiteChemicalDetailViewModel(
        siteId,
        chemicalId,
        resourceColumns,
        DocumentTypeCategory.CHEMICAL,
        AiTaskSectionCode.SITE_RESOURCES_CHEMICALS
      )}
      resourceColumns={resourceColumns}
      isReadOnly={false}
      permissions={{
        downloadDocuments: Permission.Sites_DownloadChemicalReq,
        showGlobalEvaluation: Permission.Sites_ShowGlobalChemicalEvaluation,
        setGlobalEvaluation: Permission.Sites_SetGlobalChemicalEvaluation,
        addRequirement: Permission.Sites_AddChemicalReq,
        viewRequirements: Permission.Sites_ShowChemicalReq,
        manageDocumentWithAi: Permission.Company_AIManageFile,
        documents: {
          delete: Permission.Sites_DeleteChemicalReq,
          edit: Permission.Sites_EditChemicalReq,
          editVariantSpecializations: Permission.Sites_EditChemicalVariantSpecializations,
          evaluate: Permission.Sites_EvalChemicalReq,
          viewEvaluations: Permission.Sites_ShowChemicalReqEvaluation,
          addFile: Permission.Sites_EditChemicalReq,
          downloadAll: Permission.Sites_DownloadChemicalReq,
          chat: Permission.Sites_AccessChemicalChat,
          setExpiration: Permission.Sites_EvalChemicalReq,
          viewEvaluationExpiration: Permission.Sites_ShowChemicalReqEvaluationExpiration,
          report: Permission.Sites_AddReport,
        },
      }}
    />
  );
};

export default SiteChemicalDetailView;
