import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Grid, GridItem, HStack, Text, useMediaQuery, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash, FaRegSave } from "react-icons/fa";
import { MdCancel, MdEdit } from "react-icons/md";
import User from "../../../domain/entities/user";
import i18n from "../../../i18n";
import { languageCodes, passwordIsConfirmed, passwordIsValid } from "../../../infrastructure/utilities/validator";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import DataBox from "../../components/Common/DataBox";
import FormSelectField from "../../components/Common/forms/FormSelectField";
import TextField from "../../components/Common/TextField";
import useUserSettingsViewModel, { UpdatePasswordArgs} from "../../hooks/Users/useUserSettingsViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { Alert } from "../Common/Alert";
import { ConfirmAlert } from "../Common/ConfirmAlert";
import FormTextField from "../../components/Common/forms/FormTextField";
import { UserSettingsEmails } from "./UserSettingsEmails";
import LoadingView from "../Common/LoadingView";

interface Password extends UpdatePasswordArgs {
  show: boolean;
  errors?: Array<string>;
}

const UserSettingsView = () => {
  const { t } = useTranslation("settings");
  const {
    currentUser,
    isLoading,
    updateUser,
    updateUserImage,
    passwordUpdateError,
    oldPasswordError,
    updatePassword,
    updatePasswordIsLoading,
    errorMessage,
    setErrorMessage,
    updatePasswordData,
    emails,
    emailsIsLoading,
    upsertUserEmail,
    emailError,
    setEmailError,
    upsertUserEmailLoading,
    removeUserEmail,
    filterEmails,
    updateFilterEmails,
    hasNextPage,
    fetchNextPage,
    sort,
    setSort,
  } = useUserSettingsViewModel(() => setPasswordUpdateSuccess(true));
  const toast = useToast();
  const [isTablet] = useMediaQuery("(max-width: 1024px)");

  const [init, setInit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showConfirmUpdateDetails, setShowConfirmUpdateDetails] =
    useState<boolean>(false);

  const [passwordUpdateSuccess, setPasswordUpdateSuccess] =
    useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<Password>({
    value: "",
    confirm: "",
    show: false,
  });
  const [password, setPassword] = useState<Password>({
    value: "",
    confirm: "",
    show: false,
  });
  const [uploadedImageFile, setUploadedImageFile] = useState<File>();

  const validate = (password: string, confirm: string): Array<string> => {
    const errors: Array<string> = [];
    errors.push(...(passwordIsValid(password, "errors.password.") ?? []));
    errors.push(
      ...(passwordIsConfirmed(password, confirm, "errors.password.") ?? [])
    );

    return errors.length > 0
      ? errors.map((e) => t(e, { ns: "onboarding" }))
      : undefined;
  };

  const validatePassword = (v: string) => {
    const errors = validate(v, password.confirm);
    setPassword({ ...password, value: v, errors });
  };

  const validateConfirmPassword = (v: string) => {
    const errors = validate(password.value, v);
    setPassword({ ...password, confirm: v, errors });
  };

  const methods = useForm<User>({ mode: "all", defaultValues: currentUser });
  const requiredRule = { required: t("requiredField", { ns: "common" }) };
  const {
    handleSubmit,
    reset,
    formState: { isValid },
    watch,
  } = methods;

  const confirmUpdateDetails = () => {
    const newEmail = watch("email", "").toLowerCase().trim();
    const oldEmail = currentUser.email.trim().toLocaleLowerCase().trim();

    if (newEmail == oldEmail) {
      handleSubmit(onSubmit)();
    } else {
      setShowConfirmUpdateDetails(true);
    }
  };

  const onSubmit: SubmitHandler<User> = async (data) => {
    setIsEditing(false);
    setShowConfirmUpdateDetails(false);
    if (uploadedImageFile) {
      await updateUserImage(uploadedImageFile);
    }
    await updateUser({
      ...currentUser,
      name: data.name,
      email: data.email,
      language: data.language,
    });
    i18n.changeLanguage(data.language);
  };

  useEffect(() => {
    if (!currentUser || init) {
      return;
    }

    reset({ ...currentUser, name: currentUser.name, email: currentUser.email });
    setInit(true);
  }, [currentUser, init, isEditing, reset]);

  useEffect(() => {
    if (updatePasswordData && passwordUpdateSuccess) {
      toast({
        title: t("passwordUpdateSuccess", { ns: "account" }),
        status: "success",
        duration: 9000,
        isClosable: true
      });
      setOldPassword({
        value: "",
        confirm: "",
        show: false
      });
      setPassword({
        value: "",
        confirm: "",
        show: false
      });
    }
  }, [passwordUpdateSuccess]);

  if(isLoading){
    return <LoadingView/>
  }

  return (
    <ContentLayout
      action={
        <ActionBar>
          <ActionBarItem
            icon={isEditing ? MdCancel : MdEdit}
            onClick={() => {
              setIsEditing(!isEditing);
              if (isEditing) {
                setUploadedImageFile(undefined);
              }
            }}
            description={t(isEditing ? "cancel" : "edit", { ns: "common" })}
          />
          {isEditing && isValid && (
            <ActionBarItem
              description={t("confirm", { ns: "common" })}
              icon={FaRegSave}
              onClick={confirmUpdateDetails}
            />
          )}
        </ActionBar>
      }
    >
      {currentUser && (
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(6, 1fr)"
          gap={4}
          px={3}
          py={5}
        >
          <GridItem colSpan={isTablet ? 6 : 4}>
            <Box
              h={"100%"}
              width={"100%"}
              paddingBottom={6}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
              flex={"1 1 auto"}
            >
              <FormProvider {...methods}>
                <DataBox
                  title={t("details", { ns: "common" })}
                  isEditing={isEditing}
                  isLoading={isLoading}
                  image={{
                    url: uploadedImageFile
                      ? URL.createObjectURL(uploadedImageFile)
                      : currentUser.photo,
                    onUpdate: (f) => setUploadedImageFile(f)
                  }}
                  fields={[
                    <FormTextField
                      key="name"
                      name="name"
                      label={t("name", { ns: "userSettings" })}
                      rules={requiredRule}
                    />,
                    <FormSelectField
                      key="language"
                      name="language"
                      label={t("language", { ns: "userSettings" })}
                      displayValue={t(`languages.${currentUser.language}`, {
                        ns: "userSettings"
                      })}
                      options={languageCodes.map((s) => {
                        return {
                          id: s.id,
                          name: t(`languages.${s.id}`, { ns: "userSettings" })
                        };
                      })}
                    />
                  ]}
                />
              </FormProvider>
            </Box>
          </GridItem>
          <GridItem colSpan={isTablet ? 6 : 2}>
            <Box
              h={"100%"}
              width={"100%"}
              padding={4}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="10px"
            >
              <Box>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Text textStyle="h2" marginTop={5} marginBottom={3}>
                    {t("updatePassword", { ns: "userSettings" })}
                  </Text>
                  <Button
                    borderRadius={6}
                    isLoading={updatePasswordIsLoading}
                    onClick={() =>
                      updatePassword({
                        oldPassword: oldPassword.value,
                        value: password.value,
                        confirm: password.confirm
                      })
                    }
                    colorScheme={"blue"}
                    isDisabled={
                      !oldPassword.value ||
                      !password.value ||
                      password.errors?.length > 0
                    }
                    fontSize={{ base: "14px", lg: "14px", xl: "16px" }}
                  >
                    {t("savePassword", { ns: "account" })}
                  </Button>
                </Flex>

                <Flex flexDirection={"column"} gap={2}>
                  <TextField
                    isRequired
                    id="oldPassword"
                    isProtected={!oldPassword.show}
                    label={t("oldPassword", { ns: "account" })}
                    defaultValue={oldPassword.value}
                    onChange={(_, v) =>
                      setOldPassword({ ...oldPassword, value: v })
                    }
                    actions={[
                      {
                        handler: () =>
                          setOldPassword({
                            ...oldPassword,
                            show: !oldPassword.show
                          }),
                        icon: oldPassword.show ? <FaEyeSlash /> : <FaEye />,
                        hint: t(
                          oldPassword.show ? "hidePassword" : "showPassword"
                        )
                      }
                    ]}
                  />
                </Flex>

                {oldPasswordError && (
                  <Box
                    backgroundColor="orange.100"
                    borderRadius={5}
                    marginTop={4}
                    padding={4}
                  >
                    <HStack spacing={4}>
                      <WarningTwoIcon color="red.500" />
                      <Text>{t("oldPasswordNoMatch", { ns: "account" })}</Text>
                    </HStack>
                  </Box>
                )}
              </Box>

              <Flex
                flexDirection={"column"}
                display={
                  passwordUpdateError?.message === "error.wrong-credentials"
                    ? "block"
                    : "none"
                }
              >
                <Box
                  backgroundColor="orange.100"
                  borderRadius={5}
                  marginTop={4}
                  padding={2}
                >
                  <HStack spacing={4}>
                    <WarningTwoIcon color="red.500" />
                    <Text fontSize={14}>
                      {t("error.wrongCurrentPassword", { ns: "account" })}
                    </Text>
                  </HStack>
                </Box>
              </Flex>

              <Flex flexDirection={"column"}>
                <TextField
                  marginTop={4}
                  isRequired
                  id="password"
                  isProtected={!password.show}
                  label={t("newPassword", { ns: "account" })}
                  defaultValue={password.value}
                  onChange={(_, v) => validatePassword(v)}
                  actions={[
                    {
                      handler: () =>
                        setPassword({ ...password, show: !password.show }),
                      icon: password.show ? <FaEyeSlash /> : <FaEye />,
                      hint: t(password.show ? "hidePassword" : "showPassword")
                    }
                  ]}
                />

                <TextField
                  isRequired
                  marginTop={4}
                  id="password-confirm"
                  isProtected={!password.show}
                  label={t("confirmNewPassword", { ns: "account" })}
                  defaultValue={password.confirm}
                  onChange={(_, v) => validateConfirmPassword(v)}
                  errorMessages={password.errors}
                />

                <Box
                  backgroundColor="orange.100"
                  borderRadius={5}
                  marginTop={4}
                  display={
                    passwordUpdateError &&
                    passwordUpdateError?.message != "error.wrong-credentials"
                      ? "block"
                      : "none"
                  }
                  padding={4}
                >
                  <HStack spacing={4}>
                    <WarningTwoIcon color="red.500" />
                    <Text>{t(passwordUpdateError?.message)}</Text>
                  </HStack>
                </Box>
              </Flex>
            </Box>
          </GridItem>
          <GridItem colSpan={6}>
            <UserSettingsEmails
              upsertEmail={upsertUserEmail}
              isLoading={upsertUserEmailLoading || emailsIsLoading}
              deleteEmail={removeUserEmail}
                                emails={emails}
                                filterEmails={filterEmails}
                                updateFilterEmails={updateFilterEmails}
                                hasNextPage={hasNextPage}
                                fetchNextPage={fetchNextPage}
                                sort={sort}
                                setSort={setSort}
            />
          </GridItem>
        </Grid>
      )}
      {showConfirmUpdateDetails && (
        <ConfirmAlert
          onCancel={() => setShowConfirmUpdateDetails(false)}
          onConfirm={handleSubmit(onSubmit)}
          title={t("confirmChangeUserEmailTitle")}
          message={t("confirmChangeUserEmailMessage")}
          variant={"question"}
        />
      )}

      {errorMessage && (
        <Alert
          onClose={() => setErrorMessage(undefined)}
          title={t("warning", { ns: "common" })}
          message={t(errorMessage, { ns: "errors" })}
          variant={"warning"}
        />
      )} {emailError && (
        <Alert
          onClose={() => setEmailError(undefined)}
          title={t("warning", { ns: "common" })}
          message={t(emailError.message, { ns: "userSettings" })}
          variant={"warning"}
        />
      )}
    </ContentLayout>
  );
};

export default UserSettingsView;
