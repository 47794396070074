import { COLORS } from "../../assets/theme/colors";
import { Flex, Text, TextProps } from "@chakra-ui/react";
import { formatDateBasedOnLanguage } from "../../../utils";
import { MessageType } from "../../../domain/entities/documentComment";

type Props = {
  message?: string;
  author?: string;
  date?: Date;
  subTextProps?: TextProps;
  fontSize?: number;
  searchParameter?: string;
  type?: MessageType;
} & TextProps;

const CommentMessage = ({
  message,
  author,
  date,
  fontSize = 14,
  subTextProps,
  searchParameter,
  type,
  ...textProps
}: Props) => {
  let result = message;
  const regex = /[@#]\[([^\]]+)\]\(([^)]+)\)/g;
  const tags = [];
  let match: any[];

  while ((match = regex.exec(message)) !== null) {
    tags.push({
      id: match[2],
      label: match[1],
      type: match[0].startsWith("@") ? "user" : "document",
    });
  }

  tags.forEach((tag) => {
    result = result.replace(
      `[${tag.label}](${tag.id})`,
      `<strong style="color: ${tag.type === "document" ? COLORS.green : COLORS.darkBlue};">${tag.label}</strong>`,
    );
  });

  return (
    <Flex 
      justifyContent={type === MessageType.COMMAND ? "center" : "start"} 
      flexDirection={"column"}
    >
      <Text
        marginRight={2}
        fontSize={fontSize}
        whiteSpace="pre-wrap"
        dangerouslySetInnerHTML={{
          __html: searchParameter ? result.replace(
            new RegExp(`(${searchParameter})`, 'gi'),
            '<span style="background-color: orange;">$1</span>'
          ) : result
        }}
        {...textProps}
      />
      {(author || date) && (
        <Text fontSize={8} color={"gray.600"} {...subTextProps}>
          {getSubText(author, date)}
        </Text>
      )}
    </Flex>
  );
};

const getSubText = (author?: string, date?: Date) => {
  if (!author && !date) {
    return "";
  }
  if (author && date) {
    return `${author} - ${formatDateBasedOnLanguage(date, true)}`;
  }
  return author || formatDateBasedOnLanguage(date, true);
};

export default CommentMessage;
