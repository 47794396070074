import { makeResourceConversionRepository } from './repositories';
import GetDocumentTypeMapRecords from "../../../../domain/interactors/resourceConversion/getDocumentTypeMapRecords";
import UpsertDocumentTypeMapRecords
  from "../../../../domain/interactors/resourceConversion/upsertDocumentTypeMapRecords";
import GetCustomFieldMapRecords from "../../../../domain/interactors/resourceConversion/getCustomFieldMapRecords";
import UpsertCustomFieldMapRecords from "../../../../domain/interactors/resourceConversion/upsertCustomFieldMapRecords";
import ConvertResources from "../../../../domain/interactors/resourceConversion/convertResources";

export const makeGetDocumentTypeMapRecords = () => {
	return new GetDocumentTypeMapRecords(makeResourceConversionRepository());
};

export const makeUpsertDocumentTypeMapRecords = () => {
	return new UpsertDocumentTypeMapRecords(makeResourceConversionRepository());
};

export const makeGetCustomFieldMapRecords = () => {
  return new GetCustomFieldMapRecords(makeResourceConversionRepository());
};

export const makeUpsertCustomFieldMapRecords = () => {
  return new UpsertCustomFieldMapRecords(makeResourceConversionRepository());
};

export const makeConvertResources = () => {
  return new ConvertResources(makeResourceConversionRepository());
};
