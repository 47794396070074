import { useParams } from "react-router-dom";
import { SiteToolParams } from "../../../domain/entities/siteTool";
import { Permission } from "../../components/Permissions/Permissions";
import useSiteToolsViewModel from "../../hooks/Site/useSiteToolsViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";

const SiteToolsView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["name", "serial"];
  return (
    <SiteResourcesListView<SiteToolParams>
      type={DocumentTypeCategory.TOOL}
      useViewModel={useSiteToolsViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.TOOL,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[Permission.Sites_ShowTools, Permission.Sites_AddTool]}
      evaluatePermissions={Permission.Sites_EvalToolReq}
      removeFromSitePermissions={[
        Permission.Sites_ShowTools,
        Permission.Sites_DeleteTool,
      ]}
      showStatePermission={[Permission.Sites_ShowGlobalToolEvaluation]}
    />
  );
};

export default SiteToolsView;
