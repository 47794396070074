import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge,  Flex, Grid, GridItem, Text, useMediaQuery } from "@chakra-ui/react";
import { ResourceType } from "../../../screens/Site/ResourceSelectableTable";
import { COLORS } from "../../../assets/theme/colors";
import {
  SelectedAllResourcesByType,
  SelectedResourcesByType, SmartDownloadContextType
} from "../../../hooks/SmartDownload/useSmartDownloadViewModel";
import SmartDownloadSelectSubContextDataTable from "./SmartDownloadSelectSubContextDataTable";
import { DocumentTypeFilter } from "../../../../infrastructure/adapters/documentTypeFilter";
import { GetSupplierResourcesFilters } from "../../../hooks/Suppliers/useSupplierResourcesViewModel";
export type ResourceKeys = Exclude<ResourceType, ResourceType.SUPPLIER>;

export type SelectionState = {
  selectedResources: SelectedResourcesByType;
  selectAllVisible: Record<ResourceKeys, boolean>;
  selectAllPaginated: Record<ResourceKeys, boolean>;
}
interface Props {
  data?: any;
  setSelectedDataResourcesByType?: (selectedDataResourcesByType: SelectedResourcesByType) => void;
  setSelectedAllDataResourcesByType?: (selectedAllDataResourcesByType: SelectedAllResourcesByType) => void;
  selectedResourceType?: ResourceType;
  setSelectedResourceType?: (selectedResourcesType: ResourceType) => void;
  context?: SmartDownloadContextType;
}

const resourceTypes = [ResourceType.WORKER, ResourceType.VEHICLE, ResourceType.MACHINE, ResourceType.TOOL, ResourceType.CHEMICAL];
const SmartDownloadSelectSubContextDataByResourceType: FC<Props> = ({
  data,
  setSelectedDataResourcesByType,
  setSelectedAllDataResourcesByType,
  selectedResourceType,
  setSelectedResourceType,
  context
}) => {
  const { t } = useTranslation("common");
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  const [selectionState, setSelectionState] = useState<{
    selectedResources: SelectedResourcesByType;
    selectAllVisible: Record<ResourceKeys, boolean>;
    selectAllPaginated: Record<ResourceKeys, boolean>;
  }>({
    selectedResources: {
      vehicle: [],
      worker: [],
      machine: [],
      tool: [],
      chemical: [],
    },
    selectAllVisible: {
      vehicle: false,
      worker: false,
      machine: false,
      tool: false,
      chemical: false,
    },
    selectAllPaginated: {
      vehicle: false,
      worker: false,
      machine: false,
      tool: false,
      chemical: false,
    },
  });

  useEffect(() => {
    selectedResourceType &&
    (context === "documentType" ? data.setDocumentTypesByResourceType(selectedResourceType) : data.setResourceType(selectedResourceType))
  }, [context, selectedResourceType]);

  useEffect(() => {
    setSelectedDataResourcesByType(selectionState.selectedResources);
    setSelectedAllDataResourcesByType(selectionState.selectAllPaginated);
  }, [selectionState, setSelectedDataResourcesByType, setSelectedAllDataResourcesByType]);
  return (
    <Grid
      templateColumns="25% 75%"
      gap={4}
      border="1px"
      borderColor="gray.200"
      borderRadius={6}
    >
      <GridItem
        borderRight="1px"
        borderColor="gray.200"
      >
        <Text p={4}  fontSize="md" fontWeight="bold">
          {t("smartDownload.selectResourceType")}
        </Text>
        <Flex flexDirection="column">
          {resourceTypes.map((resourceType) => {
            return (
              <Flex
                key={resourceType}
                fontSize="16"
                fontWeight="normal"
                cursor="pointer"
                justifyContent={"space-between"}
                px={6}
                py={2}
                bgColor={selectedResourceType === resourceType ? COLORS.sikuroBlue : "white"}
                color={selectedResourceType === resourceType ? "white" : "black"}
                _hover={{ backgroundColor: COLORS.sikuroBlue, color: "white" }}
                onClick={() => {
                  context === "documentType" ? data.setDocumentTypesByResourceType(resourceType) : data.setResourceType(resourceType);
                  setSelectedResourceType(resourceType);
                }}
              >
                <Text>{t(resourceType + "s", { ns: "audit" })}</Text>
                <Badge ml="1" bgColor={COLORS.darkYellow} fontSize="14px" mx={2}>
                  {selectionState.selectAllPaginated[resourceType] ? data.count : selectionState.selectedResources[resourceType]?.length}
                </Badge>
              </Flex>
            );
          })}
        </Flex>
      </GridItem>

      <GridItem>
        <Text p={4} fontSize="md" fontWeight="bold">
          {context === "documentType" ? t('smartDownload.selectedResourceDocumentsList') : t("smartDownload.selectedResourceList", {resourceType: t(selectedResourceType +'s', {ns: "audit"}).toLowerCase()})}
        </Text>
        <Flex
          width={isMobile ? "calc(100vw - 245px)" : "96%"}
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          position="relative"
          overflow="auto"
          marginTop={6}
          id="table-container-modal"
        >
          {selectedResourceType && (context === "documentType" ?
              <SmartDownloadSelectSubContextDataTable
                data={data.documentTypes}
                count={data.count}
                hasNextPage={data.hasNextPage}
                fetchNextPage={data.fetchNextPage}
                isLoading={data.isLoading}
                setSelectedDataResourcesByType={setSelectedDataResourcesByType}
                resourceType={selectedResourceType}
                filters={data.filters as DocumentTypeFilter}
                sort={data.sort}
                setSort={data.setSort}
                updateFilters={data.updateFilters}
                selectionState={selectionState}
                setSelectionState={setSelectionState}
                customColumns={[{field:"name", label: "name", type: "text"}]}
              />
             :
            <SmartDownloadSelectSubContextDataTable
              data={data.resources}
              count={data.count}
              hasNextPage={data.hasNextPage}
              fetchNextPage={data.fetchNextPage}
              isLoading={data.isLoading}
              setSelectedDataResourcesByType={setSelectedDataResourcesByType}
              resourceType={selectedResourceType}
              filters={data.filters as GetSupplierResourcesFilters}
              sort={data.sort}
              setSort={data.setSort}
              updateFilters={data.updateFilters}
              selectionState={selectionState}
              setSelectionState={setSelectionState}
            />)
          }
        </Flex>
      </GridItem>
    </Grid>
    );
  };
export default SmartDownloadSelectSubContextDataByResourceType;