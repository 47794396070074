import { useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { SiteWorkerParams } from "../../../domain/entities/siteWorker";
import useSiteWorkersViewModel from "../../hooks/Site/useSiteWorkersViewModel";
import SiteResourcesListView from "../../components/Views/Sites/SiteResourcesListView";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";


const SiteWorkersView = () => {
  const { siteId } = useParams();
  const resourceColumns = ["lastName", "firstName", "fiscalCode", "jobTitle"];
  return (
    <SiteResourcesListView<SiteWorkerParams>
      type={DocumentTypeCategory.WORKER}
      useViewModel={useSiteWorkersViewModel(
        siteId,
        resourceColumns,
        DocumentTypeCategory.WORKER,
      )}
      resourceColumns={resourceColumns}
      addToSitePermissions={[
        Permission.Sites_ShowWorkers,
        Permission.Sites_AddWorker,
      ]}
      evaluatePermissions={Permission.Sites_EvalWorkerReq}
      removeFromSitePermissions={[
        Permission.Sites_ShowWorkers,
        Permission.Sites_DeleteWorker,
      ]}
      showStatePermission={[Permission.Sites_ShowGlobalWorkerEvaluation]}
    />
  );
};

export default SiteWorkersView;
