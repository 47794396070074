import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { getSectionPermissions } from "../../components/Permissions/Permissions";
import RequirePermissions from "../../components/Permissions/RequirePermissions";
import SupplierMainView from "./SupplierMainView";
import { getSupplierFallbackRoutes } from "../../router/SupplierFallbackRoutes";

const SupplierMain = () => {
  const { supplierId } = useParams();
  if (!supplierId) {
    return <Navigate to="/access-denied" replace />;
  }

  const fallbackRoutes = getSupplierFallbackRoutes("details", supplierId);

  return (
    <RequirePermissions
      permissions={getSectionPermissions("supplier")}
      element={
        <>
          <SupplierMainView />
          <Outlet />
        </>
      }
      fallbackRoutes={fallbackRoutes}
    />
  );
};

export default SupplierMain;
