import { BsGear } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiOutlineBookOpen } from "react-icons/hi";
import SubNavBar from "../../components/Common/SubNavBar";
import { Permission } from "../../components/Permissions/Permissions";
import { useHasPermissions } from "../../components/Permissions/RenderIf";

const WorkingSiteDetailsSubNav = () => {
  const { siteId } = useParams();
  const { t } = useTranslation("sites");
  const canUseSettings = useHasPermissions([Permission.Worksite_ShowSettings]);
  const canUseDetail = useHasPermissions([Permission.Worksite_ShowDetails]);

  return (
    <SubNavBar
      items={[
        {
          icon: HiOutlineBookOpen,
          path: `/workingsites/${siteId}/site/details`,
          name: t("summary"),
          hidden: !canUseDetail
        },
        {
          icon: BsGear,
          path: `/workingsites/${siteId}/site/options`,
          name: t("options.title"),
          hidden: !canUseSettings,
        },
      ]}
    />
  );
};

export default WorkingSiteDetailsSubNav;
