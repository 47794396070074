export const COLORS = {
	sikuroBlue: '#32a2db',
	darkBlue: '#00397A',
	lightBlue: '#A7DEF5',
	red: '#a45354',
	black: '#000',
	purple: '#4A2B82',
	green: '#477c64',
	yellow: '#eece23',
	lightYellow: '#f9f9d2',
	lightRed: '#ffd7d7',
	lightGreen: '#c3f6d6',
	lightGrey: '#cccccc',
	orange: '#ff7800',
	darkYellow: '#eacd4d',
	table: {
		headerBg: '#e2e8f0',
	},
	error: '#ff5454',
	active: '#5fe6a2',
	gray: '#999999',
	calendar: {
		worker: '#c3f6d6',
		vehicle: '#b3e0f2',
		machine: '#d8b3ff',
		tool: '#fff6b0',
		chemical: '#ffcc99',
		site: '#ffc0cb',
		company: '#d3d3d3',
	},
	white: '#ffffff',
};
