import UseCase from '../../../core/app/usecase';
import DocumentType from '../../entities/documentType';
import ResourceConversionRepository from "../../repositories/resourceConversionRepository";
import {ConversionType} from "../../../presentation/hooks/Resources/useResourceConversionHook";

type Args = {
	companyId: string;
  baseType: ConversionType;
  twinType: ConversionType;
};

export type GetDocumentTypeMapRecordsResult = { baseDocumentType: DocumentType; twinDocumentType: DocumentType }[];

class GetDocumentTypeMapRecords implements UseCase<Args, GetDocumentTypeMapRecordsResult> {
	constructor(private repository: ResourceConversionRepository) {}

	async Call({ companyId, baseType, twinType }: Args): Promise<GetDocumentTypeMapRecordsResult> {
		return await this.repository.getDocumentTypeMapRecords(companyId, baseType, twinType);
	}
}

export default GetDocumentTypeMapRecords;
