import React, {useEffect, useRef, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ResourceConversionViewModel} from "../../viewmodels/resourceConversion/ResourceConversionViewModel";
import {DocumentTypeCategory} from "../../../domain/entities/documentTypeCategory.enum";
import {useNavigate} from "react-router-dom";

export interface DocumentTypeMapRecordInput {
  baseDocumentTypeId: string;
  twinDocumentTypeId: string;
}

export interface CustomFieldMapRecordInput {
  baseCustomFieldKey: string;
  twinCustomFieldKey: string;
}

export type ConversionType = "machine" | "vehicle" | "tool";
export interface useResourceConversionHookProps {
  companyId: string;
  resourceIds: string[];
  baseType: ConversionType;
  twinTypes: ConversionType[];
  hasSites: boolean;
  viewModel: ResourceConversionViewModel
  allResources?: boolean;
}

const BASE_FIELDS = {
  machine: ['model', 'plate'],
  vehicle: ['model', 'plate'],
  // tool: ['serial'],
}

const useResourceConversionHook = ({ companyId, resourceIds, baseType, twinTypes, hasSites, viewModel, allResources = false }: useResourceConversionHookProps) => {
  const [conversionTarget, setConversionTarget] = useState<ConversionType>(twinTypes[0]);
  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [init, setInit] = useState(false);
  const [records, setRecords] = React.useState<DocumentTypeMapRecordInput[]>([]);
  const [customFieldRecords, setCustomFieldRecords] = React.useState<CustomFieldMapRecordInput[]>([]);
  const hasChanged = useRef(false);
  const navigate = useNavigate();
  const conversionBaseFields = BASE_FIELDS[baseType]?.filter(field => !BASE_FIELDS[conversionTarget].includes(field)) ?? [];
  const conversionTwinFields = BASE_FIELDS[conversionTarget]?.filter(field => !BASE_FIELDS[baseType].includes(field)) ?? [];

  const getOriginDocumentTypes = useQuery(
    [baseType, `document-types-unpaginated`, companyId, resourceIds],
    async () => {
      setInit(false);
      return await viewModel.getBaseDocumentTypes(companyId, baseType, resourceIds, allResources);
    },
    {
      enabled: openMigrationModal && !hasSites,
    },
  );

  const getCustomFieldsQuery = useQuery(
    [companyId, 'custom-fields'],
    async () => await viewModel.getCustomFields(companyId),
  );

  const getMapRecordsQuery = useQuery(
    [companyId, baseType, conversionTarget, `document-type-map-records`],
    async () => {
      const results = await viewModel.getDocumentTypeMapRecords(companyId, baseType, conversionTarget);
      setRecords(
        getOriginDocumentTypes.data.map((d) => ({
          twinDocumentTypeId: results.find(
            (r) => r.baseDocumentType.id === d.id,
          )?.twinDocumentType.id,
          baseDocumentTypeId: d.id,
        })),
      );
      return results;
    },
    {
      enabled: getOriginDocumentTypes.isFetched && openMigrationModal && !hasSites
    },
  );

  const getCustomFieldMapRecordsQuery = useQuery(
    [companyId, baseType, conversionTarget, `custom-field-map-records`],
    async () => {
      const results = await viewModel.getCustomFieldMapRecords(companyId, baseType, conversionTarget);
      setCustomFieldRecords([
        ...baseCustomFields.map((customField) => ({
          twinCustomFieldKey: results.find((r) => r.baseCustomFieldKey === customField.key)?.twinCustomFieldKey,
          baseCustomFieldKey: customField.key,
        })),
        ...conversionBaseFields.map(field => ({
          twinCustomFieldKey: results.find((r) => r.baseCustomFieldKey === field)?.twinCustomFieldKey,
          baseCustomFieldKey: field,
        }))
      ])
      setInit(true);
      return results;
    },
    {
      enabled: openMigrationModal && getCustomFieldsQuery.isFetched && !hasSites,
    },
  );

  const getTargetTypologiesQuery = useQuery(
    [companyId, conversionTarget, `typologies`],
    async () => await viewModel.getTypologies(companyId, conversionTarget as DocumentTypeCategory),
    {
      enabled: openMigrationModal && !hasSites,
    },
  );

  const convertResourceMutation = useMutation(
    async ({ typologyId }: { typologyId: string }) => {
      const result =  await viewModel.convertResource(companyId, resourceIds, typologyId, baseType, conversionTarget);
      if (resourceIds.length === 1) {
        setOpenMigrationModal(false);
        navigate(
          `/resources/${conversionTarget}s/${result}/details`,
        );
        return []
      } else {
        return result as string[]
      }
    },
    {
      onError: (e) => console.error(e),
    },
  );

  const upsertMapRecords = useMutation(
    async (records: DocumentTypeMapRecordInput[]) => {
      return await viewModel.upsertDocumentTypeMapRecords(companyId, baseType, conversionTarget, records)
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getMapRecordsQuery.refetch(),
    },
  );

  const upsertCustomFieldMapRecordsMutation = useMutation(
    async (records: CustomFieldMapRecordInput[]) => {
      return await viewModel.upsertCustomFieldMapRecords(companyId, baseType, conversionTarget, records)
    },
    {
      onError: (e) => console.error(e),
      onSuccess: () => getMapRecordsQuery.refetch(),
    },
  );

  const getTargetDocumentTypesQuery = useQuery(
    [companyId, conversionTarget, 'document-types'],
    async () => await viewModel.getDocumentTypes(companyId, conversionTarget),
    {
      enabled: openMigrationModal && !hasSites,
    },
  );

  const handleSetCurrentTarget = (target: ConversionType) => {
    if (conversionTarget !== target) {
      setConversionTarget(target);
    } else {
      setOpenMigrationModal(true);
    }
  }

  const getCustomFieldsBySubject = (subject: string) => getCustomFieldsQuery.data?.filter(cf => cf.subject === subject) ?? [];
  const baseCustomFields = getCustomFieldsBySubject(baseType);
  const twinCustomFields = getCustomFieldsBySubject(conversionTarget)

  useEffect(() => {
    if (hasChanged.current) {
      setOpenMigrationModal(true)
    } else {
      hasChanged.current = true; // Set the flag after the initial render
    }
  }, [conversionTarget]);

  return {
    baseType,
    twinTypes,
    targetTypologies: getTargetTypologiesQuery.data ?? [],
    convert: convertResourceMutation.mutateAsync,
    init,
    isMulti: resourceIds.length > 1,
    loading:
      convertResourceMutation.isLoading ||
      getTargetTypologiesQuery.isFetching ||
      getMapRecordsQuery.isFetching ||
      getCustomFieldMapRecordsQuery.isLoading ||
      upsertMapRecords.isLoading ||
      upsertCustomFieldMapRecordsMutation.isLoading ||
      getOriginDocumentTypes.isFetching ||
      getTargetDocumentTypesQuery.isFetching ||
      getCustomFieldsQuery.isFetching,
    upsertMapRecords: upsertMapRecords.mutateAsync,
    upsertCustomFieldMapRecords: upsertCustomFieldMapRecordsMutation.mutateAsync,
    documentTypeMapRecords: getMapRecordsQuery.data ?? [],
    customFieldMapRecords: getCustomFieldMapRecordsQuery.data ?? [],
    documentTypes: getOriginDocumentTypes.data ?? [],
    openMigrationModal,
    setOpenMigrationModal,
    targetDocumentTypes: getTargetDocumentTypesQuery.data ?? [],
    records, setRecords,
    baseCustomFields, twinCustomFields,
    conversionBaseFields, conversionTwinFields,
    customFieldRecords, setCustomFieldRecords,
    conversionTarget: conversionTarget as ConversionType,
    setConversionTarget: handleSetCurrentTarget,
    hasSites,
    customFieldsIsFetching: getCustomFieldsQuery.isFetching,
    getCustomFieldsQuery,
    disabled: resourceIds.length === 0
  }
}

export default useResourceConversionHook