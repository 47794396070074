import {
  Button,
  Checkbox,
  Flex,
  List,
  ListIcon,
  ListItem, Spinner,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery
} from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaCheckCircle, FaCloudDownloadAlt } from "react-icons/fa";
import { FaTriangleExclamation } from "react-icons/fa6";
import { FiPlus, FiUpload } from "react-icons/fi";
import { LuPackagePlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { CompanyResourceType } from "../../../domain/entities/companyResourceType.enum";
import { CustomField, FieldType } from "../../../domain/entities/customFields";
import FileEntity from "../../../domain/entities/file";
import { Identifiable } from "../../../domain/entities/interfaces/identifiable";
import { SortDirection } from "../../../domain/entities/interfaces/paginatedResults";
import Typology from "../../../domain/entities/typology";
import { GetResourcesFilter } from "../../../domain/repositories/workerRepository";
import { removeEmptyProperties } from "../../../utils";
import { COLORS } from "../../assets/theme/colors";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import BaseModal from "../../components/Common/alerts/BaseModal";
import ArchivedResourceToggle from "../../components/Common/ArchivedResourceToggle";
import FormDateField from "../../components/Common/forms/FormDateField";
import { EditableFormFieldProps } from "../../components/Common/forms/FormField";
import FormTextField from "../../components/Common/forms/FormTextField";
import ColumnFilterComponent from "../../components/Common/table/ColumnFilterComponent";
import DeleteButton from "../../components/Common/table/DeleteButton";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import RestoreButton from "../../components/Common/table/RestoreButton";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf, {
  useHasPermissions,
} from "../../components/Permissions/RenderIf";
import SelectSitesView from "../../components/Views/common/SelectSitesView";
import CreateResourceModal from "../../components/Views/Resources/CreateResourceModal";
import ResourceImportModal from "../../components/Views/Resources/ResourcesImportModal";
import {
  ResourceListViewModel,
  useResourceListViewModel,
} from "../../hooks/Resources/useResourceListViewModel";
import ContentLayout from "../../layout/ContentLayout";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import SearchInput from "../Common/SearchInput";
import ResourceConversionButton from "./ResourceConversionButton";
import {ConversionType} from "../../hooks/Resources/useResourceConversionHook";
import SmartDownloadModal from "../../components/Common/smartDownload/SmartDownloadModal";
import { LicenseType } from "../../../domain/interactors/auth/args";
import { useAuth, PermissionCheck } from "../../providers/Auth0JWTProvider";
import useOpenNewTab from "../../hooks/Common/useOpenNewTab";

type ResourceListViewProps<T> = {
  viewModel: ResourceListViewModel<T>;
  type: CompanyResourceType;
  permissions: {
    add: Permission
    delete: Permission
    import: Permission
    showResourceSites?: Permission
    showCopyLastEvaluation?: Permission
    download?: Permission};
    columns: {
      field: keyof GetResourcesFilter;
      type: "text" | "tags";
      label: string;
    }[];
  namespace: string;
  excludeFromSorting?: Set<string>;
  cellRender?: (column: string, value: string) => React.ReactNode;
  formMethods: any;
  createFields?: Array<ReactElement<Omit<EditableFormFieldProps, "isEditing">>>;
  viewDetailsPermissions: Permission[];
  convertResourcePermissions?: {
    [key: string]: Permission
  }
  twinTypes?: ConversionType[]
};

function ResourceListView<T extends Identifiable>({
  type,
  permissions,
  formMethods,
  columns,
  namespace,
  excludeFromSorting,
  cellRender,
  createFields,
  viewDetailsPermissions,
  viewModel,
  convertResourcePermissions = {},
  twinTypes = []
}: Readonly<ResourceListViewProps<T>>) {
  const { t } = useTranslation();
  const { company, hasPermissions } = useAuth();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<string | false>(false);
  const [showRestoreModal, setShowRestoreModal] = useState<string | false>(
    false,
  );
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [errorUpload, setErrorUpload] = useState<string | null>(null);
  const [linkResourcesModal, setLinkResourcesModal] = useState<boolean>(false);
  const [selectedSiteIds, setSelectedSiteIds] = useState<string[]>();
  const [showDownload, setShowDownload] = useState<boolean>(false);

  const showCopyLastEvaluation = hasPermissions([permissions.showCopyLastEvaluation]);

  const {
    showArchived,
    setShowArchived,
    deleteResource,
    restoreResource,
    createResource,
    typologies,
    uploadResources,
    uploadResourcesError,
    createResourceLoading,
    deleteResourcesLoading,
    resources,
    resourcesCount,
    resourcesHasNextPage,
    resourcesFetchNextPage,
    isLoading,
    filter,
    updateFilterResources,
    resourcesLinkableSitesCount,
    setSelectAllLinkableSites,
    setSearch,
    sort,
    setSort,
    siteCollection,
    siteCollectionHasNextPage,
    siteCollectionFetchNextPage,
    sortSiteCollection,
    siteCollectionIsFetching,
    setSortSiteCollection,
    filterSiteCollection,
    updateFilterSiteCollection,
    addResorcesToSites,
    addResourcesToSiteWarning,
    setAddResourcesToSiteWarning,
    selectAllLinkableSites,
    setEnableGetLinkableSites,
    setFilterSiteCollection,
    isAddingResorcesToSites,
    customFields,
    setSelectedResources,
    selectedResources,
    selectAllVisibleResources,
    setSelectAllVisibleResources,
    selectAllPaginatedResources,
    setSelectAllPaginatedResources,
    conversionProps
  } = useResourceListViewModel(viewModel, type, twinTypes);
  const { handleCustomClick, handleCustomOnMouse } = useOpenNewTab();
  const [isTablet] = useMediaQuery("(max-width: 1024px)");
  const customCreateFields = !isLoading && !!customFields && !!customFields.data ? getCustomFields(customFields.data) : [];

  const [typology, setTypology] = useState<Typology>();
  const [copyLastEvaluation, setCopyLastEvaluation] = useState(false)
  const [selectAll, setSelectAll] = useState(false);
  const {
    handleSubmit,
    formState: { isValid },
    reset,
    getValues,
  } = formMethods;
  const [showClearButton, setShowClearButton] = useState(false)

  const canViewDetails = useHasPermissions(viewDetailsPermissions);

  const sortHeader = (
    column: string,
  ): {
    direction: SortDirection;
    handler: (direction: SortDirection) => void;
  } => {
    if (excludeFromSorting?.has(column)) {
      return null;
    }

    return {
      handler: (direction) => setSort({ field: column, direction }),
      direction: sort && sort.field === column ? sort.direction : null,
    };
  };

  const renderField = <T extends Identifiable>(
    resource: T,
    column: string,
  ): React.ReactNode => {
    if (!cellRender) {
      return <>{resource[column]}</>;
    }

    return cellRender(column, resource[column]);
  };

  const onSubmit: SubmitHandler<T> = async (resource: T) => {
    const res = removeEmptyProperties(resource) as T;
    if (typology) {
      resource = { ...res, typologyId: typology.id };
    }
    let image: File | null = null;
    if (
      Object.hasOwn(resource, "photo") &&
      getValues("photo") instanceof File
    ) {
      image = getValues("photo");
    }

    await createResource({ resource, image });
    setTypology(undefined);
    setShowCreateModal(false);
  };

  const typeResource = t(type + ".resources", {
    ns: "siteResources",
  }).toLowerCase();

  const handleUpload = (file: FileEntity) => {
    return uploadResources(file);
  };
  const handleLinkToSites = async () => {
    await addResorcesToSites({
      resourceIds: selectedResources,
      siteIds: selectAllLinkableSites ? [] :  selectedSiteIds,
      copyLastEvaluation,
      selectAllSites: selectAllLinkableSites,
      selectAllResources: !selectAllPaginatedResources ? false : selectAllVisibleResources,
    });
    setFilterSiteCollection(null)
    setLinkResourcesModal(false);
    setSelectedSiteIds([]);
    setSelectedResources([]);
    setSelectAllVisibleResources(false);
  };

const toggleItem = (id: string) => {
  if (selectAllVisibleResources || selectAllPaginatedResources) {
    setSelectAll(false);
    setSelectAllVisibleResources(false);
    setSelectAllPaginatedResources(false);
  }
  let updatedIds;

  if (selectedResources && !selectedResources.includes(id)) {
    updatedIds = [...selectedResources, id];
  } else {
    updatedIds = selectedResources.filter((i) => i !== id);
  }

  setSelectedResources(updatedIds);

};

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    if (!value) {
      setSelectedResources([]);
      setSelectAllPaginatedResources(false);
      setSelectAllVisibleResources(false)
    } else {
      setSelectAllVisibleResources(value);
      setSelectedResources(resources.map((site) => site.id));
    }
  };

  const handleSelectionButton = () => {
    setShowClearButton(!showClearButton)
    if (showClearButton) {
      setSelectedResources([]);
      setSelectAll(false);
      setSelectAllVisibleResources(false);
      setSelectAllPaginatedResources(false);
    } else {
      setSelectAllPaginatedResources(true);
      setSelectAllVisibleResources(true);
      setSelectAll(true);
      setSelectedResources(resources.map((site) => site.id));
    }
  };

  const convertPermissions = Object.fromEntries(
    Object.entries(convertResourcePermissions).map(([type, permission]) => {
      const newValue = hasPermissions([permission], 0);
      return [type, newValue]; // Return the mapped key-value pair
    })
  );
  const allowedTwinTypes = conversionProps.twinTypes.map(twinType => convertPermissions[twinType] && twinType)
    .filter(tt => tt) as ConversionType[]

  useEffect(() => {
    showCreateModal && reset()
  }, [reset, showCreateModal]);

  useEffect(() => {
    if (uploadResourcesError) {
      setErrorUpload(uploadResourcesError);
    }
  }, [uploadResourcesError]);

  useEffect(() => {
    if (
      resources?.length > 0 &&
      (selectAllPaginatedResources || selectAll) && showClearButton
    ) {
      const ids = resources.map((evaluation) => evaluation.id);
      const allIds = [...ids, ...selectedResources];
      const uniqueIds = Array.from(new Set(allIds));
      setSelectedResources(uniqueIds);
    }
  }, [resources]);
  
  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.add} check={PermissionCheck.All}>
            <ActionBarItem
              icon={FiPlus}
              description={t("add", { ns: namespace })}
              onClick={() => setShowCreateModal(true)}
            />
          </RenderIf>
          <RenderIf permissions={permissions.import} check={PermissionCheck.All}>
            <ActionBarItem
              icon={FiUpload}
              color="white"
              bgColor={COLORS.sikuroBlue}
              description={t("import", { ns: "common", target: typeResource })}
              onClick={() => setShowImportModal(true)}
            />
          </RenderIf>

          {company?.type !== LicenseType.Smart &&
            <RenderIf permissions={permissions.showResourceSites} check={PermissionCheck.All}>
              <ActionBarItem
                onClick={() => {
                  setEnableGetLinkableSites(true);
                  setLinkResourcesModal(true);
                }}
                icon={LuPackagePlus }
                description={t("addResourcesToSites", { ns: "sites" })}
                disabledDescription={t("noResourcesSelected", { ns: "sites" })}
                isDisabled={selectedResources.length === 0}
              />
            </RenderIf>
          }
          <RenderIf permissions={permissions.download} check={PermissionCheck.All}>
            <ActionBarItem
              description={t("smartDownload.resourcesSelected", { ns: "common" })}
              disabledDescription={selectedResources.length === 0 && t("smartDownload.noResourcesSelected", {ns: "common"})}
              icon={FaCloudDownloadAlt}
              onClick={()=>{setShowDownload(true)}}
              isDisabled={selectedResources.length === 0}/>
          </RenderIf>
          {allowedTwinTypes.length > 0 && (
            <ResourceConversionButton
              {...conversionProps}
              twinTypes={allowedTwinTypes}
            />
          )}
        </ActionBar>}
    >
      <Flex
        alignItems="center"
        justifyContent="start"
        textAlign="center"
        flex={1}
        h="100%"
        w="100%"
        paddingLeft={10}
        paddingBottom={10}
        paddingTop={10}
        paddingRight={2}
        bg="white"
        flexDirection="column"
      >
        <Flex
          flexDirection={isTablet ? "column" : "row"}
          justifyContent="space-between"
          gap={4}
          alignItems={isTablet ? "start" : "center"}
          width="calc(100vw - 180px)"
        >
          <ArchivedResourceToggle
            type={type}
            showArchived={showArchived}
            selectTab={(flag) => setShowArchived(flag)}
          />
          <SearchInput onSearch={setSearch} />
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="start"
          border="1px solid"
          borderColor="gray.300"
          borderRadius="10px"
          width="calc(100vw - 180px)"
          position="relative"
          overflow="auto"
          marginTop={6}
          id="table-container"
        >
          <InfiniteTable
            tableId="resource-list-table"
            autosize
            infiniteScroll={{
              dataLength: resources.length,
              hasNextPage: resourcesHasNextPage,
              fetchNextPage: resourcesFetchNextPage,
            }}
            emptyText={t("noActiveResource", { ns: "siteResources" })}
            showEmptyText={resources.length === 0}
            isLoading={isLoading}
          >
            <Thead>
              <Tr>
                <Th key={"selectAllCheckbox"} width={10}>
                  <Checkbox
                    borderColor={"gray.500"}
                    isChecked={selectAll || (resources.length === selectedResources.length && selectedResources.length > 0 )}
                    onChange={() => toggleSelectAll(!selectAll)}
                  ></Checkbox>
                </Th>
                {Array.isArray(columns) &&
                  columns.map((column) => (
                    <Th
                      width={column.field === "fiscalCode" ? 230 : 200}
                      key={column.field}
                    >
                      <TableColumnHeader
                        filter={{
                          component: (
                            <ColumnFilterComponent
                              type={column.type}
                              value={filter[column.field]}
                              updateFilter={(value) => {
                               if(setSelectAllVisibleResources) {
                                  setSelectedResources([])
                                  setSelectAllVisibleResources(false)}
                                  updateFilterResources(
                                    column.field,
                                    value as string
                                  );
                                }
                              }
                              namespace="siteResources"
                            />
                          ),
                          isActive: !!(Array.isArray(filter[column.field])
                            ? filter[column.field][0]
                            : filter[column.field]),
                        }}
                        text={t(column.label, { ns: namespace })}
                        sort={sortHeader(column.field)}
                      />
                    </Th>
                  ))}
                <RenderIf permissions={permissions.delete} check={PermissionCheck.All}>
                  <Th w={10} />
                </RenderIf>
              </Tr>
            </Thead>
            <Tbody style={selectAllVisibleResources ? { "tableLayout" : "auto" } : { "tableLayout" : "fixed" }}>
              {selectAllVisibleResources && (
                <Tr width="100%">
                  <Th colSpan={columns.length + 2} backgroundColor={"gray.100"}>
                    <Flex flexDirection={'column'} alignItems={'center'}>
                      {selectAllPaginatedResources ?
                        t("resourceSelectedNotVisible", {
                          ns: "sites",
                          count: resourcesCount,
                        }) :
                        t("resourceSelectedVisible", { ns: "sites" })}
                      {resourcesHasNextPage && (
                        <Button
                          mt="10px"
                          ml="4px"
                          colorScheme="blue"
                          variant="link"
                          onClick={() => handleSelectionButton() }
                        >
                          {t(
                            showClearButton
                              ? "clearSelection"
                              : "resourcesSelectAll",
                            { ns: "sites" },
                          )}
                        </Button>
                      )}
                    </Flex>
                  </Th>
                </Tr>
              )}
              {resources.map((resource) => (
                <Tr
                  key={resource.id}
                  onClick={(event) => canViewDetails && handleCustomClick(event, `${location.pathname}/${resource.id}/details`)}
                  onMouseDown={(event) => canViewDetails && handleCustomOnMouse(event, `${location.pathname}/${resource.id}/details`)}
                  sx={{ cursor: canViewDetails ? "pointer" : undefined }}
                >
                  <Td maxWidth={10} width={10} onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      borderColor={"gray.500"}
                      isChecked={
                        selectedResources
                          ? selectedResources?.includes(resource?.id)
                          : false
                      }
                      onChange={(e) => {
                        e.stopPropagation();
                        toggleItem(resource?.id);
                      }}
                      marginRight={3}
                    ></Checkbox>
                  </Td>
                  {Array.isArray(columns) &&
                    columns.map((column) => (
                      <Td
                        width={column.field === "fiscalCode" ? 230 : 200}
                        key={column.field}
                      >
                        {renderField(resource, column.field)}
                      </Td>
                    ))}
                  <RenderIf permissions={permissions.delete} check={PermissionCheck.All}>
                    <Td p={0} w={10}>
                      {showArchived && (
                        <RestoreButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowRestoreModal(resource.id);
                          }}
                        />
                      )}
                      {!showArchived && (
                        <DeleteButton
                          tooltipLabel={t("archive", { ns: "common" })}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(resource.id);
                          }}
                        />
                      )}
                    </Td>
                  </RenderIf>
                </Tr>
              ))}
            </Tbody>
          </InfiniteTable>
        </Flex>
      </Flex>
      {showCreateModal && (
        <FormProvider {...formMethods}>
          <CreateResourceModal<T>
            isLoading={createResourceLoading}
            createFields={[...createFields, ...customCreateFields]}
            namespace={namespace}
            onClose={() => {
              reset()
              setShowCreateModal(false);
            }}
            createResource={createResource}
            typologies={typologies}
            isValid={isValid}
            onConfirm={handleSubmit(onSubmit)}
            onSelectTypology={(t) => {
              setTypology(t);
            }}
            selectedTypology={typology}
          />
        </FormProvider>
      )}
      {showDeleteModal && (
        <DeleteActionAlert
          isLoading={deleteResourcesLoading}
          mainTitle={t("warning")}
          title={t("confirmArchive", { ns: namespace })}
          isOpen={true}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={async () => {
            await deleteResource(showDeleteModal);
            setShowDeleteModal(false);
          }}
        />
      )}
      {showRestoreModal && (
        <DeleteActionAlert
          mainTitle={t("warning")}
          title={t("confirmRestore", { ns: namespace })}
          isOpen={true}
          onCancel={() => setShowRestoreModal(false)}
          onConfirm={() => {
            restoreResource(showRestoreModal);
            setShowRestoreModal(false);
          }}
        />
      )}

      {showImportModal && (
        <ResourceImportModal
          error={errorUpload}
          resourceType={type}
          resourceTypeTranslation={typeResource}
          title={t("import", { ns: "common", target: typeResource })}
          onClose={() => {
            setShowImportModal(false);
            setErrorUpload(null);
          }}
          onConfirm={handleUpload}
        />
      )}

      {linkResourcesModal && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setLinkResourcesModal(false)}
          footer={[
            <Button
              key="confirm"
              isDisabled={!selectedSiteIds || selectedSiteIds?.length === 0 || isAddingResorcesToSites}
              colorScheme="blue"
              onClick={() => handleLinkToSites()}
            >
              {t("confirm" ,{ns: "common"})} {isAddingResorcesToSites && <Spinner size={'sm'} ml={2}  /> }
            </Button>,
            <Button
              key="cancel"
              colorScheme="red"
              onClick={() => setLinkResourcesModal(false)}
            >
              {t("cancel", { ns: "common" })}
            </Button>,
          ]}
        >
          <SelectSitesView
            autosize
            title={t("selectFromLinkableSite", { ns: "sites" })}
            showCopyLastEvaluation={showCopyLastEvaluation}
            copyLastEvaluation={copyLastEvaluation}
            setCopyLastEvaluation={setCopyLastEvaluation}
            siteSelectedAction={setSelectedSiteIds}
            siteList={siteCollection}
            setSortResourceSites={setSortSiteCollection}
            sortResourceSites={sortSiteCollection}
            filterResourceSites={filterSiteCollection}
            updateFilterResourceSites={updateFilterSiteCollection}
            isFetchingSites={siteCollectionIsFetching}
            hasNextPage={siteCollectionHasNextPage}
            fetchNextPage={() => siteCollectionFetchNextPage()}
            includeFooterButton={false}
            siteCount={resourcesLinkableSitesCount}
            setSelectAllSites={setSelectAllLinkableSites}
            selectAllSites={selectAllLinkableSites}
            showSelectAll={true}
            hideSubtitles={true}
          />
        </BaseModal>
      )}
      {addResourcesToSiteWarning && (
        <BaseModal
          closeOnOverlayClick={false}
          onClose={() => setAddResourcesToSiteWarning(null)}
          footer={[
            <Button
              key="confirm"
              colorScheme="blue"
              onClick={() => setAddResourcesToSiteWarning(null)}
            >
              {t("ok", { ns: "common" })}
            </Button>,
          ]}
        >
          <Text>
            {t("addResourcesToSitesWarning.title", {
              ns: "sites",
              selectedResources:
                addResourcesToSiteWarning.requestCountResources,
              selectedSites: addResourcesToSiteWarning.requestCountSites,
              total:
                addResourcesToSiteWarning.requestCountResources *
                addResourcesToSiteWarning.requestCountSites,
            })}
          </Text>
          <List spacing={3}>
            <ListItem
              display={"flex"}
              gap={4}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <ListIcon as={FaCheckCircle} color={COLORS.green} />
              {t("addResourcesToSitesWarning.success", {
                ns: "sites",
                warningCount: addResourcesToSiteWarning?.responseCountSuccess,
              })}
            </ListItem>
            <ListItem
              display={"flex"}
              gap={4}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <ListIcon as={FaTriangleExclamation} color={COLORS.error} />
              {t("addResourcesToSitesWarning.warning", {
                ns: "sites",
                successCount: addResourcesToSiteWarning?.responseCountWarning,
              })}
            </ListItem>
          </List>
        </BaseModal>
      )}
      {showDownload && (
        <SmartDownloadModal onClose={() => {
          setShowDownload(false);
          setSelectedResources([]);
          setSelectAllVisibleResources(false);
        }}
        selectedResources={selectedResources}
        selectedFilterResources={filter}
        resourceCount={resourcesCount}
        selectAllResources={selectAllPaginatedResources}
        resourceType={type as any}/>
      )}
    </ContentLayout>
  );
}

function getCustomFields(customFields: CustomField[]) {
  return customFields.map(({key, label, type}) => {
    const props = {name: `custom_${key}`, label, key}
    if (type === FieldType.NUMBER)
      return (
        <FormTextField key={key} type="number" {...props} />
      )
    if (type === FieldType.DATE)
      return (
        <FormDateField key={key} withPortal {...props} />
      )
    return (
      <FormTextField key={key} {...props} />
    )
  });
}

export default ResourceListView;
