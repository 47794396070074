import RequirementsView from "../Common/RequirementsView";
import { useLocation, useParams } from "react-router-dom";
import { Permission } from "../../components/Permissions/Permissions";
import { RequirementSubject } from "../../../domain/entities/requirementSubject.enum";
import useSiteRequirementsViewModel from "../../hooks/Site/useSiteRequirementsViewModel";
import { FC } from "react";

const SiteRequirementsView: FC = () => {
  const { siteId } = useParams();

  // here we retrieve the last segment of the current path to understand which category of documentTypes the user is browsing
  const location = useLocation();
  const segments = location.pathname.split("/");
  const last = segments.pop() || segments.pop();
  const requirementSubject = last as RequirementSubject;

  return (
    <RequirementsView
      useViewModel={useSiteRequirementsViewModel(requirementSubject)}
      requirementSubject={requirementSubject}
      siteId={siteId}
      namespace="sites"
      showSiteSelection={false}
      permissions={{
        add: Permission.Sites_AddRequirement,
        edit: Permission.Sites_EditRequirement,
        delete: Permission.Sites_DeleteRequirement,
      }}
      hasTemplate={true}
      isPropagable={false}
    />
  );
};

export default SiteRequirementsView;
