import { Permission } from "../components/Permissions/Permissions";

export const commonSettingsFallbackRoutes = [
  {
    path: "settings/access/roles",
    permissions: [Permission.Settings_ShowRoles],
  },
  {
    path: "settings/access/users",
    permissions: [Permission.Settings_ShowUsers],
  },
  {
    path: "settings/webhooks",
    permissions: [Permission.Settings_EditWebHooks],
  },
  {
    path: "settings/custom-fields",
    permissions: [Permission.Settings_EditCustomFields],
  },
];

export const getSettingsFallbackRoutes = (currentRoute) => {
  let fallbackRoutes = [...commonSettingsFallbackRoutes];

  fallbackRoutes = fallbackRoutes.filter(
    (route) => route.path !== `settings/${currentRoute}`,
  );

  return fallbackRoutes;
};
