import { Box, Grid, GridItem } from "@chakra-ui/react";

const ContentLayout = ({ children, action }: ContentLayoutProps) => {
  const templateAreas = action === undefined ? `"main"` : `"main action"`;
  const columnsWidth = action === undefined ? "1fr" : "1fr 55px";
  const rowsHeight = "1fr";

  return (
    <Grid
      position={"relative"}
      templateAreas={templateAreas}
      gridTemplateRows={rowsHeight}
      gridTemplateColumns={columnsWidth}
    >
      <GridItem bg="white" area={"main"}>
        {children}
      </GridItem>
      {action !== undefined && (
        <GridItem area="action">
          <Box>{action}</Box>
        </GridItem>
      )}
    </Grid>
  );
};

interface ContentLayoutProps {
  children: React.ReactNode;
  action?: React.ReactNode;
}

export default ContentLayout;
