import UseCase from '../../../core/app/usecase';
import ResourceConversionRepository from "../../repositories/resourceConversionRepository";
import {ConversionType} from "../../../presentation/hooks/Resources/useResourceConversionHook";

type Args = {
  companyId: string;
  baseType: ConversionType,
  twinType: ConversionType,
  records: { baseCustomFieldKey: string; twinCustomFieldKey: string }[];
};
/**
 * Returns the rename model object.
 */

class UpsertCustomFieldMapRecord implements UseCase<Args, void> {
  constructor(private repository: ResourceConversionRepository) {}

  async Call({ companyId, baseType, twinType, records }: Args): Promise<void> {
    await this.repository.upsertCustomFieldMapRecords(companyId, baseType, twinType, records);
  }
}

export default UpsertCustomFieldMapRecord;
