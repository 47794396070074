import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast
} from "@chakra-ui/react";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import DocumentType from "../../../domain/entities/documentType";
import { DocumentTypeCategory } from "../../../domain/entities/documentTypeCategory.enum";
import { COLORS } from "../../assets/theme/colors";
import ActionBar from "../../components/Common/ActionBar";
import ActionBarItem from "../../components/Common/ActionBarItem";
import { ErrorBanner } from "../../components/Common/alerts/ErrorBanner";
import { Permission } from "../../components/Permissions/Permissions";
import RenderIf from "../../components/Permissions/RenderIf";
import { useDocumentTypesListViewModels } from "../../hooks/DocumentType/useDocumentTypesListViewModels";
import ContentLayout from "../../layout/ContentLayout";
import { PermissionCheck } from "../../providers/Auth0JWTProvider";
import { DeleteActionAlert } from "../Common/DeleteActionAlert";
import SearchInput from "../Common/SearchInput";
import CompanyDocTypesTableView from "./CompanyDocTypesTableView";
import { EditDocumentTypeView } from "./EditDocumentTypeView";

interface CompanyDocumentTypesView {
  permissions: {
    editDocumentTypes: Permission;
    deleteDocumentTypes: Permission;
}
}

const CompanyDocumentTypesView = ({permissions}: CompanyDocumentTypesView) => {
  const { t } = useTranslation();

  // here we retrieve the last segment of the current path to understand which category of documentTypes the user is browsing
  const location = useLocation();
  const segments = location.pathname.split("/");
  const last = segments.pop();
  const documentCategory: DocumentTypeCategory = last as DocumentTypeCategory;
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    DocumentType | undefined
  >(undefined);
  const [showAskConfirmDelete, setShowAskConfirmDelete] =
    useState<boolean>(false);
  const [showEditGroup, setShowEditGroup] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [deleteDocumentTypeIsLoading, setDeleteDocumentTypeIsLoading] =
    useState<boolean>(false);
  const {
    error,
    documentTypes,
    documentTypesHasNextPage,
    documentTypesFetchNextPage,
    documentTypeFetching,
    systemDocumentTypes,
    systemDocumentTypesHasNextPage,
    systemDocumentTypesFetchNextPage,
    systemDocumentTypesFetching,
    deleteDocumentType,
    createDocumentType,
    updateDocumentType,
    upsertRenameModel,
    allTags,
    updateTag,
    tagRenameError,
    renameModels,
    setGetRenameModels,
    renameModelsFetching,
    updateFilter,
    resetFilters,
    filters,
    sort,
    setSort,
    filtersSystem,
    sortSystem,
    setSortSystem,
    target,
    setTarget,
    setSearchSystem,
    setSearch,
  } = useDocumentTypesListViewModels(
    documentCategory,
    selectedDocumentType?.id,
  );

  const sanitizedHtmlContent = DOMPurify.sanitize(
    t(`documentTypes.${documentCategory}Desc`, {
      ns: "documents",
      color: COLORS.sikuroBlue,
    }),
  );
  useEffect(() => {       
    resetFilters();
  }, [documentCategory, resetFilters])

  const askConfirmDelete = (documentType: DocumentType) => {
    setSelectedDocumentType(documentType);
    setShowAskConfirmDelete(true);
  };
  const cancelConfirmDelete = () => {
    setShowAskConfirmDelete(false);
    setSelectedDocumentType(undefined);
  };
  const confirmDelete = async () => {
    setDeleteDocumentTypeIsLoading(true);
    await deleteDocumentType(selectedDocumentType);
    cancelConfirmDelete();
    setDeleteDocumentTypeIsLoading(false);
  };

  const openEditModal = (documentType: DocumentType, isUpdate?: boolean) => {
    setSelectedDocumentType(documentType);
    setShowEditGroup(true);
    setGetRenameModels(true);
    setIsUpdate(isUpdate);
  };

  const onClose = () => {
    setShowEditGroup(false);
    setGetRenameModels(false);
    setSelectedDocumentType(undefined);
  };
  const toast = useToast();

  useEffect(() => {
    if (tagRenameError) {
      toast({
        title: t(tagRenameError, { ns: "common" }),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [tagRenameError]);

  return (
    <ContentLayout
      action={
        <ActionBar>
          <RenderIf permissions={permissions.editDocumentTypes}  check={PermissionCheck.All}>
            {tabIndex == 1 && (
              <ActionBarItem
                icon={FiPlus}
                description={t("createDocumentType", { ns: "documents" })}
                onClick={() => (setShowEditGroup(true), setIsUpdate(false))}
              />
            )}
          </RenderIf>
        </ActionBar>
      }
    >
      <Flex
        flex={1}
        h="100%"
        width="calc(100vw - 198px)"
        paddingTop={10}
        paddingLeft={10}
        paddingBottom={10}
        paddingRight={3}
        textAlign="center"
        flexDirection="column"
        alignItems="start"
        justifyContent="start"
      >
        {error && <ErrorBanner text={error?.message} onClose={console.log} />}

        <Text
          fontSize="xl"
          textColor={COLORS.sikuroBlue}
          fontWeight="bold"
          textAlign="left"
        >
          {t(`documentTypes.${documentCategory}`, { ns: "documents" })}
        </Text>
        <Text
          fontSize={16}
          marginTop={3}
          marginBottom={5}
          dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
          textAlign="left"
        />
        <Tabs
          width="calc(100vw - 198px)"
          onChange={(index) => {
            setTabIndex(index);
            resetFilters();
          }}
        >
          <TabList>
            <Tab
              onClick={() => {
                setTarget("system");
              }}
              width={"100%"}
              textAlign={"center"}
            >
              {t("documentTypes.system", { ns: "documents" })}
            </Tab>
            <Tab
              onClick={() => {
                setTarget("custom");
              }}
              width={"100%"}
              textAlign={"center"}
            >
              {t("documentTypes.custom", { ns: "documents" })}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel paddingLeft={0} paddingRight={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
                <SearchInput onSearch={setSearchSystem} />
              </Flex>
              <CompanyDocTypesTableView
                documentTypes={systemDocumentTypes}
                documentTypesHasNextPage={systemDocumentTypesHasNextPage}
                documentTypesFetchNextPage={systemDocumentTypesFetchNextPage}
                documentTypeFetching={systemDocumentTypesFetching}
                allTags={allTags}
                openEditModal={(docTypes) => openEditModal(docTypes)}
                askConfirmDelete={askConfirmDelete}
                onUpdate={updateTag}
                target={"system"}
                updateFilters={updateFilter}
                filters={filtersSystem}
                setSort={setSortSystem}
                sort={sortSystem}
                permissions={permissions}
              />
            </TabPanel>
            <TabPanel paddingLeft={0} paddingRight={0}>
              <Flex justifyContent={"end"} alignItems="center" w={"100%"}>
                <SearchInput onSearch={setSearch} />
              </Flex>
              <CompanyDocTypesTableView
                documentTypes={documentTypes}
                documentTypesHasNextPage={documentTypesHasNextPage}
                documentTypesFetchNextPage={documentTypesFetchNextPage}
                allTags={allTags}
                documentTypeFetching={documentTypeFetching}
                openEditModal={(docTypes) => openEditModal(docTypes, true)}
                askConfirmDelete={askConfirmDelete}
                onUpdate={updateTag}
                target={"custom"}
                updateFilters={updateFilter}
                filters={filters}
                setSort={setSort}
                sort={sort}
                permissions={permissions}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <DeleteActionAlert
        isLoading={deleteDocumentTypeIsLoading}
        onConfirm={confirmDelete}
        onCancel={cancelConfirmDelete}
        mainTitle={t("warning", { ns: "common" })}
        title={t("confirmDeleteDocumentType", { ns: "documents" })}
        leftButtonText={t("confirm", { ns: "common" })}
        rightButtonText={t("cancel", { ns: "common" })}
        isOpen={showAskConfirmDelete}
      >
        <p>
          {t("name", { ns: "documents" })} {selectedDocumentType?.name}
        </p>
      </DeleteActionAlert>

      {showEditGroup && (
        <EditDocumentTypeView
          tags={allTags}
          documentType={selectedDocumentType}
          handleDocumentType={
            selectedDocumentType ? updateDocumentType : createDocumentType
          }
          handleRenameModels={(renameModel) =>
            upsertRenameModel(renameModel, selectedDocumentType.id)
          }
          onClose={onClose}
          updateTag={(value) => (updateTag(value), setShowEditGroup(false))}
          renameModels={renameModels}
          renameModelsFetching={renameModelsFetching}
          target={target}
          isUpdate={isUpdate}
        />
      )}
    </ContentLayout>
  );
};

export default CompanyDocumentTypesView;
