import { Flex, Icon, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import TableColumnHeader from "../../components/Common/table/TableColumnHeader";
import ColumnFilterComponent, {
  ColumnFilterType,
} from "../../components/Common/table/ColumnFilterComponent";
import { GetSupplierEvaluationsFilter } from "../../../domain/repositories/dashboardRepository";
import { SortMeta } from "../../../domain/entities/interfaces/paginatedResults";
import { DashboardReport } from "../../../domain/entities/dasboardExpiringSupplierEvaluation";
import { DashboardCompanyExpiringDocument } from "../../../domain/entities/dashboardCompanyExpiringDocument";
import { DashboardSupplierInfo } from "../../../domain/entities/dashboardSupplierInfo";
import { DashboardUnevaluatedDocument } from "../../../domain/entities/dashboardUnevaluatedDocuments";
import { useNavigate } from "react-router-dom";
import { TbBuildingSkyscraper, TbCrane } from "react-icons/tb";
import InfiniteTable from "../../components/Common/table/InfiniteTable";
import ToolIcon from "../../components/Common/icons/ToolIcon";
import VehicleIcon from "../../components/Common/icons/VehicleIcon";
import MachineIcon from "../../components/Common/icons/MachineIcon";
import WorkerIcon from "../../components/Common/icons/WorkerIcon";
import ChemicalIcon from "../../components/Common/icons/ChemicalIcon";
import { formatDateBasedOnLanguage } from "../../../utils";

type DashboardTableWidgetViewProps = {
  items: DashboardItem[];
  isFetching: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  columns: TableColumn[];
  sort: SortMeta;
  setSort: (sort: SortMeta) => void;
  widget: string;
  filterFields?: GetSupplierEvaluationsFilter;
  updateFilters?: (field: string, value: string | string[]) => void;
  canFilter?: boolean;
  daysSelected?: number;
};

type DashboardItem =
  | DashboardUnevaluatedDocument
  | DashboardSupplierInfo
  | DashboardCompanyExpiringDocument
  | DashboardReport;

type TableColumn = {
  field: string;
  type: string;
  label?: string;
};

const DashboardTableWidgetView = ({
  items,
  isFetching,
  hasNextPage,
  fetchNextPage,
  columns,
  sort,
  setSort,
  widget,
  filterFields,
  updateFilters,
  daysSelected,
}: DashboardTableWidgetViewProps) => {
  const navigate = useNavigate();
  const filterReqResource: GetSupplierEvaluationsFilter = filterFields || {
    site: "",
  };

  const navigationPaths = {
    //(1 widget)
    unevaluatedDocuments: (item) =>
      navigate(`/sites/${item.id}/documents/siteRequirements/resource`, {
        state: { fromPrevUrl: "unevaluated" },
      }),
    //Evaluation expiration (2 widget)
    expiringEvaluations: (item) =>
      navigate(
        `/sites/${item.id}/documents/siteRequirements/supplier?daysSelected=${daysSelected}`,
        {
          state: { fromPrevUrl: "ASC" },
        },
      ),
    //Documents expiration of single resource (3 widget)
    expiringDocumentsResource: (item) => {
      navigate(`/resources/${item.resourceType}s/${item.resourceId}/details`);
    },
    //Documents expiration of my company (4 widget)
    expiringCompanyDocuments: (item) => {
      navigate("/company/general-documents");
    },
    //Documents expiration of single resource on working site (5 widget)
    expiringSupplierResources: (item) => {
      const itemResource =
        item.resourceType === "company" ? "companie" : item.resourceType;
      navigate(
        `/workingsites/${item.siteId}/resources/${itemResource}s/${item.resourceId}`,
      );
    },
    //Documents expiration on working site (6 widget)
    expiringSupplierDocuments: (item) => {
      navigate(`/workingsites/${item.siteId}/documents`, {
        state: { fromPrevUrl: item.resourceType },
      });
    },
    cseAuthorizations: (item) => {
      const itemResource =
        item.resourceType === "company" ? "companie" : item.resourceType;
      navigate(`/sites/${item.siteId}/approve/${itemResource}s`);
    },
    invitedSupplier: (item) => {
      if (item.isOwnedSite) {
        navigate(`/sites/${item.siteId}/suppliers?selected=invited`);
      } else {
        navigate(`/workingsites/${item.siteId}/subcontractors?selected=invited`);
      }
    },
  };

  const handleNavigation = (item) => {
    const navigateTo = navigationPaths[widget];
    if (navigateTo) {
      navigateTo(item);
    }
  };

  const resourceIcon = (type: string) => {
    switch (type) {
      case "site":
        return TbCrane;
      case "tool":
        return ToolIcon;
      case "machine":
        return MachineIcon;
      case "company":
        return TbBuildingSkyscraper;
      case "vehicle":
        return VehicleIcon;
      case "worker":
        return WorkerIcon;
      case "chemical":
        return ChemicalIcon;
      default:
        return undefined;
    }
  };
  const renderCellContent = (column: TableColumn, item: any) => {
    if (column.type === "date") {
      return item[column.field] ? formatDateBasedOnLanguage(item[column.field]) : "";
    }

    if (column.field === "resourceType") {
      return item ? <Icon as={resourceIcon(item[column.field])} /> : null;
    }

    return item ? item[column.field] : null;
  };


  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        alignItems="start"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="10px"
        width="100%"
        position="relative"
        overflow="auto"
        height="100%"
        id="table-container"
      >
        <InfiniteTable
          autosize
          tableId={`dashboard-table-widget-${widget}`}
          infiniteScroll={{
            dataLength: items.length,
            hasNextPage,
            fetchNextPage,
          }}
          isLoading={isFetching}
          maxHeight="15rem"
        >
          <Thead>
            <Tr>
              {columns?.map((column) => (
                <Th
                  key={column.field}
                  width={column.field === "resourceType" ? 18 : undefined}
                >
                  <TableColumnHeader
                    text={column.label ?? ""}
                    filter={
                      column.field === "siteName"
                        ? {
                            component: (
                              <ColumnFilterComponent
                                type={column.type as ColumnFilterType}
                                value={filterReqResource[column.field]}
                                updateFilter={(value) =>
                                  updateFilters(column.field, value as string)
                                }
                                namespace="sites"
                              />
                            ),
                            isActive: !!filterReqResource[column.field],
                          }
                        : null
                    }
                    sort={
                      column.field !== "resourceType" && {
                        handler: (direction) =>
                          setSort({ field: column.field, direction }),
                        direction:
                          sort?.field === column.field ? sort.direction : null,
                      }
                    }
                  />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {items?.map((item) => (
              <Tr
                cursor={"pointer"}
                key={Math.random()}
                onClick={() => handleNavigation(item)}
              >
                {columns?.map((column) => (
                  <Td
                    key={column.field}
                    width={column.field === "resourceType" ? 18 : undefined}
                  >
                    {renderCellContent(column, item)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </InfiniteTable>
      </Flex>
    </Flex>
  );
};

export default DashboardTableWidgetView;
